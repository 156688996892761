import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageData from 'components/Page';
import AuthContext from 'context/auth';
import { PATHS, SESSION_EXPIRED_QUERY_PARAM } from 'variables';

const Private = ({
  component: Component,
  page: Page,
  path,
  title,
  render,
  componentStyle,
  heading,
  link,
  sections,
  user,
}) => {
  const {
    state: { isAuthenticated, sessionExpired },
  } = useContext(AuthContext);

  return (
    <Route
      render={() =>
        isAuthenticated ? (
          <Route path={path}>
            <Page
              component={Component}
              title={title}
              render={render}
              componentStyle={componentStyle}
              heading={heading}
              link={link}
              sections={sections}
              user={user}
            />
          </Route>
        ) : (
          <Redirect
            to={{
              pathname: `/${PATHS.login}`,
              ...(sessionExpired && { search: SESSION_EXPIRED_QUERY_PARAM }),
            }}
          />
        )
      }
    />
  );
};

Private.defaultProps = {
  component: () => null,
  page: PageData,
  componentStyle: {},
  heading: '',
  link: '/',
  sections: {},
  user: null,
};

Private.propTypes = {
  component: PropTypes.func,
  page: PropTypes.func,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  render: PropTypes.string.isRequired,
  componentStyle: PropTypes.object,
  heading: PropTypes.string,
  link: PropTypes.string,
  user: PropTypes.string,
  sections: PropTypes.object,
};

export default Private;
