import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Body } from '@sumup/circuit-ui';
import { filter, forEach, keys, map, size } from 'lodash';
import EmptyState from 'components/EmptyState';
import ListItem from 'components/UserAdministration/components/ListItem';
import { PERMISSIONS_TREE_MAP } from 'components/UserAdministration/constants';
import { SpinnerWrapper } from 'components/UserAdministration/UserAdministrationStyled';
import { ReactComponent as NewLineArrow } from 'assets/new-line-arrow.svg';
import {
  InnerItems,
  InnerItemWrapper,
  TreeBottom,
} from './PermissionsTreeStyled';

const listItemStyle = { margin: '2px 0' };
const thirdLevelItemsStyle = { marginLeft: 32 };

const PermissionsTree = ({
  createMode,
  editMode,
  tree,
  moduleNames,
  permissions: { modules, sections, actions },
  loading,
  error,
  emptyStateText,
  onModuleSelect,
  onSectionSelect,
  onActionSelect,
}) => {
  const clearTreeChildren = (name, collection) => {
    const newCollection = { ...collection };

    forEach(keys(collection), (path) => {
      if (path.indexOf(name) >= 0) {
        newCollection[path] = false;
      }
    });

    return newCollection;
  };

  const handleModuleClick = ({ moduleName }) => {
    if (modules[moduleName]) {
      const newSelectedModules = { ...modules };
      newSelectedModules[moduleName] = false;

      onModuleSelect(newSelectedModules);

      const newSelectedSections = clearTreeChildren(moduleName, sections);
      onSectionSelect(newSelectedSections);

      const newSelectedActions = clearTreeChildren(moduleName, actions);
      onActionSelect(newSelectedActions);
    } else {
      onModuleSelect({ ...modules, [moduleName]: true });
    }
  };

  const handleSectionClick = ({ sectionName }) => {
    const newSelectedSection = sectionName;

    if (sections[newSelectedSection]) {
      const newSelectedSections = { ...sections };
      newSelectedSections[newSelectedSection] = false;

      onSectionSelect(newSelectedSections);

      const newSelectedActions = clearTreeChildren(newSelectedSection, actions);
      onActionSelect(newSelectedActions);
    } else {
      onSectionSelect({
        ...sections,
        [newSelectedSection]: true,
      });
    }
  };

  const handleActionClick = ({ actionName }) => {
    const newSelectedAction = actionName;

    if (actions[newSelectedAction]) {
      const newSelectedActions = { ...actions };
      newSelectedActions[newSelectedAction] = false;

      onActionSelect(newSelectedActions);
    } else {
      onActionSelect({
        ...actions,
        [newSelectedAction]: true,
      });
    }
  };

  if (loading && !error) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const readonly = !createMode && !editMode;
  if (readonly && !size(moduleNames) && !error) {
    return <EmptyState text={emptyStateText} centerText />;
  }

  const modulesToShow = filter(
    moduleNames,
    (moduleName) => !readonly || modules[moduleName]
  );

  const sectionsToShow = ({ moduleName }) =>
    filter(
      keys(tree[moduleName]),
      (sectionName) => !readonly || sections[sectionName]
    );

  const actionsToShow = ({ moduleName, sectionName }) =>
    filter(
      keys(tree[moduleName][sectionName]),
      (actionName) => !readonly || actions[actionName]
    );

  return (
    <>
      {map(modulesToShow, (moduleName, moduleIndex) => (
        <>
          <ListItem
            key={`${moduleName}-${moduleIndex}`}
            readonly={readonly}
            style={listItemStyle}
            checked={modules[moduleName]}
            onClick={() => handleModuleClick({ moduleName })}
          >
            <Body noMargin variant="highlight" size="one">
              {PERMISSIONS_TREE_MAP[moduleName]}
            </Body>
          </ListItem>
          {modules[moduleName] && (
            <InnerItems>
              {map(
                sectionsToShow({ moduleName }),
                (sectionName, sectionIndex) => (
                  <>
                    <InnerItemWrapper
                      key={`${sectionName}-${sectionIndex}`}
                      itemIndex={sectionIndex}
                    >
                      {sectionIndex === 0 && <NewLineArrow />}
                      <ListItem
                        readonly={readonly}
                        style={listItemStyle}
                        checked={sections[sectionName]}
                        onClick={() => handleSectionClick({ sectionName })}
                      >
                        <Body noMargin variant="highlight" size="one">
                          {PERMISSIONS_TREE_MAP[sectionName]}
                        </Body>
                      </ListItem>
                    </InnerItemWrapper>
                    {sections[sectionName] && (
                      <InnerItems style={thirdLevelItemsStyle}>
                        {map(
                          actionsToShow({ moduleName, sectionName }),
                          (actionName, actionIndex) => (
                            <InnerItemWrapper
                              key={`${actionName}-${actionIndex}`}
                              itemIndex={actionIndex}
                            >
                              {actionIndex === 0 && <NewLineArrow />}
                              <ListItem
                                readonly={readonly}
                                style={listItemStyle}
                                checked={actions[actionName]}
                                onClick={() =>
                                  handleActionClick({
                                    actionName,
                                  })
                                }
                              >
                                <Body noMargin variant="highlight" size="one">
                                  {PERMISSIONS_TREE_MAP[actionName]}
                                </Body>
                              </ListItem>
                            </InnerItemWrapper>
                          )
                        )}
                      </InnerItems>
                    )}
                  </>
                )
              )}
            </InnerItems>
          )}
        </>
      ))}
      <TreeBottom />
    </>
  );
};

PermissionsTree.defaultProps = {
  createMode: false,
  editMode: false,
  loading: true,
  error: '',
  emptyStateText: 'No permissions have been selected yet.',
};

PermissionsTree.propTypes = {
  createMode: PropTypes.bool,
  editMode: PropTypes.bool,
  tree: PropTypes.object.isRequired,
  moduleNames: PropTypes.array.isRequired,
  permissions: PropTypes.shape({
    modules: PropTypes.object,
    sections: PropTypes.object,
    actions: PropTypes.object,
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  emptyStateText: PropTypes.string,
  onModuleSelect: PropTypes.func.isRequired,
  onSectionSelect: PropTypes.func.isRequired,
  onActionSelect: PropTypes.func.isRequired,
};

export default PermissionsTree;
