import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import moment from 'moment';
import { Body, spacing } from '@sumup/circuit-ui';
import { DATE_FORMATS } from 'variables';
import {
  Card,
  CardContent,
} from 'components/ScheduledPayments/components/ScheduleDetailsStyled';
import {
  MANDATE_STATUS_CANCELLED,
  MANDATE_STATUS_COMPLETED,
  MANDATE_STATUS_REJECTED,
  STATUS_MAP,
  TYPE_LABEL,
} from '../constants';

const CardContentColumn = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 150px;

    > *:not(:first-child) {
      margin-top: ${theme.spacings.bit};
    }
  `
);

const StatusCell = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MandateDetailsCard = ({ mandate, fullWidth }) => {
  const {
    id,
    frequency,
    status,
    created_at: createdAt,
    updated_at: updatedAt,
    cancelled_at: cancelledAt,
  } = mandate;

  const { label: statusLabel, icon: statusIcon } = STATUS_MAP[status];

  const isCancelled = status === MANDATE_STATUS_CANCELLED && cancelledAt;
  const isRejected = status === MANDATE_STATUS_REJECTED && updatedAt;
  const isCompleted = status === MANDATE_STATUS_COMPLETED && updatedAt;

  return (
    <Card fullWidth={fullWidth}>
      <div>
        <Body noMargin size="one" variant="highlight">
          Mandate information
        </Body>
      </div>
      <CardContent flexDirection="row">
        <CardContentColumn>
          {statusLabel ? (
            <Body noMargin size="two" variant="highlight">
              Status
            </Body>
          ) : null}
          <Body noMargin size="two" variant="highlight">
            Reference/ID
          </Body>
          {createdAt && (
            <Body noMargin size="two" variant="highlight">
              Valid from
            </Body>
          )}
          {isCompleted && (
            <Body noMargin size="two" variant="highlight">
              Ended on
            </Body>
          )}
          {isCancelled && (
            <Body noMargin size="two" variant="highlight">
              Cancelled at
            </Body>
          )}
          {isRejected && (
            <Body noMargin size="two" variant="highlight">
              Rejected at
            </Body>
          )}
          {frequency && (
            <Body noMargin size="two" variant="highlight">
              Frequency
            </Body>
          )}
        </CardContentColumn>
        <CardContentColumn>
          {statusLabel ? (
            <StatusCell>
              {statusIcon}
              <Body
                noMargin
                size="two"
                variant="highlight"
                css={spacing({ left: 'bit' })}
              >
                {statusLabel}
              </Body>
            </StatusCell>
          ) : null}
          <Body noMargin size="two">
            {id}
          </Body>
          {createdAt && (
            <Body noMargin size="two">
              {moment(createdAt).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          {isCompleted && (
            <Body noMargin size="two">
              {moment(updatedAt).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          {isCancelled && (
            <Body noMargin size="two">
              {moment(cancelledAt).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          {isRejected && (
            <Body noMargin size="two">
              {moment(updatedAt).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          <Body noMargin size="two">
            {TYPE_LABEL[frequency]}
          </Body>
        </CardContentColumn>
      </CardContent>
    </Card>
  );
};

MandateDetailsCard.defaultProps = {
  fullWidth: true,
};

MandateDetailsCard.propTypes = {
  mandate: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
};
