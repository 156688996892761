export const IBAN_PREFIXES = {
  GB: 'GB',
};

export const FR_SOLE_TRADER_SUFFIX = 'EI';
export const ACCOUNT_HOLDER_REGEX = /^[a-zA-Z0-9-/?:().,+#=!%&<>;{@ "']*$/;
export const SORT_CODE_REGEX =
  /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/;
export const ACCOUNT_NUMBER_REGEX = /^[0-9]{8}$/;
export const IBAN_REGEX = /^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{11,30}$/;
export const PAYMENT_REFERENCE_REGEX = /^[a-zA-Z0-9-/?:().,+#=!%&<>;{@ "']*$/;

// Countries in which we offer business account and are in the EEA SEPA zone
export const EEA_SEPA_COUNTRIES = {
  AT: true,
  BE: true,
  BG: true,
  HR: true,
  CY: true,
  CZ: true,
  DK: true,
  EE: true,
  FI: true,
  FR: true,
  DE: true,
  GR: true,
  HU: true,
  IS: true,
  IE: true,
  IT: true,
  LV: true,
  LI: true,
  LT: true,
  LU: true,
  MT: true,
  NL: true,
  NO: true,
  PL: true,
  PT: true,
  RO: true,
  SK: true,
  SI: true,
  ES: true,
  SE: true,
};

export const IBAN_LETTER_DICTIONARY = {
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 18,
  J: 19,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  O: 24,
  P: 25,
  Q: 26,
  R: 27,
  S: 28,
  T: 29,
  U: 30,
  V: 31,
  W: 32,
  X: 33,
  Y: 34,
  Z: 35,
};

export const EUR_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH = 140;
export const UK_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH = 18;
export const OPS_TRANSFER_ADDITIONAL_INFO_MAX_LENGTH = 140;
export const OPS_TRANSFER_COMMENT_MAX_LENGTH = 255;
