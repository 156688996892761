import {
  AUTH_TRANSACTION_STATUS,
  PRE_AUTH_TRANSACTION_STATUS,
} from '../components/CancelAuthorizations/constants';

const getPreAuthInfo = (transactionStatus) => {
  if (transactionStatus === PRE_AUTH_TRANSACTION_STATUS) {
    return 'Yes';
  }
  if (transactionStatus === AUTH_TRANSACTION_STATUS) {
    return 'No';
  }
  return '';
};

export default getPreAuthInfo;
