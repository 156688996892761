import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';
import { css } from '@emotion/react';

export const TooltipPivot = styled(Body)(
  ({ theme }) => css`
    margin: ${theme.spacings.bit};
    border-radius: ${theme.borderRadius.bit};
    background: ${theme.colors.n100};
    padding: ${theme.spacings.byte} ${theme.spacings.bit};
  `
);

export const TooltipContent = styled('div')`
  width: 245px;
`;

export const MissingCode = styled('span')`
  color: var(--Special-colors-n40, #ccc);
`;
