import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledLoadingButton,
  StyledIssueCardTooltip,
} from 'components/Account/AccountStyled';

export const IssueCardTooltip = ({
  disabled,
  isLoading,
  cardId,
  onIssueCard,
}) => {
  const button = (
    <StyledLoadingButton
      isLoading={isLoading}
      loadingLabel="Loading"
      variant="primary"
      disabled={disabled}
      onClick={onIssueCard}
    >
      Issue new card
    </StyledLoadingButton>
  );

  return disabled ? (
    <StyledIssueCardTooltip
      position="left"
      pivot={button}
      content={`To issue a new card, you must first permanently block the currently
          active one ${cardId && `(ID: ${cardId})`}`}
    />
  ) : (
    button
  );
};

IssueCardTooltip.defaultProps = {
  cardId: null,
};

IssueCardTooltip.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  cardId: PropTypes.number,
  onIssueCard: PropTypes.func.isRequired,
};
