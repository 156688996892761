import { actionTypes } from 'variables';

export const setGroupsLoading = (groupsLoading) => ({
  type: actionTypes.SET_GROUPS_LOADING,
  payload: { groupsLoading },
});

export const setGroupLoading = (groupLoading) => ({
  type: actionTypes.SET_GROUP_LOADING,
  payload: { groupLoading },
});

export const selectGroup = ({ name }) => ({
  type: actionTypes.SELECT_GROUP,
  payload: { name },
});

export const setNewGroupName = ({ name }) => ({
  type: actionTypes.SET_NEW_GROUP_NAME,
  payload: { name },
});

export const setNewGroupApp = ({ app }) => ({
  type: actionTypes.SET_NEW_GROUP_APP,
  payload: { app },
});

export const loadAppsSuccess = ({ data }) => ({
  type: actionTypes.LOAD_APPS_SUCCESS,
  payload: { data },
});

export const loadAppsError = (error) => ({
  type: actionTypes.LOAD_APPS_ERROR,
  payload: { error },
});

export const clearNewGroupData = () => ({
  type: actionTypes.CLEAR_NEW_GROUP_DATA,
});

export const setNewGroupModules = ({ modules }) => ({
  type: actionTypes.SET_NEW_GROUP_MODULES,
  payload: { modules },
});

export const setNewGroupSections = ({ sections }) => ({
  type: actionTypes.SET_NEW_GROUP_SECTIONS,
  payload: { sections },
});

export const setNewGroupActions = ({ actions }) => ({
  type: actionTypes.SET_NEW_GROUP_ACTIONS,
  payload: { actions },
});

export const setSelectedGroupModules = ({ modules }) => ({
  type: actionTypes.SET_SELECTED_GROUP_MODULES,
  payload: { modules },
});

export const setSelectedGroupSections = ({ sections }) => ({
  type: actionTypes.SET_SELECTED_GROUP_SECTIONS,
  payload: { sections },
});

export const setSelectedGroupActions = ({ actions }) => ({
  type: actionTypes.SET_SELECTED_GROUP_ACTIONS,
  payload: { actions },
});

export const setSelectedGroupNewName = ({ name }) => ({
  type: actionTypes.SET_SELECTED_GROUP_NEW_NAME,
  payload: { name },
});

export const loadGroupSuccess = ({ data }) => ({
  type: actionTypes.LOAD_GROUP_SUCCESS,
  payload: { data },
});

export const loadGroupError = (error) => ({
  type: actionTypes.LOAD_GROUP_ERROR,
  payload: { error },
});

export const updateGroupSuccess = () => ({
  type: actionTypes.UPDATE_GROUP_SUCCESS,
});

export const updateGroupError = (error) => ({
  type: actionTypes.UPDATE_GROUP_ERROR,
  payload: { error },
});

export const deleteGroupError = (error) => ({
  type: actionTypes.DELETE_GROUP_ERROR,
  payload: { error },
});

export const createGroupError = (error) => ({
  type: actionTypes.CREATE_GROUP_ERROR,
  payload: { error },
});
