import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Headline } from '@sumup/circuit-ui';
import { map, reduce, split, trim } from 'lodash';
import { getClipboardText } from 'services/common';
import { EDITABLE_SECTION_WIDTH, ID_TYPES } from './constants';

const Wrapper = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings.kilo} 20px;
  `
);

const EditableSection = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    margin: ${theme.spacings.mega} 0;
  `
);

const Row = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${theme.spacings.bit};
  `
);

const IdsColumn = styled('div')`
  width: ${EDITABLE_SECTION_WIDTH}px;
  word-break: break-word;
`;

const ResultsColumnRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 38%;

    svg {
      min-width: 24px;
      min-height: 24px;
      margin-right: ${theme.spacings.byte};
    }
  `
);

const StyledHeadline = styled(Headline)`
  text-align: center;
`;

export const splitRawIds = (rawIds = '') =>
  split(rawIds.replace(/\s/g, ','), ',');

export const filterRawIds = (rawIds = [], processedIds = {}) =>
  reduce(
    rawIds,
    (accumulator, text) => {
      const trimmedText = trim(text);

      return trimmedText
        ? {
            ...accumulator,
            [trimmedText]: {
              id: trimmedText,
              result: processedIds[trimmedText]?.result || {},
            },
          }
        : accumulator;
    },
    {}
  );

export const EditableIdsSection = ({
  selectedOption,
  ids,
  setIds,
  disableRunButton,
}) => {
  const [focused, setFocused] = useState(true);
  const [rawIds, setRawIds] = useState('');

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [focused]);

  const handlePaste = (e) => {
    const clipboardText = getClipboardText(e, 'text/plain');

    if (clipboardText) {
      const text = `${rawIds}${clipboardText}`;

      setRawIds(text);
      disableRunButton(!text);
    }
  };

  const handleKeyUp = ({ target: { innerText } }) => {
    setRawIds(innerText);
    disableRunButton(!innerText);
  };

  const handleClick = () => {
    setFocused(true);
  };

  const handleFocus = () => {
    ref.current.innerText = rawIds;
  };

  const handleBlur = () => {
    ref.current.innerText = '';

    const filteredContent = filterRawIds(splitRawIds(rawIds), ids);
    setIds(filteredContent);

    setFocused(false);
  };

  return (
    <Wrapper>
      <StyledHeadline noMargin as="h2" size="three">
        Paste a list of {ID_TYPES[selectedOption?.value]}
      </StyledHeadline>
      <EditableSection
        ref={ref}
        contentEditable={focused}
        onPaste={handlePaste}
        onKeyUp={handleKeyUp}
        onClick={handleClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {!focused &&
          map(ids, ({ id, result = {} }) => (
            <Row key={id}>
              <IdsColumn>{id}</IdsColumn>
              <ResultsColumnRow>
                {result.icon} {result.label}
              </ResultsColumnRow>
            </Row>
          ))}
      </EditableSection>
    </Wrapper>
  );
};

EditableIdsSection.propTypes = {
  selectedOption: PropTypes.object.isRequired,
  ids: PropTypes.object.isRequired,
  setIds: PropTypes.func.isRequired,
  disableRunButton: PropTypes.func.isRequired,
};
