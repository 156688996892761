import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Pagination, spacing } from '@sumup/circuit-ui';
import { PAGE_TITLES } from 'variables';
import Loading from 'components/Loading';
import PageSizeDropdown from 'components/Account/components/PageSizeDropdown';
import DataGrid from 'components/DataGrid';
import { StatusFilter } from 'components/ScheduledPayments/components/StatusFilter';
import { MANDATES_NON_NUMERIC_FIELDS_MAP } from './columns';

const TotalCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

const Filters = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacings.mega};
    height: 32px;

    * {
      max-height: 32px;
    }

    > div {
      display: flex;

      :not(:first-of-type) {
        margin-left: auto;
      }
    }
  `
);

const DirectDebitMandates = ({
  loading,
  columns,
  mandates,
  currentPage,
  totalPages,
  totalCount,
  pageSize,
  statusFilter,
  sort,
  transactionHistoryLoadingForId,
  reloadMandates,
  onPageChange,
  onPageSizeChange,
  onStatusFilterChange,
  onViewDetailsClick,
  onSort,
}) => (
  <>
    <Headline noMargin as="h2" size="two">
      {PAGE_TITLES.DIRECT_DEBITS.heading}
      {totalCount != null && <TotalCount>({totalCount})</TotalCount>}
    </Headline>
    {loading ? (
      <Loading />
    ) : (
      <>
        <Filters>
          <StatusFilter filter={statusFilter} onChange={onStatusFilterChange} />
          <PageSizeDropdown size={pageSize} onSizeChange={onPageSizeChange} />
        </Filters>
        <DataGrid
          columns={columns}
          dataSource={mandates}
          sortable
          centered
          sort={sort}
          nonNumericFields={MANDATES_NON_NUMERIC_FIELDS_MAP}
          noResultsText="No direct debits yet"
          mandateHistoryLoadingForId={transactionHistoryLoadingForId}
          reloadMandates={reloadMandates}
          onViewMandateDetailsClick={onViewDetailsClick}
          onSort={onSort}
        />
        <Pagination
          css={spacing({ top: 'kilo' })}
          label="Direct debit mandates pagination"
          previousLabel="Previous page"
          nextLabel="Next page"
          totalPages={totalPages}
          totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
          currentPage={currentPage}
          onChange={onPageChange}
        />
      </>
    )}
  </>
);

DirectDebitMandates.defaultProps = {
  mandates: [],
  totalCount: null,
  transactionHistoryLoadingForId: null,
};

DirectDebitMandates.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  mandates: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  statusFilter: PropTypes.string.isRequired,
  sort: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  transactionHistoryLoadingForId: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  reloadMandates: PropTypes.func.isRequired,
  onViewDetailsClick: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default DirectDebitMandates;
