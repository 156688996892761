import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Body,
  Popover as CircuitPopover,
  useModal,
  Hr,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { More } from '@sumup/icons';
import { useHttpClient } from 'hooks';
import { map, filter, find, size, isEmpty } from 'lodash';
import Modal from 'components/Modal';
import { ReactComponent as Ellipsis } from 'assets/ellipsis.svg';
import {
  DOWNLOAD_STATEMENT_ACTION,
  MARK_AS_FRAUD_ACTION,
  MARK_FRAUD_REPORT_ACTION,
  UNMARK_FRAUD_REPORT_ACTION,
  EDIT_FRAUD_REPORT_ACTION,
  REQUEST_OPS_TRANSFER_ACTION,
  UNMARK_FRAUD_DIALOG_WIDTH,
  UNMARK_FRAUD_DIALOG_HEIGHT,
  ISSUE_A_RETURN_ACTION,
  CUSTOM_FIELD_NAMES,
} from 'components/Transactions/constants';
import {
  TRANSACTION_IDENTIFIER,
  TRANSACTION_FRAUD_REPORT,
  TRANSACTION_FRAUD_REPORT_IDENTIFIER,
} from 'components/Transaction/fieldsConfig';
import { DEFAULT_CURRENCY_EXPONENT } from 'variables';
import { createUpdateOpsTransfer, getBankTransactionFraud } from 'api';
import DownloadTransferStatementModal from 'components/DownloadStatementModal/DownloadTransferStatementModal';
import RequestTransferModal from 'components/Account/components/BusinessAccount/components/RequestTransferModal';
import { FIELD_NAMES as REQUEST_TRANSFER_FIELD_NAMES } from 'components/Account/components/BusinessAccount/components/RequestTransferModal/constants';
import MarkAsFraudModal from './components/MarkAsFraudModal';
import FraudReportModal from './components/FraudReportModal';
import UnmarkFraudReportModal from './components/UnmarkFraudReportModal';
import IssueReturnModal from './components/IssueReturnModal';

const MORE_BUTTON_HEIGHT = 18;

const ComponentWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CircleButton = styled(More)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${MORE_BUTTON_HEIGHT}px;
    width: ${MORE_BUTTON_HEIGHT}px;
    margin-top: ${theme.spacings.bit};
    border: ${theme.borderWidth.mega} solid ${theme.colors.n500};
    border-radius: ${theme.borderRadius.circle};
    cursor: pointer;
    transform: rotate(90deg);

    svg {
      path {
        fill: ${theme.colors.n700};
      }
    }
  `
);

const PopoverOption = styled('div')(
  ({ disabled }) => css`
    cursor: pointer;
    ${disabled && 'opacity: 0.5 !important;'};

    svg {
      max-width: 16px;
      margin-right: 18px;
    }
  `
);

const POPOVER_WIDTH = 260;

const DIVIDER_WIDTH = 228;

const Popover = styled(CircuitPopover)`
  button {
    width: ${POPOVER_WIDTH}px;

    :focus {
      box-shadow: none;
    }
  }

  > div {
    margin: 6px 0 0 -230px;
  }
`;

const StyledHr = styled(Hr)(
  ({ theme }) => css`
    width: ${DIVIDER_WIDTH}px;
    margin-bottom: -${theme.spacings.byte};
  `
);

const FraudMarker = styled('div')(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: 13px;
    line-height: ${theme.typography.body.two.lineHeight};
    display: flex;
    align-items: center;
    text-align: center;
  `
);

const MARK_AS_FRAUD_ACTION_KEYS = {
  MARK_AS_FRAUD_ACTION: true,
  EDIT_FRAUD_REPORT_ACTION: true,
  UNMARK_FRAUD_REPORT_ACTION: true,
  MARK_FRAUD_REPORT_ACTION: true,
};

const PopoverCell = ({
  rowData,
  popoverOptions,
  ukTransfer,
  balance,
  accountId,
  exponent,
  hasActionAccess,
  hasSectionAccess,
  onMarkAsPotentialFraud,
  onCancelTransaction,
  onChargebackTransaction,
  onMarkBankTransactionFraud,
  onEditBankTransactionFraud,
  onUnmarkBankTransactionFraud,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const httpClient = useHttpClient();

  const { setModal } = useModal();

  const handlePopoverOptionClick = ({ onConfirm, ...info }) => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            cancelText: 'Cancel',
            centered: true,
            ...info,
            disabled: false,
            onConfirm: () =>
              onConfirm(rowData, {
                onCancelTransaction,
                onChargebackTransaction,
              }),
          }}
          onClose={onClose}
        />
      ),
    });
  };

  const handleMarkAsFraud = ({ fraudTypeCode, fraudSubtypeCode }) =>
    onMarkAsPotentialFraud({
      txId: rowData[TRANSACTION_IDENTIFIER.name],
      fraudTypeCode,
      fraudSubtypeCode,
    });

  const handleMarkAsFraudOptionClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <MarkAsFraudModal onConfirm={handleMarkAsFraud} onClose={onClose} />
      ),
    });
  };

  const handleMarkBankTransactionFraud = ({
    type,
    subtype,
    date,
    status,
    recoveredAmount,
    reference,
    details,
  }) => {
    onMarkBankTransactionFraud({
      paymentId: rowData[TRANSACTION_IDENTIFIER.name],
      type,
      subtype,
      date,
      status,
      recoveredAmount,
      reference,
      details,
    });
  };

  const handleMarkFraudOptionClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <FraudReportModal
          record={rowData}
          onConfirm={handleMarkBankTransactionFraud}
          onClose={onClose}
        />
      ),
    });
  };

  const handleEditBankTransactionFraud = ({
    type,
    subtype,
    date,
    status,
    recoveredAmount,
    reference,
    details,
    lastModified,
  }) => {
    onEditBankTransactionFraud({
      paymentId: rowData[TRANSACTION_IDENTIFIER.name],
      type,
      subtype,
      date,
      status,
      recoveredAmount,
      reference,
      details,
      lastModified,
      reportId:
        rowData[TRANSACTION_FRAUD_REPORT.name][
          TRANSACTION_FRAUD_REPORT_IDENTIFIER.name
        ],
    });
  };

  const handleEditBankFraudOptionClick = () => {
    getBankTransactionFraud({
      httpClient,
      txId: rowData[TRANSACTION_IDENTIFIER.name],
      reportId:
        rowData[TRANSACTION_FRAUD_REPORT.name][
          TRANSACTION_FRAUD_REPORT_IDENTIFIER.name
        ],
    }).then(({ data, headers }) => {
      if (!isEmpty(data)) {
        setModal({
          closeButtonLabel: 'Close',
          onClose: () => {},
          // eslint-disable-next-line react/prop-types
          children: ({ onClose }) => (
            <FraudReportModal
              record={rowData}
              report={data}
              lastModified={headers['last-modified']}
              onConfirm={handleEditBankTransactionFraud}
              onClose={onClose}
            />
          ),
        });
      }
    });
  };

  const handleUnmarkBankTransactionFraud = ({
    reportId,
    comment,
    lastModified,
  }) => {
    onUnmarkBankTransactionFraud({
      paymentId: rowData[TRANSACTION_IDENTIFIER.name],
      reportId,
      comment,
      lastModified,
    });
  };

  const handleUnmarkBankFraudOptionClick = () => {
    getBankTransactionFraud({
      httpClient,
      txId: rowData[TRANSACTION_IDENTIFIER.name],
      reportId:
        rowData[TRANSACTION_FRAUD_REPORT.name][
          TRANSACTION_FRAUD_REPORT_IDENTIFIER.name
        ],
    }).then(({ data, headers }) => {
      if (!isEmpty(data)) {
        setModal({
          css: css`
            width: ${UNMARK_FRAUD_DIALOG_WIDTH}px;
            min-width: ${UNMARK_FRAUD_DIALOG_WIDTH}px !important;
            height: ${UNMARK_FRAUD_DIALOG_HEIGHT}px;
          `,
          closeButtonLabel: 'Close',
          preventClose: true,
          // eslint-disable-next-line react/prop-types
          children: ({ onClose }) => (
            <UnmarkFraudReportModal
              reportId={
                rowData[TRANSACTION_FRAUD_REPORT.name][
                  TRANSACTION_FRAUD_REPORT_IDENTIFIER.name
                ]
              }
              lastModified={headers['last-modified']}
              onConfirm={handleUnmarkBankTransactionFraud}
              onClose={onClose}
            />
          ),
        });
      }
    });
  };

  const handleDownloadStatementOptionClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <DownloadTransferStatementModal record={rowData} onClose={onClose} />
      ),
    });
  };

  const handleRequestOpsTransferOptionClick = () => {
    const { bank_data: bankData, total_amount: totalAmount } = rowData || {};
    const {
      bank_account_holder: accountHolder,
      bank_identifier: bankIdentifier,
      bank_account_number: bankAccountNumber,
    } = bankData;
    const { amount, currency } = totalAmount;

    const initialValues = {
      [REQUEST_TRANSFER_FIELD_NAMES.name]: accountHolder,
      [REQUEST_TRANSFER_FIELD_NAMES.amount]: amount ? amount.toString() : null,
      ...(ukTransfer
        ? {
            [REQUEST_TRANSFER_FIELD_NAMES.sort_code]: bankIdentifier,
            [REQUEST_TRANSFER_FIELD_NAMES.account_number]: bankAccountNumber,
          }
        : {
            [REQUEST_TRANSFER_FIELD_NAMES.iban]: bankAccountNumber,
          }),
      [REQUEST_TRANSFER_FIELD_NAMES.show_beneficiary]: true,
    };

    setModal({
      closeButtonLabel: 'Close',
      children: ({ onClose }) => (
        <RequestTransferModal
          initialValues={initialValues}
          transfer={rowData}
          ukTransfer={ukTransfer}
          balance={balance}
          exponent={exponent}
          accountId={accountId}
          currency={currency}
          submitSuccessNotificationHeadline="Transfer requested successfully"
          submitErrorNotificationHeadline="Transfer request unsuccessful"
          submitErrorNotificationBody="Please try again later."
          submitButtonLabel="Request"
          onSubmit={createUpdateOpsTransfer}
          onClose={onClose}
        />
      ),
    });
  };

  const handleIssueAReturnOptionClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      children: ({ onClose }) => (
        <IssueReturnModal transfer={rowData} onClose={onClose} />
      ),
    });
  };

  const togglePopover = () => setPopoverOpen((opened) => !opened);

  const component = (props) => (
    <ComponentWrapper>
      {rowData[TRANSACTION_FRAUD_REPORT.name] ? (
        <FraudMarker>Fraud</FraudMarker>
      ) : null}
      <CircleButton onClick={togglePopover} {...props}>
        <Ellipsis />
      </CircleButton>
    </ComponentWrapper>
  );

  const handleClick = (key, option) => {
    const actionMap = {
      [MARK_AS_FRAUD_ACTION]: handleMarkAsFraudOptionClick,
      [DOWNLOAD_STATEMENT_ACTION]: handleDownloadStatementOptionClick,
      [MARK_FRAUD_REPORT_ACTION]: handleMarkFraudOptionClick,
      [UNMARK_FRAUD_REPORT_ACTION]: handleUnmarkBankFraudOptionClick,
      [EDIT_FRAUD_REPORT_ACTION]: handleEditBankFraudOptionClick,
      [REQUEST_OPS_TRANSFER_ACTION]: handleRequestOpsTransferOptionClick,
      [ISSUE_A_RETURN_ACTION]: handleIssueAReturnOptionClick,
    };

    return actionMap[key]
      ? actionMap[key](option)
      : handlePopoverOptionClick(option.confirmationModalInfo);
  };

  const getActions = () => {
    const { permissions: markAsFraudPermissions = {} } =
      find(popoverOptions, ({ key }) => MARK_AS_FRAUD_ACTION_KEYS[key]) || {};

    const visibleOptions = filter(popoverOptions, ({ permissions, name }) => {
      if (name === CUSTOM_FIELD_NAMES.TRANSACTION_STATEMENT) {
        return hasSectionAccess(permissions);
      }

      return hasActionAccess(permissions);
    });

    return map(
      visibleOptions,
      ({ key, label, title, isDisabled, action, ...option }, index) => {
        const disabled = isDisabled && isDisabled(rowData);
        const dividerIndex = rowData[TRANSACTION_FRAUD_REPORT.name] ? 1 : 0;
        const hasMarkAsFraudAccess = hasActionAccess(markAsFraudPermissions);
        const hasMultipleOptions = size(visibleOptions) > 1;
        const showDivider =
          index === dividerIndex && hasMarkAsFraudAccess && hasMultipleOptions;

        return {
          children: (
            <PopoverOption key={label} disabled={disabled}>
              <Body noMargin size="one">
                {title}
              </Body>
              {showDivider && <StyledHr />}
            </PopoverOption>
          ),
          onClick: () => {
            if (!disabled) {
              if (option.onClick) {
                return option.onClick(httpClient, rowData);
              }

              return handleClick(key, option);
            }

            return null;
          },
        };
      }
    );
  };

  return (
    <Popover
      placement="bottom"
      fallbackPlacements={['bottom', 'top-start']}
      isOpen={popoverOpen}
      actions={getActions()}
      component={component}
      onToggle={togglePopover}
    />
  );
};

PopoverCell.defaultProps = {
  rowData: {},
  ukTransfer: false,
  balance: null,
  accountId: null,
  exponent: DEFAULT_CURRENCY_EXPONENT,
};

PopoverCell.propTypes = {
  popoverOptions: PropTypes.array.isRequired,
  rowData: PropTypes.object,
  ukTransfer: PropTypes.bool,
  balance: PropTypes.number,
  accountId: PropTypes.string,
  exponent: PropTypes.number,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  onMarkAsPotentialFraud: PropTypes.func.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  onChargebackTransaction: PropTypes.func.isRequired,
  onMarkBankTransactionFraud: PropTypes.func.isRequired,
  onEditBankTransactionFraud: PropTypes.func.isRequired,
  onUnmarkBankTransactionFraud: PropTypes.func.isRequired,
};

export default PopoverCell;
