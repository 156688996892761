import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as LinkIcon } from 'assets/link-to-page.svg';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled('a')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacings.byte};
    color: ${theme.colors.p500};
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};
    cursor: pointer;

    svg {
      margin-left: ${theme.spacings.bit};
    }
  `
);

export const LinkCell = ({ component, link }) => {
  const { title, href } = link || {};

  return (
    <div>
      <Wrapper>
        {component}
        {title && href && (
          <StyledLink target="_blank" rel="noreferrer" href={href}>
            {title} <LinkIcon />
          </StyledLink>
        )}
      </Wrapper>
    </div>
  );
};

LinkCell.defaultProps = {
  component: null,
  link: {},
};

LinkCell.propTypes = {
  component: PropTypes.node,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
};
