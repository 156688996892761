import { actionTypes } from 'variables';

export const selectUser = ({ email }) => ({
  type: actionTypes.SELECT_USER,
  payload: { email },
});

export const filterUsers = ({ pattern }) => ({
  type: actionTypes.FILTER_USERS,
  payload: { pattern },
});

export const setGroupsLoading = (groupsLoading) => ({
  type: actionTypes.SET_GROUPS_LOADING,
  payload: { groupsLoading },
});

export const loadGroupsSuccess = ({ data }) => ({
  type: actionTypes.LOAD_GROUPS_SUCCESS,
  payload: { data },
});

export const loadGroupsError = (error) => ({
  type: actionTypes.LOAD_GROUPS_ERROR,
  payload: { error },
});

export const setUsersLoading = (usersLoading) => ({
  type: actionTypes.SET_USERS_LOADING,
  payload: { usersLoading },
});

export const setUserGroupsLoading = (userGroupsLoading) => ({
  type: actionTypes.SET_USER_GROUPS_LOADING,
  payload: userGroupsLoading,
});

export const loadUserGroupsSuccess = ({ data }) => ({
  type: actionTypes.LOAD_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const loadUserGroupsError = (error) => ({
  type: actionTypes.LOAD_USER_GROUPS_ERROR,
  payload: { error },
});

export const createUserError = (error) => ({
  type: actionTypes.CREATE_USER_ERROR,
  payload: { error },
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserError = (error) => ({
  type: actionTypes.UPDATE_USER_ERROR,
  payload: { error },
});

export const deleteUserError = (error) => ({
  type: actionTypes.DELETE_USER_ERROR,
  payload: { error },
});

export const setSelectedUserGroups = ({ groups }) => ({
  type: actionTypes.SET_SELECTED_USER_GROUPS,
  payload: { groups },
});
