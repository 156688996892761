import { actionTypes } from 'variables';

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: { loading },
});

export const setPrimaryCardStatusSuccess = (data) => ({
  type: actionTypes.SET_PRIMARY_CARD_STATUS_SUCCESS,
  payload: data,
});

export const setReplacementCardStatusSuccess = (data) => ({
  type: actionTypes.SET_REPLACEMENT_CARD_STATUS_SUCCESS,
  payload: data,
});

export const setPrimaryCardStatusError = (error) => ({
  type: actionTypes.SET_PRIMARY_CARD_STATUS_ERROR,
  payload: error,
});

export const setReplacementCardStatusError = (error) => ({
  type: actionTypes.SET_REPLACEMENT_CARD_STATUS_ERROR,
  payload: error,
});

export const issueCardSuccess = (data) => ({
  type: actionTypes.ISSUE_CARD_SUCCESS,
  payload: data,
});

export const replaceCardSuccess = (data) => ({
  type: actionTypes.REPLACE_CARD_SUCCESS,
  payload: data,
});

export const issueCardError = (error) => ({
  type: actionTypes.ISSUE_CARD_ERROR,
  payload: error,
});

export const replaceCardError = (error) => ({
  type: actionTypes.REPLACE_CARD_ERROR,
  payload: error,
});

export const clearCardPinsAttemptsSuccess = (data) => ({
  type: actionTypes.CLEAR_PIN_ATTEMPTS_SUCCESS,
  payload: data,
});

export const clearCardPinsAttemptsError = (error) => ({
  type: actionTypes.CLEAR_PIN_ATTEMPTS_ERROR,
  payload: error,
});

export const setIssueCardLoading = (issueCardLoading) => ({
  type: actionTypes.SET_ISSUE_CARD_LOADING,
  payload: { issueCardLoading },
});

export const setReplaceCardLoading = (replaceCardLoading) => ({
  type: actionTypes.SET_REPLACE_CARD_LOADING,
  payload: { replaceCardLoading },
});

export const setPrimaryPsdStatusLoading = (primaryPsdStatusLoading) => ({
  type: actionTypes.SET_PRIMARY_PSD_STATUS_LOADING,
  payload: { primaryPsdStatusLoading },
});

export const setReplacementPsdStatusLoading = (
  replacementPsdStatusLoading
) => ({
  type: actionTypes.SET_REPLACEMENT_PSD_STATUS_LOADING,
  payload: { replacementPsdStatusLoading },
});

export const setCreateBankAccountLoading = (createBankAccountLoading) => ({
  type: actionTypes.SET_CREATE_BANK_ACCOUNT_LOADING,
  payload: { createBankAccountLoading },
});

export const createBankAccountSuccess = (data) => ({
  type: actionTypes.CREATE_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const createBankAccountError = (error) => ({
  type: actionTypes.CREATE_BANK_ACCOUNT_ERROR,
  payload: error,
});

export const setPrimaryPsdStatusSuccess = ({ data, activeCards }) => ({
  type: actionTypes.SET_PRIMARY_PSD_STATUS_SUCCESS,
  payload: { data, activeCards },
});

export const setReplacementPsdStatusSuccess = (data) => ({
  type: actionTypes.SET_REPLACEMENT_PSD_STATUS_SUCCESS,
  payload: data,
});

export const setPrimaryPsdStatusError = (error) => ({
  type: actionTypes.SET_PRIMARY_PSD_STATUS_ERROR,
  payload: error,
});

export const setReplacementPsdStatusError = (error) => ({
  type: actionTypes.SET_REPLACEMENT_PSD_STATUS_ERROR,
  payload: error,
});

export const setLockCardForAuditLoading = (lockCardForAuditLoading) => ({
  type: actionTypes.SET_LOCK_CARD_LOADING,
  payload: { lockCardForAuditLoading },
});

export const lockCardForAuditError = (error) => ({
  type: actionTypes.LOCK_CARD_FOR_AUDIT_ERROR,
  payload: error,
});

export const setPrimaryCardStatusLoading = (primaryCardStatusLoading) => ({
  type: actionTypes.SET_PRIMARY_CARD_STATUS_LOADING,
  payload: { primaryCardStatusLoading },
});

export const setReplacementCardStatusLoading = (
  replacementCardStatusLoading
) => ({
  type: actionTypes.SET_REPLACEMENT_CARD_STATUS_LOADING,
  payload: { replacementCardStatusLoading },
});

export const setTransferToBankLoading = (transferToBankLoading) => ({
  type: actionTypes.SET_TRANSFER_TO_BANK_LOADING,
  payload: { transferToBankLoading },
});

export const setTransferToBankError = (transferToBankError) => ({
  type: actionTypes.TRANSFER_TO_BANK_ERROR,
  payload: { transferToBankError },
});

export const setBalanceLoading = (balanceLoading) => ({
  type: actionTypes.SET_BALANCE_LOADING,
  payload: { balanceLoading },
});

export const setAccountStatus = (data) => ({
  type: actionTypes.SET_ACCOUNT_STATUS,
  payload: { data },
});

export const setAccountStatusLoading = (accountStatusLoading) => ({
  type: actionTypes.SET_ACCOUNT_STATUS_LOADING,
  payload: { accountStatusLoading },
});

export const setAccountStatusError = (error) => ({
  type: actionTypes.ACCOUNT_STATUS_ERROR,
  payload: { error },
});
