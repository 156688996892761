import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { StyledCell } from './DateCellStyled';

const DateCell = ({ value, formatDate, style, clickable, centered }) => (
  <StyledCell style={style} clickable={clickable} centered={centered}>
    <Body noMargin size="two">
      {value && formatDate(value)}
    </Body>
  </StyledCell>
);

DateCell.defaultProps = {
  value: '',
  formatDate: (date) => date,
  style: {},
  clickable: false,
  centered: false,
};

DateCell.propTypes = {
  value: PropTypes.any,
  formatDate: PropTypes.func,
  style: PropTypes.object,
  clickable: PropTypes.bool,
  centered: PropTypes.bool,
};

export default DateCell;
