import { includes } from 'lodash/fp';
import { toUpper } from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import { DATE_FORMATS } from 'variables';
import {
  BANK_TRANSFER,
  FAILED_STATUSES,
  TRANSACTION_TYPE_SELF_TRANSFER_RETURN,
  TRANSACTION_TYPE_PAY_IN,
  TRANSACTION_TYPE_SELF_TRANSFER,
  TRANSACTION_TYPE_BALANCE_TO_BALANCE,
} from './constants';

export const isPayIn = ({ type }) => type === TRANSACTION_TYPE_PAY_IN;

export const isSelfTransfer = ({ type }) =>
  type === TRANSACTION_TYPE_SELF_TRANSFER;

export const isCreditTransfer = ({ transactionType }) =>
  transactionType === BANK_TRANSFER;

export const isFailedType = ({ status = '' }) =>
  includes(toUpper(status), FAILED_STATUSES);

export const isSelfTransferReturn = ({ type }) =>
  type === TRANSACTION_TYPE_SELF_TRANSFER_RETURN;

export const isBalanceToBalance = ({ type }) =>
  type === TRANSACTION_TYPE_BALANCE_TO_BALANCE;

export const setDatePeriodCookie = ({ name, startDate, endDate }, cookie) => {
  Cookies.set(
    cookie,
    `${name}_${moment(startDate).format(
      DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME
    )}_${moment(endDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME)}`
  );
};

export const formatDatePeriodStartDate = (date) =>
  moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME);

export const formatDatePeriodEndDate = (date) =>
  moment(date).endOf('day').format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME);
