import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SelectorGroup = styled('div')(
  ({ theme: { spacings } }) => css`
    display: flex;

    label {
      :first-of-type {
        margin-right: ${spacings.byte};
      }

      display: flex;
      align-items: center;
      max-height: ${spacings.peta};
      border-radius: 8px;
    }
  `
);

export const HeadingWrapper = styled('div')`
  display: flex;
  align-items: center;

  > div {
    margin-left: auto;
  }
`;
