import React from 'react';
import PropTypes from 'prop-types';
import { Body, Hr } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  ADDITIONAL_INFO,
  AMOUNT,
  BENEFICIARY,
  RECIPIENT_ACCOUNT_NUMBER,
  RECIPIENT_IBAN,
  RECIPIENT_NAME,
  RECIPIENT_SORT_CODE,
  REFERENCE,
} from 'components/OpsTransfers/fieldsConfig';
import { CURRENCY_CODES } from 'variables';
import Empty from 'components/Empty';

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacings.mega};
    background: ${theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};
  `
);

const TransferInfoSection = styled('div')(
  ({ theme }) => css`
    padding: 7px ${theme.spacings.kilo};
  `
);

const TransferInfoRow = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 3px 0;
  text-align: left;
`;

const TransferInfoLabel = styled(Body)`
  min-width: 120px;
`;

const BankDetails = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacings.giga};
  `
);

const StyledHr = styled(Hr)`
  margin: 0;
`;

export const TransferInfo = ({ transfer, exponent }) => {
  const amount = transfer[AMOUNT.name];
  const beneficiary = transfer[BENEFICIARY.name];
  const accountHolder = beneficiary[RECIPIENT_NAME.name];
  const accountNumber = beneficiary[RECIPIENT_ACCOUNT_NUMBER.name];
  const sortCode = beneficiary[RECIPIENT_SORT_CODE.name];
  const iban = beneficiary[RECIPIENT_IBAN.name];
  const reference = transfer[REFERENCE.name];
  const additionalInfo = transfer[ADDITIONAL_INFO.name];
  const ukTransfer = !!(accountNumber && sortCode);
  const currency = ukTransfer ? CURRENCY_CODES.GBP : CURRENCY_CODES.EUR;

  return (
    <Wrapper>
      <TransferInfoSection>
        <TransferInfoRow>
          <TransferInfoLabel noMargin variant="highlight" size="two">
            Recipient
          </TransferInfoLabel>
          <Body noMargin size="two">
            {accountHolder}
          </Body>
        </TransferInfoRow>
        <TransferInfoRow>
          <TransferInfoLabel noMargin variant="highlight" size="two">
            Bank details
          </TransferInfoLabel>
          <BankDetails>
            {sortCode && (
              <Body noMargin size="two">
                {sortCode}
              </Body>
            )}
            {accountNumber && (
              <Body noMargin size="two">
                {accountNumber}
              </Body>
            )}
            {iban && (
              <Body noMargin size="two">
                {iban}
              </Body>
            )}
          </BankDetails>
        </TransferInfoRow>
        <TransferInfoRow>
          <TransferInfoLabel noMargin variant="highlight" size="two">
            Reference
          </TransferInfoLabel>
          <Body noMargin size="two">
            {reference}
          </Body>
        </TransferInfoRow>
        <TransferInfoRow>
          <TransferInfoLabel noMargin variant="highlight" size="two">
            Additional info
          </TransferInfoLabel>
          {additionalInfo ? (
            <Body noMargin size="two">
              {additionalInfo}
            </Body>
          ) : (
            <Empty>—</Empty>
          )}
        </TransferInfoRow>
      </TransferInfoSection>
      <StyledHr />
      <TransferInfoSection>
        <TransferInfoRow>
          <TransferInfoLabel noMargin variant="highlight" size="two">
            Amount
          </TransferInfoLabel>
          <Body noMargin size="two">
            {amount / 10 ** exponent} {currency}
          </Body>
        </TransferInfoRow>
      </TransferInfoSection>
    </Wrapper>
  );
};

TransferInfo.propTypes = {
  transfer: PropTypes.object.isRequired,
  exponent: PropTypes.number.isRequired,
};
