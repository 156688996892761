import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledHeaderCell = styled('div')`
  ${({ theme: { colors }, sorted, sortable, centered, border }) => css`
    :not(:last-of-type) {
      border-right: ${border || `1px solid ${colors.n300}`};
    }

    p {
      margin-right: 2px;
    }

    p,
    span {
      ${sorted &&
      `
          cursor: pointer;
          color: ${colors.p500};
        `}
    }

    ${sortable &&
    `
        &:hover {
          cursor: pointer;
          color: ${colors.p500};
        }
      `}

    ${centered &&
    `
      display: flex;
      justify-content: center;
    `}
  `}
`;
