import React from 'react';
import { Headline, Pagination } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Loading from 'components/Loading';
import DateFilter from 'components/DateFilter';
import FiltersPicker from './components/FiltersPicker';
import PageSizeDropdown from '../Account/components/PageSizeDropdown';
import {
  AUTHORIZATIONS_FOR_ADJUSTMENT,
  DATE_PERIOD_OPTIONS,
} from './constants';
import AuthorizationsGrid from './components/AuthorizationsGrid';

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Filters = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

export const AuthorizationsCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

export const StyledPagination = styled(Pagination)`
  padding-bottom: 0;
`;

export const AdjustAuthorizations = ({
  loading,
  ignoreAuthorizationLoading,
  adjustAuthorizationLoading,
  authorizationsForAdjustment,
  columns,
  onPageSizeChange,
  size,
  totalPages,
  currentPage,
  onPageChange,
  filters,
  filtersApplied,
  applyFiltersEnabled,
  onFilterChange,
  onFiltersApply,
  onFiltersClear,
  datePeriod,
  onDateRangeChange,
  onAdjustAuthorizationModal,
  onAdjustAuthorizationConfirm,
}) => (
  <>
    <Header>
      <Headline noMargin as="h2" size="two">
        {AUTHORIZATIONS_FOR_ADJUSTMENT}
        {!isEmpty(authorizationsForAdjustment) && (
          <AuthorizationsCount>
            ({authorizationsForAdjustment.length})
          </AuthorizationsCount>
        )}
      </Headline>
      {loading && <Loading />}
      <Filters>
        <DateFilter
          datePeriod={datePeriod}
          onRangeChange={onDateRangeChange}
          datePeriodOptions={DATE_PERIOD_OPTIONS}
        />
        <PageSizeDropdown size={size} onSizeChange={onPageSizeChange} />
        <FiltersPicker
          filters={filters}
          filtersApplied={filtersApplied}
          applyFiltersEnabled={applyFiltersEnabled}
          onFilterChange={onFilterChange}
          onFiltersApply={onFiltersApply}
          onFiltersClear={onFiltersClear}
        />
      </Filters>
    </Header>
    <AuthorizationsGrid
      columns={columns}
      ignoreAuthorizationLoading={ignoreAuthorizationLoading}
      adjustAuthorizationLoading={adjustAuthorizationLoading}
      authorizationsForAdjustment={authorizationsForAdjustment}
      onAdjustAuthorizationModal={onAdjustAuthorizationModal}
      onAdjustAuthorizationConfirm={onAdjustAuthorizationConfirm}
    />
    <StyledPagination
      label="Pagination"
      previousLabel="Previous page"
      nextLabel="Next page"
      totalPages={totalPages}
      totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
      currentPage={currentPage}
      onChange={onPageChange}
    />
  </>
);

AdjustAuthorizations.propTypes = {
  loading: PropTypes.bool.isRequired,
  ignoreAuthorizationLoading: PropTypes.array.isRequired,
  adjustAuthorizationLoading: PropTypes.array.isRequired,
  authorizationsForAdjustment: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
  applyFiltersEnabled: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  datePeriod: PropTypes.object.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  onAdjustAuthorizationModal: PropTypes.func.isRequired,
  onAdjustAuthorizationConfirm: PropTypes.func.isRequired,
};
