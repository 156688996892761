import React, { useContext, useRef, useState } from 'react';
import { useModal } from '@sumup/circuit-ui';
import { Delete } from '@sumup/icons';
import { capitalize, find, keys, map, reduce, replace, size } from 'lodash';
import {
  PageContent,
  DetailsSectionSubHeading,
  DetailsSectionBodyHeadingWrapper,
  DetailsSectionBody,
  StyledSelect,
  StyledText,
} from 'components/UserAdministration/UserAdministrationStyled';
import { getApps, getGroups, getGroup, deleteGroup } from 'api';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { loadError, loadSuccess } from 'actions';
import {
  buildPermissionsTree,
  createGroup,
  updateGroup,
} from 'components/UserAdministration/userAdministrationService';
import Error from 'components/Error';
import { setError } from 'actions/commonActions';
import { Separator } from 'components/Admin/StyledAdmin';
import { APP_CODES } from 'components/UserAdministration/constants';
import AuthContext from 'context/auth';
import { hasActionAccess } from 'services/permissions';
import { ACTIONS, APPS } from 'variables';
import ListSection from '../UserAdministration/components/ListSection';
import DetailsSection from '../UserAdministration/components/DetailsSection';
import { initialState } from './state';
import { userAdministrationUserGroupsReducer } from './userAdministrationUserGroupsReducer';
import {
  setGroupsLoading,
  loadAppsError,
  loadAppsSuccess,
  selectGroup,
  setNewGroupName,
  setNewGroupApp,
  clearNewGroupData,
  setNewGroupModules,
  setNewGroupSections,
  setNewGroupActions,
  setSelectedGroupModules,
  setSelectedGroupSections,
  setSelectedGroupActions,
  setSelectedGroupNewName,
  loadGroupError,
  loadGroupSuccess,
  updateGroupError,
  setGroupLoading,
  createGroupError,
  deleteGroupError,
} from './userAdministrationUserGroupsActions';
import PermissionsTree from '../UserAdministration/components/PermissionsTree';
import DeleteGroupConfirmationModal from './components/DeleteGroupConfirmationModal';

const getAppsWrapped = ({ httpClient, dispatch }) =>
  getApps({ httpClient })
    .then(({ data }) => dispatch(loadAppsSuccess({ data })))
    .catch((error) => dispatch(loadAppsError(error)));

const getGroupsWrapped = (id, httpClient) => getGroups({ httpClient });

const getGroupWrapped = (httpClient, dispatch, { data }) => {
  const { groups = [{}] } = data || {};
  dispatch(setGroupLoading(true));

  return getGroup({ httpClient, code: groups[0].code })
    .then(({ data: groupData }) => {
      dispatch(loadGroupSuccess({ data: groupData }));

      return getAppsWrapped({ httpClient, dispatch });
    })
    .catch((error) => {
      dispatch(loadGroupError(error));
    });
};

const UserAdministrationUserGroups = () => {
  const httpClient = useHttpClient();
  const [createGroupMode, setCreateGroupMode] = useState(false);
  const [editGroupMode, setEditGroupMode] = useState(false);

  const selectedGroupRef = useRef(null);

  const {
    state: { permissions: loggedUserPermissions },
  } = useContext(AuthContext);

  const { setModal } = useModal();

  const hasEditRights = hasActionAccess({
    permissions: loggedUserPermissions,
    app: APPS.administration,
    action: ACTIONS[APPS.administration].groupsEdit,
  });

  const [state, dispatch] = useApiCallOnLoad(
    getGroupsWrapped,
    null,
    userAdministrationUserGroupsReducer,
    initialState,
    getGroupWrapped
  );

  const {
    groups,
    groupsLoading,
    groupLoading,
    selectedGroup: {
      text: selectedGroupName,
      count: usersCount,
      app: selectedGroupApp = {},
      permissions: selectedGroupPermissions = {},
      newName: selectedGroupNewName,
    },
    newGroup: {
      name: newGroupName,
      app: newGroupApp = {},
      permissions: newGroupPermissions = {},
    },
    apps,
    error,
  } = state;

  let primaryButtonLabel = 'Edit group';
  let primaryButtonDisabled = false;
  let inputValue = '';
  let permissions = {};
  let selectedPermissions = {};
  let permissionsTree = {};
  let moduleNames = [];

  if (createGroupMode) {
    primaryButtonLabel = 'Create group';
    primaryButtonDisabled = !keys(newGroupApp).length;
    inputValue = newGroupName;
    permissions = find(apps, ({ code }) => code === newGroupApp);
    selectedPermissions = newGroupPermissions;
  } else if (editGroupMode) {
    primaryButtonLabel = 'Save changes';
    inputValue = selectedGroupNewName;
    permissions = find(apps, ({ code }) => code === selectedGroupApp);
    selectedPermissions = selectedGroupPermissions;
  } else {
    permissions = find(apps, ({ code }) => code === selectedGroupApp);
    selectedPermissions = selectedGroupPermissions;
  }

  if (keys(permissions).length) {
    permissionsTree = buildPermissionsTree(permissions) || {};
    moduleNames = keys(permissionsTree) || [];
  }

  const handleGroupClick = ({ text: name }) => {
    if (createGroupMode) {
      setCreateGroupMode(false);
    }

    if (selectedGroupName !== name) {
      dispatch(selectGroup({ name }));
    }

    dispatch(setGroupLoading(true));

    getGroup({ httpClient, code: name })
      .then(({ data }) => {
        dispatch(loadGroupSuccess({ data }));

        return getAppsWrapped({ httpClient, dispatch });
      })
      .catch((err) => {
        dispatch(loadGroupError(err));
      });
  };

  const handleCreateGroupButtonClick = () => {
    if (editGroupMode) {
      setEditGroupMode(false);
    }

    setCreateGroupMode(true);
    getAppsWrapped({ httpClient, dispatch });
  };

  const handleNameChange = ({ target: { value } }) => {
    if (createGroupMode) {
      dispatch(setNewGroupName({ name: value }));
    } else if (editGroupMode) {
      dispatch(setSelectedGroupNewName({ name: value }));
    }
  };

  const handleNewGroupAppChange = ({ target: { value } }) => {
    dispatch(setNewGroupApp({ app: value }));
  };

  const handlePrimaryButtonClick = () => {
    if (createGroupMode) {
      if (keys(newGroupApp).length) {
        createGroup({
          httpClient,
          newGroupName,
          newGroupApp,
          newGroupPermissions,
        })
          .catch((err) => {
            dispatch(createGroupError(err));

            return Promise.reject();
          })
          .then(() => {
            setCreateGroupMode(false);
            dispatch(clearNewGroupData());
            dispatch(setGroupsLoading(true));

            return getGroups({ httpClient });
          })
          .catch((err) => {
            dispatch(loadError(err));

            return Promise.reject();
          })
          .then((data) => {
            dispatch(loadSuccess(data));

            dispatch(selectGroup({ name: newGroupName }));

            if (selectedGroupRef && selectedGroupRef.current) {
              selectedGroupRef.current.scrollIntoView();
            }

            dispatch(setGroupLoading(true));

            return getGroup({ httpClient, code: newGroupName });
          })
          .then(({ data }) => {
            dispatch(loadGroupSuccess({ data }));

            return getAppsWrapped({ httpClient, dispatch });
          })
          .catch((err) => {
            dispatch(loadGroupError(err));
          });
      } else {
        getAppsWrapped({ httpClient, dispatch });
      }
    } else if (editGroupMode) {
      updateGroup({
        httpClient,
        selectedGroupName,
        selectedGroupNewName,
        selectedGroupApp,
        selectedGroupPermissions,
      })
        .catch((err) => {
          dispatch(updateGroupError(err));

          return Promise.reject();
        })
        .then(() => {
          setEditGroupMode(false);
          dispatch(setGroupsLoading(true));

          return getGroups({ httpClient });
        })
        .catch((err) => {
          dispatch(loadError(err));

          return Promise.reject();
        })
        .then((data) => {
          dispatch(loadSuccess(data));

          dispatch(selectGroup({ name: selectedGroupNewName }));

          if (selectedGroupRef && selectedGroupRef.current) {
            selectedGroupRef.current.scrollIntoView();
          }

          dispatch(setGroupLoading(true));

          return getGroup({ httpClient, code: selectedGroupNewName });
        })
        .then(({ data }) => {
          dispatch(loadGroupSuccess({ data }));

          return getAppsWrapped({ httpClient, dispatch });
        })
        .catch((err) => {
          dispatch(loadGroupError(err));
        });
    } else {
      dispatch(setError(null));
      setEditGroupMode(true);

      if (createGroupMode) {
        setCreateGroupMode(false);
      }
    }
  };

  const handleDeleteGroupConfirm = () =>
    deleteGroup({
      httpClient,
      code: selectedGroupName,
    })
      .catch((err) => {
        dispatch(deleteGroupError(err));

        return Promise.reject();
      })
      .then(() => {
        dispatch(setGroupsLoading(true));

        return getGroups({ httpClient });
      })
      .catch((err) => {
        dispatch(loadError(err));

        return Promise.reject();
      })
      .then((data) => {
        dispatch(loadSuccess(data));

        const {
          data: { groups: allGroups },
        } = data;
        const { code } = allGroups[0];
        dispatch(selectGroup({ name: code }));

        if (selectedGroupRef && selectedGroupRef.current) {
          selectedGroupRef.current.scrollIntoView();
        }

        return getGroup({ httpClient, code });
      })
      .then(({ data }) => {
        dispatch(loadGroupSuccess({ data }));

        return getAppsWrapped({ httpClient, dispatch });
      })
      .catch((err) => {
        dispatch(loadGroupError(err));
      });

  const handleCancelButtonClick = () => {
    if (createGroupMode) {
      setCreateGroupMode(false);
      dispatch(clearNewGroupData());
    } else if (editGroupMode) {
      setEditGroupMode(false);
      dispatch(setSelectedGroupNewName({ name: selectedGroupName }));
      dispatch(setGroupLoading(true));

      getGroup({ httpClient, code: selectedGroupName })
        .then(({ data }) => {
          dispatch(loadGroupSuccess({ data }));

          return getAppsWrapped({ httpClient, dispatch });
        })
        .catch((err) => {
          dispatch(loadGroupError(err));
        });
    }
  };

  const handleModuleSelect = (modules) => {
    if (createGroupMode) {
      dispatch(setNewGroupModules({ modules }));
    } else if (editGroupMode) {
      dispatch(setSelectedGroupModules({ modules }));
    }
  };

  const handleSectionSelect = (sections) => {
    if (createGroupMode) {
      dispatch(setNewGroupSections({ sections }));
    } else if (editGroupMode) {
      dispatch(setSelectedGroupSections({ sections }));
    }
  };

  const handleActionSelect = (actions) => {
    if (createGroupMode) {
      dispatch(setNewGroupActions({ actions }));
    } else if (editGroupMode) {
      dispatch(setSelectedGroupActions({ actions }));
    }
  };

  const setPassivePermissions = () => {
    const { modules = [], sections = [] } = permissions;
    const selectedModules = reduce(
      modules,
      (accumulator, module) => ({
        ...accumulator,
        [module]: true,
      }),
      {}
    );
    const selectedSections = reduce(
      sections,
      (accumulator, section) => ({
        ...accumulator,
        [section]: true,
      }),
      {}
    );

    if (size(modules)) {
      handleModuleSelect(selectedModules);
      handleSectionSelect(selectedSections);
    } else {
      handleModuleSelect(selectedSections);
    }
  };

  const handleSetPassivePermissions = () => {
    setPassivePermissions();
    handleActionSelect({});

    const { modules = [] } = permissions;

    if (!size(modules)) {
      handleSectionSelect({});
    }
  };

  const handleSetActivePermissions = () => {
    const { modules = [], actions = [] } = permissions;
    const selectedActions = reduce(
      actions,
      (accumulator, action) => ({
        ...accumulator,
        [action]: true,
      }),
      {}
    );

    setPassivePermissions();

    if (size(modules)) {
      handleActionSelect(selectedActions);
    } else {
      handleSectionSelect(selectedActions);
    }
  };

  const openDeleteGroupConfirmationModal = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <DeleteGroupConfirmationModal
          usersCount={usersCount}
          onClose={onClose}
          onConfirm={handleDeleteGroupConfirm}
        />
      ),
      onClose: () => {},
      closeButtonLabel: 'Close',
    });
  };

  const actions = [
    {
      label: 'Delete group',
      icon: <Delete size="16" />,
      color: 'alert',
      onClick: () => openDeleteGroupConfirmationModal(true),
    },
  ];

  return (
    <>
      <PageContent>
        <ListSection
          buttonLabel="Create new group"
          items={groups}
          itemsLoading={groupsLoading}
          error={error}
          selectedItem={{ text: selectedGroupName }}
          selectedItemRef={selectedGroupRef}
          hasEditRights={hasEditRights}
          onButtonClick={handleCreateGroupButtonClick}
          onItemClick={handleGroupClick}
        />
        <DetailsSection
          text={selectedGroupNewName}
          primaryButtonLabel={primaryButtonLabel}
          primaryButtonDisabled={primaryButtonDisabled}
          readonly={!createGroupMode && !editGroupMode}
          inputValue={inputValue}
          inputLabel="Group name"
          actions={actions}
          editTextEnabled={createGroupMode || editGroupMode}
          showHeader={!!size(groups) || createGroupMode}
          hasEditRights={hasEditRights}
          onInputChange={handleNameChange}
          onPrimaryButtonClick={handlePrimaryButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
        >
          <DetailsSectionBody>
            {error && <Error>{error}</Error>}
            {(createGroupMode || editGroupMode) && (
              <>
                <StyledSelect
                  noMargin
                  label="Select an application / Selected application"
                  hideLabel
                  placeholder={
                    editGroupMode
                      ? APP_CODES[selectedGroupApp]
                      : 'Select an application'
                  }
                  disabled={editGroupMode}
                  onChange={handleNewGroupAppChange}
                >
                  <optgroup label="Applications:">
                    {map(apps, ({ code }, index) => (
                      <option key={`${code}-${index}`} value={code}>
                        {capitalize(replace(code, '_', ' '))}
                      </option>
                    ))}
                  </optgroup>
                </StyledSelect>
                {!!size(moduleNames) && (
                  <DetailsSectionBodyHeadingWrapper>
                    <DetailsSectionSubHeading noMargin as="h2">
                      Select permissions:
                    </DetailsSectionSubHeading>
                    <div>
                      <StyledText
                        noMargin
                        size="two"
                        onClick={handleSetActivePermissions}
                      >
                        Set active permissions
                      </StyledText>
                      <Separator noMargin size="two">
                        /
                      </Separator>
                      <StyledText
                        noMargin
                        size="two"
                        onClick={handleSetPassivePermissions}
                      >
                        Set passive permissions
                      </StyledText>
                    </div>
                  </DetailsSectionBodyHeadingWrapper>
                )}
              </>
            )}
            <PermissionsTree
              createMode={createGroupMode}
              editMode={editGroupMode}
              tree={permissionsTree}
              moduleNames={moduleNames}
              permissions={selectedPermissions}
              loading={groupLoading}
              error={error}
              emptyStateText={size(groups) ? '' : 'No user groups created yet.'}
              onModuleSelect={handleModuleSelect}
              onSectionSelect={handleSectionSelect}
              onActionSelect={handleActionSelect}
            />
          </DetailsSectionBody>
        </DetailsSection>
      </PageContent>
    </>
  );
};

export default UserAdministrationUserGroups;
