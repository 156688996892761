import {
  actionTypes,
  LOGGED_USER_COOKIE,
  TRANSACTIONS_DATE_PERIOD_COOKIE,
  TRANSACTIONS_PAGE_SIZE_COOKIE,
} from 'variables';
import Cookies from 'js-cookie';
import { initialState } from './state';

export const authReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.AUTH: {
      const {
        payload: { user },
      } = action;

      if (user) {
        return {
          ...state,
          isAuthenticated: Boolean(user),
          user,
        };
      }

      Cookies.remove(LOGGED_USER_COOKIE);
      Cookies.remove(TRANSACTIONS_DATE_PERIOD_COOKIE);
      Cookies.remove(TRANSACTIONS_PAGE_SIZE_COOKIE);

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        permissions: initialState.permissions,
      };
    }
    case actionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.data,
        loading: false,
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case actionTypes.SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
