import React from 'react';
import { ArrowLeft } from '@sumup/icons';
import PropTypes from 'prop-types';
import { Button } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NAVIGATION_BACK_BUTTON_WIDTH } from 'variables';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    border: none;
    border-right: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    border-radius: 0;
    background: ${theme.colors.n100};
    width: ${NAVIGATION_BACK_BUTTON_WIDTH}px;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-right-color: ${theme.colors.n200};
    }
  `
);

const StyledArrow = styled(ArrowLeft)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.tera};
  `
);

const BackButton = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    <StyledArrow size="16" />
  </StyledButton>
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
