import styled from '@emotion/styled';
import { css } from '@emotion/react';

const CONTENT_WIDTH = 320;

export const PageContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 40vh;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
      max-width: ${CONTENT_WIDTH}px;
      margin-bottom: ${theme.spacings.mega};
    }

    button {
      margin-top: ${theme.spacings.giga};
      min-width: ${CONTENT_WIDTH}px;
    }
  `
);

export const RangePickerWrapper = styled('div')`
  > div > div {
    min-width: ${CONTENT_WIDTH}px;
  }
`;

export const HiddenLink = styled('a')`
  display: hidden;
`;
