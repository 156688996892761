import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Button } from '@sumup/circuit-ui';
import { TRANSACTION_DETAILS_MODAL_WIDTH } from 'variables';

export const TransactionDetailsWrapper = styled('div')(
  ({ theme }) => css`
    min-width: ${TRANSACTION_DETAILS_MODAL_WIDTH}px;
    border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
  `
);

export const TransactionDetails = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacings.mega} 0 ${theme.spacings.giga};
  `
);

export const TransactionDetailsSection = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const TransactionDetailsSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const ActionsSection = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacings.mega};
    align-items: center;

    button {
      max-height: ${theme.spacings.tera};
      cursor: pointer;
    }
  `
);

export const StyledButton = styled(Button)(
  ({ theme, color }) => css`
    display: flex;
    align-items: center;

    ${color &&
    `
      color: ${theme.colors[color]};
      border-color: ${theme.colors[color]};

      :hover {
        border-color: ${theme.colors[color]};
      }
    `}
  `
);

export const Clearings = styled('div')(
  ({ theme }) => css`
    padding-top: ${theme.spacings.mega};

    & > div {
      margin-top: ${theme.spacings.mega};

      > div > div:not(:first-of-type) {
        border-left: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
      }
    }
  `
);

export const ClearingsCount = styled('span')(
  ({ theme }) => css`
    margin: 0 ${theme.spacings.byte};
    color: ${theme.colors.n500};
  `
);

export const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SubHeading = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.n700};
  `
);
