import { ERROR_MESSAGE } from 'variables';
import {
  SET_LOADING,
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_CANCEL_AUTHORIZATIONS_PAGE_SIZE,
  SET_CANCEL_AUTHORIZATIONS_PAGE,
  APPLY_CANCEL_AUTHORIZATIONS_FILTERS,
  CLEAR_CANCEL_AUTHORIZATIONS_FILTERS,
  SET_CANCEL_AUTHORIZATIONS_FILTER,
  SET_CANCEL_AUTHORIZATIONS_DATE_FILTER,
  SORT_CANCEL_AUTHORIZATIONS,
} from 'variables/actionTypes';
import { filter, values, isEmpty } from 'lodash';
import {
  TRANSACTION_FILTERS_ANY_OPTION,
  CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE,
} from './constants';
import { initialState } from './state';

export const cancelAuthorizationsReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }

    case LOAD_SUCCESS: {
      const {
        payload: {
          data: { content = [], totalSize, total_pages: totalPages },
        },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        authorizationsForCancellation: content,
        totalSize,
        totalPages,
      };
    }

    case LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }

    case SET_CANCEL_AUTHORIZATIONS_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        currentPage: CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE,
        size,
      };
    }

    case SET_CANCEL_AUTHORIZATIONS_PAGE: {
      const {
        payload: { page },
      } = action;
      const { totalPages } = state;

      return {
        ...state,
        currentPage: page > totalPages ? totalPages : page,
      };
    }

    case APPLY_CANCEL_AUTHORIZATIONS_FILTERS: {
      const { filters } = state;
      const selectedFilters = filter(
        values(filters),
        (selectedFilterValue) =>
          !isEmpty(selectedFilterValue) &&
          selectedFilterValue.name !== TRANSACTION_FILTERS_ANY_OPTION.name
      );

      return {
        ...state,
        currentPage: CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE,
        filtersApplied: !!selectedFilters.length,
        applyFiltersEnabled: true,
      };
    }

    case CLEAR_CANCEL_AUTHORIZATIONS_FILTERS: {
      const { filters } = initialState;

      return {
        ...state,
        currentPage: CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE,
        filters,
        filtersApplied: false,
        applyFiltersEnabled: false,
      };
    }

    case SET_CANCEL_AUTHORIZATIONS_FILTER: {
      const {
        payload: { field, value },
      } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          [field]: value,
        },
        applyFiltersEnabled: true,
      };
    }

    case SET_CANCEL_AUTHORIZATIONS_DATE_FILTER: {
      const {
        payload: { date },
      } = action;

      return {
        ...state,
        date,
      };
    }

    case SORT_CANCEL_AUTHORIZATIONS: {
      const {
        payload: { name: newSortFieldName, order },
      } = action;

      return {
        ...state,
        sort: {
          [newSortFieldName]: order,
          fieldName: newSortFieldName,
        },
      };
    }

    default:
      return state;
  }
};
