import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Anchor, Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { MFA_STATUSES, MFA_STATUS_BUTTONS } from 'variables';
import keyMirror from 'key-mirror-nested';
import { GrayedOutSplit } from '../../AccountStyled';

export const SELECTORS = keyMirror({
  ENROLLMENT_CHECKBOX: null,
  BLOCKING_LINK: null,
});

const EnrolledCheckboxWrapper = styled('div')`
  display: flex;
  align-items: center;

  > div > label {
    bottom: 18px;
  }

  > p {
    padding-left: 0;
  }
`;

const PsdStatus = ({ card, onPsdStatusChange, disabled, type }) => {
  const { psd_status: status } = card;
  const checkboxChecked =
    status === MFA_STATUSES.ENROLLED ||
    status === MFA_STATUSES.BLOCKED ||
    status === MFA_STATUSES.UNBLOCKED;
  const blockVisible =
    status === MFA_STATUSES.ENROLLED ||
    status === MFA_STATUSES.BLOCKED ||
    status === MFA_STATUSES.UNBLOCKED;
  const blockActive =
    status === MFA_STATUSES.ENROLLED ||
    status === MFA_STATUSES.UNENROLLED ||
    status === MFA_STATUSES.UNBLOCKED;
  let readonlyValue = blockActive
    ? MFA_STATUSES.ENROLLED
    : MFA_STATUSES.ENROLLED_BLOCKED;

  if (!checkboxChecked) {
    readonlyValue = MFA_STATUSES.NOT_ENROLLED;
  }

  return (
    <GrayedOutSplit disabled={disabled}>
      {disabled ? (
        <Body size="two" noMargin>
          {readonlyValue}
        </Body>
      ) : (
        <EnrolledCheckboxWrapper>
          <Checkbox
            name={status}
            checked={checkboxChecked}
            disabled={status === MFA_STATUSES.BLOCKED}
            noMargin
            data-testid={SELECTORS.ENROLLMENT_CHECKBOX}
            onChange={() => !disabled && onPsdStatusChange(card, type)}
          />
          <Body size="two" noMargin>
            {status}
          </Body>
        </EnrolledCheckboxWrapper>
      )}
      {blockVisible && !disabled && (
        <Anchor
          size="two"
          onClick={() => !disabled && onPsdStatusChange(card, type, true)}
          data-testid={SELECTORS.BLOCKING_LINK}
        >
          <Body size="two" noMargin>
            {blockActive
              ? MFA_STATUS_BUTTONS.BLOCK
              : MFA_STATUS_BUTTONS.UNBLOCK}
          </Body>
        </Anchor>
      )}
    </GrayedOutSplit>
  );
};

PsdStatus.propTypes = {
  card: PropTypes.object.isRequired,
  onPsdStatusChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.bool.isRequired,
};

export default PsdStatus;
