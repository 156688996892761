import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledAmountCell = styled('div')(
  ({ theme: { spacings, colors }, clickable, centered }) => css`
    display: flex;
    flex-direction: row;

    & > p:first-of-type {
      margin-right: ${spacings.bit};
    }

    ${clickable &&
    `
      text-decoration: underline;
      cursor: pointer;
      color: ${colors.p500};
    `}

    ${centered && 'text-align: center;'}
  `
);
