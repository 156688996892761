import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import { Body } from '@sumup/circuit-ui';
import {
  MissingCode,
  TooltipContent,
  TooltipPivot,
} from './MerchantCategoryCodeStyled';

const MerchantCategoryCode = ({ mcc, mccDescription }) =>
  mcc ? (
    <Tooltip
      position="top"
      pivot={
        <TooltipPivot noMargin size="two" data-testid="mcc-tooltip-pivot">
          {mcc}
        </TooltipPivot>
      }
      content={
        <TooltipContent data-testid="mcc-tooltip-content">
          {mccDescription ? (
            <>
              <header>Merchant category:</header>
              <Body noMargin variant="highlight" size="two">
                {mccDescription}
              </Body>
            </>
          ) : (
            `Cannot find description for MCC ${mcc}`
          )}
        </TooltipContent>
      }
      data-testid="mcc-tooltip"
    />
  ) : (
    <MissingCode data-testid="mcc-missing-fallback">—</MissingCode>
  );

MerchantCategoryCode.defaultProps = {
  mcc: '',
  mccDescription: '',
};

MerchantCategoryCode.propTypes = {
  mcc: PropTypes.any,
  mccDescription: PropTypes.any,
};

export default MerchantCategoryCode;
