import { ONE_WEEK } from 'variables';
import moment from 'moment/moment';
import {
  CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE,
  CARD_TOKEN_FILTER,
} from './constants';
import {
  BILLING_CURRENCY_FILTER,
  BILLING_CURRENCY_FILTERS_ANY_OPTION,
  PRE_AUTH_FILTER,
  PRE_AUTH_FILTER_ANY_OPTION,
} from '../AdjustAuthorizations/constants';

export const initialState = {
  loading: true,
  error: null,
  size: 10,
  currentPage: CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  authorizationsForCancellation: [],
  totalSize: 0,
  date: moment().subtract(ONE_WEEK, 'd'),
  filtersApplied: false,
  applyFiltersEnabled: false,
  filters: {
    [CARD_TOKEN_FILTER]: '',
    [BILLING_CURRENCY_FILTER]: BILLING_CURRENCY_FILTERS_ANY_OPTION,
    [PRE_AUTH_FILTER]: PRE_AUTH_FILTER_ANY_OPTION,
  },
};
