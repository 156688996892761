import { upperCase } from 'lodash';
import {
  getActiveCards,
  getPrimaryCard,
  getReplacementCard,
} from 'services/clientData';
import { ERROR_MESSAGE, actionTypes } from 'variables';

export const accountReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        accountDataLoading: loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        accountDataLoading: false,
        accountData: data,
        activeCards: getActiveCards(data),
        transferToBankError: null,
        createBankAccountError: null,
      };
    }
    case actionTypes.SET_PRIMARY_PSD_STATUS_LOADING: {
      const {
        payload: { primaryPsdStatusLoading },
      } = action;
      return {
        ...state,
        primaryPsdStatusLoading,
      };
    }
    case actionTypes.SET_REPLACEMENT_PSD_STATUS_LOADING: {
      const {
        payload: { replacementPsdStatusLoading },
      } = action;
      return {
        ...state,
        replacementPsdStatusLoading,
      };
    }
    case actionTypes.SET_CREATE_BANK_ACCOUNT_LOADING: {
      const {
        payload: { createBankAccountLoading },
      } = action;
      return {
        ...state,
        createBankAccountLoading,
      };
    }
    case actionTypes.SET_PRIMARY_PSD_STATUS_SUCCESS: {
      const {
        payload: {
          data: { card_status: cardStatus },
          activeCards,
        },
      } = action;
      const primaryCard = getPrimaryCard(activeCards);
      primaryCard.psd_status = cardStatus;

      return {
        ...state,
        accountDataLoading: false,
        primaryPsdStatusLoading: false,
        activeCards,
        transferToBankError: null,
        createBankAccountError: null,
      };
    }
    case actionTypes.SET_REPLACEMENT_PSD_STATUS_SUCCESS: {
      const {
        payload: {
          data: { card_status: cardStatus },
          activeCards,
        },
      } = action;
      const replacementCard = getReplacementCard(activeCards);
      replacementCard.psd_status = cardStatus;

      return {
        ...state,
        accountDataLoading: false,
        replacementPsdStatusLoading: false,
        activeCards,
        transferToBankError: null,
        createBankAccountError: null,
      };
    }
    case actionTypes.ISSUE_CARD_SUCCESS: {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        issueCardLoading: false,
        accountData: {
          ...state.accountData,
          ...data,
        },
        activeCards: getActiveCards(data),
        transferToBankError: null,
        createBankAccountError: null,
      };
    }
    case actionTypes.REPLACE_CARD_SUCCESS: {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        replaceCardLoading: false,
        accountData: {
          ...state.accountData,
          ...data,
        },
        activeCards: getActiveCards(data),
        transferToBankError: null,
        createBankAccountError: null,
      };
    }

    case actionTypes.SET_PRIMARY_CARD_STATUS_SUCCESS:
    case actionTypes.SET_REPLACEMENT_CARD_STATUS_SUCCESS: {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        accountDataLoading: false,
        accountData: {
          ...state.accountData,
          ...data,
        },
        activeCards: getActiveCards(data),
        transferToBankError: null,
        createBankAccountError: null,
      };
    }
    case actionTypes.CREATE_BANK_ACCOUNT_SUCCESS: {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        accountDataLoading: false,
        accountData: {
          ...state.accountData,
          ...data,
        },
        transferToBankError: null,
        createBankAccountError: null,
      };
    }
    case actionTypes.SET_LOCK_CARD_LOADING: {
      const {
        payload: { lockCardForAuditLoading },
      } = action;

      return {
        ...state,
        lockCardForAuditLoading,
      };
    }
    case actionTypes.SET_PRIMARY_CARD_STATUS_LOADING: {
      const {
        payload: { primaryCardStatusLoading },
      } = action;

      return {
        ...state,
        primaryCardStatusLoading,
      };
    }
    case actionTypes.SET_REPLACEMENT_CARD_STATUS_LOADING: {
      const {
        payload: { replacementCardStatusLoading },
      } = action;

      return {
        ...state,
        replacementCardStatusLoading,
      };
    }
    case actionTypes.ISSUE_CARD_ERROR: {
      const { payload: issueCardFail } = action;

      return {
        ...state,
        issueCardLoading: false,
        issueCardFail,
      };
    }
    case actionTypes.REPLACE_CARD_ERROR: {
      const { payload: replaceCardFail } = action;

      return {
        ...state,
        replaceCardLoading: false,
        replaceCardFail,
      };
    }
    case actionTypes.CLEAR_PIN_ATTEMPTS_SUCCESS: {
      return {
        ...state,
        accountDataLoading: false,
      };
    }
    case actionTypes.SET_ISSUE_CARD_LOADING: {
      const {
        payload: { issueCardLoading },
      } = action;

      return {
        ...state,
        issueCardLoading,
      };
    }
    case actionTypes.SET_REPLACE_CARD_LOADING: {
      const {
        payload: { replaceCardLoading },
      } = action;

      return {
        ...state,
        replaceCardLoading,
      };
    }
    case actionTypes.SET_PRIMARY_PSD_STATUS_ERROR: {
      return {
        ...state,
        primaryPsdStatusLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_REPLACEMENT_PSD_STATUS_ERROR: {
      return {
        ...state,
        replacementPsdStatusLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.LOCK_CARD_FOR_AUDIT_ERROR: {
      return {
        ...state,
        lockCardForAuditLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_PRIMARY_CARD_STATUS_ERROR: {
      return {
        ...state,
        primaryCardStatusLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_REPLACEMENT_CARD_STATUS_ERROR: {
      return {
        ...state,
        replacementCardStatusLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_TRANSFER_TO_BANK_LOADING: {
      const {
        payload: { transferToBankLoading },
      } = action;

      return {
        ...state,
        transferToBankLoading,
      };
    }
    case actionTypes.TRANSFER_TO_BANK_ERROR: {
      return {
        ...state,
        transferToBankLoading: false,
        transferToBankError: ERROR_MESSAGE,
      };
    }
    case actionTypes.CREATE_BANK_ACCOUNT_ERROR: {
      return {
        ...state,
        createBankAccountLoading: false,
        createBankAccountError: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_BALANCE_LOADING: {
      const {
        payload: { balanceLoading },
      } = action;

      return {
        ...state,
        balanceLoading,
      };
    }
    case actionTypes.SET_ACCOUNT_STATUS: {
      const {
        payload: {
          data: { status },
        },
      } = action;

      return {
        ...state,
        accountStatus: upperCase(status),
      };
    }
    case actionTypes.SET_ACCOUNT_STATUS_LOADING: {
      const {
        payload: { accountStatusLoading },
      } = action;

      return {
        ...state,
        accountStatusLoading,
      };
    }
    case actionTypes.ACCOUNT_STATUS_ERROR: {
      return {
        ...state,
        accountStatusLoading: false,
        accountStatusError: ERROR_MESSAGE,
      };
    }
    case actionTypes.LOAD_ERROR:
    case actionTypes.GET_CLIENT_DATA_ERROR:
    case actionTypes.UPDATE_CLIENT_ERROR:
    case actionTypes.CLEAR_PIN_ATTEMPTS_ERROR: {
      return {
        ...state,
        accountDataLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    default:
      return state;
  }
};
