import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_FIELD_TYPES } from 'variables';
import CopyableCell from 'components/CopyableCell';
import MerchantCategoryCell from 'components/MerchantCategoryCode';
import { StyledEmptyCell } from './cellStyled';
import ButtonsGroup from '../components/ButtonsGroup';
import AuthorizationDifference from '../components/AuthorizationDifference';
import Link from '../components/Link';

export const CellFactory = ({
  renderAs,
  value,
  ignoreAuthorizationLoading,
  adjustAuthorizationLoading,
  rowData,
  dispatch,
  selected,
  matched,
  formattedAmount,
  onAdjustAuthorizationModal,
  action,
  authorizationId,
  onAdjustAuthorizationConfirm,
  ...props
}) => {
  if (value == null) {
    return <StyledEmptyCell>—</StyledEmptyCell>;
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({ value, record: rowData });
  }

  switch (render) {
    case CUSTOM_FIELD_TYPES.BUTTONS_GROUP:
      return (
        <ButtonsGroup
          {...props}
          value={value}
          ignoreAuthorizationLoading={ignoreAuthorizationLoading}
          adjustAuthorizationLoading={adjustAuthorizationLoading}
          formattedAmount={formattedAmount}
          onAdjustAuthorizationModal={onAdjustAuthorizationModal}
          authorizationAdjustmentId={rowData.adjustmentId}
          adjusted={rowData.adjusted}
          onAdjustAuthorizationConfirm={onAdjustAuthorizationConfirm}
        />
      );
    case CUSTOM_FIELD_TYPES.AUTHORIZATION_DIFFERENCE:
      return <AuthorizationDifference {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.LINK:
      return <Link {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.MCC:
      return (
        <MerchantCategoryCell
          mcc={value.code}
          mccDescription={value.description}
        />
      );
    default:
      return <CopyableCell {...props} value={value} centered />;
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  rowData: {},
  dispatch: () => {},
  formattedAmount: '',
  onAdjustAuthorizationModal: () => {},
  onAdjustAuthorizationConfirm: () => {},
  action: '',
  authorizationId: '',
  selected: false,
  matched: false,
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  ignoreAuthorizationLoading: PropTypes.array.isRequired,
  adjustAuthorizationLoading: PropTypes.array.isRequired,
  rowData: PropTypes.object,
  dispatch: PropTypes.func,
  selected: PropTypes.bool,
  matched: PropTypes.bool,
  formattedAmount: PropTypes.string,
  onAdjustAuthorizationModal: PropTypes.func,
  onAdjustAuthorizationConfirm: PropTypes.func,
  action: PropTypes.string,
  authorizationId: PropTypes.number,
};
