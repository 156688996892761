import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import { DATE_FORMATS } from 'variables';
import { getCurrentDate } from 'services/dateTime';
import { CancelUpcomingPaymentButton } from './CancelUpcomingPaymentButton';
import { Card, CardContent } from './ScheduleDetailsStyled';

export const UpcomingPaymentCard = ({
  scheduledPayment,
  exponent,
  onCancelPaymentConfirm,
}) => {
  const { id, schedule = {}, amount, currency } = scheduledPayment;
  const { next_due: nextDue, next_payment_due: nextPaymentDue } = schedule;

  const nextDueDate = moment(nextPaymentDue || nextDue);

  const today = getCurrentDate();

  return (
    <Card fullWidth={false}>
      <div>
        <Body noMargin size="one" variant="highlight">
          Upcoming payment
        </Body>
      </div>
      <CardContent flexDirection="column">
        <Headline noMargin size="one">
          {amount / 10 ** exponent} {currency}
        </Headline>
        <Body noMargin size="one" css={spacing({ top: 'bit' })}>
          {nextDueDate.format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
        </Body>
        {!nextDueDate.isBefore(today) && (
          <CancelUpcomingPaymentButton
            schedule={schedule}
            paymentRequestId={id}
            paymentDetails
            onConfirm={onCancelPaymentConfirm}
          />
        )}
      </CardContent>
    </Card>
  );
};

UpcomingPaymentCard.propTypes = {
  scheduledPayment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    schedule: PropTypes.shape({
      next_due: PropTypes.string,
      next_payment_due: PropTypes.string,
    }),
  }).isRequired,
  exponent: PropTypes.number.isRequired,
  onCancelPaymentConfirm: PropTypes.func.isRequired,
};
