import { Headline } from '@sumup/circuit-ui';
import React from 'react';
import { PAGE_TITLES as TITLES } from 'variables';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { HeadingWrapper, Separator, StyledHeading } from './WelcomeStyled';

const Welcome = (props) => (
  <HeadingWrapper {...props}>
    <StyledHeading as="h1" size="two" noMargin>
      Welcome to
    </StyledHeading>
    <Logo />
    <Separator noMargin size="two">
      /
    </Separator>
    <Headline as="h2" size="two" noMargin>
      {TITLES.HOME.heading}
    </Headline>
  </HeadingWrapper>
);

export default Welcome;
