import { Button, Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowLeft } from '@sumup/icons';
import Card from 'components/Card';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  ${({ theme: { spacings } }) => css`
    margin: ${spacings.bit};
  `}
`;

export const StyledCard = styled(Card)`
  ${({ theme: { spacings, colors }, faded }) => css`
    margin-top: ${spacings.bit};
    padding: ${spacings.byte} ${spacings.byte} ${spacings.byte} ${spacings.kilo};

    p {
      ${faded &&
      `
        color: ${colors.n500};
      `}
    }
  `}
`;

export const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SecondaryText = styled('div')`
  ${({ theme: { colors } }) => css`
    display: flex;
    color: ${colors.n700};
  `}
`;

export const Separator = styled(Body)`
  ${({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte} ${spacings.byte};
    color: ${colors.n300};
  `}
`;

export const Applications = styled('div')`
  ${({ theme: { spacings, colors } }) => css`
    display: flex;

    > a {
      text-decoration: none;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-height: 82px;
        min-width: 320px;
        padding: ${spacings.mega};
        margin: 0 ${spacings.bit};

        :hover {
          background: ${colors.p100};
        }
      }
    }
  `}
`;

export const IconWrapper = styled('div')`
  ${({ theme: { spacings, colors } }) => css`
    display: flex;
    width: 46px;
    height: 46px;
    margin-right: ${spacings.kilo};
    border-radius: 16px;
    justify-content: center;
    background: ${colors.p500};
    align-items: center;
  `}
`;

export const StyledArrowRight = styled(ArrowLeft)`
  ${({ theme: { colors } }) => css`
    margin-left: auto;
    color: ${colors.n500};
    transform: matrix(-1, 0, 0, 1, 0, 0);
  `}
`;

export const ApplicationHeadingWrapper = styled('div')`
  max-width: 170px;
`;
