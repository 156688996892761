import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TOP_BAR_HEIGHT } from 'variables';
import { Body } from '@sumup/circuit-ui';

export const TopBarBlock = styled('div')(
  ({ theme }) => css`
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: ${TOP_BAR_HEIGHT}px;
    z-index: 29;
    background: ${theme.colors.n800};
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    justify-content: space-between;
  `
);

export const TopBarLeft = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 50%;
    color: ${theme.colors.bodyBg};
    cursor: pointer;
    svg {
      max-width: 60px;
      margin-right: ${theme.spacings.byte};
      filter: invert(1);
    }
  `
);

export const TopBarRight = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    color: ${theme.colors.bodyBg};
  `
);

export const LogOut = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.bodyBg};
    margin: 0;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  `
);

export const SearchInputWrapper = styled('div')(
  ({ theme: { colors, spacings, typography, borderRadius } }) => css`
    width: 40%;
    display: flex;
    align-items: center;

    > label {
      width: 100%;
    }

    input {
      max-height: 28px;
      background: ${colors.n900};
      box-shadow: none;
      border-radius: ${borderRadius.byte};
      color: ${colors.bodyBg};
      padding: ${spacings.bit} ${spacings.byte} ${spacings.bit} ${spacings.tera};
      font-size: ${typography.body.two.fontSize};
      line-height: ${typography.body.two.lineHeight};
      &:active,
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
    svg {
      width: 28px;
      height: 28px;
      padding: ${spacings.byte};
    }
  `
);

export const Separator = styled(Body)`
  ${({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte} 0 0;
    color: ${colors.n500};
  `}
`;

export const TrimmedText = styled(Body)`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
