import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from 'components/Transaction/components/StatusBadge';
import styled from '@emotion/styled';

const Cell = styled('div')(
  ({ centered }) =>
    centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const StatusBadgeCell = ({ cellStyle, centered, ...props }) => (
  <Cell style={cellStyle} centered={centered}>
    <StatusBadge {...props} />
  </Cell>
);

StatusBadgeCell.defaultProps = {
  centered: false,
  cellStyle: {},
};

StatusBadgeCell.propTypes = {
  centered: PropTypes.bool,
  cellStyle: PropTypes.object,
};

export default StatusBadgeCell;
