import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledText = styled(Body)(
  ({ theme }) => css`
    text-decoration: underline;
    color: ${theme.colors.p500};
    cursor: pointer;
  `
);

const ButtonLinkCell = ({ value, style, rowData, onClick }) => {
  const handleClick = () => onClick(rowData);

  return (
    <div style={style} onClick={handleClick}>
      <StyledText noMargin size="two">
        {value}
      </StyledText>
    </div>
  );
};

ButtonLinkCell.defaultProps = {
  value: '',
  style: {},
  rowData: {},
};

ButtonLinkCell.propTypes = {
  value: PropTypes.any,
  style: PropTypes.object,
  rowData: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default ButtonLinkCell;
