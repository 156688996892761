import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { find, map } from 'lodash';
import { Body } from '@sumup/circuit-ui';
import { STATUS_CHANGES_SKELETON } from 'components/OpsTransfers/constants';
import { getNameFromEmail } from 'services/common';

const Wrapper = styled('div')`
  display: flex;
`;

const Empty = styled('span')(
  ({ theme }) => css`
    color: ${theme.colors.n700};
  `
);

const Prefix = styled(Body)`
  width: 32px;
`;

const getActor = (statusChanges, skeletonStatus) =>
  (find(statusChanges, ({ status }) => skeletonStatus === status) || {}).actor;

export const StatusChangesCell = ({ statusChanges }) =>
  statusChanges
    ? map(STATUS_CHANGES_SKELETON, ({ prefix, status }, index) => (
        <Wrapper key={`status-change-${index}`}>
          <Prefix noMargin size="two" variant="highlight">
            {prefix}:
          </Prefix>
          <Body noMargin size="two">
            {getNameFromEmail(getActor(statusChanges, status)) || (
              <Empty>—</Empty>
            )}
          </Body>
        </Wrapper>
      ))
    : null;

StatusChangesCell.defaultProps = {
  statusChanges: null,
};

StatusChangesCell.propTypes = {
  statusChanges: PropTypes.array,
};
