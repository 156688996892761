import { ERROR_MESSAGE, actionTypes } from 'variables';
import { ALL_FILTERS_VALUE, OPS_TRANSFERS_GRID_FIRST_PAGE } from './constants';

export const opsTransferReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: {
            total_pages: totalPages,
            total_elements: totalCount,
            content = [],
          },
        },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        opsTransfers: content,
        totalPages,
        totalCount,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_OPS_TRANSFERS_PAGE: {
      const {
        payload: { page },
      } = action;

      const { totalPages } = state;

      return {
        ...state,
        currentPage: page > totalPages ? totalPages : page,
      };
    }
    case actionTypes.SET_OPS_TRANSFERS_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        currentPage: OPS_TRANSFERS_GRID_FIRST_PAGE,
        pageSize: size,
      };
    }
    case actionTypes.SET_OPS_TRANSFERS_STATUS_FILTER: {
      const {
        payload: { status },
      } = action;

      return {
        ...state,
        currentPage: OPS_TRANSFERS_GRID_FIRST_PAGE,
        statusFilter: status,
        filtersApplied: status !== ALL_FILTERS_VALUE,
      };
    }
    case actionTypes.SET_OPS_TRANSFERS_CURRENCY_FILTER: {
      const {
        payload: { currency },
      } = action;

      return {
        ...state,
        currentPage: OPS_TRANSFERS_GRID_FIRST_PAGE,
        currencyFilter: currency,
        filtersApplied: currency !== ALL_FILTERS_VALUE,
      };
    }
    case actionTypes.SORT_OPS_TRANSFERS: {
      const {
        payload: { name: newSortFieldName, order },
      } = action;

      return {
        ...state,
        sort: {
          [newSortFieldName]: order,
          fieldName: newSortFieldName,
        },
      };
    }
    default:
      return state;
  }
};
