import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import {
  filter,
  includes,
  keys,
  map,
  startCase,
  toLower,
  toUpper,
} from 'lodash';
import AuthContext from 'context/auth';
import ClientDataContext from 'context/clientData';
import {
  PATHS,
  CLIENT_ONBOARD_STATUSES,
  URLS,
  MODULES,
  NON_ONBOARDED_MERCHANT_ROUTES,
} from 'variables';
import buildLink from 'services/buildLink';
import restrictRoutes from 'services/restrictRoutes';
import TextSeparator from 'components/TextSeparator';
import ClientCards from '../ClientCards';
import BackButton from '../../../Navigation/components/BackButton';
import ModulePicker from '../ModulePicker';
import {
  NAV_BADGE_COLOR_MAP,
  ONBOARDING_STATUS_BADGE_LABEL_MAP,
  VERIFICATION_STATUS_BADGE_LABEL_MAP,
  MERCHANT_ACCOUNT_STATUS_BADGE_LABEL_MAP,
} from '../../constants';
import {
  Header,
  HeaderContent,
  HeaderContentWrapper,
  HeadingWrapper,
  ModulePickerSections,
  RightSizeClientCards,
  StyledBadge,
  StyledHeading,
  SubHeadingWrapper,
} from './NavigationHeaderStyled';
import { StyledLink } from '../../MerchantNavigationStyled';

const NavigationHeader = ({ render, backButtonUrl }) => {
  const history = useHistory();
  const { clientId } = useParams();
  const [modulePickerModal, setModulePickerModal] = useState(false);
  const {
    state: { permissions },
  } = useContext(AuthContext);
  const {
    state: { clientData, externalLinks = {} },
  } = useContext(ClientDataContext);

  const [activeModule] = filter(MODULES, (module) =>
    includes(keys(module.sections), toUpper(render))
  );

  const {
    personal_profile: personalProfile = {},
    onboarding_status: onboarded,
    verification_status: verificationStatus,
    risk_level: riskLevel,
    account = {},
    id: userId,
    source,
    merchantAccountStatus,
  } = clientData;
  const { first_name: firstName, last_name: lastName } = personalProfile;
  const { username } = account;
  const isClientPage = render === PATHS.client;
  const { paysolut } = externalLinks;

  return (
    <Header>
      {!isClientPage && (
        <BackButton onClick={() => history.push(backButtonUrl)} />
      )}
      <HeaderContentWrapper>
        <HeaderContent>
          <div>
            <HeadingWrapper>
              {firstName || lastName ? (
                <StyledHeading noMargin as="h2" size="two">
                  {firstName} {lastName}
                </StyledHeading>
              ) : null}
              {onboarded && (
                <StyledBadge bgColor={NAV_BADGE_COLOR_MAP[onboarded]}>
                  <Body variant="highlight" noMargin size="two">
                    {ONBOARDING_STATUS_BADGE_LABEL_MAP[onboarded]}
                  </Body>
                </StyledBadge>
              )}
              {verificationStatus && (
                <StyledBadge bgColor={NAV_BADGE_COLOR_MAP[verificationStatus]}>
                  <Body variant="highlight" noMargin size="two">
                    KYC:{' '}
                    {VERIFICATION_STATUS_BADGE_LABEL_MAP[verificationStatus]}
                  </Body>
                </StyledBadge>
              )}
              {merchantAccountStatus && (
                <StyledBadge
                  bgColor={NAV_BADGE_COLOR_MAP[merchantAccountStatus]}
                >
                  <Body variant="highlight" noMargin size="two">
                    Risk status:{' '}
                    {
                      MERCHANT_ACCOUNT_STATUS_BADGE_LABEL_MAP[
                        merchantAccountStatus
                      ]
                    }
                  </Body>
                </StyledBadge>
              )}
              {riskLevel && (
                <StyledBadge bgColor={NAV_BADGE_COLOR_MAP[riskLevel]}>
                  <Body variant="highlight" noMargin size="two">
                    AML risk: {startCase(toLower(riskLevel))}
                  </Body>
                </StyledBadge>
              )}
              {!isClientPage && (
                <RightSizeClientCards>
                  <ClientCards
                    inner
                    soapUrl={`${URLS.soap}/${userId}`}
                    ebcUrl={paysolut}
                  />
                </RightSizeClientCards>
              )}
            </HeadingWrapper>
          </div>
          <SubHeadingWrapper>
            <Body noMargin size="one">
              {clientId}
            </Body>
            {username ? (
              <>
                <TextSeparator noMargin size="one">
                  ·
                </TextSeparator>
                <Body noMargin size="one">
                  {username}
                </Body>
              </>
            ) : null}
            {source ? (
              <>
                <TextSeparator noMargin size="one">
                  ·
                </TextSeparator>
                <Body noMargin size="one">
                  Source: {source}
                </Body>
              </>
            ) : null}
          </SubHeadingWrapper>
        </HeaderContent>
        {!isClientPage && (
          <ModulePicker
            activeModule={activeModule}
            render={render}
            onboarded={onboarded}
            clientId={clientId}
            isOpen={modulePickerModal}
            onClick={() => setModulePickerModal(true)}
            onClose={() => setModulePickerModal(false)}
          >
            <ModulePickerSections>
              {map(
                restrictRoutes({
                  appName: activeModule,
                  status: onboarded,
                  permissions,
                  clientId,
                }),
                (section) => (
                  <StyledLink
                    key={section.title}
                    disabled={
                      !NON_ONBOARDED_MERCHANT_ROUTES(clientData)[
                        section.link
                      ] && onboarded !== CLIENT_ONBOARD_STATUSES.ONBOARDED
                    }
                    inner="true"
                    collapsed="true"
                    active={render === section.link ? 1 : 0}
                    to={buildLink({
                      type: PATHS.client,
                      segment: section.link,
                      id: clientId,
                    })}
                  >
                    {section.title}
                  </StyledLink>
                )
              )}
            </ModulePickerSections>
          </ModulePicker>
        )}
      </HeaderContentWrapper>
    </Header>
  );
};

NavigationHeader.defaultProps = {
  backButtonUrl: '/',
};

NavigationHeader.propTypes = {
  render: PropTypes.string.isRequired,
  backButtonUrl: PropTypes.string,
};

export default NavigationHeader;
