import React from 'react';
import PropTypes from 'prop-types';
import { IndicatorDot } from './IndicatorStyled';

const Indicator = ({ style }) => <IndicatorDot style={style} />;

Indicator.defaultProps = {
  style: {},
};

Indicator.propTypes = {
  style: PropTypes.object,
};

export default Indicator;
