import keyMirror from 'key-mirror-nested';
import { CURRENCY_CODES } from 'variables';

export const OPS_TRANSFERS_FILTERS_COMPACT_WINDOW_MAX_WIDTH = 1700;

export const OPS_TRANSFERS_GRID_FIRST_PAGE = 1;

export const STATUSES = keyMirror({
  REQUESTED: null,
  INITIATED: null,
  PRE_APPROVED: null,
  APPROVED: null,
  CANCELLED: null,
  REJECTED: null,
});

export const HISTORY_STATUSES = keyMirror({
  PREAPPROVED: null,
  UPDATED: null,
});

export const STATUS_MAP = {
  [STATUSES.REQUESTED]: {
    name: STATUSES.REQUESTED,
    background: '#E6E6E6',
    color: '#1A1A1A',
    label: 'Requested',
  },
  [STATUSES.INITIATED]: {
    name: STATUSES.INITIATED,
    background: '#3063E9',
    label: 'Initiated',
  },
  [HISTORY_STATUSES.PREAPPROVED]: {
    name: HISTORY_STATUSES.PREAPPROVED,
    background: '#138849',
    label: 'Pre-approved',
  },
  [STATUSES.PRE_APPROVED]: {
    name: STATUSES.PRE_APPROVED,
    background: '#138849',
    label: 'Pre-approved',
  },
  [STATUSES.APPROVED]: {
    name: STATUSES.APPROVED,
    background: '#138849',
    label: 'Authorized',
  },
  [STATUSES.CANCELLED]: {
    name: STATUSES.CANCELLED,
    background: '#D23F47',
    label: 'Cancelled',
  },
  [STATUSES.REJECTED]: {
    name: STATUSES.REJECTED,
    background: '#D23F47',
    label: 'Rejected',
  },
  [HISTORY_STATUSES.UPDATED]: {
    name: HISTORY_STATUSES.UPDATED,
    background: 'transparent',
    color: '#666666',
    label: 'Updated',
  },
};

export const ALL_FILTERS_VALUE = 'ALL';
export const ALL_FILTERS_LABEL = 'All';

export const STATUS_CHANGES_SKELETON = [
  {
    prefix: 'R',
    status: 'REQUESTED',
  },
  {
    prefix: 'I',
    status: 'INITIATED',
  },
  {
    prefix: 'A1',
    status: 'PRE_APPROVED',
  },
  {
    prefix: 'A2',
    status: 'APPROVED',
  },
];

export const TRY_AGAIN_LATER_MESSAGE = 'Please try again later.';

const REQUESTED = STATUS_MAP[STATUSES.REQUESTED];
const CANCELLED = STATUS_MAP[STATUSES.CANCELLED];
const INITIATED = STATUS_MAP[STATUSES.INITIATED];
const PRE_APPROVED = STATUS_MAP[STATUSES.PRE_APPROVED];
const APPROVED = STATUS_MAP[STATUSES.APPROVED];
const REJECTED = STATUS_MAP[STATUSES.REJECTED];

export const STATUS_FILTER_OPTIONS = [
  {
    value: ALL_FILTERS_VALUE,
    label: ALL_FILTERS_LABEL,
  },
  {
    value: REQUESTED.name,
    label: REQUESTED.label,
  },
  {
    value: CANCELLED.name,
    label: CANCELLED.label,
  },
  {
    value: INITIATED.name,
    label: INITIATED.label,
  },
  {
    value: PRE_APPROVED.name,
    label: PRE_APPROVED.label,
  },
  {
    value: APPROVED.name,
    label: APPROVED.label,
  },
  {
    value: REJECTED.name,
    label: REJECTED.label,
  },
];

export const CURRENCY_FILTER_OPTIONS = [
  {
    value: ALL_FILTERS_VALUE,
    label: ALL_FILTERS_LABEL,
  },
  {
    value: CURRENCY_CODES.EUR,
    label: CURRENCY_CODES.EUR,
  },
  {
    value: CURRENCY_CODES.GBP,
    label: CURRENCY_CODES.GBP,
  },
];
