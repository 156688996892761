export const FIELD_TYPES = {
  DATE_TIME: 'DateTime',
  FLOAT: 'Float',
};

export const CUSTOM_FIELD_TYPES = {
  STATUS_BADGE: 'StatusBadge',
  INPUT: 'Input',
  AMOUNT: 'Amount',
  COMPOSITE_AMOUNT: 'CompositeAmount',
  STATUS: 'Status',
  BUTTON_WARNING: 'ButtonWarning',
  BUTTON_CANCEL: 'ButtonCancel',
  BUTTON_LINK: 'ButtonLink',
  WALLET: 'Wallet',
  POPOVER: 'Popover',
  EMPTY: 'Empty',
  CURRENCY_CONVERSION: 'CurrencyConversion',
  ENTRY_MODE: 'EntryMode',
  MCC: 'MerchantCategory',
  MCC_DESCRIPTION: 'MerchantCategoryDescription',
  TEXT: 'Text',
  BUTTONS_GROUP: 'ButtonsGroup',
  AUTHORIZATION_DIFFERENCE: 'AuthorizationDifference',
  DELETE_SCA_BUTTON: 'DeleteScaButton',
  LINK: 'Link',
  CANCEL_BUTTON: 'CancelButton',
  OPS_TRANSFER_NEXT_STATUS: 'OpsTransferNextStatus',
  CELL: 'Cell',
  CARD_TYPE: 'CardType',
};
