import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import Empty from 'components/Empty';
import {
  StyledText,
  StyledCard,
  StyledCardHeading,
  StyledCardContent,
  StyledCardRow,
} from '../../ClientCardsStyled';

const PersonalCard = ({ inner, popover, clientData, soapUrl }) => {
  const { personal_profile: personalProfile = {} } = clientData;
  const {
    first_name: firstName,
    last_name: lastName,
    mobile_phone: mobilePhone,
    date_of_birth: dateOfBirth,
  } = personalProfile;

  return (
    <StyledCard inner={inner} popover={popover}>
      <StyledCardHeading>
        <Body noMargin variant="highlight" size="one">
          Personal Details
        </Body>
        <StyledText
          noMargin
          variant="highlight"
          size="two"
          onClick={() => soapUrl && window.open(soapUrl)}
        >
          Edit in SOAP <ChevronRight />
        </StyledText>
      </StyledCardHeading>
      <StyledCardContent>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            First name
          </Body>
          <Body noMargin size="two">
            {firstName || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Last name
          </Body>
          <Body noMargin size="two">
            {lastName || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Mobile phone
          </Body>
          <Body noMargin size="two">
            {mobilePhone || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Date of birth
          </Body>
          <Body noMargin size="two">
            {dateOfBirth || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
      </StyledCardContent>
    </StyledCard>
  );
};

PersonalCard.defaultProps = {
  inner: false,
  popover: false,
  clientData: {},
  soapUrl: '',
};

PersonalCard.propTypes = {
  inner: PropTypes.bool,
  popover: PropTypes.bool,
  clientData: PropTypes.shape({
    personal_profile: PropTypes.object,
  }),
  soapUrl: PropTypes.string,
};

export default PersonalCard;
