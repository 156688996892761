import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import Tooltip from 'components/Tooltip';
import { TRANSACTIONS_GRID_TOOLTIP_PADDING } from 'variables';
import {
  CARD_DATA_FIELD,
  CARD_DATA_MCC_DESCRIPTION,
} from 'components/Transaction/fieldsConfig';
import { TooltipContent, StyledText } from './MerchantCategoryCellStyled';
import Card from '../Card';

const tooltipStyle = {
  padding: TRANSACTIONS_GRID_TOOLTIP_PADDING,
};

const MerchantCategoryCell = ({ value, style, rowData }) => (
  <div style={style}>
    <Card>
      <Tooltip
        position="top"
        pivot={
          <StyledText noMargin size="two">
            {value}
          </StyledText>
        }
        content={
          <TooltipContent>
            Merchant category:
            <Body noMargin variant="highlight" size="two">
              {rowData[CARD_DATA_FIELD.name]
                ? rowData[CARD_DATA_FIELD.name][CARD_DATA_MCC_DESCRIPTION.name]
                : value}
            </Body>
          </TooltipContent>
        }
        tooltipStyle={tooltipStyle}
      />
    </Card>
  </div>
);

MerchantCategoryCell.defaultProps = {
  value: '',
  style: {},
  rowData: {},
};

MerchantCategoryCell.propTypes = {
  value: PropTypes.any,
  style: PropTypes.object,
  rowData: PropTypes.object,
};

export default MerchantCategoryCell;
