import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Button } from '@sumup/circuit-ui';

export const DataGridSplit = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${theme.mq.untilMega} {
      flex-direction: column;
    }
    & > div {
      width: calc(65% - 10px);
      & + div {
        width: calc(35% - 10px);
      }
      ${theme.mq.untilMega} {
        &, & + div {
        width: 100%;
      }
    }
`
);

export const StyledHeading = styled(Headline)`
  display: flex;
  justify-content: space-between;
`;

export const StyledLoadingButton = styled(Button)`
  display: flex;
  max-height: 40px;
  align-items: center;
`;
