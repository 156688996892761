import { CREATED_AT } from 'components/Transaction/fieldsConfig';
import { values, sortBy } from 'lodash/fp';
import { SORT_ORDER_DESCENDING, DATE_PERIOD_HALF_YEAR } from 'variables';
import {
  DATE_PERIOD_OPTIONS,
  TRANSACTION_STATUS_MAP,
  TRANSACTION_STATUS_FILTER,
  TRANSACTION_FILTERS_ANY_OPTION,
  TRANSACTION_TYPE_FILTER,
  TRANSACTIONS_GRID_FIRST_PAGE,
  TRANSACTION_TYPE_MAP,
  ALL_TRANSACTION_ORIGINS,
  TRANSACTION_MCC_FILTER,
  TRANSACTION_ID_FILTER,
  TRANSACTION_RRN_FILTER,
  TRANSACTION_AUTH_CODE_FILTER,
  TRANSACTION_DETAILS_FILTER,
  ALL_TRANSACTION_DIRECTIONS,
} from './constants';

export const initialState = {
  loading: true,
  error: null,
  transactions: [],
  pageSize: 10,
  currentPage: TRANSACTIONS_GRID_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  transactionOriginFilter: ALL_TRANSACTION_ORIGINS,
  transactionDirectionFilter: ALL_TRANSACTION_DIRECTIONS,
  filters: {
    [TRANSACTION_STATUS_FILTER]: TRANSACTION_FILTERS_ANY_OPTION,
    [TRANSACTION_TYPE_FILTER]: TRANSACTION_FILTERS_ANY_OPTION,
    [TRANSACTION_MCC_FILTER]: '',
    [TRANSACTION_ID_FILTER]: '',
    [TRANSACTION_RRN_FILTER]: '',
    [TRANSACTION_AUTH_CODE_FILTER]: '',
    [TRANSACTION_DETAILS_FILTER]: '',
  },
  filtersApplied: false,
  applyFiltersEnabled: false,
  datePeriod: DATE_PERIOD_OPTIONS[DATE_PERIOD_HALF_YEAR],
  transactionStatuses: [
    TRANSACTION_FILTERS_ANY_OPTION,
    ...values(TRANSACTION_STATUS_MAP),
  ],
  transactionTypes: [
    TRANSACTION_FILTERS_ANY_OPTION,
    ...sortBy('label')(values(TRANSACTION_TYPE_MAP)),
  ],
  sort: {
    [CREATED_AT.name]: SORT_ORDER_DESCENDING,
    fieldName: CREATED_AT.name,
  },
};
