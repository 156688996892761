import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

export const GoogleLoginButton = styled('div')(
  ({ theme }) => css`
    width: 190px;
    height: 42px;
    margin-top: ${theme.spacings.kilo};
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
    &:active {
      background: #1669f2;
    }
  `
);

export const GoogleLoginButtonIcon = styled('div')`
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: white;
  svg {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
`;

export const GoogleLoginButtonText = styled('p')`
  float: right;
  margin: 11px 11px 0 0;
  color: white;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: bold;
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
  }
`;

export const StyledText = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.n700};
  `
);
