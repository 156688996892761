import React from 'react';
import PropTypes from 'prop-types';
import { Body, spacing } from '@sumup/circuit-ui';
import {
  DATA_SELECTORS,
  SORT_ARROW_MAP,
  SORT_ORDER_ASCENDING,
} from 'variables';
import { StyledHeaderCell } from './StyledHeaderCell';

const HeaderCell = ({
  label,
  name,
  style,
  border,
  sort,
  sortable,
  centered,
  onSort,
}) => (
  <StyledHeaderCell
    data-testid={DATA_SELECTORS.DATA_GRID_HEADER_CELL}
    key={label}
    style={style}
    border={border}
    sorted={!!sort[name]}
    sortable={sortable}
    centered={centered}
    onClick={onSort}
  >
    <Body
      variant="highlight"
      noMargin
      size="two"
      css={spacing({ right: 'bit' })}
    >
      {label}
    </Body>
    {!!sort[name] && (
      <span data-testid={DATA_SELECTORS.DATA_GRID_HEADER_CELL_SORT_ARROW}>
        {SORT_ARROW_MAP[sort[name]] || SORT_ARROW_MAP[SORT_ORDER_ASCENDING]}
      </span>
    )}
  </StyledHeaderCell>
);

HeaderCell.defaultProps = {
  name: '',
  border: null,
  sort: {},
  style: {},
  sortable: false,
  centered: false,
  onSort: () => {},
};

HeaderCell.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  border: PropTypes.string,
  sort: PropTypes.object,
  style: PropTypes.object,
  sortable: PropTypes.bool,
  centered: PropTypes.bool,
  onSort: PropTypes.func,
};

export default HeaderCell;
