import React from 'react';
import PropTypes from 'prop-types';
import { Headline, Body, Button } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ACTIONS, APPS } from 'variables';
import { ReactComponent as Card } from './assets/card.svg';

const Container = styled('div')`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  align-items: center;
`;

const ContainerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  // Navigation height 220px + top padding 40px
  height: calc(100vh - 260px);
`;

const ButtonActivate = styled(Button)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.giga};
  `
);

const StyledText = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.n700};
  `
);

const StyledHeadline = styled(Headline)(
  ({ theme }) => css`
    margin: ${theme.spacings.giga} 0 ${theme.spacings.byte};
  `
);

const Activation = ({ hasActionAccess, onActivate }) => (
  <ContainerWrapper>
    <Container>
      <Card />
      <StyledHeadline as="h3" size="two" noMargin>
        Account and Card are not yet activated for this merchant
      </StyledHeadline>
      <StyledText noMargin size="two">
        Once activated, you will be able to manage their business account, card,
        transactions and more.
      </StyledText>
      <ButtonActivate
        variant="primary"
        disabled={
          !hasActionAccess({
            action: ACTIONS[APPS.merchant].accountcardActivationActivate,
          })
        }
        onClick={onActivate}
      >
        Activate now
      </ButtonActivate>
    </Container>
  </ContainerWrapper>
);

Activation.propTypes = {
  hasActionAccess: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
};

export default Activation;
