import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import Empty from 'components/Empty';
import moment from 'moment';
import { DATE_FORMATS } from 'variables';
import {
  StyledText,
  StyledCard,
  StyledCardHeading,
  StyledCardContent,
  StyledCardRow,
} from '../../ClientCardsStyled';

const AccountCard = ({
  inner,
  popover,
  clientData: { id, client_code: clientId, ...clientData },
  soapUrl,
}) => {
  const {
    address = {},
    merchant_profile: merchantProfile = {},
    account = {},
    signup_time: signupTime,
    verification_status: verificationStatus,
    account_contract_status: accountStatus,
  } = clientData;
  const { locale } = merchantProfile;
  const { country } = address;
  const { username } = account;

  return (
    <StyledCard inner={inner} popover={popover}>
      <StyledCardHeading>
        <Body noMargin variant="highlight" size="two">
          Account Details
        </Body>
        <StyledText
          noMargin
          variant="highlight"
          size="two"
          onClick={() => soapUrl && window.open(soapUrl)}
        >
          Edit in SOAP <ChevronRight />
        </StyledText>
      </StyledCardHeading>
      <StyledCardContent>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Language
          </Body>
          <Body noMargin size="two">
            {locale || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Country
          </Body>
          <Body noMargin size="two">
            {country || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Email
          </Body>
          <Body noMargin size="two">
            {username || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Merchant ID
          </Body>
          <Body noMargin size="two">
            {id} {clientId && `(${clientId})`}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Verification Status
          </Body>
          <Body noMargin size="two">
            {verificationStatus || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Account Status
          </Body>
          <Body noMargin size="two">
            {accountStatus || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Sign up time
          </Body>
          <Body noMargin size="two">
            {signupTime ? (
              moment(signupTime).format(
                DATE_FORMATS.SHORT_MONTH_DAY_YEAR_SHORT_TIME
              )
            ) : (
              <Empty>—</Empty>
            )}
          </Body>
        </StyledCardRow>
      </StyledCardContent>
    </StyledCard>
  );
};

AccountCard.defaultProps = {
  inner: false,
  popover: false,
  clientData: {},
  soapUrl: '',
};

AccountCard.propTypes = {
  inner: PropTypes.bool,
  popover: PropTypes.bool,
  clientData: PropTypes.shape({
    id: PropTypes.number,
    client_code: PropTypes.string,
    account: PropTypes.object,
    address: PropTypes.object,
    merchant_profile: PropTypes.object,
    verification_status: PropTypes.string,
  }),
  soapUrl: PropTypes.string,
};

export default AccountCard;
