import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Input, Body } from '@sumup/circuit-ui';
import { debounce } from 'lodash/fp';
import { DEBOUNCE_TIMEOUT_SHORT } from 'variables';
import {
  TRANSACTION_AUTH_CODE_FILTER,
  CARD_TOKEN_FILTER,
  TRANSACTION_RRN_FILTER,
} from '../../constants';

const POPOVER_ITEM_MAX_WIDTH = 288;

const StyledText = styled(Body)(
  ({ theme: { spacings } }) => css`
    align-self: flex-start;
    margin-bottom: ${spacings.bit};
  `
);

const StyledInput = styled(Input)`
  max-height: 40px;
`;

const RowFilterWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${POPOVER_ITEM_MAX_WIDTH}px;

    > p {
      align-self: flex-start;
    }

    > label {
      margin: 0;

      > span {
        margin-bottom: ${theme.spacings.bit};
        float: left;
      }
    }
  `
);

const ColFilterWrapper = styled(RowFilterWrapper)`
  display: flex;
  flex-direction: column;

  > label {
    margin: 0;
  }
`;

const stopEventPropagation = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getFilters = ({
  applyFiltersEnabled,
  cardTokenFilterValue,
  rrnFilterValue,
  authFilterValue,
  onCardTokenFilterValueChange,
  onRrnFilterValueChange,
  onAuthFilterValueChange,
  onFilterChange,
  onFiltersApply,
}) => [
  {
    children: (
      <ColFilterWrapper onClick={stopEventPropagation}>
        <StyledText noMargin size="two">
          RRN:
        </StyledText>
        <StyledInput
          noMargin
          placeholder="Enter value"
          label="Transaction RRN"
          hideLabel
          value={rrnFilterValue}
          onChange={({ target: { value } }) => onRrnFilterValueChange(value)}
          onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) =>
            onFilterChange({ field: TRANSACTION_RRN_FILTER, value })
          )}
        />
      </ColFilterWrapper>
    ),
  },
  {
    children: (
      <ColFilterWrapper onClick={stopEventPropagation}>
        <StyledText noMargin size="two">
          Auth code:
        </StyledText>
        <StyledInput
          noMargin
          placeholder="Enter value"
          label="Transaction authorization code"
          hideLabel
          value={authFilterValue}
          onChange={({ target: { value } }) => onAuthFilterValueChange(value)}
          onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) =>
            onFilterChange({ field: TRANSACTION_AUTH_CODE_FILTER, value })
          )}
        />
      </ColFilterWrapper>
    ),
  },
  {
    children: (
      <ColFilterWrapper onClick={stopEventPropagation}>
        <StyledText noMargin size="two">
          Token:
        </StyledText>
        <StyledInput
          noMargin
          placeholder="Enter value"
          label="Transaction ID"
          hideLabel
          value={cardTokenFilterValue}
          onChange={({ target: { value } }) =>
            onCardTokenFilterValueChange(value)
          }
          onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) => {
            onFilterChange({ field: CARD_TOKEN_FILTER, value });
          })}
        />
      </ColFilterWrapper>
    ),
  },
  {
    children: (
      <Button disabled={!applyFiltersEnabled} variant="primary">
        Apply filters
      </Button>
    ),
    onClick: onFiltersApply,
  },
];
