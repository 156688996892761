import { SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING } from 'variables';

export const getFieldsValues = ({ fields = [], record }) =>
  fields.reduce(
    (accumulator, { name: fieldName, ...field }) => ({
      ...accumulator,
      [fieldName]: {
        ...field,
        fieldName,
        value: record[fieldName],
      },
    }),
    {}
  );

export const getSortOrder = (currentOrder) =>
  currentOrder && currentOrder === SORT_ORDER_ASCENDING
    ? SORT_ORDER_DESCENDING
    : SORT_ORDER_ASCENDING;
