import {
  ERROR_MESSAGE,
  SEARCH_FIELDS_ACTIVITY_LOG,
  actionTypes,
} from 'variables';
import searchByFields from 'services/searchByFields';
import { map } from 'lodash/fp';

const addActorField = (
  activity,
  { first_name: firstName, last_name: lastName }
) =>
  map(
    ({ actor, ...record }) => ({
      ...record,
      actor: actor || `${firstName || ''} ${lastName || ''}`.trim(),
    }),
    activity
  );

export const activityLogReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: {
            client_activity_log: activity = [],
            personal_profile: personalProfile = {},
          },
        },
      } = action;

      return {
        ...state,
        loading: false,
        activity,
        personalProfile,
        filteredActivity: addActorField(activity, personalProfile),
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.FILTER_ACTIVITY_LOG: {
      const {
        payload: { searchTerm },
      } = action;

      const { personalProfile } = state;

      return {
        ...state,
        loading: false,
        filteredActivity: searchByFields({
          data: addActorField(state.activity, personalProfile),
          fields: SEARCH_FIELDS_ACTIVITY_LOG,
          value: searchTerm,
        }),
        filterApplied: !!searchTerm,
      };
    }
    default:
      return state;
  }
};
