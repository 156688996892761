import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Body,
  Input,
  RadioButtonGroup,
  spacing,
  useNotificationToast,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { size, split } from 'lodash';
import Modal from 'components/Modal';
import {
  DEFAULT_CURRENCY_EXPONENT,
  DEFAULT_FRACTION_DIGITS,
  MODALS,
} from 'variables';
import { isEmpty } from 'services/common';
import { issueSelfTransferReturn } from 'api';
import {
  TOTAL_AMOUNT,
  TRANSACTION_IDENTIFIER,
} from 'components/Transaction/fieldsConfig';
import { useHttpClient } from 'hooks';

const AmountRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${theme.spacings.giga};

    > div:first-of-type {
      margin-bottom: ${theme.spacings.byte};
    }
  `
);

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > div {
    display: flex;
    align-items: flex-start;
    width: 100%;

    > div {
      width: 100%;
    }
  }

  > label {
    width: 100%;
  }
`;

const Explanation = styled(Body)(
  ({ theme }) => css`
    margin: -${theme.spacings.byte} 0 ${theme.spacings.giga};
  `
);

const FULL_AMOUNT_OPTION = 'FULL';
const PARTIAL_AMOUNT_OPTION = 'PARTIAL';
const MIN_PARTIAL_AMOUNT = 0.01;

const getOptions = (amount) => [
  {
    value: FULL_AMOUNT_OPTION,
    label: `Full amount of ${amount}`,
  },
  {
    value: PARTIAL_AMOUNT_OPTION,
    label: 'Partial amount',
  },
];

const IssueReturnModal = ({ transfer, onClose }) => {
  const [selectedOption, setSelectedOption] = useState(FULL_AMOUNT_OPTION);
  const [partialAmount, setPartialAmount] = useState('');
  const [partialAmountValid, setPartialAmountValid] = useState(true);
  const [comment, setComment] = useState('');

  const totalAmount = transfer[TOTAL_AMOUNT.name];
  const { amount, currency } = totalAmount;
  const nonNegativeAmount = Math.abs(amount);
  const fullAmount = nonNegativeAmount / 10 ** DEFAULT_CURRENCY_EXPONENT;
  const formattedFullAmount = `${fullAmount.toFixed(
    DEFAULT_FRACTION_DIGITS
  )} ${currency}`;

  const httpClient = useHttpClient();
  const { setToast } = useNotificationToast();

  const handleSelectOption = ({ target: { value } }) => {
    setSelectedOption(value);

    if (value === FULL_AMOUNT_OPTION) {
      setPartialAmount('');
      setPartialAmountValid(true);
    }
  };

  const handlePartialAmountChange = ({ target: { value } }) => {
    setPartialAmount(value);

    const [, decimals] = split(value, '.') || [];
    const parsedValue = Number(value);

    setPartialAmountValid(
      parsedValue > 0 &&
        parsedValue <= fullAmount &&
        size(decimals) <= DEFAULT_FRACTION_DIGITS
    );
  };

  const handleCommentChange = ({ target: { value } }) => setComment(value);

  const handleConfirm = () =>
    issueSelfTransferReturn(httpClient, {
      token: transfer[TRANSACTION_IDENTIFIER.name],
      amount:
        selectedOption === FULL_AMOUNT_OPTION
          ? nonNegativeAmount
          : partialAmount * 10 ** DEFAULT_CURRENCY_EXPONENT,
      comment: comment || undefined,
    })
      .then(() => {
        setToast({
          variant: 'confirm',
          headline: 'Return issued successfully.',
          body: 'It is on the way to the recipient.',
        });
      })
      .catch(() => {
        setToast({
          variant: 'alert',
          headline: `Couldn't issue a transfer for this transaction.`,
          body: 'Please try again later.',
        });
      });

  const handleClose = () => {
    onClose();
    setSelectedOption(FULL_AMOUNT_OPTION);
    setPartialAmount('');
    setComment('');
  };

  const transferEnabled =
    selectedOption === FULL_AMOUNT_OPTION ||
    (partialAmountValid && !isEmpty(partialAmount));

  return (
    <Modal
      modalInfo={{
        ...MODALS.ISSUE_A_RETURN,
        content: (
          <>
            <Explanation noMargin size="one">
              This action is irreversible.
            </Explanation>
            <ContentWrapper>
              <AmountRadioButtonGroup
                value={selectedOption}
                label="Select full or partial amount"
                hideLabel
                options={getOptions(formattedFullAmount)}
                onChange={handleSelectOption}
              />
              {selectedOption === PARTIAL_AMOUNT_OPTION && (
                <Input
                  noMargin
                  css={spacing({ bottom: 'mega' })}
                  type="number"
                  min={MIN_PARTIAL_AMOUNT}
                  placeholder={`Amount (${MIN_PARTIAL_AMOUNT} - ${formattedFullAmount})`}
                  invalid={!partialAmountValid}
                  label="Partial amount"
                  hideLabel
                  onChange={handlePartialAmountChange}
                />
              )}
              <Input
                noMargin
                css={spacing({ bottom: 'byte' })}
                placeholder="Comment (optional)"
                label="Comment"
                hideLabel
                onChange={handleCommentChange}
              />
            </ContentWrapper>
          </>
        ),
        disabled: !transferEnabled,
        onConfirm: handleConfirm,
      }}
      onClose={handleClose}
    />
  );
};

IssueReturnModal.propTypes = {
  transfer: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IssueReturnModal;
