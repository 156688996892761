import React from 'react';
import { RadioButtonGroup } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    margin: ${theme.spacings.kilo} 0 ${theme.spacings.peta};

    > div {
      margin: ${theme.spacings.bit} 0;
      padding: ${theme.spacings.byte} 10px;
      background: ${theme.colors.n100};
    }
  `
);

export const ActionsRadioButtonGroup = ({ value, options, onChange }) => (
  <StyledRadioButtonGroup
    value={value}
    label="Select action"
    hideLabel
    options={options}
    onChange={onChange}
  />
);

ActionsRadioButtonGroup.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
