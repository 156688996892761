import React from 'react';
import { CUSTOM_FIELD_TYPES, EN_GB_LOCALE } from 'variables';
import { formatCurrency } from '@sumup/intl';
import moment from 'moment';
import getPreAuthInfo from '../../services/authorisations';

export const FEE = {
  label: 'Fee',
  name: 'fee',
  getValue: ({ authorization }) =>
    authorization?.fee
      ? formatCurrency(
          authorization.fee.amount / 100,
          EN_GB_LOCALE,
          authorization.fee.currency
        )
      : '—',
};

export const MCC = {
  label: 'MCC',
  name: 'mcc',
  getValue: ({ merchantDetails: { mcc, merchantCodeDescription } }) => {
    return { code: mcc, description: merchantCodeDescription };
  },
  renderAs: CUSTOM_FIELD_TYPES.MCC,
};

export const MERCHANT_DETAILS = {
  name: 'details',
  label: 'Details',
  getValue: ({ merchantDetails: { merchantNameLocation } }) =>
    merchantNameLocation,
};

export const PRE_AUTH = {
  name: 'pre_auth',
  label: 'PreAuth',
  getValue: ({ authorization: { transactionStatus } }) =>
    getPreAuthInfo(transactionStatus),
};

export const BILLING_AMOUNT = {
  label: 'Amount',
  name: 'billingAmount',
  getValue: ({
    transactionAmounts: {
      billingAmount: { amount, currency },
    },
  }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const AMOUNT = {
  label: 'Amount',
  name: 'billingAmount',
  getValue: ({
    authorization: {
      amount: { amount, currency },
    },
  }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const CLEARING_AMOUNT = {
  label: 'Amount',
  name: 'clearedAmount',
  getValue: ({
    transactionAmounts: {
      clearedAmount: { amount, currency },
    },
  }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const AUTH_IDENTIFIER = {
  label: 'Auth',
  copyable: true,
  cropText: true,
  getValue: ({ authorization: { authorizationCode } }) => authorizationCode,
};

export const CARD_IDENTIFIER = {
  label: 'Card token',
  name: 'cardToken',
  copyable: true,
  cropText: true,
  getValue: ({ authorization: { cardToken } }) => cardToken,
};

export const MID = {
  label: 'MID',
  name: 'MID',
  cropText: false,
  getValue: ({ authorization: { authorizationCode, merchantId } }) => ({
    details: { authorizationCode, merchantId },
    type: 'transactions',
  }),
  renderAs: CUSTOM_FIELD_TYPES.LINK,
};

export const TX_IDENTIFIER = {
  name: 'txId',
  label: 'Token',
  copyable: true,
  cropText: true,
  getValue: ({ authorization: { authorizationIdentifier } }) =>
    authorizationIdentifier,
};

export const RETRIEVAL_REFERENCE_NUMBER = {
  label: 'RRN',
  name: 'retrievalReferenceNumber',
  copyable: true,
  cropText: true,
  getValue: ({ authorization: { retrievalReferenceNumber } }) =>
    retrievalReferenceNumber,
};

export const DATE = {
  name: 'date',
  label: 'Date',
  getValue: ({ authorization }) =>
    authorization?.date
      ? moment(authorization.date).format('MMM DD, YYYY HH:MM:SS')
      : '—',
};

export const DIFFERENCE = {
  label: 'Amount',
  name: 'delta',
  getValue: ({
    transactionAmounts: {
      delta: { amount, currency },
    },
  }) => ({
    formattedAmount: formatCurrency(amount / 100, EN_GB_LOCALE, currency),
    plain: amount,
  }),
  renderAs: CUSTOM_FIELD_TYPES.AUTHORIZATION_DIFFERENCE,
};

export const BUTTONS_GROUP = {
  label: ' ',
  name: 'buttons',
  renderAs: CUSTOM_FIELD_TYPES.BUTTONS_GROUP,
  getValue: ({
    authorization: { authorizationId },
    transactionAmounts: {
      delta: { amount, currency },
    },
  }) => ({
    amount,
    formattedAmount: formatCurrency(amount / 100, EN_GB_LOCALE, currency),
    authorizationId,
  }),
};

const differenceColumns = [
  {
    key: DIFFERENCE.name,
    fields: [
      {
        ...DIFFERENCE,
        fullLabel: DIFFERENCE.name,
      },
    ],
  },
];

const billingAmountColumns = [
  {
    key: BILLING_AMOUNT.name,
    fields: [
      {
        ...BILLING_AMOUNT,
        fullLabel: BILLING_AMOUNT.label,
      },
    ],
  },
];

const clearingAmountColumns = [
  {
    key: CLEARING_AMOUNT.name,
    fields: [
      {
        ...CLEARING_AMOUNT,
        fullLabel: CLEARING_AMOUNT.label,
      },
    ],
  },
];

const authorizationColumns = [
  {
    key: DATE.name,
    fields: [
      {
        ...DATE,
      },
    ],
    style: {
      width: '17%',
    },
  },
  {
    key: CARD_IDENTIFIER.name,
    fields: [
      {
        ...MID,
        fullLabel: MID.name,
      },
      {
        ...CARD_IDENTIFIER,
        prefix: <span>C:</span>,
        fullLabel: CARD_IDENTIFIER.label,
      },
    ],
    style: {
      width: '20%',
    },
  },
  {
    key: 'txDetails',
    fields: [
      {
        ...TX_IDENTIFIER,
        prefix: <span>T:</span>,
        fullLabel: TX_IDENTIFIER.label,
      },
      {
        ...RETRIEVAL_REFERENCE_NUMBER,
        prefix: <span>R:</span>,
        fullLabel: RETRIEVAL_REFERENCE_NUMBER.label,
      },
      {
        ...AUTH_IDENTIFIER,
        prefix: <span>A:</span>,
        fullLabel: AUTH_IDENTIFIER.label,
      },
    ],
    style: {
      width: '25%',
    },
  },
  {
    key: PRE_AUTH.name,
    fields: [
      {
        ...PRE_AUTH,
      },
    ],
    style: {
      width: '9%',
    },
  },
  {
    key: AMOUNT.name,
    fields: [
      {
        ...AMOUNT,
        fullLabel: AMOUNT.label,
      },
    ],
    style: {
      width: '15%',
    },
  },
  {
    key: FEE.name,
    fields: [
      {
        ...FEE,
        fullLabel: FEE.label,
      },
    ],
    style: {
      width: '14%',
    },
  },
];

const buttonsColumns = [
  {
    key: BUTTONS_GROUP.name,
    fields: [
      {
        ...BUTTONS_GROUP,
        fullLabel: BUTTONS_GROUP.label,
      },
    ],
  },
];

const merchantColumns = [
  {
    key: MCC.name,
    fields: [
      {
        ...MCC,
      },
    ],
    style: {
      width: '30%',
    },
  },
  {
    key: MERCHANT_DETAILS.name,
    fields: [
      {
        ...MERCHANT_DETAILS,
      },
    ],
    style: {
      width: '70%',
    },
  },
];

export const columns = [
  {
    label: 'Authorization',
    style: {
      width: '42%',
    },
    subColumns: authorizationColumns,
  },
  {
    label: 'Billing',
    style: {
      width: '8%',
    },
    subColumns: billingAmountColumns,
  },
  {
    label: 'Clearing',
    style: {
      width: '8%',
    },
    subColumns: clearingAmountColumns,
  },
  {
    label: 'Difference',
    style: {
      width: '8%',
    },
    subColumns: differenceColumns,
  },
  {
    label: 'Merchant',
    style: {
      width: '20%',
    },
    subColumns: merchantColumns,
  },
  {
    label: '',
    style: {
      width: '14%',
    },
    subColumns: buttonsColumns,
  },
];
