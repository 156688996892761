import { map } from 'lodash';
import { Body } from '@sumup/circuit-ui';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../services/common';
import { getSortOrder } from '../../services/dataGrid';
import { SORT_ARROW_MAP, SORT_ORDER_ASCENDING } from '../../variables';

const renderSubColumnTitleContent = (
  { name, sortName, label: subColumnLabel, sortable },
  index,
  sort,
  onSort,
  SubColumnTitleContent
) => (
  <SubColumnTitleContent
    key={`sub-column-title-content-${name}`}
    sorted={!!sort[name]}
    sortable={sortable}
    onClick={() =>
      sortable &&
      onSort({
        name: sortName,
        order: getSortOrder(sort[sortName]),
      })
    }
  >
    <Body noMargin variant="highlight" size="two">
      <span>{index !== 0 && '/'}</span>
      <span>{subColumnLabel}</span>
      {!!sort[name] && (
        <span>
          {SORT_ARROW_MAP[sort[name]] || SORT_ARROW_MAP[SORT_ORDER_ASCENDING]}
        </span>
      )}
    </Body>
  </SubColumnTitleContent>
);

const renderSubColumns = (
  subColumns,
  sort,
  onSort,
  SubColumnTitle,
  SubColumnTitleContent
) => {
  return map(subColumns, ({ key, fields = [], style: colStyle }) => (
    <SubColumnTitle key={key} style={colStyle}>
      {fields
        .filter(({ label: subColumnLabel }) => !isEmpty(subColumnLabel))
        .map((field, index) =>
          renderSubColumnTitleContent(
            field,
            index,
            sort,
            onSort,
            SubColumnTitleContent
          )
        )}
    </SubColumnTitle>
  ));
};

const SortableHeaderWrapper = ({
  columns,
  sort,
  onSort,
  HeaderWrapper,
  HeaderRow,
  ColumnTitle,
  SubColumnTitle,
  SubColumnTitleContent,
}) => {
  return (
    <HeaderWrapper>
      {map(columns, ({ label: columnTitle, style, subColumns }) => (
        <div key={columnTitle} style={style}>
          <ColumnTitle>
            <Body noMargin variant="highlight" size="one">
              {columnTitle}
            </Body>
          </ColumnTitle>
          <HeaderRow>
            {renderSubColumns(
              subColumns,
              sort,
              onSort,
              SubColumnTitle,
              SubColumnTitleContent
            )}
          </HeaderRow>
        </div>
      ))}
    </HeaderWrapper>
  );
};

SortableHeaderWrapper.defaultProps = {
  sort: {},
  onSort: () => {},
};

SortableHeaderWrapper.propTypes = {
  columns: PropTypes.array.isRequired,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  HeaderRow: PropTypes.element.isRequired,
  HeaderWrapper: PropTypes.element.isRequired,
  ColumnTitle: PropTypes.element.isRequired,
  SubColumnTitle: PropTypes.element.isRequired,
  SubColumnTitleContent: PropTypes.element.isRequired,
};

export default SortableHeaderWrapper;
