import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Button, spacing, useModal } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ACTIONS, APPS } from 'variables';
import { hasActionAccess } from 'services/permissions';
import AuthContext from 'context/auth';
import { CancelDirectDebitMandateModal } from './CancelDirectDebitMandateModal';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacings.bit} ${theme.spacings.mega};
  `
);

export const CancelDirectDebitMandateButton = ({
  id,
  icon,
  onConfirm,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const { setModal } = useModal();

  const handleCancelMandate = () => {
    setModal({
      children: ({ onClose }) => (
        <CancelDirectDebitMandateModal
          id={id}
          setLoading={setLoading}
          onConfirm={onConfirm}
          onClose={onClose}
        />
      ),
      closeButtonLabel: 'Close',
    });
  };

  return hasActionAccess({
    permissions,
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardDirectDebitsCancelMandate,
  }) ? (
    <StyledButton
      {...props}
      noMargin
      destructive
      variant="primary"
      isLoading={loading}
      loadingLabel="Cancelling mandate..."
      onClick={handleCancelMandate}
    >
      {icon}
      <Body
        noMargin
        size="one"
        variant="highlight"
        css={icon && spacing({ left: 'byte' })}
      >
        Cancel mandate
      </Body>
    </StyledButton>
  ) : null;
};

CancelDirectDebitMandateButton.defaultProps = {
  icon: null,
};

CancelDirectDebitMandateButton.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
};
