import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RangePicker, Body, Popover } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import { map } from 'lodash';
import { isSameOrBeforeToday, weekOrLessPassed } from 'services/dateTime';
import {
  DATE_PERIOD_CUSTOM,
  DATE_FILTER_MIN_WIDTH,
  FILTERS_MIN_HEIGHT,
} from 'variables';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyledCard } from 'components/Transactions/TransactionsStyled';

const RangePickerWrapper = styled('div')(
  ({ theme: { spacings, typography } }) => css`
    display: flex;
    min-height: 32px;

    div:nth-of-type(3) {
      min-height: 32px;
    }

    div.DateRangePicker {
      min-width: 225px;
    }

    div.DateRangePickerInput {
      display: flex;
      align-items: center;
      padding: 0;
      border: unset !important;
      box-shadow: 0 0 0 1px rgb(12 15 20 / 7%), 0 0 1px 0 rgb(12 15 20 / 7%),
        0 2px 2px 0 rgb(12 15 20 / 7%);

      div.DateInput_1 {
        display: flex;
        align-items: center;
        margin: 0 ${spacings.byte};
        max-width: 72px;

        > input {
          min-width: 85px;
          font-size: ${typography.body.two.fontSize};
          line-height: ${typography.body.two.lineHeight};
        }
      }

      div.DateRangePickerInput_arrow {
        display: flex;
        align-items: center;
        width: 16px;
      }
    }
  `
);

const DatePeriodPopoverComponent = styled(StyledCard)(
  ({ theme: { spacings, colors } }) => css`
    display: flex;
    align-items: center;
    min-height: ${FILTERS_MIN_HEIGHT}px;
    min-width: ${DATE_FILTER_MIN_WIDTH}px;

    svg {
      margin: auto ${spacings.byte};
      color: ${colors.n500};
    }
  `
);

const StyledText = styled(Body)(
  ({ theme: { colors }, disabled }) => css`
    ${disabled
      ? `
      color: ${colors.n300};
    `
      : `
      cursor: pointer;
    `}
  `
);

const StyledPopover = styled(Popover)`
  button {
    &:focus {
      box-shadow: none;
    }
  }
`;

const DateFilter = ({
  datePeriod,
  onRangeChange,
  datePeriodOptions,
  showDateFilter = true,
}) => {
  const [datePeriodPopoverOpen, setDatePeriodPopoverOpen] = useState(false);
  const [rangePickerVisible, setRangePickerVisible] = useState(
    datePeriod.name === DATE_PERIOD_CUSTOM
  );
  const [focusedInput, setFocusedInput] = useState(null);

  const handleSelectDateRange = ({ startDate, endDate }) => {
    onRangeChange({
      ...datePeriodOptions[DATE_PERIOD_CUSTOM],
      startDate,
      endDate,
    });

    setDatePeriodPopoverOpen(false);
  };

  const handleSelectDatePeriod = ({ name, disabled, ...period }) => {
    if (!disabled) {
      setDatePeriodPopoverOpen(false);

      if (name === DATE_PERIOD_CUSTOM) {
        setRangePickerVisible(true);
      } else {
        setRangePickerVisible(false);
      }

      onRangeChange({ name, ...period });
    }
  };

  const handleTogglePopover = () => {
    setDatePeriodPopoverOpen(!datePeriodPopoverOpen);
  };

  const popoverActions = map(
    datePeriodOptions,
    ({ name, label, ...period }) => ({
      children: (
        <StyledText
          key={label}
          noMargin
          size="two"
          disabled={datePeriod.name === name}
        >
          {label}
        </StyledText>
      ),
      onClick: () =>
        handleSelectDatePeriod({
          name,
          label,
          disabled: datePeriod.name === name,
          ...period,
        }),
    })
  );

  const popoverComponent = () => (
    <DatePeriodPopoverComponent
      onClick={(e) => {
        e.stopPropagation();
        setDatePeriodPopoverOpen(true);
      }}
    >
      <Body size="two" noMargin>
        Period:
      </Body>
      <Body size="two" variant="highlight" noMargin>
        {datePeriod.label}
      </Body>
      {datePeriodPopoverOpen ? <ChevronUp /> : <ChevronDown />}
    </DatePeriodPopoverComponent>
  );

  return (
    <>
      {showDateFilter && (
        <StyledPopover
          isOpen={datePeriodPopoverOpen}
          actions={popoverActions}
          component={popoverComponent}
          onToggle={handleTogglePopover}
        />
      )}
      {rangePickerVisible && (
        <RangePickerWrapper>
          <RangePicker
            startDateId="startDate"
            endDateId="endDate"
            startDate={datePeriod.startDate}
            endDate={datePeriod.endDate}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            onDatesChange={handleSelectDateRange}
            isOutsideRange={
              (!showDateFilter && weekOrLessPassed) || isSameOrBeforeToday
            }
          />
        </RangePickerWrapper>
      )}
    </>
  );
};

DateFilter.defaultProps = {
  showDateFilter: true,
};

DateFilter.propTypes = {
  datePeriod: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    days: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
  onRangeChange: PropTypes.func.isRequired,
  datePeriodOptions: PropTypes.object.isRequired,
  showDateFilter: PropTypes.bool,
};

export default DateFilter;
