import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FileInput } from 'components/FileInput';
import { batchEnrol } from 'api';
import { useHttpClient } from 'hooks';
import { Anchor, Body, Table, spacing } from '@sumup/circuit-ui';
import { Notify } from '@sumup/icons';
import { last } from 'lodash/fp';
import { convertBatchEnrolmentErrorsIntoTableRows } from './helpers';

const tableHeaders = [
  { children: 'Code', sortable: true },
  { children: 'Message', sortable: true },
];

const Container = styled('div')`
  display: block;
  width: 700px;
  height: 150px;
  position: relative;
  margin: 0 auto;
`;

const BatchEnrolment = () => {
  const httpClient = useHttpClient();
  const [loading, setLoading] = useState(false);
  const [errorRows, setErrorRows] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [processedFile, setProcessedFile] = useState(null);

  const handleUploadSuccess = (res) => {
    try {
      setLoading(false);
      setSuccess(true);
      setProcessedFile(
        new Blob([res.data], {
          type: 'text/csv;charset=utf-8;',
        })
      );
    } catch (e) {
      setError('There was an error while processing the processed clients.');
    }
  };

  const createHandleUploadError = (file) => (res) => {
    setLoading(false);

    if (res.response?.data?.error?.length) {
      setError('There was an error while processing the file.');
      setErrorRows(
        convertBatchEnrolmentErrorsIntoTableRows(res.response.data.error)
      );
      setProcessedFile(file);
    } else {
      setError('There was an error while contacting the service.');
    }
  };

  const handleUpload = (event) => {
    const file = last(event.target.files);
    setSuccess(false);
    setError(false);

    if (!file) return;

    setLoading(true);

    batchEnrol(httpClient, file)
      .then(handleUploadSuccess)
      .catch(createHandleUploadError(file));
  };

  return (
    <Container>
      <FileInput
        loading={loading}
        error={
          error ? (
            <>
              {error}{' '}
              {processedFile ? (
                <>
                  Please check the{' '}
                  <Anchor
                    size="two"
                    variant="alert"
                    href={processedFile && URL.createObjectURL(processedFile)}
                    download
                  >
                    file
                  </Anchor>{' '}
                  for errors.
                </>
              ) : (
                'Please try again later.'
              )}
            </>
          ) : null
        }
        onChange={handleUpload}
      />
      {error && !!errorRows.length && (
        <Table
          css={spacing({ top: 'exa' })}
          rowHeaders
          headers={tableHeaders}
          rows={errorRows}
        />
      )}
      {success && (
        <>
          <Body variant="confirm" noMargin size="two">
            Horray! 🥳 All clients were processed correctly.
          </Body>
          <br />
          <Body variant="subtle" noMargin>
            <Notify size={16} /> While the file was processed correctly, some
            clients may not have been enrolled due to errors in the data. Please
            check the{' '}
            <Anchor
              href={processedFile && URL.createObjectURL(processedFile)}
              download
            >
              file
            </Anchor>{' '}
            for errors.
          </Body>
        </>
      )}
    </Container>
  );
};

export default BatchEnrolment;
