import React from 'react';
import { URLS } from 'variables';
import Welcome from 'components/Welcome';
import { ReactComponent as GoogleLogo } from './assets/googleLogo.svg';
import {
  GoogleLoginButton,
  GoogleLoginButtonIcon,
  GoogleLoginButtonText,
  Wrapper,
  StyledText,
} from './LoginStyled';

const Login = () => (
  <Wrapper>
    <Welcome bottomSeparator />
    <StyledText noMargin>
      To start using this tool,
      <br />
      please sign in with your SumUp account:
    </StyledText>
    <GoogleLoginButton onClick={() => window.location.replace(URLS.oauth)}>
      <GoogleLoginButtonIcon>
        <GoogleLogo />
      </GoogleLoginButtonIcon>
      <GoogleLoginButtonText>Sign in with Google</GoogleLoginButtonText>
    </GoogleLoginButton>
  </Wrapper>
);

export default Login;
