import { Card } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { SEARCH_WIDTH } from 'variables';

export const StyledSearchResults = styled(Card)(
  ({ theme }) => css`
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.giga};
    margin-top: ${theme.spacings.byte};
    max-height: 418px;
    padding: 0;
    overflow-y: auto;
    width: ${SEARCH_WIDTH}px;
    z-index: 1;
  `
);

export const StyledSearchResultsItem = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-bottom: 1px solid ${theme.colors.n200};
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    max-height: 60px;

    :hover {
      background: ${theme.colors.p500};
      color: ${theme.colors.bodyBg};

      * {
        color: ${theme.colors.bodyBg};
      }
    }

    svg {
      margin-left: auto;
      color: ${theme.colors.n500};
    }
  `
);

export const SearchResultContent = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 60%;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SearchResultInfoRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    color: ${theme.colors.n700};
  `
);

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:only-child,
  &:last-of-type {
    div {
      border: none;
    }
  }
`;
