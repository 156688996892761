import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TooltipComponent } from '@sumup/circuit-ui';
import { Info } from '@sumup/icons';
import { DATA_SELECTORS } from 'variables';
import { TooltipHolder } from './TooltipStyled';

const Tooltip = ({
  content,
  pivot,
  position,
  tooltipStyle,
  pivotStyle,
  ...rest
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const handleTooltipShow = () => setTooltipVisible(true);
  const handleTooltipHide = () => setTooltipVisible(false);

  return (
    <TooltipHolder
      onMouseEnter={handleTooltipShow}
      onMouseLeave={handleTooltipHide}
      data-testid={DATA_SELECTORS.TOOLTIP_CONTAINER}
      pivotStyle={pivotStyle}
      {...rest}
    >
      {pivot}
      {tooltipVisible && (
        <TooltipComponent
          data-testid={DATA_SELECTORS.TOOLTIP_CONTENT}
          position={position}
          style={tooltipStyle}
        >
          {content}
        </TooltipComponent>
      )}
    </TooltipHolder>
  );
};

Tooltip.defaultProps = {
  pivot: <Info size="16" />,
  position: 'right',
  tooltipStyle: {},
  pivotStyle: {},
};

Tooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  pivot: PropTypes.element,
  position: PropTypes.string,
  tooltipStyle: PropTypes.object,
  pivotStyle: PropTypes.object,
};

export default Tooltip;
