import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Body,
  spacing,
  TextArea,
  useNotificationToast,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import Modal from 'components/Modal';
import {
  OPS_TRANSFERS_CONFIRMATION_MODAL_WIDTH,
  OPS_TRANSFER_COMMENT_MAX_CHARACTERS,
  OPS_TRANSFER_COMMENT_MAX_LENGTH,
} from 'variables';
import { useHttpClient } from 'hooks';
import { TRY_AGAIN_LATER_MESSAGE } from 'components/OpsTransfers/constants';
import { trim } from 'lodash';

const Content = styled('div')(
  ({ theme }) => `
    width: 100%;
    margin-top: -${theme.spacings.byte};

    label {
      text-align: left;
    }
  `
);

const TextAreaWrapper = styled('div')`
  textarea {
    min-height: 96px;
  }
`;

export const OpsTransferModal = ({
  id,
  title,
  explanation,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonProps,
  confirmButtonStyles,
  successNotificationHeadline,
  successNotificationBody,
  errorNotificationHeadline,
  errorNotificationBody,
  successHandler,
  content,
  onConfirm,
  onClose,
}) => {
  const [comment, setComment] = useState();
  const [confirmButtonClicked, setConfirmButtonClicked] = useState(false);
  const [commentValidationHint, setCommentValidationHint] = useState();
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const httpClient = useHttpClient();

  const { setToast } = useNotificationToast();

  const validateComment = (value = '') => {
    const hint =
      value.length > OPS_TRANSFER_COMMENT_MAX_LENGTH
        ? OPS_TRANSFER_COMMENT_MAX_CHARACTERS
        : '';

    setCommentValidationHint(hint);

    return hint;
  };

  const handleCommentChange = ({ target: { value } }) => {
    setComment(trim(value));

    if (confirmButtonClicked) {
      validateComment(value);
    }
  };

  const handleConfirm = () => {
    if (!confirmButtonClicked) {
      setConfirmButtonClicked(true);
    }

    const hint = validateComment(comment);

    if (!hint) {
      setConfirmationLoading(true);

      onConfirm(httpClient, {
        id,
        comment,
      })
        .then(() => {
          onClose();
          successHandler();
          setConfirmationLoading(false);
        })
        .then(() => {
          setToast({
            variant: 'confirm',
            headline: successNotificationHeadline,
            body: successNotificationBody,
          });
        })
        .catch(() => {
          setToast({
            variant: 'alert',
            headline: errorNotificationHeadline,
            body: errorNotificationBody,
          });

          setConfirmationLoading(false);
        });
    }
  };

  return (
    <Modal
      modalInfo={{
        centered: true,
        width: OPS_TRANSFERS_CONFIRMATION_MODAL_WIDTH,
        title,
        confirmText: confirmButtonLabel,
        cancelText: cancelButtonLabel,
        confirmButtonProps,
        confirmButtonStyles,
        disabled: !!commentValidationHint,
        closeOnConfirm: false,
        isLoading: confirmationLoading,
        content: (
          <Content>
            <Body noMargin size="one" css={spacing({ bottom: 'giga' })}>
              {explanation}
            </Body>
            {content}
            <TextAreaWrapper>
              <TextArea
                noMargin
                label="Comment"
                placeholder="Enter value"
                optionalLabel="Optional"
                required={false}
                validationHint={commentValidationHint}
                invalid={!!commentValidationHint}
                onChange={handleCommentChange}
              />
            </TextAreaWrapper>
          </Content>
        ),
        onConfirm: handleConfirm,
      }}
      onClose={onClose}
    />
  );
};

OpsTransferModal.defaultProps = {
  successNotificationBody: '',
  errorNotificationBody: TRY_AGAIN_LATER_MESSAGE,
  confirmButtonProps: {
    destructive: false,
  },
  confirmButtonStyles: null,
  content: null,
};

OpsTransferModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  confirmButtonProps: PropTypes.object,
  confirmButtonStyles: PropTypes.object,
  successNotificationHeadline: PropTypes.string.isRequired,
  successNotificationBody: PropTypes.string,
  errorNotificationHeadline: PropTypes.string.isRequired,
  errorNotificationBody: PropTypes.string,
  successHandler: PropTypes.func.isRequired,
  content: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
