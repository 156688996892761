import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Headline, Button } from '@sumup/circuit-ui';
import { chunk, filter, values } from 'lodash';
import AuthContext from 'context/auth';
import { hasActionAccess } from 'services/permissions';
import { useHttpClient } from 'hooks';
import { ActionsRadioButtonGroup } from './ActionsRadioButtonGroup';
import {
  ACTION_RESULTS_MAP,
  ACTION_RESULT_ERROR,
  ACTION_RESULT_PENDING,
  ACTION_RESULT_SUCCESS,
  getErrorLabel,
  MASS_ACTIONS_NAV_HEIGHT,
  OPTIONS,
} from './constants';
import { EditableIdsSection } from './EditableIdsSection';

const PageContent = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
`;

const ContentBlock = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${MASS_ACTIONS_NAV_HEIGHT}px);
`;

const Block50 = styled(ContentBlock)(
  ({ theme, background }) => css`
    width: calc(50% - 18px);
    padding: ${theme.spacings.kilo} 20px;
    background: ${background ? theme.colors[background] : theme.colors.n100};
  `
);

const IdsBlock = styled(Block50)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    padding: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.borderRadius.bit};
    overflow: auto;
  `
);

export const GrayedOutText = styled(Body)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.kilo};
    color: ${theme.colors.n700};
  `
);

const optionsValues = values(OPTIONS);

const ACTIONS_CHUNK_SIZE = 10;

const MassActions = () => {
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const options = filter(optionsValues, ({ permissions: optionPermissions }) =>
    hasActionAccess({ permissions, ...optionPermissions })
  );

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [ids, setIds] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const httpClient = useHttpClient();

  const handleActionRun = async () => {
    setLoading(true);

    const { onActionRun } = selectedOption || {};

    const idsValues = values(ids);
    const batchValues = chunk(idsValues, ACTIONS_CHUNK_SIZE);

    for (let i = 0; i < batchValues.length; i += 1) {
      const batch = batchValues[i];

      const promises = batch.map(({ id }) => {
        setIds((currentIds) => ({
          ...currentIds,
          [id]: { id, result: ACTION_RESULTS_MAP[ACTION_RESULT_PENDING] },
        }));

        return onActionRun(httpClient, id)
          .then(() => {
            setIds((currentIds) => ({
              ...currentIds,
              [id]: { id, result: ACTION_RESULTS_MAP[ACTION_RESULT_SUCCESS] },
            }));
          })
          .catch((error) => {
            setIds((currentIds) => ({
              ...currentIds,
              [id]: {
                id,
                result: {
                  ...ACTION_RESULTS_MAP[ACTION_RESULT_ERROR],
                  label: getErrorLabel(error),
                },
              },
            }));
          });
      });

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(promises);
    }

    setLoading(false);

    return true;
  };

  const handleOptionChange = ({ target: { value } }) => {
    setSelectedOption(OPTIONS[value]);
  };

  return (
    <PageContent>
      {!!options.length && (
        <IdsBlock>
          {selectedOption && (
            <EditableIdsSection
              selectedOption={selectedOption}
              ids={ids}
              setIds={setIds}
              disableRunButton={setDisabled}
            />
          )}
        </IdsBlock>
      )}
      <Block50 background="white">
        {options.length ? (
          <>
            <Headline noMargin as="h2" size="two">
              List of actions
            </Headline>
            <ActionsRadioButtonGroup
              value={selectedOption?.value}
              options={options}
              onChange={handleOptionChange}
            />
            <div>
              <Button
                variant="primary"
                loadingLabel="Loading"
                isLoading={loading}
                disabled={disabled || !options.length}
                onClick={handleActionRun}
              >
                Run action
              </Button>
            </div>
          </>
        ) : (
          <GrayedOutText noMargin size="one">
            You have no permissions to perform any mass action.
          </GrayedOutText>
        )}
      </Block50>
    </PageContent>
  );
};

export default MassActions;
