import moment from 'moment';
import keyMirror from 'key-mirror-nested';

import {
  DATE_PERIOD_YESTERDAY,
  DATE_PERIOD_TWO_DAYS,
  DATE_PERIOD_FIVE_DAYS,
  DATE_PERIOD_CUSTOM,
} from 'variables';

export const AUTHORIZATIONS_FOR_ADJUSTMENT = 'Authorizations for adjustment';
export const AUTHORIZATIONS_GRID_FIRST_PAGE = 1;

export const DATE_PERIOD_OPTIONS = {
  [DATE_PERIOD_YESTERDAY]: {
    name: DATE_PERIOD_YESTERDAY,
    label: 'Yesterday',
    days: null,
    startDate: moment().subtract(1, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_TWO_DAYS]: {
    name: DATE_PERIOD_TWO_DAYS,
    label: 'Last 2 days',
    days: 2,
    startDate: moment().subtract(2, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_FIVE_DAYS]: {
    name: DATE_PERIOD_FIVE_DAYS,
    label: 'Last 5 days',
    days: 5,
    startDate: moment().subtract(5, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_CUSTOM]: {
    name: DATE_PERIOD_CUSTOM,
    label: 'Custom period',
    days: null,
  },
};

export const BUTTONS_ACTIONS = keyMirror({
  IGNORE: null,
  RETURN: null,
  CHARGE: null,
});

export const CARD_TOKEN_FILTER = 'cardToken';
export const BILLING_CURRENCY_FILTER = 'billingCurrency';

export const BILLING_CURRENCY_FILTERS_ANY_OPTION = {
  name: 'any',
  label: 'Any',
};

export const CURRENCIES = [
  'EUR',
  'GBP',
  'PLN',
  'SEK',
  'NOK',
  'CZK',
  'RON',
  'DKK',
  'HUF',
];

export const PRE_AUTH_FILTER = 'transactionStatus';
export const PRE_AUTH_FILTER_ANY_OPTION = {
  name: 'all',
  label: 'All',
};

export const PRE_AUTH_FILTER_MAP = [
  { name: '4', label: 'Yes' },
  { name: '0', label: 'No' },
];

export const TRANSACTION_FILTERS_ANY_OPTION = { name: 'any', label: 'Any' };
