import { filter, includes, some, split, indexOf, reduce, keys } from 'lodash';
import {
  createGroup as createGroupApiCall,
  updateGroup as updateGroupApiCall,
} from 'api';

export const pluralize = (text, count) => `${text}${count === 1 ? '' : 's'}`;

export const filterUsers = ({ pattern, users }) =>
  pattern
    ? filter(users, ({ text }) => {
        const patterns = split(pattern, /\s+/);

        return some(
          patterns,
          (currentPattern) => currentPattern && includes(text, currentPattern)
        );
      })
    : [...users];

export const buildPermissionsTree = ({ sections, actions }) =>
  reduce(
    sections,
    (accumulator, section) => {
      const [moduleName, sectionName] = split(section, '.');
      const sectionPath = sectionName ? section : moduleName;

      const sectionActions = filter(
        actions,
        (action) => indexOf(split(action, '.'), sectionName || moduleName) > -1
      );

      if (sectionName) {
        return {
          ...accumulator,
          [moduleName]: {
            ...(accumulator[moduleName] || {}),
            [sectionPath]: reduce(
              sectionActions,
              (prevActions, currAction) => ({
                ...prevActions,
                [currAction]: true,
              }),
              {}
            ),
          },
        };
      }

      return {
        ...accumulator,
        [moduleName]: reduce(
          sectionActions,
          (prevActions, currAction) => ({
            ...prevActions,
            [currAction]: true,
          }),
          {}
        ),
      };
    },
    {}
  );

export const getPermissionsFromPaths = ({ paths }) =>
  keys(paths).length
    ? reduce(
        paths,
        (accumulator, { path }) => {
          const [moduleName, sectionName, actionName] = split(path, '.');

          let sectionPath = {};
          let actionPath = {};

          if (moduleName && sectionName) {
            if (actionName) {
              sectionPath = `${moduleName}.${sectionName}`;
              actionPath = path;
            } else {
              sectionPath = path;
            }
          }

          return {
            ...accumulator,
            modules: {
              ...(accumulator.modules || {}),
              ...(moduleName ? { [moduleName]: true } : {}),
            },
            sections: {
              ...(accumulator.sections || {}),
              ...(sectionName ? { [sectionPath]: true } : {}),
            },
            actions: {
              ...(accumulator.actions || {}),
              ...(actionName ? { [actionPath]: true } : {}),
            },
          };
        },
        {}
      )
    : {
        modules: {},
        sections: {},
        actions: {},
      };

export const createGroup = ({
  httpClient,
  newGroupName,
  newGroupApp,
  newGroupPermissions: { modules, sections, actions },
}) =>
  createGroupApiCall({
    httpClient,
    groupName: newGroupName,
    appCode: newGroupApp,
    permissions: [
      // To do: remove active: true, when the BE stops needing it
      ...reduce(
        modules,
        (accumulator, isSelected, module) =>
          isSelected
            ? [...accumulator, { path: module, active: true }]
            : accumulator,
        []
      ),
      ...reduce(
        sections,
        (accumulator, isSelected, section) =>
          isSelected
            ? [...accumulator, { path: section, active: true }]
            : accumulator,
        []
      ),
      ...reduce(
        actions,
        (accumulator, isSelected, action) =>
          isSelected
            ? [...accumulator, { path: action, active: true }]
            : accumulator,
        []
      ),
    ],
  });

export const updateGroup = ({
  httpClient,
  selectedGroupName,
  selectedGroupNewName,
  selectedGroupApp,
  selectedGroupPermissions: { modules, sections, actions },
}) =>
  updateGroupApiCall({
    httpClient,
    groupName: selectedGroupName,
    newName: selectedGroupNewName,
    appCode: selectedGroupApp,
    permissions: [
      // To do: remove active: true, when the BE stops needing it
      ...reduce(
        modules,
        (accumulator, isSelected, module) =>
          isSelected
            ? [...accumulator, { path: module, active: true }]
            : accumulator,
        []
      ),
      ...reduce(
        sections,
        (accumulator, isSelected, section) =>
          isSelected
            ? [...accumulator, { path: section, active: true }]
            : accumulator,
        []
      ),
      ...reduce(
        actions,
        (accumulator, isSelected, action) =>
          isSelected
            ? [...accumulator, { path: action, active: true }]
            : accumulator,
        []
      ),
    ],
  });
