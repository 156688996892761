import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Input, Body, Select } from '@sumup/circuit-ui';
import { debounce } from 'lodash/fp';
import { DEBOUNCE_TIMEOUT_SHORT } from 'variables';
import { map } from 'lodash';
import {
  BILLING_CURRENCY_FILTER,
  BILLING_CURRENCY_FILTERS_ANY_OPTION,
  CARD_TOKEN_FILTER,
  CURRENCIES,
  PRE_AUTH_FILTER,
  PRE_AUTH_FILTER_ANY_OPTION,
  PRE_AUTH_FILTER_MAP,
} from '../../constants';

const POPOVER_ITEM_MAX_WIDTH = 288;

const StyledText = styled(Body)(
  ({ theme: { spacings } }) => css`
    align-self: flex-start;
    margin-bottom: ${spacings.bit};
  `
);

const StyledInput = styled(Input)`
  max-height: 40px;
`;

const RowFilterWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${POPOVER_ITEM_MAX_WIDTH}px;

    > p {
      align-self: flex-start;
    }

    > label {
      margin: 0;

      > span {
        margin-bottom: ${theme.spacings.bit};
        float: left;
      }
    }
  `
);
const StyledSelect = styled(Select)(
  ({ theme }) => css`
    select {
      max-height: 40px;
      padding: ${theme.spacings.byte} ${theme.spacings.mega};
    }

    svg {
      margin-top: 18px;
    }
  `
);

const ColFilterWrapper = styled(RowFilterWrapper)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 216px;

    :first-of-type {
      margin-right: ${theme.spacings.mega};
    }

    > label {
      margin: 0;
    }
  `
);

const stopEventPropagation = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getFilters = ({
  filters,
  applyFiltersEnabled,
  cardIdFilterValue,
  onCardTokenFilterValueChange,
  onFilterChange,
  onFiltersApply,
  showCurrencyFilter,
}) => {
  const filterChildren = [
    {
      children: (
        <>
          <ColFilterWrapper onClick={stopEventPropagation}>
            <StyledText noMargin size="two">
              Token:
            </StyledText>
            <StyledInput
              noMargin
              placeholder="Enter value"
              label="Transaction ID"
              hideLabel
              value={cardIdFilterValue}
              onChange={({ target: { value } }) =>
                onCardTokenFilterValueChange(value)
              }
              onKeyUp={debounce(
                DEBOUNCE_TIMEOUT_SHORT,
                ({ target: { value } }) => {
                  onFilterChange({ field: CARD_TOKEN_FILTER, value });
                }
              )}
            />
          </ColFilterWrapper>
        </>
      ),
    },
  ];
  if (showCurrencyFilter) {
    filterChildren.push({
      children: (
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledSelect
            noMargin
            placeholder={BILLING_CURRENCY_FILTERS_ANY_OPTION.label}
            label="Billing currency:"
            value={filters[BILLING_CURRENCY_FILTER]}
            onChange={({ target: { value } }) =>
              onFilterChange({
                field: BILLING_CURRENCY_FILTER,
                value,
              })
            }
          >
            <option
              key={BILLING_CURRENCY_FILTERS_ANY_OPTION.name}
              value={BILLING_CURRENCY_FILTERS_ANY_OPTION.name}
            >
              {BILLING_CURRENCY_FILTERS_ANY_OPTION.label}
            </option>
            {map(CURRENCIES, (currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </StyledSelect>
        </ColFilterWrapper>
      ),
    });
  }

  filterChildren.push(
    {
      children: (
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledSelect
            noMargin
            placeholder={PRE_AUTH_FILTER_ANY_OPTION}
            data-testid="transaction-status-filter"
            label="Preauthorisation:"
            value={filters[PRE_AUTH_FILTER]}
            onChange={({ target: { value } }) =>
              onFilterChange({
                field: PRE_AUTH_FILTER,
                value,
              })
            }
          >
            <option
              key={PRE_AUTH_FILTER_ANY_OPTION.name}
              value={PRE_AUTH_FILTER_ANY_OPTION.name}
            >
              {PRE_AUTH_FILTER_ANY_OPTION.label}
            </option>
            {PRE_AUTH_FILTER_MAP.map((option) => (
              <option key={option.name} value={option.name}>
                {option.label}
              </option>
            ))}
          </StyledSelect>
        </ColFilterWrapper>
      ),
    },
    {
      children: (
        <Button disabled={!applyFiltersEnabled} variant="primary">
          Apply filters
        </Button>
      ),
      onClick: onFiltersApply,
    }
  );
  return filterChildren;
};
