import {
  getUserDetails,
  getClient,
  getMerchantAccountStatus,
  getAccountById,
  getGasAccountDetails,
} from 'api';

export const getMerchantPageData = async ({ clientId, httpClient }) => {
  let result = await getUserDetails({ clientId, httpClient });
  let externalLinks = {};

  try {
    const { data: clientData } = await getClient({ clientId, httpClient });
    const {
      data: { merchant_account_status: merchantAccountStatus },
    } = await getMerchantAccountStatus({ merchantCode: clientId, httpClient });

    const { bank_account_identifier: accountId } = clientData || {};

    try {
      const {
        data: { iban, created_at: createdAt },
      } = await getGasAccountDetails(httpClient, {
        merchantCode: clientId,
      });

      clientData.iban = iban;
      clientData.bank_account_issue_date = createdAt;
    } catch (e) {} // eslint-disable-line

    if (accountId) {
      const { data: accountData } = await getAccountById(httpClient, {
        accountId,
      });

      externalLinks = accountData?.external_links || {};
    }

    result = {
      ...result,
      data: {
        ...result.data,
        ...clientData,
        merchantAccountStatus,
      },
      externalLinks,
    };
  } catch (e) {
    result = {
      ...result,
      error: e,
    };
  }

  return result;
};
