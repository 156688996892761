import moment from 'moment';
import { DATE_PERIOD_CUSTOM, ONE_WEEK } from 'variables';

export const AUTHORIZATIONS_FOR_CANCELLATION =
  'Authorizations for cancellation';
export const CANCEL_AUTHORIZATIONS_GRID_FIRST_PAGE = 1;

export const DATE_PERIOD_OPTIONS = {
  [DATE_PERIOD_CUSTOM]: {
    name: DATE_PERIOD_CUSTOM,
    label: 'Custom period',
    startDate: moment().subtract(ONE_WEEK + 1, 'd'),
    endDate: moment().subtract(ONE_WEEK, 'd'),
  },
};

export const TRANSACTION_FILTERS_ANY_OPTION = { name: 'any', label: 'Any' };

export const CARD_TOKEN_FILTER = 'cardToken';

export const PRE_AUTH_TRANSACTION_STATUS = '4';

export const AUTH_TRANSACTION_STATUS = '0';
