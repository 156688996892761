import React from 'react';
import moment from 'moment';
import { Body, Button, spacing } from '@sumup/circuit-ui';
import { ExternalLink } from '@sumup/icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  CUSTOM_FIELD_TYPES,
  DATE_FORMATS,
  FIELD_TYPES,
  PATHS,
} from 'variables';
import {
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_CREATED,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_REJECTED,
} from './constants';

const NARROW_CELL_MAX_WIDTH = '140px';
const VIEW_TRANSACTION_CELL_WIDTH = '240px';

const ViewTransactionButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacings.bit} ${theme.spacings.mega};
  `
);

const ViewTransactionWrapper = styled('div')`
  display: flex;
  justify-content: center;
  max-width: ${VIEW_TRANSACTION_CELL_WIDTH};
`;

export const DATE = {
  label: 'Date',
  name: 'execution_time',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  centered: true,
  sortable: true,
  sorted: true,
  style: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
};

const STATUS_BADGE_MAP = {
  [PAYMENT_STATUS_CREATED]: {
    label: PAYMENT_STATUS_CREATED,
    background: '#F6F7F9',
    color: '#000000',
  },
  [PAYMENT_STATUS_COMPLETED]: { label: 'Successful', background: '#018850' },
  [PAYMENT_STATUS_REJECTED]: { label: 'Unsuccessful', background: '#DE331D' },
  [PAYMENT_STATUS_CANCELLED]: {
    label: PAYMENT_STATUS_CANCELLED,
    background: '#DE331D',
  },
  [PAYMENT_STATUS_PENDING]: {
    label: 'Processing',
    background: '#000000',
  },
};

const STATUS = {
  label: 'Status',
  name: 'status',
  renderAs: CUSTOM_FIELD_TYPES.STATUS_BADGE,
  getValue: ({ status }) => STATUS_BADGE_MAP[status],
  centered: true,
  style: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
  cellStyle: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
};

const CURRENCY = {
  name: 'currency',
};

const AMOUNT = {
  label: 'Amount',
  name: 'amount',
  renderAs: CUSTOM_FIELD_TYPES.AMOUNT,
  currencyField: CURRENCY,
  headerBorder: 'none',
  // Amount of 10 comes as 1000 from BE
  formatNumber: (number) => number / 100,
  getTextStyles: ({ status }) =>
    status === PAYMENT_STATUS_REJECTED || status === PAYMENT_STATUS_CANCELLED
      ? {
          variant: 'subtle',
          style: {
            textDecoration: 'line-through',
          },
        }
      : {},
};

const VIEW_TRANSACTION = {
  label: '',
  centered: true,
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (record, fieldName, { clientId }) => (
    <ViewTransactionWrapper>
      <ViewTransactionButton
        onClick={() =>
          window.open(
            `/${PATHS.client}/${clientId}/${PATHS.transactions}?rrn=${record?.id}`
          )
        }
      >
        <ExternalLink size="16" />
        <Body
          noMargin
          size="one"
          variant="highlight"
          css={spacing({ left: 'bit' })}
        >
          View transaction
        </Body>
      </ViewTransactionButton>
    </ViewTransactionWrapper>
  ),
  style: {
    minWidth: VIEW_TRANSACTION_CELL_WIDTH,
    maxWidth: VIEW_TRANSACTION_CELL_WIDTH,
  },
};

export const columns = [
  {
    ...DATE,
    active: true,
  },
  STATUS,
  AMOUNT,
  VIEW_TRANSACTION,
];
