// To do
export const TOOLTIPS = {
  // CASH: 'Cash example tooltip',
  // ALL: 'All example tooltip',
  // ATM: 'ATM example tooltip',
  // CASHBACK: 'Cashback example tooltip',
  // CNP: 'CNP example tooltip',
  // NON_CASH: 'Non cash example tooltip',
  // POS: 'POS example tooltip',
  // CONTACTLESS: 'Contactless example tooltip',
  // ATM_BALANCE_INQUIRY: 'ATM balance inquiry example tooltip',
  // ATM_CHANGE_PIN: 'ATM change pin example tooltip',
};
