import { CREATED_AT } from 'components/Transaction/fieldsConfig';
import { SORT_ORDER_DESCENDING } from 'variables';
import { OPS_TRANSFERS_GRID_FIRST_PAGE, ALL_FILTERS_VALUE } from './constants';

export const initialState = {
  loading: true,
  error: null,
  opsTransfers: [],
  pageSize: 10,
  currentPage: OPS_TRANSFERS_GRID_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  statusFilter: ALL_FILTERS_VALUE,
  currencyFilter: ALL_FILTERS_VALUE,
  statuses: [],
  sort: {
    [CREATED_AT.name]: SORT_ORDER_DESCENDING,
    fieldName: CREATED_AT.name,
  },
  filtersApplied: false,
};
