import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import moment from 'moment';
import { Alert, Confirm, PaidOut } from '@sumup/icons';
import {
  ONE_OFF_SCHEDULE_STATUS_COMPLETED,
  ONE_OFF_SCHEDULE_STATUS_REJECTED,
  PAYMENT_SCHEDULE_TYPE_MONTHLY,
  PAYMENT_SCHEDULE_TYPE_WEEKLY,
  PAYMENT_STATUS_CANCELLED,
  STATUS_ACTIVE,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  TYPE_ONE_OFF,
} from './constants';

const IconSuccessful = styled(Confirm)(
  ({ theme }) => css`
    color: ${theme.colors.confirm};
  `
);

const IconUnsuccessful = styled(Alert)(
  ({ theme }) => css`
    color: ${theme.colors.alert};
  `
);

const ONE_OFF_INACTIVE_SCHEDULE_STATUS = {
  [ONE_OFF_SCHEDULE_STATUS_COMPLETED.value]: {
    ...ONE_OFF_SCHEDULE_STATUS_COMPLETED,
    icon: <IconSuccessful size="16" />,
  },
  [PAYMENT_STATUS_CANCELLED]: {
    label: PAYMENT_STATUS_CANCELLED,
    icon: <IconUnsuccessful size="16" />,
  },
  [ONE_OFF_SCHEDULE_STATUS_REJECTED.value]: {
    ...ONE_OFF_SCHEDULE_STATUS_REJECTED,
    icon: <IconUnsuccessful size="16" />,
  },
};

const SCHEDULES_STATUS_MAP = {
  [STATUS_ACTIVE.value]: {
    ...STATUS_ACTIVE,
    icon: <IconSuccessful size="16" />,
  },
  [STATUS_COMPLETED.value]: {
    ...STATUS_COMPLETED,
    icon: <PaidOut size="16" />,
  },
  [STATUS_CANCELLED.value]: {
    ...STATUS_CANCELLED,
    icon: <IconUnsuccessful size="16" />,
  },
};

export const getScheduledPaymentStatus = ({ schedule }) => {
  if (!schedule) {
    return {};
  }

  const { type, status, latest_payment_status: latestPaymentStatus } = schedule;
  const oneOffInactiveScheduleStatus = latestPaymentStatus
    ? ONE_OFF_INACTIVE_SCHEDULE_STATUS[latestPaymentStatus]
    : SCHEDULES_STATUS_MAP[status];

  return type === TYPE_ONE_OFF.value && status !== STATUS_ACTIVE.value
    ? oneOffInactiveScheduleStatus || {}
    : SCHEDULES_STATUS_MAP[status] || {};
};

const calculateNextDueDate = ({ currentDueDate, startDate }) => {
  const oneMonthAfterDueDate = moment(currentDueDate).add(1, 'month');
  const day = Math.min(
    Math.max(oneMonthAfterDueDate.date(), startDate.date()),
    oneMonthAfterDueDate.daysInMonth()
  );

  return new Date(
    oneMonthAfterDueDate.year(),
    oneMonthAfterDueDate.month(),
    day
  );
};

export const isFinalPaymentOfSchedule = ({
  type,
  nextDue: currentDueDate,
  scheduleStart,
  scheduleEnd,
}) => {
  const dueDate = moment(currentDueDate).startOf('day');
  const startDate = moment(scheduleStart).startOf('day');
  const endDate = moment(scheduleEnd).startOf('day');

  if (type === PAYMENT_SCHEDULE_TYPE_WEEKLY) {
    return dueDate.add(1, 'week').isAfter(endDate);
  }

  if (type === PAYMENT_SCHEDULE_TYPE_MONTHLY) {
    const nextDueDate = calculateNextDueDate({ currentDueDate, startDate });

    return moment(nextDueDate).isAfter(endDate);
  }

  return false;
};
