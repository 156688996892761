import keyMirror from 'key-mirror-nested';
import { SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING } from './globals';

export const STATUS_COLORS = {
  ACTIVE: 'confirm',
  ACTIVE_NO_RENEWAL: 'confirm',
  BLOCKED_PERMANENTLY: 'alert',
  BLOCKED_TEMPORARILY: 'alert',
  BLOCKED_TEMPORARILY_NO_RENEWAL: 'alert',
  LOST: 'alert',
  PENDING: 'neutral',
  REJECTED: 'neutral',
  FAILED: 'neutral',
  STOLEN: 'alert',
  REFUNDED: 'alert',
  DEFAULT: 'neutral',
};

export const STATUSES = {
  APPROVED: 'approved',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  STOLEN: 'STOLEN',
  LOST: 'LOST',
  BLOCKED_PERMANENTLY: 'BLOCKED_PERMANENTLY',
  BLOCKED_TEMPORARILY: 'BLOCKED_TEMPORARILY',
  ACTIVE: 'ACTIVE',
  REPLACED: 'REPLACED',
};

export const STATE_LABELS = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
};

export const DATA_SELECTORS = keyMirror({
  DATA_GRID_HEADER_CELL: null,
  DATA_GRID_HEADER_CELL_SORT_ARROW: null,
  TRANSACTION_BUTTON: null,
  CREATE_BANK_ACCOUNT_BUTTON: null,
  ISSUE_CARD_BUTTON: null,
  SEARCH_INPUT: null,
  ADMIN_SEARCH_BUTTON: null,
  ADMIN_SEARCH_INPUT: null,
  ADMIN_DETAILS_LINK: null,
  TOOLTIP_CONTAINER: null,
  TOOLTIP_CONTENT: null,
  ERROR_CONTAINER: null,
  HIDE_ERROR: null,
  SEARCH_RESULTS: null,
  LOG_OUT_BUTTON: null,
});

export const TEXTAREA_MAX_LENGTH = 100;

export const MFA_STATUSES = {
  BLOCKED: 'blocked',
  UNBLOCKED: 'unblocked',
  ENROLLED: 'enrolled',
  UNENROLLED: 'unenrolled',
  ENROLLED_BLOCKED: 'enrolled, blocked',
  NOT_ENROLLED: 'not enrolled',
};

export const MFA_STATUS_BUTTONS = {
  BLOCK: 'block',
  UNENROLL: 'unenroll',
  UNBLOCK: 'unblock',
  ENROLL: 'enroll ',
};

export const CARD_STATUSES = keyMirror({
  ACTIVE: null,
  ACTIVE_NO_RENEWAL: null,
  BLOCKED_TEMPORARILY: null,
  BLOCKED_TEMPORARILY_NO_RENEWAL: null,
  BLOCKED_PERMANENTLY: null,
  LOST: null,
  STOLEN: null,
  READY_FOR_REISSUING: null,
  EXPIRED: null,
});

export const CARD_STATUSES_MAPPED = {
  ACTIVE: 'Active',
  ACTIVE_NO_RENEWAL: 'Active no renewal',
  BLOCKED_PERMANENTLY: 'Blocked, permanently',
  BLOCKED_TEMPORARILY: 'Blocked, temporary',
  LOST: 'Lost',
  READY_FOR_REISSUING: 'Active',
  STOLEN: 'Stolen',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  REPLACED: 'Replaced',
};

export const ACTIVE_CARD_STATUSES = {
  [CARD_STATUSES.ACTIVE]: true,
  [CARD_STATUSES.ACTIVE_NO_RENEWAL]: true,
  [CARD_STATUSES.BLOCKED_TEMPORARILY]: true,
  [CARD_STATUSES.BLOCKED_TEMPORARILY_NO_RENEWAL]: true,
  [CARD_STATUSES.READY_FOR_REISSUING]: true,
};

export const CARD_FINAL_STATUSES = keyMirror({
  BLOCKED_PERMANENTLY: null,
  LOST: null,
  STOLEN: null,
  EXPIRED: null,
  REPLACED: null,
});

export const CLIENT_ONBOARD_STATUSES = keyMirror({
  ONBOARDED: null,
});

export const PAYMENTS_BLOCKED_STATUS = 'PAYMENTS_BLOCKED';

export const PAGE_PADDING = 40;
export const NARROW_GRID_COLUMN_MAX_WIDTH = 130;
export const DATE_GRID_COLUMN_MAX_WIDTH = 180;
export const NAVIGATION_BACK_BUTTON_WIDTH = 40;
export const TOP_BAR_HEIGHT = 40;
export const NAVIGATION_MAX_HEIGHT = 140;
export const NAVIGATION_CARDS_MAX_ROWS_COUNT = 4;
export const NAVIGATION_HEADING_MAX_HEIGHT = 41;
export const NAVIGATION_HEADING_PADDING_TOP_BOTTOM = 16;
export const WALLET_TOKENS_MODAL_MIN_WIDTH = 1240;
export const WALLET_TOKENS_MODAL_MIN_HEIGHT = 500;
export const WALLET_TOKENS_CONFIRMATION_MODAL_WIDTH = 420;
export const LOCK_CARD_CONFIRMATION_MODAL_WIDTH = 420;
export const TRANSACTIONS_GRID_TOOLTIP_PADDING = 8;
export const CANCEL_TRANSACTION_CONFIRMATION_MODAL_WIDTH = 440;
export const DATE_FILTER_MIN_WIDTH = 175;
export const FILTERS_MIN_HEIGHT = 32;
export const PAGE_SIZE_DROPDOWN_MIN_WIDTH = 130;
export const TRANSACTION_DETAILS_MODAL_WIDTH = 1200;
export const TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH = 150;
export const TRANSACTION_TOOLTIP_CONTENT_MAX_WIDTH = 245;
export const SEARCH_WIDTH = 620;
export const TRANSACTION_DIRECTION_DROPDOWN_FILTER_MIN_WIDTH = 85;
export const TRANSACTION_ORIGIN_ICON_SIZE_LARGE = 16;
export const TRANSACTION_ORIGIN_ICON_SIZE_SMALL = 11;
export const OPS_TRANSFERS_CONFIRMATION_MODAL_WIDTH = 420;

export const CURRENCY_CONVERSION_DEFAULT_VALUE = 1;
export const CURRENCY_CONVERSION_FRACTION_DIGITS = 3;

export const SORT_ARROW_MAP = {
  [SORT_ORDER_ASCENDING]: '▲',
  [SORT_ORDER_DESCENDING]: '▼',
};

export const ACCOUNT_STATUS_MAP = keyMirror({
  ACTIVE: null,
  BLOCKED: null,
  CLOSED: null,
});

export const BANK_TRX_FRAUD_STATUS_MAP = keyMirror({
  SUCCESSFUL: null,
  PENDING: null,
  REJECTED: null,
});

export const POPOVER_DIVIDER = 'divider';

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const REPLACE_REASONS = keyMirror({
  OTHER: null,
  STOLEN: null,
  EXPIRING: null,
});
