export const convertBatchEnrolmentErrorsIntoTableRows = (errors) => {
  const rows = [];

  errors.forEach((error) => {
    const cells = [];

    cells[0] = error?.code;
    cells[1] = error?.message;

    rows.push({ cells });
  });

  return rows;
};
