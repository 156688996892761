import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHttpClient } from 'hooks';
import { buildDownloadTransferStatementUrl, downloadAsBlob } from 'api';
import {
  CREATED_AT,
  RETRIEVAL_REFERENCE_NUMBER,
} from 'components/Transaction/fieldsConfig';
import { EN_GB_LOCALE, LOCALES_MAP } from 'variables';
import DownloadStatementModal from './DownloadStatementModal';
import { STATEMENT_DATE_FORMAT, STATEMENT_FILE_TYPE } from './constants';

const getStatementFileName = (date) =>
  `${moment(date).format(
    STATEMENT_DATE_FORMAT
  )}_Transfer_statement.${STATEMENT_FILE_TYPE}`;

const DownloadTransferStatementModal = ({ record, ...props }) => {
  const httpClient = useHttpClient();

  const handleConfirm = (language) =>
    downloadAsBlob(httpClient, {
      url: buildDownloadTransferStatementUrl({
        paymentId: record[RETRIEVAL_REFERENCE_NUMBER.name],
        locale: LOCALES_MAP[language] || EN_GB_LOCALE,
      }),
      filename: getStatementFileName(record[CREATED_AT.name]),
    });

  return <DownloadStatementModal {...props} onConfirm={handleConfirm} />;
};

DownloadTransferStatementModal.propTypes = {
  record: PropTypes.object.isRequired,
};

export default DownloadTransferStatementModal;
