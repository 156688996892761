import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const TooltipHolder = styled('div')(
  ({ theme, pivotStyle }) => css`
    position: relative;
    display: flex;

    svg {
      margin-left: ${theme.spacings.byte};
      color: ${theme.colors.n300};
      cursor: pointer;
      ${pivotStyle};
    }
  `
);
