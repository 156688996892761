import keyMirror from 'key-mirror-nested';
import { EN_GB_LOCALE } from './globals';

export const LANGUAGE_NAMES = keyMirror({
  Bulgarian: null,
  Czech: null,
  Croatia: null,
  Danish: null,
  Dutch: null,
  Estonian: null,
  English: null,
  Finish: null,
  French: null,
  German: null,
  Greek: null,
  Hungarian: null,
  Italian: null,
  Latvia: null,
  Lithuanian: null,
  Norwegian: null,
  Polish: null,
  Portuguese: null,
  Spanish: null,
  Romanian: null,
  Slovenian: null,
  Slovakian: null,
  Swedish: null,
});

const LOCALES = {
  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: EN_GB_LOCALE,
  es: 'es-ES',
  et: 'et-EE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  it: 'it-IT',
  lt: 'lt-LT',
  lv: 'lv-LV',
  nb: 'nb-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-BR',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
};

export const LOCALES_MAP = {
  [LANGUAGE_NAMES.Bulgarian]: LOCALES.bg,
  [LANGUAGE_NAMES.Czech]: LOCALES.cs,
  [LANGUAGE_NAMES.Croatia]: LOCALES.hr,
  [LANGUAGE_NAMES.Danish]: LOCALES.da,
  [LANGUAGE_NAMES.Dutch]: LOCALES.nl,
  [LANGUAGE_NAMES.Estonian]: LOCALES.et,
  [LANGUAGE_NAMES.English]: LOCALES.en,
  [LANGUAGE_NAMES.Finish]: LOCALES.fi,
  [LANGUAGE_NAMES.French]: LOCALES.fr,
  [LANGUAGE_NAMES.German]: LOCALES.de,
  [LANGUAGE_NAMES.Greek]: LOCALES.el,
  [LANGUAGE_NAMES.Hungarian]: LOCALES.hu,
  [LANGUAGE_NAMES.Italian]: LOCALES.it,
  [LANGUAGE_NAMES.Latvia]: LOCALES.lv,
  [LANGUAGE_NAMES.Lithuanian]: LOCALES.lt,
  [LANGUAGE_NAMES.Norwegian]: LOCALES.nb,
  [LANGUAGE_NAMES.Polish]: LOCALES.pl,
  [LANGUAGE_NAMES.Portuguese]: LOCALES.pt,
  [LANGUAGE_NAMES.Spanish]: LOCALES.es,
  [LANGUAGE_NAMES.Romanian]: LOCALES.ro,
  [LANGUAGE_NAMES.Slovenian]: LOCALES.sl,
  [LANGUAGE_NAMES.Slovakian]: LOCALES.sk,
  [LANGUAGE_NAMES.Swedish]: LOCALES.sv,
};
