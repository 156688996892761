import React, { useCallback } from 'react';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { useParams } from 'react-router-dom';
import { reduce } from 'lodash';
import moment from 'moment';
import {
  DATE_FORMATS,
  CLEARINGS_DATE_PERIOD_COOKIE,
  CLEARINGS_PAGE_SIZE_COOKIE,
} from 'variables';
import Cookies from 'js-cookie';
import Error from 'components/Error';
import {
  getUnmatchedClearings,
  getCardAuthorizations,
  matchAuthorization,
} from 'api';
import { setDatePeriodCookie } from 'services/transactions';
import { loadSuccess, loadError, setLoading } from 'actions';
import { matchClearingsReducer } from './matchClearingsReducer';
import {
  setPageSize,
  setFilterDateRange,
  setPage,
  setFilter,
  applyFilters,
  clearFilters,
  loadAuthorizationsSuccess,
} from './matchClearingsActions';
import { MatchClearings } from './MatchClearings';
import { initialState } from './state';
import { DATE_PERIOD_OPTIONS } from './constants';
import { columns } from './fieldsConfig';

const datePeriodCookie = Cookies.get(CLEARINGS_DATE_PERIOD_COOKIE);
const pageSizeCookie = Cookies.get(CLEARINGS_PAGE_SIZE_COOKIE);

let initialDatePeriod = { ...initialState.datePeriod };
let initialPageSize = initialState.size;

if (datePeriodCookie) {
  const [name, startDate, endDate] = datePeriodCookie.split('_');

  initialDatePeriod = {
    name,
    startDate: moment(startDate, DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
    endDate: moment(endDate, DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
    ...DATE_PERIOD_OPTIONS[name],
  };
} else {
  const { datePeriod } = initialState;
  setDatePeriodCookie(datePeriod, CLEARINGS_DATE_PERIOD_COOKIE);
}

if (pageSizeCookie) {
  initialPageSize = pageSizeCookie;
} else {
  const { size } = initialState;
  Cookies.set(CLEARINGS_PAGE_SIZE_COOKIE, size);
}

const MatchClearingsData = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();
  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getUnmatchedClearings({
          httpClient,
          page: initialState.currentPage,
          size: Number(initialPageSize),
          from: moment(initialDatePeriod.startDate).format(
            DATE_FORMATS.YEAR_SHORT_MONTH_DAY
          ),
          to: moment(initialDatePeriod.endDate).format(
            DATE_FORMATS.YEAR_SHORT_MONTH_DAY
          ),
        }),
      [httpClient]
    ),
    clientId,
    matchClearingsReducer,
    {
      ...initialState,
      datePeriod: {
        ...initialState.datePeriod,
        ...initialDatePeriod,
      },
      size: Number(initialPageSize),
      filters: initialState.filters,
      filtersApplied: initialState.filtersApplied,
    }
  );

  const {
    loading,
    error,
    size,
    filtersApplied,
    applyFiltersEnabled,
    filters,
    unmatchedClearings,
    cardAuthorizations,
    datePeriod = {},
    totalPages,
    currentPage,
    authorizationsTotalPages,
    authorizationsPageNumber,
  } = state;

  const from = datePeriod.startDate;
  const to = datePeriod.endDate;

  const selectedFilters = reduce(
    filters,
    (accumulator, value, filter) => ({
      ...accumulator,
      [filter]: value.name || value,
    }),
    {}
  );

  const updateUnmatchedClearings = (args, successHandler) => {
    dispatch(setLoading(true));

    return getUnmatchedClearings({
      httpClient,
      ...args,
    })
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          successHandler();
        }
      })
      .catch(() => dispatch(loadError()));
  };

  const handleFilterChange = ({ field, value }) => {
    dispatch(setFilter({ field, value }));
  };

  const handleFiltersApply = () => {
    const { currentPage: page } = initialState;

    updateUnmatchedClearings(
      {
        size,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        ...selectedFilters,
      },
      () => {
        dispatch(applyFilters());
      }
    );
  };

  const handleFiltersClear = () => {
    const { currentPage: page } = initialState;

    updateUnmatchedClearings(
      {
        size,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
      },
      () => {
        dispatch(clearFilters());
      }
    );
  };

  const handlePageSizeChange = (pageSize) => {
    const { currentPage: page } = initialState;

    updateUnmatchedClearings(
      {
        size: pageSize,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        ...selectedFilters,
      },
      () => {
        dispatch(setPageSize({ size: pageSize }));

        Cookies.set(CLEARINGS_PAGE_SIZE_COOKIE, pageSize);
      }
    );
  };

  const handleDateRangeChange = (dateRange) => {
    dispatch(setFilterDateRange(dateRange));

    const { name, startDate, endDate } = dateRange;

    if (startDate && endDate) {
      setDatePeriodCookie(
        { name, startDate, endDate },
        CLEARINGS_DATE_PERIOD_COOKIE
      );

      const { currentPage: page } = initialState;

      updateUnmatchedClearings(
        {
          size,
          page,
          from: moment(startDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          to: moment(endDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          ...selectedFilters,
        },
        () => dispatch(setPage({ page }))
      );
    }
  };

  const handleGetCardAuthorizations = ({
    cardToken,
    unmatchedClearingDate,
    page,
  }) =>
    getCardAuthorizations({
      httpClient,
      cardToken,
      from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
      to: moment(unmatchedClearingDate)
        .endOf('day')
        .format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
      page,
    })
      .then((data) => {
        dispatch(
          loadAuthorizationsSuccess(data, page ? state.cardAuthorizations : [])
        );
      })
      .catch(() => dispatch(loadError()));

  const handleMatchAuthorization = ({ clearingIds, authorizationId, type }) =>
    matchAuthorization({
      httpClient,
      clearingIds,
      authorizationId,
      type,
    }).then((data) => {
      dispatch(loadAuthorizationsSuccess(data, []));
    });

  const handlePageChange = (page) => {
    updateUnmatchedClearings(
      {
        size,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        ...selectedFilters,
      },
      () => dispatch(setPage({ page }))
    );
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      <MatchClearings
        size={size}
        onPageSizeChange={handlePageSizeChange}
        datePeriod={datePeriod}
        onDateRangeChange={handleDateRangeChange}
        filtersApplied={filtersApplied}
        applyFiltersEnabled={applyFiltersEnabled}
        onFilterChange={handleFilterChange}
        onFiltersApply={handleFiltersApply}
        onFiltersClear={handleFiltersClear}
        onGetCardAuthorizations={handleGetCardAuthorizations}
        filters={filters}
        unmatchedClearings={unmatchedClearings}
        columns={columns}
        cardAuthorizations={cardAuthorizations}
        onMatchAuthorization={handleMatchAuthorization}
        dispatch={dispatch}
        loading={loading}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        authorizationsTotalPages={authorizationsTotalPages}
        authorizationsPageNumber={authorizationsPageNumber}
      />
    </>
  );
};

export default MatchClearingsData;
