import { actionTypes } from 'variables';

export const limitsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: { clientLimits },
      } = action;

      return {
        ...state,
        loading: false,
        clientLimits,
      };
    }
    case actionTypes.SET_CLIENT_LIMITS: {
      const {
        payload: { clientLimits },
      } = action;

      return {
        ...state,
        loading: false,
        clientLimits,
      };
    }
    default:
      return state;
  }
};
