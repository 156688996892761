import { actionTypes } from 'variables';

export const setPage = (page) => ({
  type: actionTypes.SET_DIRECT_DEBIT_MANDATES_PAGE,
  payload: { page },
});

export const setPageSize = (size) => ({
  type: actionTypes.SET_DIRECT_DEBIT_MANDATES_PAGE_SIZE,
  payload: { size },
});

export const setStatusFilter = (status) => ({
  type: actionTypes.SET_DIRECT_DEBIT_MANDATES_STATUS_FILTER,
  payload: { status },
});

export const sortMandates = ({ name, order }) => ({
  type: actionTypes.SORT_DIRECT_DEBIT_MANDATES,
  payload: { name, order },
});

export const setSelectedMandate = (selectedMandate) => ({
  type: actionTypes.SET_SELECTED_MANDATE,
  payload: { selectedMandate },
});

export const loadMandateTransactionHistorySuccess = (data) => ({
  type: actionTypes.LOAD_MANDATE_TRANSACTION_HISTORY_SUCCESS,
  payload: data,
});

export const clearMandateTransactionHistory = () => ({
  type: actionTypes.CLEAR_MANDATE_TRANSACTION_HISTORY,
});

export const setMandateTransactionHistoryLoading = (mandateId) => ({
  type: actionTypes.SET_MANDATE_TRANSACTION_HISTORY_LOADING,
  payload: { mandateId },
});

export const setMandateUpcomingPayment = (upcomingPayment) => ({
  type: actionTypes.SET_MANDATE_UPCOMING_PAYMENT,
  payload: { upcomingPayment },
});

export const sortMandateHistory = ({ name, order }) => ({
  type: actionTypes.SORT_DIRECT_DEBIT_MANDATE_HISTORY,
  payload: { name, order },
});

export const setMandateHistoryPage = (page) => ({
  type: actionTypes.SET_MANDATE_HISTORY_PAGE,
  payload: { page },
});

export const setMandateHistoryPageSize = (size) => ({
  type: actionTypes.SET_MANDATE_HISTORY_PAGE_SIZE,
  payload: { size },
});
