import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';
import { TRANSACTION_TOOLTIP_CONTENT_MAX_WIDTH } from 'variables';

export const TooltipContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: ${TRANSACTION_TOOLTIP_CONTENT_MAX_WIDTH}px;
  justify-content: flex-start;
`;

export const StyledText = styled(Body)`
  margin: 2px;
`;
