import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Headline, Button, RangePicker } from '@sumup/circuit-ui';
import { isBeforeToday } from 'services/dateTime';
import { FILE_EXTENSIONS, APPS, ACTIONS } from 'variables';
import {
  PageContent,
  RangePickerWrapper,
  HiddenLink,
} from '../FraudReport/FraudReportStyled';

const BankFraudReport = ({
  hasActionAccess,
  startDate,
  endDate,
  href,
  csvLink,
  loading,
  onDatesChange,
  onDownloadButtonClick,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <PageContent>
      <Headline as="h2" size="two" noMargin>
        New bank fraud report
      </Headline>
      {hasActionAccess({
        action: ACTIONS[APPS.reports].mcReportGenerate,
      }) && (
        <>
          <RangePickerWrapper>
            <RangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              onDatesChange={onDatesChange}
              isOutsideRange={isBeforeToday}
            />
          </RangePickerWrapper>
          <Button
            variant="primary"
            isLoading={loading}
            loadingLabel="Loading"
            disabled={!startDate || !endDate}
            onClick={onDownloadButtonClick}
          >
            Generate and download
          </Button>
          <HiddenLink
            download={`bank-fraud-report.${FILE_EXTENSIONS.CSV}`}
            href={href}
            ref={csvLink}
          />
        </>
      )}
    </PageContent>
  );
};

BankFraudReport.defaultProps = {
  startDate: null,
  endDate: null,
  href: null,
  csvLink: null,
  loading: true,
};

BankFraudReport.propTypes = {
  hasActionAccess: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  href: PropTypes.string,
  csvLink: PropTypes.shape({ current: PropTypes.object }),
  loading: PropTypes.bool,
  onDatesChange: PropTypes.func.isRequired,
  onDownloadButtonClick: PropTypes.func.isRequired,
};

export default BankFraudReport;
