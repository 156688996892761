import { actionTypes } from 'variables';

export const setPage = ({ page }) => ({
  type: actionTypes.SET_OPS_TRANSFERS_PAGE,
  payload: { page },
});

export const setPageSize = ({ size }) => ({
  type: actionTypes.SET_OPS_TRANSFERS_PAGE_SIZE,
  payload: { size },
});

export const setStatusFilter = ({ status }) => ({
  type: actionTypes.SET_OPS_TRANSFERS_STATUS_FILTER,
  payload: { status },
});

export const setCurrencyFilter = ({ currency }) => ({
  type: actionTypes.SET_OPS_TRANSFERS_CURRENCY_FILTER,
  payload: { currency },
});

export const sortOpsTransfers = ({ name, order }) => ({
  type: actionTypes.SORT_OPS_TRANSFERS,
  payload: { name, order },
});
