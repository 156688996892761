import { size } from 'lodash';
import { POST_CODES } from 'variables';

export const CUSTOM_ADDRESS_FIELDS = {
  address_line_1: {
    name: 'address_line_1',
    validation: {
      required: true,
    },
    placeholder: 'Address (Line 1)',
    invalid: false,
    value: '',
  },
  address_line_2: {
    name: 'address_line_2',
    placeholder: 'Address (Line 2, optional)',
    value: '',
    validation: {},
  },
  city: {
    name: 'city',
    validation: {
      required: true,
    },
    placeholder: 'City or town',
    invalid: false,
    value: '',
  },
  post_code: {
    name: 'post_code',
    validation: {
      required: true,
      getPattern: ({ country }) => POST_CODES[country],
      hintInvalid: 'Invalid postal code.',
    },
    placeholder: 'Postal code',
    invalid: false,
    value: '',
  },
};

export const CARDHOLDER_FIELDS = {
  cardholder_name: {
    name: 'cardholder_name',
    placeholder: 'Cardholder name',
    validation: {
      required: true,
      maxLength: 26,
      hint: 'This name will be printed on the newly-issued card.',
      hintInvalid:
        'The maximum name’s length of 26 characters has been exceeded.',
    },
    invalid: false,
    value: '',
  },
};

export const validateField = (
  { required, maxLength, hint, hintInvalid, value, getPattern },
  country
) => {
  if (required && !value) {
    return {
      invalid: true,
      validationHint: 'This field is required.',
    };
  }

  if (maxLength != null && size(value) > maxLength) {
    return {
      invalid: true,
      validationHint: hintInvalid,
    };
  }

  if (getPattern) {
    const invalid = !value.match(getPattern({ country }));

    return {
      invalid,
      validationHint: invalid ? hintInvalid : hint,
    };
  }

  return {
    invalid: false,
    validationHint: hint,
  };
};
