import React from 'react';
import { Body } from '@sumup/circuit-ui';
import moment from 'moment';
import { toUpper } from 'lodash';
import {
  ACTIONS,
  APPS,
  CURRENCY_CODES,
  CUSTOM_FIELD_TYPES,
  DATE_FORMATS,
  DEFAULT_FRACTION_DIGITS,
  FIELD_TYPES,
  PATHS,
  SECTIONS,
  SORT_ORDER_DESCENDING,
} from 'variables';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as ViewHistory } from 'assets/view-history.svg';
import { ReactComponent as CancelRequest } from 'assets/cancel-request.svg';
import RequestTransferModal from 'components/Account/components/BusinessAccount/components/RequestTransferModal';
import {
  cancelOpsTransfer,
  createUpdateOpsTransfer,
  rejectOpsTransfer,
} from 'api';
import { FIELD_NAMES as REQUEST_TRANSFER_FIELD_NAMES } from 'components/Account/components/BusinessAccount/components/RequestTransferModal/constants';
import { getNameFromEmail } from 'services/common';
import StatusBadge from 'components/Transaction/components/StatusBadge';
import { STATUSES, STATUS_MAP } from './constants';
import { OpsTransferModal } from './OpsTransfersGrid/components/OpsTransferModal';
import { HideRecipientDetailsCell } from './OpsTransfersGrid/components/HideRecipientDetailsCell';
import { StatusChangesCell } from './OpsTransfersGrid/components/StatusChangesCell';
import { LinkCell } from './OpsTransfersGrid/components/LinkCell';
import HistoryModal from './OpsTransfersGrid/components/HistoryModal';

export const CREATED_AT = {
  label: 'Date',
  name: 'created_at',
  sortName: 'created_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

export const TRANSFER_ID = {
  label: 'ID',
  name: 'transfer_id',
  copyable: true,
  cropText: true,
};

export const STATUS = {
  label: 'Status',
  name: 'status',
  sortName: 'status',
  sortable: true,
};

const CURRENCY = {
  name: 'currency',
};

export const AMOUNT = {
  label: 'Amount',
  name: 'amount',
  sortName: 'amount',
  sortable: true,
  currencyField: CURRENCY,
  renderAs: CUSTOM_FIELD_TYPES.AMOUNT,
  getValue: ({ amount }) => parseFloat(amount) / 100,
};

export const SOURCE = {
  name: 'source',
  getValue: (transfer) => transfer?.source || {},
};

const SENDER_NAME = {
  name: 'name',
};

export const SENDER_ACCOUNT_ID = {
  name: 'account_id',
};

export const SENDER_CLIENT_CODE = {
  name: 'client_code',
};

export const SENDER_ID = {
  name: 'client_code',
  getValue: (transfer, name) => SOURCE.getValue(transfer)[name],
};

const SENDER = {
  label: 'Name / MID',
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (transfer) => {
    const senderFields = SOURCE.getValue(transfer);
    const clientId = senderFields[SENDER_ID.name];

    return (
      <LinkCell
        component={
          <Body noMargin size="two">
            {senderFields[SENDER_NAME.name]}
          </Body>
        }
        link={{
          title: clientId,
          href: `/${PATHS.client}/${clientId}`,
        }}
      />
    );
  },
};

export const BENEFICIARY = {
  label: 'Recipient',
  name: 'beneficiary',
  getValue: (transfer) => transfer?.beneficiary || {},
};

export const SHOW_BENEFICIARY = {
  name: 'show_beneficiary',
};

export const RECIPIENT_NAME = {
  name: 'account_name',
  label: 'Name / Details',
  centered: false,
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (transfer, name) => (
    <HideRecipientDetailsCell
      recipient={BENEFICIARY.getValue(transfer)[name]}
      showBeneficiary={transfer[SHOW_BENEFICIARY.name]}
    />
  ),
  style: {
    marginBottom: '16px',
  },
};

export const RECIPIENT_ACCOUNT_NUMBER = {
  name: 'account_number',
  prefix: <span>A:</span>,
  fullLabel: 'Account number',
  copyable: true,
  getValue: (transfer, name) => BENEFICIARY.getValue(transfer)[name],
};

export const RECIPIENT_SORT_CODE = {
  name: 'sort_code',
  prefix: <span>S:</span>,
  fullLabel: 'Sort code',
  copyable: true,
  getValue: (transfer, name) => BENEFICIARY.getValue(transfer)[name],
};

export const RECIPIENT_IBAN = {
  name: 'iban',
  prefix: <span>I:</span>,
  fullLabel: 'IBAN',
  copyable: true,
  getValue: (transfer, name) => BENEFICIARY.getValue(transfer)[name],
};

export const REFERENCE = {
  label: 'Reference',
  name: 'reference',
};

export const ADDITIONAL_INFO = {
  label: 'Add. info',
  name: 'additional_info',
  centered: false,
  getValue: (transfer, name) =>
    transfer[name] === undefined ? null : transfer[name],
};

export const COMMENT = {
  name: 'comment',
  label: 'Comment',
};

export const STATUS_CHANGES_FIELDS = {
  name: 'status_changes',
  getValue: (transfer) => transfer?.status_changes || {},
};

const ACTOR = {
  label: 'Agent',
  name: 'actor',
};

export const PAYMENT_ID = {
  name: 'payment_id',
};

export const singleTransferHistoryColumns = [
  {
    ...CREATED_AT,
    style: {
      maxWidth: 120,
    },
  },
  {
    ...ACTOR,
    getValue: (transfer, name) => getNameFromEmail(transfer[name]),
  },
  {
    ...STATUS,
    centered: true,
    renderAs: CUSTOM_FIELD_TYPES.CELL,
    getValue: (transfer, name, { clientId, paymentId }) => {
      const status = transfer[STATUS.name];

      let link = null;
      if (toUpper(status) === STATUSES.APPROVED) {
        link = {
          title: 'View transfer',
          href: `/${PATHS.client}/${clientId}/${PATHS.transactions}?rrn=${paymentId}`,
        };
      }

      return (
        <LinkCell
          component={
            <StatusBadge
              rowData={transfer}
              value={STATUS_MAP[toUpper(transfer[name])]}
            />
          }
          link={link}
        />
      );
    },
  },
  {
    ...COMMENT,
    style: { minWidth: 380 },
    getValue: (transfer, name) =>
      transfer[name] === undefined ? null : transfer[name],
  },
];

export const VIEW_HISTORY_FIELD_NAME = 'view_history_custom_field';
const VIEW_HISTORY = {
  key: 'view-history',
  name: VIEW_HISTORY_FIELD_NAME,
  title: (
    <>
      <ViewHistory />
      <Body noMargin>View history</Body>
    </>
  ),
  getModal: ({ transfer }) => {
    const senderFields = SOURCE.getValue(transfer);

    return (
      <HistoryModal
        id={transfer[TRANSFER_ID.name]}
        clientId={senderFields[SENDER_ID.name]}
        paymentId={transfer[PAYMENT_ID.name]}
        columns={singleTransferHistoryColumns}
      />
    );
  },
  permissions: {
    app: APPS.ops_transfers,
    section: SECTIONS[APPS.ops_transfers].requests,
  },
};

const EDIT_REQUEST = {
  key: 'edit-request',
  name: 'edit_request_custom_field',
  title: (
    <>
      <Edit />
      <Body noMargin>Edit request</Body>
    </>
  ),
  getModal: ({ transfer, clientId, updateOpsTransfers, onClose }) => {
    const amount = transfer[AMOUNT.name];
    const beneficiary = transfer[BENEFICIARY.name];
    const accountHolder = beneficiary[RECIPIENT_NAME.name];
    const accountNumber = beneficiary[RECIPIENT_ACCOUNT_NUMBER.name];
    const sortCode = beneficiary[RECIPIENT_SORT_CODE.name];
    const iban = beneficiary[RECIPIENT_IBAN.name];
    const reference = transfer[REFERENCE.name];
    const additionalInfo = transfer[ADDITIONAL_INFO.name];
    const comment = transfer[COMMENT.name];
    const showBeneficiary = transfer[SHOW_BENEFICIARY.name];
    const source = transfer[SOURCE.name];
    const transferId = transfer[TRANSFER_ID.name];
    const accountId = source[SENDER_ACCOUNT_ID.name];
    const ukTransfer = !!(accountNumber && sortCode);
    const currency = ukTransfer ? CURRENCY_CODES.GBP : CURRENCY_CODES.EUR;

    const initialValues = {
      [REQUEST_TRANSFER_FIELD_NAMES.name]: accountHolder,
      [REQUEST_TRANSFER_FIELD_NAMES.amount]: amount ? amount.toString() : null,
      ...(ukTransfer
        ? {
            [REQUEST_TRANSFER_FIELD_NAMES.sort_code]: sortCode,
            [REQUEST_TRANSFER_FIELD_NAMES.account_number]: accountNumber,
          }
        : {
            [REQUEST_TRANSFER_FIELD_NAMES.iban]: iban,
          }),
      [REQUEST_TRANSFER_FIELD_NAMES.reference]: reference,
      [REQUEST_TRANSFER_FIELD_NAMES.additional_info]: additionalInfo,
      [REQUEST_TRANSFER_FIELD_NAMES.comment]: comment,
      [REQUEST_TRANSFER_FIELD_NAMES.show_beneficiary]: showBeneficiary,
    };

    return (
      <RequestTransferModal
        heading="Edit transfer request"
        initialValues={initialValues}
        ukTransfer={ukTransfer}
        accountId={accountId}
        currency={currency}
        clientId={clientId}
        transferId={transferId}
        successHandler={updateOpsTransfers}
        onSubmit={createUpdateOpsTransfer}
        onClose={onClose}
      />
    );
  },
  permissions: {
    app: APPS.ops_transfers,
    action: ACTIONS[APPS.ops_transfers].requestsInitiate,
  },
};

export const CANCEL_REQUEST_OPTION_KEY = 'cancel-request';

const CANCEL_REQUEST = {
  key: CANCEL_REQUEST_OPTION_KEY,
  name: 'cancel_request_custom_field',
  title: (
    <>
      <CancelRequest />
      <Body noMargin variant="alert">
        Cancel request
      </Body>
    </>
  ),
  getModal: ({ transfer, updateOpsTransfers, onClose }) => (
    <OpsTransferModal
      id={transfer[TRANSFER_ID.name]}
      title="Cancel transfer request"
      explanation="The transfer request will be cancelled."
      confirmButtonLabel="Cancel request"
      cancelButtonLabel="Keep"
      confirmButtonProps={{
        destructive: true,
      }}
      successNotificationHeadline="Transfer request cancelled"
      errorNotificationHeadline="Couldn’t cancel transfer request"
      successHandler={updateOpsTransfers}
      onConfirm={cancelOpsTransfer}
      onClose={onClose}
    />
  ),
  permissions: {
    app: APPS.ops_transfers,
    action: ACTIONS[APPS.ops_transfers].requestsInitiate,
  },
};

export const REJECT_REQUEST_OPTION_KEY = 'reject-request';

const REJECT_REQUEST = {
  key: REJECT_REQUEST_OPTION_KEY,
  name: 'reject_request_custom_field',
  title: (
    <>
      <CancelRequest />
      <Body noMargin variant="alert">
        Reject request
      </Body>
    </>
  ),
  getModal: ({ transfer, updateOpsTransfers, onClose }) => {
    const amount = AMOUNT.getValue(transfer).toFixed(DEFAULT_FRACTION_DIGITS);
    const beneficiary = transfer[BENEFICIARY.name];
    const accountHolder = beneficiary[RECIPIENT_NAME.name];
    const accountNumber = beneficiary[RECIPIENT_ACCOUNT_NUMBER.name];
    const sortCode = beneficiary[RECIPIENT_SORT_CODE.name];
    const ukTransfer = !!(accountNumber && sortCode);
    const currency = ukTransfer ? CURRENCY_CODES.GBP : CURRENCY_CODES.EUR;

    return (
      <OpsTransferModal
        id={transfer[TRANSFER_ID.name]}
        title={`Reject transfer request of ${amount} ${currency} to ${accountHolder}?`}
        explanation="The request will be rejected."
        confirmButtonLabel="Reject"
        cancelButtonLabel="Keep"
        confirmButtonProps={{
          destructive: true,
        }}
        successNotificationHeadline="Transfer request rejected"
        errorNotificationHeadline="Couldn’t reject transfer request"
        successHandler={updateOpsTransfers}
        onConfirm={rejectOpsTransfer}
        onClose={onClose}
      />
    );
  },
  permissions: {
    app: APPS.ops_transfers,
    action: ACTIONS[APPS.ops_transfers].requestsPreApproveAndAuthorize,
  },
};

const ACTIONS_PER_STATUS = {
  [STATUSES.REQUESTED]: [EDIT_REQUEST, VIEW_HISTORY, CANCEL_REQUEST],
  [STATUSES.CANCELLED]: [VIEW_HISTORY],
  [STATUSES.INITIATED]: [VIEW_HISTORY, REJECT_REQUEST],
  [STATUSES.PRE_APPROVED]: [VIEW_HISTORY, REJECT_REQUEST],
  [STATUSES.APPROVED]: [VIEW_HISTORY],
  [STATUSES.REJECTED]: [VIEW_HISTORY],
};

const ACTIONS_FIELD = {
  name: 'actions_field',
  renderAs: CUSTOM_FIELD_TYPES.POPOVER,
  popoverOptions: (transfer) => ACTIONS_PER_STATUS[transfer[STATUS.name]],
};

const transferColumns = [
  {
    key: CREATED_AT.name,
    fields: [
      {
        ...CREATED_AT,
        sortable: true,
        sortOrder: SORT_ORDER_DESCENDING,
      },
    ],
  },
  {
    key: TRANSFER_ID.name,
    fields: [
      {
        ...TRANSFER_ID,
        label: 'ID',
      },
    ],
    style: {
      maxWidth: 88,
    },
  },
  {
    key: STATUS.name,
    fields: [{ ...STATUS, renderAs: CUSTOM_FIELD_TYPES.STATUS_BADGE }],
    style: {
      minWidth: 129,
    },
  },
  {
    key: AMOUNT.name,
    fields: [AMOUNT],
    style: {
      minWidth: 129,
    },
  },
];

const senderColumns = [
  {
    key: SENDER_NAME.name,
    fields: [SENDER],
  },
];

const recipientColumns = [
  {
    key: RECIPIENT_NAME.name,
    fields: [
      RECIPIENT_NAME,
      RECIPIENT_SORT_CODE,
      RECIPIENT_ACCOUNT_NUMBER,
      RECIPIENT_IBAN,
    ],
    centered: false,
  },
];

const detailsColumns = [
  {
    key: REFERENCE.name,
    fields: [REFERENCE],
    style: { maxWidth: 120 },
  },
  {
    key: ADDITIONAL_INFO.name,
    fields: [ADDITIONAL_INFO],
  },
];

const otherColumns = [
  {
    key: 'status_changes_agent',
    fields: [
      {
        ...STATUS_CHANGES_FIELDS,
        label: 'Agent',
        renderAs: CUSTOM_FIELD_TYPES.CELL,
        getValue: (transfer, name) => (
          <StatusChangesCell statusChanges={transfer[name]} />
        ),
      },
    ],
    centered: false,
  },
  {
    key: 'status_changes_next_status',
    fields: [
      {
        ...STATUS_CHANGES_FIELDS,
        renderAs: CUSTOM_FIELD_TYPES.OPS_TRANSFER_NEXT_STATUS,
      },
    ],
  },
  {
    key: 'ops_transfers_actions',
    fields: [ACTIONS_FIELD],
    style: {
      maxWidth: 60,
    },
  },
];

export const columns = [
  {
    label: 'Transfer / request',
    style: {
      width: '31%',
    },
    subColumns: transferColumns,
  },
  {
    label: 'Sender',
    style: {
      width: '10%',
    },
    subColumns: senderColumns,
  },
  {
    label: 'Recipient',
    style: {
      width: '15%',
    },
    subColumns: recipientColumns,
  },
  {
    label: 'Details',
    style: {
      width: '20%',
    },
    subColumns: detailsColumns,
  },
  {
    label: '',
    style: {
      width: '24%',
    },
    subColumns: otherColumns,
  },
];
