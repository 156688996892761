import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Card, Checkbox, Spinner, useModal } from '@sumup/circuit-ui';
import { ACTIONS, APPS, PATHS } from 'variables';
import { hasActionAccess } from 'services/permissions';
import AuthContext from 'context/auth';
import { first } from 'lodash';
import {
  ACTIVITY_LOG_TYPE_DD_DISABLED,
  ACTIVITY_LOG_TYPE_DD_ENABLED,
} from 'components/DirectDebits/constants';
import { ToggleDirectDebitsModal } from './ToggleDirectDebitsModal';
import DirectDebitsEnabledTimestamp from '../DirectDebitsEnabledTimestamp';

const Wrapper = styled(Card)(
  ({ theme, hasBusinessAccount }) => css`
    display: flex;
    min-height: 84px;
    margin-top: 20px;
    padding: ${theme.spacings.mega} ${theme.spacings.giga};
    border-radius: ${theme.borderRadius.bit};
    justify-content: center;

    ${!hasBusinessAccount &&
    `
      background: ${theme.colors.n200};
    `}
  `
);

const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 160px;
    height: 40px;
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    margin-left: auto;
    background: ${theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};
  `
);

const CheckboxWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const DirectDebitsToggle = ({
  clientId,
  hasBusinessAccount,
  directDebitsEnabled,
  directDebitsEnabledLoading,
  directDebitsEvents,
  toggleDirectDebits,
}) => {
  const [date, setDate] = useState();

  useEffect(() => {
    const { date: eventDate } =
      first(
        directDebitsEvents,
        ({ type }) =>
          type ===
          (directDebitsEnabled
            ? ACTIVITY_LOG_TYPE_DD_ENABLED
            : ACTIVITY_LOG_TYPE_DD_DISABLED)
      ) || {};

    setDate(eventDate);
  }, [directDebitsEvents, directDebitsEnabled]);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const toggleEnabled = useMemo(
    () =>
      hasActionAccess({
        permissions,
        app: APPS.merchant,
        action: ACTIONS[APPS.merchant].accountcardDirectDebitsEnable,
      }),
    [permissions]
  );

  const { setModal } = useModal();

  const openModal = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <ToggleDirectDebitsModal
          directDebitsEnabled={directDebitsEnabled}
          onClose={onClose}
          onConfirm={toggleDirectDebits}
        />
      ),
      closeButtonLabel: 'Close',
      preventClose: true,
    });
  };

  if (!hasBusinessAccount) {
    return (
      <Wrapper hasBusinessAccount={hasBusinessAccount}>
        <Body noMargin size="one" variant="subtle">
          This merchant needs to have a business account in order to use direct
          debits. You can create one for them from the{' '}
          <Link to={`/${PATHS.client}/${clientId}/${PATHS.account}`}>
            Overview
          </Link>{' '}
          section.
        </Body>
      </Wrapper>
    );
  }

  return (
    <Wrapper hasBusinessAccount={hasBusinessAccount}>
      <CheckboxWrapper>
        {directDebitsEnabled == null ? (
          <Body noMargin size="one" variant="subtle">
            This merchant does not have direct debits enabled.
          </Body>
        ) : (
          <DirectDebitsEnabledTimestamp
            date={date}
            directDebitsEnabled={directDebitsEnabled}
          />
        )}
        <StyledCheckbox
          disabled={!toggleEnabled}
          noMargin
          checked={directDebitsEnabled}
          onChange={openModal}
        >
          {directDebitsEnabledLoading ? (
            <StyledSpinner />
          ) : (
            <Body noMargin size="one">
              {directDebitsEnabled ? 'Enabled' : 'Enable'}
            </Body>
          )}
        </StyledCheckbox>
      </CheckboxWrapper>
    </Wrapper>
  );
};

DirectDebitsToggle.defaultProps = {
  hasBusinessAccount: null,
  directDebitsEnabled: null,
  directDebitsEnabledLoading: false,
  directDebitsEvents: [],
};

DirectDebitsToggle.propTypes = {
  clientId: PropTypes.string.isRequired,
  hasBusinessAccount: PropTypes.bool,
  directDebitsEnabled: PropTypes.bool,
  directDebitsEnabledLoading: PropTypes.bool,
  directDebitsEvents: PropTypes.array,
  toggleDirectDebits: PropTypes.func.isRequired,
};

export default DirectDebitsToggle;
