import { Alert, Confirm, Time } from '@sumup/icons';
import React from 'react';
import {
  PATHS,
  APP_TITLE,
  APPS,
  SECTIONS as SECTIONS_BY_MODULE,
  ACTIONS,
} from 'variables';
import {
  createRecipientFromMerchantCode,
  enableDirectDebits,
  publishPaymentEvents,
} from './service';

const PAGE_TITLES = {
  ACTIONS: {
    title: `Actions | ${APP_TITLE}`,
    heading: 'Actions',
  },
};

export const SECTIONS = {
  [PATHS.actions]: {
    key: PATHS.actions,
    title: PAGE_TITLES.ACTIONS.heading,
    link: PATHS.actions,
    permissions: {
      app: APPS.merchant,
      section: SECTIONS_BY_MODULE[APPS.merchant].accountcardActivation,
    },
  },
};

export const ALLOW_DIRECT_DEBITS_OPTION = 'ALLOW_DIRECT_DEBITS';
export const ISSUE_CARD_OPTION = 'ISSUE_CARDS';
export const CHANGE_CARD_STATUS_OPTION = 'CHANGE_CARD_STATUS';
export const CREATE_RECIPIENT_CONTACT_OPTION = 'CREATE_RECIPIENT_CONTACT';
export const PUBLISH_PAYMENT_EVENTS_OPTION = 'PUBLISH_PAYMENT_EVENTS';

export const OPTIONS = {
  [ALLOW_DIRECT_DEBITS_OPTION]: {
    value: ALLOW_DIRECT_DEBITS_OPTION,
    label: 'Allow direct debits',
    permissions: {
      app: APPS.merchant,
      section: SECTIONS_BY_MODULE[APPS.merchant].accountcardDirectDebits,
      action: ACTIONS[APPS.merchant].accountcardDirectDebitsEnable,
    },
    onActionRun: enableDirectDebits,
  },
  [CREATE_RECIPIENT_CONTACT_OPTION]: {
    value: CREATE_RECIPIENT_CONTACT_OPTION,
    label: 'Create recipient',
    app: APPS.merchant,
    permissions: {
      app: APPS.merchant,
      section: SECTIONS_BY_MODULE[APPS.merchant].accountcardOverview,
      action: ACTIONS[APPS.merchant].accountcardOverviewCreateRecipient,
    },
    onActionRun: createRecipientFromMerchantCode,
  },
  [PUBLISH_PAYMENT_EVENTS_OPTION]: {
    value: PUBLISH_PAYMENT_EVENTS_OPTION,
    label: 'Publish payment events',
    permissions: {
      app: APPS.mass_actions,
      section: SECTIONS_BY_MODULE[APPS.mass_actions].actions,
      action: ACTIONS[APPS.mass_actions].actionsPublishPaymentEvents,
    },
    onActionRun: publishPaymentEvents,
  },
};

export const ID_TYPES = {
  [ALLOW_DIRECT_DEBITS_OPTION]: 'account IDs',
  [CREATE_RECIPIENT_CONTACT_OPTION]: 'merchant codes',
  [PUBLISH_PAYMENT_EVENTS_OPTION]: 'payment IDs',
};

export const EDITABLE_SECTION_WIDTH = 450;
export const MASS_ACTIONS_NAV_HEIGHT = 230;

export const ACTION_RESULT_SUCCESS = 'success';
export const ACTION_RESULT_PENDING = 'pending';
export const ACTION_RESULT_ERROR = 'error';

export const ACTION_RESULTS_MAP = {
  [ACTION_RESULT_SUCCESS]: {
    label: 'Success',
    icon: <Confirm size="24" />,
  },
  [ACTION_RESULT_PENDING]: {
    label: 'Pending',
    icon: <Time size="24" />,
  },
  [ACTION_RESULT_ERROR]: {
    label: 'Error',
    icon: <Alert size="24" />,
  },
};

export const getErrorLabel = (error = {}) => {
  const { response } = error;

  if (response) {
    const { data } = response;

    if (data.message) {
      return data.message;
    }

    return data;
  }

  return ACTION_RESULTS_MAP[ACTION_RESULT_ERROR].label;
};
