import AuthContext from 'context/auth';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { hasActionAccess } from 'services/permissions';
import { APPS } from 'variables';
import Activation from './Activation';

const ActivationData = ({ onboardClient }) => {
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const hasActionAccessWrapped = ({ action }) =>
    hasActionAccess({ permissions, app: APPS.merchant, action });

  return (
    <Activation
      hasActionAccess={hasActionAccessWrapped}
      onActivate={onboardClient}
    />
  );
};

ActivationData.propTypes = {
  onboardClient: PropTypes.func.isRequired,
};

export default ActivationData;
