import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import Empty from 'components/Empty';
import {
  StyledText,
  StyledCard,
  StyledCardHeading,
  StyledCardContent,
  StyledCardRow,
} from '../../ClientCardsStyled';

const BusinessCard = ({ inner, popover, clientData, soapUrl }) => {
  const { merchant_profile: merchantProfile = {} } = clientData;
  const {
    address,
    company_name: company,
    mobile_phone: mobilePhone,
    legal_type: legalType,
  } = merchantProfile;
  const {
    address_line1: addressLine1,
    address_line2: addressLine2,
    city,
    post_code: postCode,
    country,
  } = address || {};
  const { full_description: fullDescription, description } = legalType || {};

  return (
    <StyledCard inner={inner} popover={popover}>
      <StyledCardHeading>
        <Body noMargin variant="highlight" size="one">
          Business Details
        </Body>
        <StyledText
          noMargin
          variant="highlight"
          size="two"
          onClick={() => soapUrl && window.open(soapUrl)}
        >
          Edit in SOAP <ChevronRight />
        </StyledText>
      </StyledCardHeading>
      <StyledCardContent>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Name
          </Body>
          <Body noMargin size="two">
            {company || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Phone
          </Body>
          <Body noMargin size="two">
            {mobilePhone || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Address line 1
          </Body>
          <Body noMargin size="two">
            {addressLine1 || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Address line 2
          </Body>
          <Body noMargin size="two">
            {addressLine2 || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            City
          </Body>
          <Body noMargin size="two">
            {city || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Post code
          </Body>
          <Body noMargin size="two">
            {postCode || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Country
          </Body>
          <Body noMargin size="two">
            {country || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Legal type
          </Body>
          <Body noMargin size="two">
            {fullDescription || description || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
      </StyledCardContent>
    </StyledCard>
  );
};

BusinessCard.defaultProps = {
  inner: false,
  popover: false,
  clientData: {},
  soapUrl: '',
};

BusinessCard.propTypes = {
  inner: PropTypes.bool,
  popover: PropTypes.bool,
  clientData: PropTypes.shape({
    merchant_profile: PropTypes.object,
  }),
  soapUrl: PropTypes.string,
};

export default BusinessCard;
