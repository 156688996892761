import React, { useCallback, useContext, useRef, useState } from 'react';
import moment from 'moment';
import ClientDataContext from 'context/clientData';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { getDirectDebitMandates, getDirectDebits } from 'api';
import Error from 'components/Error';
import { loadError, loadSuccess, setLoading } from 'actions';
import { DATE_FORMATS } from 'variables';
import { getUpcomingDirectDebits } from 'api/api';
import { directDebitMandatesReducer } from './directDebitMandatesReducer';
import { initialState } from './state';
import DirectDebitMandates from './DirectDebitMandates';
import { MANDATE_STATUS_CANCELLED, STATUS_FILTER_MAP } from './constants';
import {
  clearMandateTransactionHistory,
  loadMandateTransactionHistorySuccess,
  setMandateHistoryPage,
  setMandateHistoryPageSize,
  setMandateTransactionHistoryLoading,
  setMandateUpcomingPayment,
  setPage,
  setPageSize,
  setSelectedMandate,
  setStatusFilter,
  sortMandateHistory,
  sortMandates,
} from './directDebitMandatesActions';
import { columns } from './columns';
import MandateDetails from './components/MandateDetails';

const DirectDebitMandatesData = () => {
  const [selectedMandateCancelled, setSelectedMandateCancelled] =
    useState(false);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const { bank_account_identifier: accountId } = clientData || {};

  const scrollPosition = useRef('');

  const setScrollPosition = (value) => {
    scrollPosition.current = value;
  };

  const httpClient = useHttpClient();
  const { fieldName: initialSortName } = initialState.sort;

  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getDirectDebitMandates(httpClient, {
          accountId,
          active: true,
          page: initialState.currentPage,
          size: initialState.pageSize,
          sortBy: initialSortName,
          sortDirection: initialState.sort[initialSortName],
        }),
      [httpClient, accountId, initialSortName]
    ),
    null,
    directDebitMandatesReducer,
    {
      ...initialState,
      pageSize: initialState.pageSize,
    }
  );

  const {
    loading,
    error,
    mandates,
    totalPages,
    totalCount,
    pageSize,
    currentPage,
    sort = {},
    statusFilter,
    selectedMandate,
    upcomingPayment,
    transactionHistory,
    transactionHistoryLoadingForId,
  } = state;

  const loadMandateHistory = ({
    mandateId,
    sort: historySort = {},
    page,
    size,
    successHandler,
    errorHandler,
  }) => {
    const sortBy = historySort?.name || transactionHistory.sort.fieldName;
    const sortDirection = historySort?.order || transactionHistory.sort[sortBy];

    return getUpcomingDirectDebits(httpClient, { mandateId })
      .then(({ data }) => {
        dispatch(setMandateUpcomingPayment(data?.content[0]));

        return getDirectDebits(httpClient, {
          mandateId,
          page: page || transactionHistory.currentPage,
          size: size || transactionHistory.pageSize,
          sortBy,
          sortDirection,
        });
      })
      .then((data) => {
        dispatch(loadMandateTransactionHistorySuccess(data));

        if (successHandler) {
          successHandler();
        }
      })
      .catch(() => {
        if (errorHandler) {
          errorHandler();
        }
      });
  };

  const { fieldName: sortBy } = sort;
  const sortDirection = sort[sortBy];

  const updateMandates = (args, successHandler) => {
    dispatch(setLoading(true));

    return getDirectDebitMandates(httpClient, args)
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          return successHandler();
        }

        return Promise.resolve();
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const reloadMandates = () =>
    updateMandates({
      accountId,
      page: currentPage,
      size: pageSize,
      active: STATUS_FILTER_MAP[statusFilter],
      sortBy,
      sortDirection,
    });

  const handlePageChange = (page) => {
    updateMandates(
      {
        page,
        accountId,
        size: pageSize,
        active: STATUS_FILTER_MAP[statusFilter],
        sortBy,
        sortDirection,
      },
      () => {
        dispatch(setPage(page));
        setScrollPosition(0);
      }
    );
  };

  const handlePageSizeChange = (size) => {
    const { currentPage: page } = initialState;

    updateMandates(
      {
        size,
        page,
        accountId,
        active: STATUS_FILTER_MAP[statusFilter],
        sortBy,
        sortDirection,
      },
      () => {
        dispatch(setPageSize(size));
        setScrollPosition(0);
      }
    );
  };

  const handleStatusFilterChange = (status) => {
    const { currentPage: page } = initialState;

    updateMandates(
      {
        size: pageSize,
        page,
        accountId,
        active: STATUS_FILTER_MAP[status],
        sortBy,
        sortDirection,
      },
      () => dispatch(setStatusFilter(status))
    );
  };

  const handleSort = ({ name, order }) => {
    updateMandates(
      {
        size: pageSize,
        page: currentPage,
        accountId,
        active: STATUS_FILTER_MAP[statusFilter],
        sortBy: name,
        sortDirection: order,
      },
      () => {
        dispatch(sortMandates({ name, order }));
      }
    );
  };

  const handleCancelMandateConfirm = () => {
    setSelectedMandateCancelled(true);
    dispatch(
      setSelectedMandate({
        ...selectedMandate,
        status: MANDATE_STATUS_CANCELLED,
        cancelled_at: moment().format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR),
      })
    );
  };

  const handleAllClick = () => {
    dispatch(setSelectedMandate(null));
    dispatch(clearMandateTransactionHistory());
    dispatch(setMandateUpcomingPayment(null));

    if (selectedMandateCancelled) {
      setSelectedMandateCancelled(false);
      reloadMandates();
    }
  };

  const handleViewDetailsClick = (mandate) => {
    dispatch(setMandateTransactionHistoryLoading(mandate?.id));

    const successHandler = () => dispatch(setSelectedMandate(mandate));
    const errorHandler = () => {
      dispatch(setSelectedMandate(mandate));
      dispatch(setMandateTransactionHistoryLoading(null));
    };

    loadMandateHistory({
      mandateId: mandate.id,
      successHandler,
      errorHandler,
    });
  };

  const handleHistorySort = ({ name, order }) => {
    const successHandler = () => dispatch(sortMandateHistory({ name, order }));

    loadMandateHistory({
      mandateId: selectedMandate.id,
      sort: { name, order },
      successHandler,
    });
  };

  const handleHistoryPageChange = (page) => {
    const successHandler = () => dispatch(setMandateHistoryPage(page));

    loadMandateHistory({
      mandateId: selectedMandate.id,
      page,
      successHandler,
    });
  };

  const handleHistoryPageSizeChange = (size) => {
    const { currentPage: page } = initialState;

    const successHandler = () => dispatch(setMandateHistoryPageSize(size));

    loadMandateHistory({
      mandateId: selectedMandate.id,
      page,
      size,
      successHandler,
    });
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      {selectedMandate ? (
        <MandateDetails
          mandate={selectedMandate}
          upcomingPayment={upcomingPayment}
          transactionHistory={transactionHistory}
          onHistorySort={handleHistorySort}
          onCancelMandateConfirm={handleCancelMandateConfirm}
          onAllClick={handleAllClick}
          onHistoryPageChange={handleHistoryPageChange}
          onHistoryPageSizeChange={handleHistoryPageSizeChange}
        />
      ) : (
        <DirectDebitMandates
          loading={loading}
          columns={columns}
          mandates={mandates}
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          pageSize={pageSize}
          sort={sort}
          statusFilter={statusFilter}
          transactionHistoryLoadingForId={transactionHistoryLoadingForId}
          reloadMandates={reloadMandates}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onStatusFilterChange={handleStatusFilterChange}
          onSort={handleSort}
          onViewDetailsClick={handleViewDetailsClick}
        />
      )}
    </>
  );
};

export default DirectDebitMandatesData;
