import { includes, toLower, filter, some, map } from 'lodash/fp';

const searchByFields = ({ data, fields, value }) =>
  filter(
    (ctx) =>
      some(
        (prop) => {
          let trimmedValue = toLower(value);
          if (typeof value === 'string') {
            trimmedValue = trimmedValue.replace(/\s/g, '');
          }

          let trimmedProp = toLower(prop);
          if (typeof prop === 'string') {
            trimmedProp = trimmedProp.replace(/\s/g, '');
          }

          return includes(trimmedValue, trimmedProp);
        },
        map((field) => ctx[field], fields)
      ),
    data
  );

export default searchByFields;
