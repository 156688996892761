import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Headline,
  Input,
  Spinner,
  SubHeadline,
  Body,
} from '@sumup/circuit-ui';
import { debounce, map, reduce, trim } from 'lodash';
import ListItem from 'components/UserAdministration/components/ListItem';
import { DEBOUNCE_TIMEOUT_LONG, VALIDATION_PATTERNS } from 'variables';
import {
  StyledModalWrapper,
  GroupsList,
  Actions,
  StyledSpinnerWrapper,
} from './AddUserModalStyled';

const AddUserModal = ({ groups, groupsLoading, onClose, onConfirm }) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState({});

  const handleEmailChange = debounce(({ target: { value } }) => {
    setEmail(trim(value));
  }, DEBOUNCE_TIMEOUT_LONG);

  const handleInputFocus = () => {
    setEmailValid(true);
  };

  const handleInputBlur = () => {
    setEmailValid(VALIDATION_PATTERNS.CARD_ADMIN_USER_EMAIL_REGEX.test(email));
  };

  const handleSelectGroup = ({ code }) => {
    const newSelectedGroups = { ...selectedGroups };
    newSelectedGroups[code] = !selectedGroups[code];

    setSelectedGroups(newSelectedGroups);
  };

  const handleConfirm = () => {
    onConfirm({
      email,
      selectedGroups: reduce(
        selectedGroups,
        (accumulator, isSelected, group) =>
          isSelected ? [...accumulator, group] : accumulator,
        []
      ),
    });

    setEmailValid(true);
    setSelectedGroups({});
  };

  const handleClose = () => {
    onClose();

    setEmail('');
    setEmailValid(true);
    setSelectedGroups({});
  };

  return (
    <StyledModalWrapper>
      <header>
        <Headline noMargin as="h2" size="three">
          Add new user
        </Headline>
      </header>
      <Input
        noMargin
        label="User's email"
        placeholder="e.g. taylor@sumup.com"
        invalid={!isEmailValid}
        validationHint={
          email
            ? 'Only emails ending in @sumup.com are allowed.'
            : 'This field is required.'
        }
        onKeyUp={handleEmailChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <SubHeadline noMargin as="h3">
        Select user groups:
      </SubHeadline>
      {groupsLoading ? (
        <StyledSpinnerWrapper>
          <Spinner />
        </StyledSpinnerWrapper>
      ) : (
        <GroupsList>
          {map(groups, ({ code }, index) => (
            <ListItem
              key={`${code}-${index}`}
              readonly={false}
              checked={selectedGroups[code]}
              onClick={() => handleSelectGroup({ code })}
            >
              <Body noMargin variant="highlight" size="one">
                {code}
              </Body>
            </ListItem>
          ))}
        </GroupsList>
      )}
      <Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="primary"
          disabled={!email || !isEmailValid}
          onClick={isEmailValid && handleConfirm}
        >
          Add user
        </Button>
      </Actions>
    </StyledModalWrapper>
  );
};

AddUserModal.propTypes = {
  groups: PropTypes.array.isRequired,
  groupsLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AddUserModal;
