import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Button, spacing } from '@sumup/circuit-ui';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    max-height: 40px;
    flex-shrink: 0;
    padding: ${theme.spacings.bit} ${theme.spacings.mega};
  `
);

const LinkButton = ({ url, text }) => (
  <StyledButton
    css={spacing({ left: 'byte' })}
    onClick={() => url && window.open(url)}
  >
    <LinkIcon />
    <Body
      noMargin
      size="one"
      variant="highlight"
      css={spacing({ left: 'byte' })}
    >
      {text}
    </Body>
  </StyledButton>
);

LinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default LinkButton;
