import keyMirror from 'key-mirror-nested';
import { isEmpty, size, trim } from 'lodash';
import {
  matchesRegex,
  normalizeAmount,
  validateAccountNumber,
  validateEeaSepaIban,
  validateIban,
  validatePaymentReferenceMaxLength,
  validateSortCode,
} from 'services/validation';
import {
  EMPTY_FIELD,
  LETTERS_ONLY_FIELD,
  RECIPIENT_NAME_MAX_LENGTH_EU,
  RECIPIENT_NAME_MAX_LENGTH_UK,
  RECIPIENT_NAME_MIN_LENGTH,
  INCORRECT_ACCOUNT_NUMBER,
  INCORRECT_SORT_CODE,
  INCORRECT_IBAN,
  NON_EEA_COUNTRY_IBAN,
  ACCOUNT_HOLDER_REGEX,
  TRANSFER_AMOUNT_GREATER_THAN_ZERO,
  NOT_ENOUGH_BALANCE_FOR_TRANSFER,
  PAYMENT_REFERENCE_REGEX,
  PAYMENT_REFERENCE_MAX_LENGTH_UK,
  PAYMENT_REFERENCE_MAX_LENGTH_EU,
  OPS_TRANSFER_ADDITIONAL_INFO_MAX_CHARACTERS,
  OPS_TRANSFER_COMMENT_MAX_CHARACTERS,
  OPS_TRANSFER_COMMENT_MAX_LENGTH,
  OPS_TRANSFER_ADDITIONAL_INFO_MAX_LENGTH,
} from 'variables';

export const MODAL_WIDTH = 792;
export const MODAL_MEGA_TO_GIGA_WIDTH = 420;
export const FIELD_PLACEHOLDER = 'Enter value';

export const FIELD_NAMES = keyMirror({
  name: null,
  sort_code: null,
  account_number: null,
  iban: null,
  amount: null,
  reference: null,
  additional_info: null,
  comment: null,
  show_beneficiary: null,
});

export const FIELD_RULES = ({ ukTransfer, balance, exponent }) => ({
  [FIELD_NAMES.name]: {
    rules: [
      { required: (value) => !isEmpty(value) },
      { minLength: (value) => size(trim(value)) >= 3 },
      {
        maxLength: (value) => {
          const trimmedValue = trim(value);

          return (
            !trimmedValue ||
            (ukTransfer ? trimmedValue.length <= 40 : trimmedValue.length <= 70)
          );
        },
      },
      {
        regex: (value) =>
          ukTransfer ? matchesRegex(value, ACCOUNT_HOLDER_REGEX) : true,
      },
    ],
    hints: {
      required: EMPTY_FIELD,
      ...(ukTransfer
        ? {
            regex: LETTERS_ONLY_FIELD,
          }
        : null),
      minLength: RECIPIENT_NAME_MIN_LENGTH,
      maxLength: ukTransfer
        ? RECIPIENT_NAME_MAX_LENGTH_UK
        : RECIPIENT_NAME_MAX_LENGTH_EU,
    },
    priorities: ['required', 'regex', 'maxLength'],
  },
  [FIELD_NAMES.sort_code]: {
    rules: [
      { required: (value) => !ukTransfer || !isEmpty(value) },
      { sortCode: (value) => !ukTransfer || validateSortCode(value) },
    ],
    hints: {
      required: EMPTY_FIELD,
      sortCode: INCORRECT_SORT_CODE,
    },
    priorities: ['required', 'sortCode'],
  },
  [FIELD_NAMES.account_number]: {
    rules: [
      { required: (value) => !ukTransfer || !isEmpty(value) },
      { accountNumber: (value) => !ukTransfer || validateAccountNumber(value) },
    ],
    hints: {
      required: EMPTY_FIELD,
      accountNumber: INCORRECT_ACCOUNT_NUMBER,
    },
    priorities: ['required', 'accountNumber'],
  },
  [FIELD_NAMES.iban]: {
    rules: [
      { required: (value) => ukTransfer || !isEmpty(value) },
      { iban: (value) => ukTransfer || validateIban(value) === 1 },
      { eeaSepaIban: (value) => ukTransfer || validateEeaSepaIban(value) },
    ],
    hints: {
      required: EMPTY_FIELD,
      iban: INCORRECT_IBAN,
      eeaSepaIban: NON_EEA_COUNTRY_IBAN,
    },
    priorities: ['required', 'iban', 'eeaSepaIban'],
  },
  [FIELD_NAMES.amount]: {
    rules: [
      { required: (value) => !isEmpty(value) },
      {
        hasEnoughBalance: (value) =>
          normalizeAmount(value) <= normalizeAmount(balance / 10 ** exponent),
      },
      { greaterThanZero: (value) => parseFloat(value) > 0 },
    ],
    hints: {
      required: EMPTY_FIELD,
      hasEnoughBalance: NOT_ENOUGH_BALANCE_FOR_TRANSFER,
      greaterThanZero: TRANSFER_AMOUNT_GREATER_THAN_ZERO,
    },
    priorities: ['required', 'hasEnoughBalance'],
  },
  [FIELD_NAMES.reference]: {
    rules: [
      { required: (value) => !isEmpty(value) },
      {
        regex: (value) =>
          ukTransfer ? matchesRegex(value, PAYMENT_REFERENCE_REGEX) : true,
      },
      {
        maxLength: (value) =>
          validatePaymentReferenceMaxLength(value, ukTransfer),
      },
    ],
    hints: {
      required: EMPTY_FIELD,
      ...(ukTransfer
        ? {
            regex: LETTERS_ONLY_FIELD,
          }
        : null),
      maxLength: ukTransfer
        ? PAYMENT_REFERENCE_MAX_LENGTH_UK
        : PAYMENT_REFERENCE_MAX_LENGTH_EU,
    },
    priorities: ['required', 'regex', 'maxLength'],
  },
  [FIELD_NAMES.additional_info]: {
    rules: [
      {
        maxLength: (value) => {
          if (!value) {
            return true;
          }

          return value.length <= OPS_TRANSFER_ADDITIONAL_INFO_MAX_LENGTH;
        },
      },
    ],
    hints: {
      maxLength: OPS_TRANSFER_ADDITIONAL_INFO_MAX_CHARACTERS,
    },
  },
  [FIELD_NAMES.comment]: {
    rules: [
      {
        maxLength: (value) => {
          if (!value) {
            return true;
          }

          return value.length <= OPS_TRANSFER_COMMENT_MAX_LENGTH;
        },
      },
    ],
    hints: {
      maxLength: OPS_TRANSFER_COMMENT_MAX_CHARACTERS,
    },
  },
});
