import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { DEFAULT_FRACTION_DIGITS } from 'variables';
import { StyledAmountCell } from './AmountCellStyled';

const AmountCell = ({
  value,
  fractionDigits,
  currencyField,
  rowData,
  clickable,
  centered,
  formatNumber,
  getPrefix,
  getTextStyles,
  ...props
}) => {
  const prefix = getPrefix(rowData, value);
  const textStyles = getTextStyles(rowData);

  return (
    <StyledAmountCell clickable={clickable} centered={centered} {...props}>
      <Body noMargin size="two" {...textStyles}>
        {(value || value === 0) &&
          `${prefix}${prefix ? ' ' : ''}${parseFloat(
            formatNumber(value)
          ).toFixed(fractionDigits)} ${rowData[currencyField.name]}`}
      </Body>
    </StyledAmountCell>
  );
};

AmountCell.defaultProps = {
  value: '',
  fractionDigits: DEFAULT_FRACTION_DIGITS,
  clickable: false,
  centered: false,
  formatNumber: (number) => number,
  getPrefix: () => '',
  getTextStyles: () => {},
};

AmountCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fractionDigits: PropTypes.number,
  currencyField: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
  centered: PropTypes.bool,
  formatNumber: PropTypes.func,
  getPrefix: PropTypes.func,
  getTextStyles: PropTypes.func,
};

export default AmountCell;
