import {
  ERROR_MESSAGE,
  actionTypes,
  SEARCH_FIELDS_CHALLENGES_ONLINE,
  SEARCH_FIELDS_CHALLENGES_BANK,
} from 'variables';
import {
  CHALLENGES_TYPES_ONLINE,
  COLUMNS_BANK,
  COLUMNS_ONLINE,
} from './constants';

const onlineValue = CHALLENGES_TYPES_ONLINE.value;

export const challengesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: {
            challenges: { bank, online },
          },
        },
      } = action;

      return {
        ...state,
        loading: false,
        bankChallenges: bank,
        onlineChallenges: online,
        challenges: online,
        activeChallenges: online,
        activeFields: SEARCH_FIELDS_CHALLENGES_ONLINE,
        activeColumns: COLUMNS_ONLINE,
        activeChallengeType: onlineValue,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_ACTIVE_CHALLENGES: {
      const {
        payload: { activeChallengeType },
      } = action;

      const { onlineChallenges, bankChallenges } = state;
      const activeChallenges =
        activeChallengeType === onlineValue ? onlineChallenges : bankChallenges;

      return {
        ...state,
        activeChallengeType,
        activeColumns:
          activeChallengeType === onlineValue ? COLUMNS_ONLINE : COLUMNS_BANK,
        activeFields:
          activeChallengeType === onlineValue
            ? SEARCH_FIELDS_CHALLENGES_ONLINE
            : SEARCH_FIELDS_CHALLENGES_BANK,
        activeChallenges,
        challenges: activeChallenges,
      };
    }
    default:
      return state;
  }
};
