import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_FIELD_TYPES } from 'variables';
import { Button } from '@sumup/circuit-ui';
import CopyableCell from 'components/CopyableCell';
import { ReactComponent as LinkIcon } from 'assets/link.svg';
import MerchantCategoryCell from 'components/MerchantCategoryCode';
import { StyledEmptyCell } from './cellStyled';
import Link from '../../../../AdjustAuthorizations/components/AuthorizationsGrid/components/Link';

export const CellFactory = ({ renderAs, value, rowData, ...props }) => {
  const handleRedirect = ({ merchantId, authorizationCode }) => {
    window.open(
      `${window.location.origin}/client/${merchantId}/transactions?auth_code=${authorizationCode}`
    );
  };

  if (value == null) {
    return <StyledEmptyCell>—</StyledEmptyCell>;
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({ value, record: rowData });
  }

  switch (render) {
    case CUSTOM_FIELD_TYPES.LINK:
      return <Link {...props} value={value} />;
    case CUSTOM_FIELD_TYPES.CANCEL_BUTTON:
      return (
        <Button
          icon={LinkIcon}
          destructive
          variant="primary"
          size="kilo"
          onClick={() => handleRedirect(value)}
        >
          Cancel
        </Button>
      );
    case CUSTOM_FIELD_TYPES.MCC:
      return (
        <MerchantCategoryCell
          mcc={value.code}
          mccDescription={value.description}
        />
      );
    default:
      return <CopyableCell {...props} value={value} centered />;
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  rowData: {},
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  rowData: PropTypes.object,
};
