import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Card, Body, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledAuthorization = styled(Card)(
  ({ theme, selected }) => css`
    cursor: pointer;
    width: 100%;
    padding: ${theme.spacings.kilo} ${theme.spacings.mega};
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.byte};
    }
    ${selected &&
    `
      border: 2px solid ${theme.colors.b500};
      background: ${theme.colors.b100};
      `}
    &:hover {
      border: 2px solid ${theme.colors.b500};
      background: ${theme.colors.b100};
    }
  `
);

const StyledBody = styled(Body)`
  span {
    font-weight: bold;
  }
`;

const CardAuthorization = ({
  authorization: {
    authorizationCode,
    merchantName,
    authorizationId: id,
    retrievalReferenceNumber,
    date,
    billing: { amount: amountBilling, currency: currencyBilling },
    amountInSourceCurrency: {
      amount: amountTransaction,
      currency: currencyTransaction,
    },
  },
  onClickAuthorization,
  selected,
}) => {
  const formatAmount = (amount, currency, fractionDigits) =>
    (amount || amount === 0) &&
    `${parseFloat(amount / 100).toFixed(fractionDigits)} ${currency}`;
  const authorizationDate = moment(date).format('MMM DD, YYYY HH:MM:SS');

  return (
    <StyledAuthorization
      onClick={() => {
        onClickAuthorization({ id });
      }}
      selected={selected}
    >
      <Body variant="highlight" size="two" noMargin>
        {merchantName}
      </Body>
      <Body size="two" noMargin>
        {authorizationDate}
      </Body>
      <Headline size="three" noMargin>
        -{formatAmount(amountTransaction, currencyTransaction, 2)}
      </Headline>
      <Body size="two" noMargin>
        Billed: -{formatAmount(amountBilling, currencyBilling, 2)}
      </Body>
      <StyledBody size="two" noMargin variant="subtle">
        <span>RRN:</span> {retrievalReferenceNumber}
      </StyledBody>
      <StyledBody size="two" noMargin variant="subtle">
        <span>Auth:</span> {authorizationCode}
      </StyledBody>
    </StyledAuthorization>
  );
};

CardAuthorization.propTypes = {
  authorization: PropTypes.object.isRequired,
  onClickAuthorization: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default CardAuthorization;
