import { DATE_PERIOD_FIVE_DAYS } from 'variables';
import {
  AUTHORIZATIONS_GRID_FIRST_PAGE,
  DATE_PERIOD_OPTIONS,
  CARD_TOKEN_FILTER,
  PRE_AUTH_FILTER_ANY_OPTION,
  PRE_AUTH_FILTER,
} from './constants';

export const initialState = {
  loading: true,
  adjustAuthorizationLoading: [],
  ignoreAuthorizationLoading: [],
  error: null,
  size: 10,
  currentPage: AUTHORIZATIONS_GRID_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  authorizationsForAdjustment: [],
  totalSize: 0,
  datePeriod: DATE_PERIOD_OPTIONS[DATE_PERIOD_FIVE_DAYS],
  filtersApplied: false,
  applyFiltersEnabled: false,
  filters: {
    [CARD_TOKEN_FILTER]: '',
    [PRE_AUTH_FILTER]: PRE_AUTH_FILTER_ANY_OPTION,
  },
};
