import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Card = styled('div')(
  ({ theme: { spacings, colors, borderRadius }, background, disabled }) => css`
    display: flex;
    justify-content: center;
    margin: ${spacings.bit};
    border-radius: ${borderRadius.bit};
    background: ${background || colors.n100};

    > div {
      padding: 2px 6px;
    }

    svg {
      ${disabled && 'opacity: 0.5;'}
    }
  `
);

export default Card;
