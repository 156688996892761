import moment from 'moment';

export const isValidDate = (date) => date && moment(date).isValid();

export const getCurrentDate = () => moment().startOf('day');

export const isSameOrBeforeToday = (day) => {
  const normalizedDay = moment(day).startOf('day');

  return moment().endOf('day').isSameOrBefore(normalizedDay);
};

export const isBeforeToday = (day) => {
  const normalizedDay = moment(day).startOf('day');

  return getCurrentDate().isBefore(normalizedDay);
};

export const weekOrLessPassed = (day) =>
  day.startOf('day').isAfter(moment().subtract(7, 'days').startOf('day'));
