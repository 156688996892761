import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonGroup, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { normalizeAmount } from 'services/validation';
import { BANK_TRX_FRAUD_STATUS_MAP, DEBOUNCE_TIMEOUT_LONG } from 'variables';
import {
  BANK_TRX_FRAUD_TYPE_MAP,
  BANK_TRX_FRAUD_SCAM_ALERT_SUB_TYPE_MAP,
  BANK_TRX_FRAUD_CANCELLATION_REQUEST_SUB_TYPE_MAP,
  FRAUD_REPORT_DATE_FORMAT,
} from 'components/Transactions/constants';
import {
  TRANSACTION_IDENTIFIER,
  TOTAL_AMOUNT,
  TRANSACTION_FRAUD_REPORT_TYPE,
  TRANSACTION_FRAUD_REPORT_SUBTYPE,
  TRANSACTION_FRAUD_REPORT_DATE,
  TRANSACTION_FRAUD_REPORT_STATUS,
  TRANSACTION_FRAUD_REPORT_RECOVERED_AMOUNT,
  TRANSACTION_FRAUD_REPORT_REFERENCE,
  TRANSACTION_FRAUD_REPORT_DETAILS,
} from 'components/Transaction/fieldsConfig';
import { copyToClipboard } from 'services/common';
import { FraudReportModalContent } from './FraudReportModalContent';

const REFERENCE_MAX_LENGTH = 40;
const DETAILS_MAX_LENGTH = 700;

const ModalWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mq.tera} {
      width: 832px;
    }
  `
);

const HeadlineStyled = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.kilo};
  `
);

const Body = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${theme.spacings.giga};
  `
);

const Footer = styled('div')`
  display: flex;
  justify-content: center;
`;

const FraudReportModal = ({
  record,
  report,
  lastModified,
  onConfirm,
  onClose,
}) => {
  const isCancellationRequestSubtype =
    report?.[TRANSACTION_FRAUD_REPORT_TYPE.name] ===
    BANK_TRX_FRAUD_TYPE_MAP.CANCELLATION_REQUEST;

  const [subtypeMap, setSubtypeMap] = useState(
    isCancellationRequestSubtype
      ? BANK_TRX_FRAUD_CANCELLATION_REQUEST_SUB_TYPE_MAP
      : BANK_TRX_FRAUD_SCAM_ALERT_SUB_TYPE_MAP
  );

  const [type, setType] = useState(
    report?.[TRANSACTION_FRAUD_REPORT_TYPE.name] ||
      BANK_TRX_FRAUD_TYPE_MAP.SCAM_ALERT
  );

  const [subtype, setSubtype] = useState(
    report?.[TRANSACTION_FRAUD_REPORT_SUBTYPE.name] ||
      BANK_TRX_FRAUD_SCAM_ALERT_SUB_TYPE_MAP.ADVANCE
  );

  const [date, setDate] = useState(
    report
      ? moment(report[TRANSACTION_FRAUD_REPORT_DATE.name]).format(
          FRAUD_REPORT_DATE_FORMAT
        )
      : moment().format(FRAUD_REPORT_DATE_FORMAT)
  );

  const [status, setStatus] = useState(
    report?.[TRANSACTION_FRAUD_REPORT_STATUS.name] ||
      BANK_TRX_FRAUD_STATUS_MAP.PENDING
  );

  const [reference, setReference] = useState(
    report?.[TRANSACTION_FRAUD_REPORT_REFERENCE.name] || ''
  );

  const [referenceInvalid, setReferenceInvalid] = useState(false);

  const [recoveredAmount, setRecoveredAmount] = useState(
    report
      ? Math.abs(report[TRANSACTION_FRAUD_REPORT_RECOVERED_AMOUNT.name] / 100)
      : 0
  );

  const [recoveredAmountInvalid, setRecoveredAmountInvalid] = useState(false);

  const [details, setDetails] = useState(
    report?.[TRANSACTION_FRAUD_REPORT_DETAILS.name] || ''
  );

  const [detailsInvalid, setDetailsInvalid] = useState(false);

  const [copied, setCopied] = useState(false);

  const generateComment = `FRD BNK: ${type}, ${subtype}, Claim ID ${reference}. TX ID ${
    record[TRANSACTION_IDENTIFIER.name]
  } (${Math.abs(record[TOTAL_AMOUNT.name].amount) / 100} ${
    record[TOTAL_AMOUNT.name].currency
  }). Amount secured (${recoveredAmount} ${
    record[TOTAL_AMOUNT.name].currency
  }).`;

  const handleFraudTypeChange = ({ target: { value } }) => {
    if (value === BANK_TRX_FRAUD_TYPE_MAP.SCAM_ALERT) {
      setSubtypeMap(BANK_TRX_FRAUD_SCAM_ALERT_SUB_TYPE_MAP);
      setSubtype(BANK_TRX_FRAUD_SCAM_ALERT_SUB_TYPE_MAP.ADVANCE);
    } else {
      setSubtypeMap(BANK_TRX_FRAUD_CANCELLATION_REQUEST_SUB_TYPE_MAP);
      setSubtype(BANK_TRX_FRAUD_CANCELLATION_REQUEST_SUB_TYPE_MAP.FRAD);
    }
    setType(value);
  };

  const handleFraudSubTypeChange = ({ target: { value } }) => {
    setSubtype(value);
  };

  const handleDateChange = ({ target: { value } }) => {
    setDate(value);
  };

  const handleStatusChange = ({ status: statusValue }) => {
    setStatus(statusValue);
  };

  const handleRecoveredAmountChange = ({ target: { value } }) => {
    const normalizedAmount = normalizeAmount(value);
    setRecoveredAmount(normalizedAmount);
    setRecoveredAmountInvalid(!normalizedAmount && normalizedAmount !== 0);
  };

  const handleReferenceChange = ({ target: { value } }) => {
    setReference(value);
    setReferenceInvalid(value.length > REFERENCE_MAX_LENGTH);
  };

  const handleDetailsChange = ({ target: { value } }) => {
    setDetails(value);
    setDetailsInvalid(value.length > DETAILS_MAX_LENGTH);
  };

  const handleCommentCopy = () => {
    setCopied(true);
    copyToClipboard(generateComment);
    setTimeout(() => {
      setCopied(false);
    }, DEBOUNCE_TIMEOUT_LONG);
  };

  const onConfirmHandler = () => {
    onConfirm({
      type,
      subtype,
      date,
      status,
      recoveredAmount: recoveredAmount * 100,
      reference,
      details,
      lastModified,
    });
  };

  const buttonsDisabled =
    referenceInvalid || detailsInvalid || recoveredAmountInvalid;

  const handleClick = (e) => {
    onConfirmHandler();
    onClose(e);
  };

  return (
    <ModalWrapper>
      <HeadlineStyled as="h1" size="three" noMargin>
        Mark as fraud
      </HeadlineStyled>
      <Body noMargin>
        <FraudReportModalContent
          record={record}
          type={type}
          subtype={subtype}
          date={date}
          status={status}
          recoveredAmount={recoveredAmount}
          recoveredAmountInvalid={recoveredAmountInvalid}
          reference={reference}
          referenceInvalid={referenceInvalid}
          details={details}
          detailsInvalid={detailsInvalid}
          subtypeMap={subtypeMap}
          copied={copied}
          onFraudTypeChange={handleFraudTypeChange}
          onFraudSubTypeChange={handleFraudSubTypeChange}
          onDateChange={handleDateChange}
          onStatusChange={handleStatusChange}
          onRecoveredAmountChange={handleRecoveredAmountChange}
          onReferenceChange={handleReferenceChange}
          onDetailsChange={handleDetailsChange}
          onCommentCopy={handleCommentCopy}
        />
      </Body>
      <Footer>
        <ButtonGroup
          actions={{
            primary: {
              destructive: !report,
              disabled: buttonsDisabled,
              onClick: handleClick,
              children: report ? 'Save' : 'Mark as fraud',
            },
            secondary: {
              onClick: onClose,
              children: 'Cancel',
            },
          }}
          align="right"
        />
      </Footer>
    </ModalWrapper>
  );
};

FraudReportModal.defaultProps = {
  report: null,
  lastModified: null,
};

FraudReportModal.propTypes = {
  record: PropTypes.object.isRequired,
  report: PropTypes.object,
  lastModified: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FraudReportModal;
