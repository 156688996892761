import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Hide } from '@sumup/icons';
import Tooltip from 'components/Tooltip';

const Wrapper = styled('div')(
  ({ theme }) => css`
    > div:first-of-type {
      max-width: 24px;
    }

    svg {
      margin: 0 0 ${theme.spacings.bit};
      color: ${theme.colors.n900};
    }
  `
);

const TooltipContent = styled('div')`
  min-width: 190px;
`;

export const HideRecipientDetailsCell = ({ recipient, showBeneficiary }) => (
  <Wrapper>
    {!showBeneficiary && (
      <Tooltip
        position="top"
        pivot={<Hide />}
        content={
          <TooltipContent noMargin size="two">
            Details hidden from merchant
          </TooltipContent>
        }
      />
    )}
    <Body noMargin size="two">
      {recipient}
    </Body>
  </Wrapper>
);

HideRecipientDetailsCell.propTypes = {
  recipient: PropTypes.string.isRequired,
  showBeneficiary: PropTypes.bool.isRequired,
};
