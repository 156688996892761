import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { clickableCellStyles } from 'components/DataGrid/DataGridStyled';

export const StyledCell = styled('div')(
  ({ theme, clickable, centered }) => css`
    ${clickable && clickableCellStyles(theme)}
    ${centered &&
    `
    justify-content: center;
    text-align: center;
  `}
  `
);
