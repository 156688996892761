import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Tooltip from 'components/Tooltip';
import { POS_ENTRY_MODE_MAP } from 'components/Transactions/constants';
import {
  APPLE_PAY_WALLET_ID,
  GOOGLE_PAY_WALLET_ID,
  TRANSACTIONS_GRID_TOOLTIP_PADDING,
  TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH,
} from 'variables';
import ClientDataContext from 'context/clientData';
import { find } from 'lodash';
import { CARD_TOKEN, PAN } from 'components/Account/columns';
import {
  CARD_DATA_CARD_TOKEN,
  CARD_DATA_FIELD,
  CARD_DATA_WALLET_IDENTIFIER,
  CARD_DATA_WALLET_NAME,
} from 'components/Transaction/fieldsConfig';
import Card from '../Card';

const StyledPan = styled('div')(
  ({ theme }) => css`
    display: flex;

    > p {
      margin-right: ${theme.spacings.bit};
    }
  `
);

const TooltipContent = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: ${TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH}px;
`;

const tooltipStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: TRANSACTIONS_GRID_TOOLTIP_PADDING,
};

const pivotStyle = {
  margin: '2px 0',
};

const EntryModeCell = ({ value, style, disabled, rowData }) => {
  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const cardData = rowData[CARD_DATA_FIELD.name] || {};
  const txCardToken = cardData[CARD_DATA_CARD_TOKEN.name];

  const txCard = txCardToken
    ? find(
        clientData.cards || [],
        (card) => card[CARD_TOKEN.name] === txCardToken
      )
    : {};

  const pan = txCard[PAN.name];
  const walletId = cardData[CARD_DATA_WALLET_IDENTIFIER.name];
  const walletName = cardData[CARD_DATA_WALLET_NAME.name];

  const {
    label,
    component: DefaultComponent,
    appleWalletComponent: AppleComponent,
    googleWalletComponent: GoogleComponent,
    defaultWalletComponent: DefaultWalletComponent,
  } = POS_ENTRY_MODE_MAP[value] || {};

  const Component =
    (walletId === APPLE_PAY_WALLET_ID && AppleComponent) ||
    (walletId === GOOGLE_PAY_WALLET_ID && GoogleComponent) ||
    (walletId && DefaultWalletComponent) ||
    DefaultComponent;

  return (
    <div style={style}>
      {Component ? (
        <Card disabled={disabled}>
          <Tooltip
            position="top"
            content={
              <TooltipContent>
                Entry mode:
                <Body noMargin variant="highlight" size="two">
                  {label} {walletName ? ` via ${walletName}` : ''}
                </Body>
                {pan && (
                  <StyledPan>
                    <Body noMargin variant="highlight" size="two">
                      PAN:
                    </Body>
                    {pan}
                  </StyledPan>
                )}
              </TooltipContent>
            }
            pivot={<Component />}
            tooltipStyle={tooltipStyle}
            pivotStyle={pivotStyle}
          />
        </Card>
      ) : (
        label
      )}
    </div>
  );
};

EntryModeCell.defaultProps = {
  value: '',
  style: {},
  disabled: false,
  rowData: {},
};

EntryModeCell.propTypes = {
  value: PropTypes.any,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  rowData: PropTypes.object,
};

export default EntryModeCell;
