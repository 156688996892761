import { getUserDetails, getUserActivity as getUserActivityApiCall } from 'api';

export const getUserActivity = async ({ clientId, httpClient }) => {
  const { data: userDetails } = await getUserDetails({ clientId, httpClient });
  const { data: userActivity } = await getUserActivityApiCall({
    clientId,
    httpClient,
  });

  return {
    data: {
      ...userDetails,
      ...userActivity,
    },
  };
};
