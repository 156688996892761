import moment from 'moment';
import {
  DATE_FORMATS,
  DATE_GRID_COLUMN_MAX_WIDTH,
  FIELD_TYPES,
} from 'variables';
import { SORT_ORDER_DESCENDING } from 'variables/globals';

const DATE = {
  label: 'Date',
  name: 'date',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  style: {
    maxWidth: DATE_GRID_COLUMN_MAX_WIDTH,
  },
};

const ACTOR = {
  label: 'Actor',
  name: 'actor',
  style: {
    maxWidth: 220,
  },
};

const SOURCE = {
  label: 'Source',
  name: 'source',
  style: {
    maxWidth: 210,
  },
};

const EVENT = {
  label: 'Event',
  name: 'type',
  style: {
    maxWidth: 220,
  },
};

const DETAILS = {
  label: 'Details',
  name: 'details',
};

const COMMENT = {
  label: 'Comment',
  name: 'comment',
};

export const columns = [
  { ...DATE, active: true, sorted: true, sortOrder: SORT_ORDER_DESCENDING },
  ACTOR,
  SOURCE,
  EVENT,
  DETAILS,
  COMMENT,
];

export const ACTIVITY_LOG_NON_NUMERIC_FIELDS_MAP = {
  [DATE.name]: true,
};
