import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Alert } from '@sumup/icons';
import moment from 'moment';
import { Body, Button, spacing, useModal } from '@sumup/circuit-ui';
import { ACTIONS, APPS, DATE_FORMATS } from 'variables';
import { getCurrentDate } from 'services/dateTime';
import { hasActionAccess } from 'services/permissions';
import AuthContext from 'context/auth';
import ClientDataContext from 'context/clientData';
import { PAYMENT_SCHEDULE_TYPE_ONE_OFF } from '../constants';
import { isFinalPaymentOfSchedule } from '../service';
import { CancelUpcomingPaymentModal } from './CancelUpcomingPaymentModal';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 130px;
`;

const StyledButton = styled(Button)(
  ({ theme, paymentDetails }) => css`
    font-size: 14px;
    margin-bottom: -11px;

    ${paymentDetails &&
    `
      background: ${theme.colors.white};
      color: ${theme.colors.alert};

      svg {
        color: ${theme.colors.alert};
      }

      :hover {
        background: ${theme.colors.white};
        color: ${theme.colors.r700};
        border-color: ${theme.colors.r700};
  
        svg {
          color: ${theme.colors.r700};
        }
      }
    `}
  `
);

export const CancelUpcomingPaymentButton = ({
  schedule,
  paymentRequestId,
  paymentDetails,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const { bank_account_identifier: accountId } = clientData || {};

  const { setModal } = useModal();

  const { type, start_date: startDate, end_date: endDate } = schedule || {};

  const nextPaymentDue = moment(schedule.next_payment_due || schedule.next_due);

  const finalPayment =
    type !== PAYMENT_SCHEDULE_TYPE_ONE_OFF &&
    endDate &&
    isFinalPaymentOfSchedule({
      type,
      nextDue: nextPaymentDue,
      scheduleStart: startDate,
      scheduleEnd: endDate,
    });

  const handleCancelPayment = () => {
    setModal({
      children: ({ onClose }) => (
        <CancelUpcomingPaymentModal
          type={type}
          finalPayment={finalPayment}
          paymentRequestId={paymentRequestId}
          accountId={accountId}
          setLoading={setLoading}
          onConfirm={onConfirm}
          onClose={onClose}
        />
      ),
      closeButtonLabel: 'Close',
    });
  };

  const hasPermissions = hasActionAccess({
    permissions,
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardScheduledPaymentsSkipNextPayment,
  });

  const today = getCurrentDate();

  return (
    <Wrapper>
      {!paymentDetails && (
        <>
          <Body noMargin size="two">
            Upcoming payment
          </Body>
          <Body noMargin size="two" variant="highlight">
            {nextPaymentDue.format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
          </Body>
        </>
      )}
      {hasPermissions &&
        nextPaymentDue.isAfter(today) &&
        (!endDate || (endDate && moment(endDate).isSameOrAfter(today))) && (
          <StyledButton
            css={paymentDetails ? spacing({ top: 'kilo' }) : null}
            variant={paymentDetails ? 'primary' : 'tertiary'}
            destructive
            paymentDetails={paymentDetails}
            isLoading={loading}
            loadingLabel="Cancelling payment..."
            onClick={handleCancelPayment}
          >
            {paymentDetails && <Alert />}
            <Body
              noMargin
              size="one"
              variant="highlight"
              css={paymentDetails ? spacing({ left: 'byte' }) : null}
            >
              Cancel payment
            </Body>
          </StyledButton>
        )}
    </Wrapper>
  );
};

CancelUpcomingPaymentButton.defaultProps = {
  paymentDetails: false,
};

CancelUpcomingPaymentButton.propTypes = {
  schedule: PropTypes.object.isRequired,
  paymentRequestId: PropTypes.string.isRequired,
  paymentDetails: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};
