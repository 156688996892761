import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, Alert } from '@sumup/icons';
import { ReactComponent as FilterFunnel } from 'assets/filter-funnel.svg';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Card, Popover } from '@sumup/circuit-ui';
import { FILTERS_MIN_HEIGHT } from 'variables';
import { getFilters } from './filters';
import { CARD_TOKEN_FILTER } from '../../constants';

const FiltersPopoverComponent = styled(Card)(
  ({
    theme: { spacings, colors, borderWidth, borderRadius },
    filtersApplied,
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: ${FILTERS_MIN_HEIGHT}px;
    padding: 0;
    margin: 0 2px;
    cursor: pointer;
    border: ${borderWidth.kilo} solid ${colors.n200};
    border-radius: ${borderRadius.byte};

    & > div > p {
      display: flex;
    }

    & svg {
      color: ${colors.n500};
      margin: auto ${spacings.byte};
    }

    ${filtersApplied &&
    `
        background: ${colors.confirm};
        color: ${colors.white};

        & svg {
          color: ${colors.white};
        }
      `}
  `
);

const FiltersButton = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ClearFiltersButton = styled('div')(
  ({ theme: { spacings } }) => css`
    display: flex;
    align-items: center;
    background: #0f6d3a;
    min-height: ${FILTERS_MIN_HEIGHT}px;
    margin-left: ${spacings.byte};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    & svg {
      color: #07371d;
    }
  `
);

const StyledText = styled(Body)`
  display: flex;
`;

const StyledPopover = styled(Popover)(
  ({ theme }) => `
    & > div {
      padding: 0;
    }
    button {
      &:focus {
        box-shadow: none;
      }
      &:not(:last-child) {
        padding: ${theme.spacings.mega};
      }
      &:last-child {
        width: 100%;
      }
    }
  `
);

const FiltersPicker = ({
  filters,
  filtersApplied,
  applyFiltersEnabled,
  onFilterChange,
  onFiltersApply,
  onFiltersClear,
  showCurrencyFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cardIdFilterValue, setCardIdFilterValue] = useState();

  useEffect(() => {
    setCardIdFilterValue(filters[CARD_TOKEN_FILTER]);
  }, [filters]);

  const toggleIsOpen = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const popoverActions = getFilters({
    filters,
    applyFiltersEnabled,
    cardIdFilterValue,
    onCardTokenFilterValueChange: setCardIdFilterValue,
    onFilterChange,
    onFiltersApply,
    showCurrencyFilter,
  });

  const popoverComponent = () => (
    <FiltersPopoverComponent filtersApplied={filtersApplied}>
      {filtersApplied ? (
        <FiltersButton>
          <StyledText
            size="two"
            noMargin
            variant="highlight"
            filtersApplied
            onClick={toggleIsOpen}
          >
            <FilterFunnel /> Filters applied
          </StyledText>
          <ClearFiltersButton onClick={onFiltersClear}>
            <Alert size="16" />
          </ClearFiltersButton>
        </FiltersButton>
      ) : (
        <FiltersButton onClick={toggleIsOpen}>
          <StyledText size="two" noMargin variant="highlight">
            <FilterFunnel /> Filters
          </StyledText>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </FiltersButton>
      )}
    </FiltersPopoverComponent>
  );

  return (
    <StyledPopover
      placement="bottom-start"
      isOpen={isOpen}
      actions={popoverActions}
      component={popoverComponent}
      onToggle={() => setIsOpen(!isOpen)}
    />
  );
};

FiltersPicker.defaultProps = {
  showCurrencyFilter: false,
};

FiltersPicker.propTypes = {
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
  applyFiltersEnabled: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  showCurrencyFilter: PropTypes.bool,
};

export default FiltersPicker;
