import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Dropdown from 'components/Dropdown';

const POPOVER_WIDTH = 128;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: ${POPOVER_WIDTH}px;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin: 0;

    &:first-of-type {
      margin: 0 6px 0 2px;
    }
  }
`;

export const DropdownOption = styled('div')(
  ({ disabled }) => css`
    cursor: pointer;

    ${disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}

    > div {
      display: flex;
      flex-direction: row;
    }
  `
);
