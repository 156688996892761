import React from 'react';
import PropTypes from 'prop-types';
import StyledInputCell from './StyledInputCell';

const InputCell = ({ onInputChange, label, value, invalid, disabled }) => (
  <div>
    <StyledInputCell
      noMargin
      label={label}
      hideLabel
      onChange={onInputChange}
      invalid={invalid}
      value={value}
      disabled={disabled}
    />
  </div>
);

InputCell.defaultProps = {
  invalid: false,
  disabled: false,
};

InputCell.propTypes = {
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default InputCell;
