import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Headline } from '@sumup/circuit-ui';

export const HeadingWrapper = styled('div')(
  ({ theme, bottomSeparator, padding, margin }) => css`
    display: flex;
    padding: ${padding || theme.spacings.zetta} 0;
    margin: ${margin || theme.spacings.zetta} 0;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;

    ${bottomSeparator &&
    `
      &:after {
        width: 100px;
        height: 1px;
        display: block;
        content: '';
        background: ${theme.colors.n300};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    `}
  `
);

export const Separator = styled(Body)(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n300};
  `
);

export const StyledHeading = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${theme.colors.n500};
  `
);
