import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { some } from 'lodash';
import { CUSTOM_FIELD_TYPES, FIELD_TYPES } from 'variables';
import { VIEW_HISTORY_FIELD_NAME } from 'components/OpsTransfers/fieldsConfig';
import DateCell from 'components/DataGrid/components/DateCell';
import CopyableCell from 'components/CopyableCell';
import Empty from 'components/Empty';
import StatusBadgeCell from 'components/DataGrid/components/StatusBadgeCell';
import { STATUS_MAP } from 'components/OpsTransfers/constants';
import AmountCell from 'components/DataGrid/components/AmountCell';
import { NextStatusCell } from '../components/NextStatusCell';
import { PopoverCell } from '../components/PopoverCell';

export const StyledEmptyCell = styled(Empty)`
  align-items: center;
`;

export const CellFactory = ({
  renderAs,
  value,
  rowData,
  centered,
  popoverOptions,
  dispatch,
  hasActionAccess,
  hasSectionAccess,
  updateOpsTransfers,
  ...props
}) => {
  if (value == null) {
    return <StyledEmptyCell>—</StyledEmptyCell>;
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({ value, record: rowData });
  }

  switch (render) {
    case FIELD_TYPES.DATE_TIME:
      return <DateCell {...props} value={value} centered />;
    case CUSTOM_FIELD_TYPES.STATUS_BADGE:
      return (
        <StatusBadgeCell
          {...props}
          value={STATUS_MAP[value] || value}
          rowData={rowData}
        />
      );
    case CUSTOM_FIELD_TYPES.AMOUNT:
      return <AmountCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.OPS_TRANSFER_NEXT_STATUS:
      return (
        <NextStatusCell
          {...props}
          value={value}
          rowData={rowData}
          hasActionAccess={hasActionAccess}
          updateOpsTransfers={updateOpsTransfers}
        />
      );
    case CUSTOM_FIELD_TYPES.POPOVER:
      return some(popoverOptions(rowData), ({ name, permissions }) => {
        if (name === VIEW_HISTORY_FIELD_NAME) {
          return hasSectionAccess(permissions);
        }

        return hasActionAccess(permissions);
      }) ? (
        <PopoverCell
          {...props}
          value={value}
          rowData={rowData}
          popoverOptions={popoverOptions(rowData)}
          updateOpsTransfers={updateOpsTransfers}
        />
      ) : null;
    case CUSTOM_FIELD_TYPES.CELL:
      return value;
    default:
      return <CopyableCell {...props} value={value} centered={centered} />;
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  rowData: {},
  centered: true,
  popoverOptions: () => {},
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  rowData: PropTypes.object,
  centered: PropTypes.bool,
  popoverOptions: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  updateOpsTransfers: PropTypes.func.isRequired,
};
