import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Popover } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import { map } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyledCard } from 'components/Transactions/TransactionsStyled';
import {
  TRANSACTION_DIRECTION_DROPDOWN_FILTER_MIN_WIDTH,
  FILTERS_MIN_HEIGHT,
} from 'variables';
import { TRANSACTION_DIRECTION_FILTER_OPTIONS } from 'components/Transactions/constants';

const PopoverComponent = styled(StyledCard)(
  ({ theme: { spacings, colors } }) => css`
    display: flex;
    align-items: center;
    min-height: ${FILTERS_MIN_HEIGHT}px;
    min-width: ${TRANSACTION_DIRECTION_DROPDOWN_FILTER_MIN_WIDTH}px;

    svg {
      margin: auto ${spacings.byte};
      color: ${colors.n500};
    }
  `
);

const PopoverOption = styled(Body)(
  ({ theme: { colors }, disabled }) => css`
    min-width: ${TRANSACTION_DIRECTION_DROPDOWN_FILTER_MIN_WIDTH}px;
    text-align: left;

    ${disabled
      ? `
      color: ${colors.n300};
    `
      : `
      cursor: pointer;
    `}
  `
);

const StyledPopover = styled(Popover)`
  button {
    &:focus {
      box-shadow: none;
    }
  }
`;

const TransactionDirectionDropdownFilter = ({
  direction: selectedDirection,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectDirection = ({ disabled, direction }) => {
    if (!disabled) {
      setIsOpen(false);
      onChange(direction);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const popoverActions = map(
    TRANSACTION_DIRECTION_FILTER_OPTIONS,
    ({ value, label }) => ({
      children: (
        <PopoverOption
          key={value}
          noMargin
          size="two"
          disabled={value === selectedDirection}
        >
          {label}
        </PopoverOption>
      ),
      onClick: () =>
        handleSelectDirection({
          disabled: value === selectedDirection,
          direction: value,
        }),
    })
  );

  const popoverComponent = () => (
    <PopoverComponent
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(true);
      }}
    >
      <Body size="two" noMargin>
        Direction:
      </Body>
      <Body size="two" variant="highlight" noMargin>
        {TRANSACTION_DIRECTION_FILTER_OPTIONS[selectedDirection]?.label}
      </Body>
      {isOpen ? <ChevronUp /> : <ChevronDown />}
    </PopoverComponent>
  );

  return (
    <StyledPopover
      isOpen={isOpen}
      component={popoverComponent}
      actions={popoverActions}
      onToggle={handleToggle}
    />
  );
};

TransactionDirectionDropdownFilter.propTypes = {
  direction: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TransactionDirectionDropdownFilter;
