import React from 'react';
import PropTypes from 'prop-types';
import { Headline, Selector, Body } from '@sumup/circuit-ui';
import { PAGE_TITLES } from 'variables';
import DataGrid from '../../DataGrid';
import { CHALLENGES_TYPES_ONLINE, CHALLENGES_TYPES_BANK } from '../constants';
import { HeadingWrapper, SelectorGroup } from './ChallengesStyled';

export const Challenges = ({
  columns,
  challenges,
  activeChallengeType,
  nonNumericFields,
  sortable,
  noResultsText,
  onChallengeTypeChange,
}) => (
  <>
    <HeadingWrapper>
      <Headline noMargin as="h2" size="two">
        {PAGE_TITLES.CHALLENGES.heading}
      </Headline>
      <SelectorGroup>
        <Selector
          noMargin
          checked={activeChallengeType === CHALLENGES_TYPES_ONLINE.value}
          onChange={() => onChallengeTypeChange(CHALLENGES_TYPES_ONLINE.value)}
        >
          <Body noMargin size="one">
            {CHALLENGES_TYPES_ONLINE.label}
          </Body>
        </Selector>
        <Selector
          noMargin
          checked={activeChallengeType === CHALLENGES_TYPES_BANK.value}
          onChange={() => onChallengeTypeChange(CHALLENGES_TYPES_BANK.value)}
        >
          <Body noMargin size="one">
            {CHALLENGES_TYPES_BANK.label}
          </Body>
        </Selector>
      </SelectorGroup>
    </HeadingWrapper>
    <DataGrid
      columns={columns}
      dataSource={challenges}
      nonNumericFields={nonNumericFields}
      sortable={sortable}
      noResultsText={noResultsText}
    />
  </>
);

Challenges.defaultProps = {
  activeChallengeType: CHALLENGES_TYPES_ONLINE.value,
  nonNumericFields: {},
  sortable: false,
  noResultsText: null,
};

Challenges.propTypes = {
  columns: PropTypes.array.isRequired,
  challenges: PropTypes.array.isRequired,
  activeChallengeType: PropTypes.string,
  nonNumericFields: PropTypes.object,
  sortable: PropTypes.bool,
  noResultsText: PropTypes.string,
  onChallengeTypeChange: PropTypes.func.isRequired,
};
