import { SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING } from 'variables';
import {
  DIRECT_DEBIT_MANDATES_FIRST_PAGE,
  MANDATE_HISTORY_PAGE_SIZE,
  STATUS_ACTIVE,
} from './constants';
import { BENEFICIARY } from './columns';
import { DATE } from './mandateHistoryColumns';

export const initialState = {
  loading: true,
  error: null,
  mandates: [],
  pageSize: 10,
  currentPage: DIRECT_DEBIT_MANDATES_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  statusFilter: STATUS_ACTIVE.value,
  sort: {
    [BENEFICIARY.name]: SORT_ORDER_ASCENDING,
    fieldName: BENEFICIARY.name,
  },
  selectedMandate: null,
  upcomingPayment: null,
  transactionHistoryLoadingForId: null,
  transactionHistory: {
    transactions: [],
    pageSize: MANDATE_HISTORY_PAGE_SIZE,
    currentPage: DIRECT_DEBIT_MANDATES_FIRST_PAGE,
    totalPages: 1,
    totalCount: 0,
    sort: {
      [DATE.name]: SORT_ORDER_DESCENDING,
      fieldName: DATE.name,
    },
  },
};
