import React from 'react';
import {
  isPayIn,
  isSelfTransfer,
  isSelfTransferReturn,
  isBalanceToBalance,
} from 'services/transactions';
import { CUSTOM_FIELD_TYPES, SORT_ORDER_DESCENDING } from 'variables';
import {
  CREATED_AT,
  CARD_DATA_CLEARING_DATE,
  TRANSACTION_IDENTIFIER,
  CARD_DATA_MESSAGE_TYPE,
  TRANSACTION_TYPE,
  CARD_DATA_POS_ENTRY_MODE,
  TOTAL_AMOUNT,
  BILLING_AMOUNT,
  CARD_DATA_CLEARING_AMOUNT,
  CARD_DATA_CLEARING_BILLING_AMOUNT,
  FEE_AMOUNT,
  RETRIEVAL_REFERENCE_NUMBER,
  CARD_DATA_AUTH_CODE,
  MERCHANT_DETAILS,
  CARD_DATA_MCC,
  CONVERSION_RATE_BILLING_FIELD,
  TRANSACTION_STATUS,
  CARD_DATA_CANCELLED,
  CARD_DATA_CHARGED_BACK,
  CARD_DATA_MARKED_AS_FRAUD,
  TRANSACTION_CANCELLATION,
  TRANSACTION_CHARGEBACK,
  TRANSACTION_MARK_AS_FRAUD,
  TRANSACTION_ORIGIN,
  BANK_DATA_MANDATE_ID,
  DPAN,
  DOWNLOAD_STATEMENT,
  MARK_FRAUD_REPORT,
  EDIT_FRAUD_REPORT,
  UNMARK_FRAUD_REPORT,
  TRANSACTION_FRAUD_REPORT,
  REQUEST_OPS_TRANSFER,
  isInboundTransaction,
  ISSUE_A_RETURN,
} from '../Transaction/fieldsConfig';
import {
  CARD_TRANSACTION_ORIGIN,
  DOWNLOAD_STATEMENT_ACTION,
  MARK_AS_FRAUD_ACTION,
  TRANSACTIONS_CLEARING_SECTION_KEY,
  MARK_FRAUD_REPORT_ACTION,
  EDIT_FRAUD_REPORT_ACTION,
  UNMARK_FRAUD_REPORT_ACTION,
  REQUEST_OPS_TRANSFER_ACTION,
  TRANSACTION_STATUSES,
} from './constants';

const transactionColumns = [
  {
    key: CREATED_AT.name,
    fields: [
      {
        ...CREATED_AT,
        sortable: true,
        sortOrder: SORT_ORDER_DESCENDING,
      },
    ],
    style: {
      maxWidth: 105,
    },
  },
  {
    key: TRANSACTION_IDENTIFIER.name,
    fields: [
      {
        ...TRANSACTION_IDENTIFIER,
        prefix: <span>T:</span>,
        fullLabel: TRANSACTION_IDENTIFIER.label,
        label: 'IDs',
      },
      {
        ...RETRIEVAL_REFERENCE_NUMBER,
        prefix: <span>R:</span>,
        fullLabel: RETRIEVAL_REFERENCE_NUMBER.label,
        label: '',
      },
      {
        ...CARD_DATA_AUTH_CODE,
        prefix: <span>A:</span>,
        fullLabel: CARD_DATA_AUTH_CODE.label,
        label: '',
      },
      {
        ...BANK_DATA_MANDATE_ID,
        prefix: <span>M:</span>,
        fullLabel: BANK_DATA_MANDATE_ID.label,
        label: '',
      },
    ],
    style: {
      minWidth: 135,
    },
  },
  {
    key: CARD_DATA_MESSAGE_TYPE.name,
    fields: [
      { ...CARD_DATA_MESSAGE_TYPE, sortable: true },
      { ...TRANSACTION_STATUS, sortable: true },
    ],
    style: {
      minWidth: 140,
    },
  },
  {
    key: TRANSACTION_TYPE.name,
    fields: [
      { ...TRANSACTION_TYPE, sortable: true },
      { ...CARD_DATA_POS_ENTRY_MODE, sortable: true },
      { ...DPAN, sortable: true },
    ],
    style: {
      minWidth: 110,
    },
  },
  {
    key: TOTAL_AMOUNT.name,
    fields: [{ ...TOTAL_AMOUNT, sortable: true }],
    style: {
      minWidth: 85,
    },
  },
  {
    key: FEE_AMOUNT.name,
    fields: [{ ...FEE_AMOUNT, sortable: true }],
    style: { maxWidth: 90 },
  },
];

const clearingColumns = [
  {
    key: CARD_DATA_CLEARING_DATE.name,
    fields: [{ ...CARD_DATA_CLEARING_DATE, sortable: true }],
  },
  {
    key: CARD_DATA_CLEARING_AMOUNT.name,
    fields: [{ ...CARD_DATA_CLEARING_AMOUNT, sortable: true }],
  },
];

const billingColumns = [
  {
    key: BILLING_AMOUNT.name,
    fields: [
      { ...BILLING_AMOUNT, sortable: true },
      { ...CONVERSION_RATE_BILLING_FIELD, sortable: true },
    ],
  },
  {
    key: CARD_DATA_CLEARING_BILLING_AMOUNT.name,
    fields: [{ ...CARD_DATA_CLEARING_BILLING_AMOUNT, sortable: true }],
  },
];

const otherInformationColumns = [
  {
    key: CARD_DATA_MCC.name,
    fields: [{ ...CARD_DATA_MCC, sortable: true }],
    style: { maxWidth: 80 },
  },
  {
    key: MERCHANT_DETAILS.name,
    fields: [
      {
        ...MERCHANT_DETAILS,
        sortable: true,
        renderAs: CUSTOM_FIELD_TYPES.MCC_DESCRIPTION,
      },
    ],
  },
];

const fraudReportActions = (record) =>
  record[TRANSACTION_FRAUD_REPORT.name]
    ? [
        {
          ...EDIT_FRAUD_REPORT,
          key: EDIT_FRAUD_REPORT_ACTION,
        },
        {
          ...UNMARK_FRAUD_REPORT,
          key: UNMARK_FRAUD_REPORT_ACTION,
        },
      ]
    : [
        {
          ...MARK_FRAUD_REPORT,
          key: MARK_FRAUD_REPORT_ACTION,
        },
      ];

const ACTIONS_FIELD = {
  name: '',
  renderAs: CUSTOM_FIELD_TYPES.POPOVER,
  popoverOptions: (record) => {
    if (
      !record[TRANSACTION_ORIGIN.name] ||
      isPayIn(record) ||
      isSelfTransferReturn(record) ||
      isBalanceToBalance(record)
    ) {
      return [];
    }

    const { status } = record;
    if (isSelfTransfer(record) && status === TRANSACTION_STATUSES.APPROVED) {
      return [ISSUE_A_RETURN];
    }

    return record[TRANSACTION_ORIGIN.name] === CARD_TRANSACTION_ORIGIN
      ? [
          {
            ...TRANSACTION_MARK_AS_FRAUD,
            label: 'Report confirmed fraud',
            key: MARK_AS_FRAUD_ACTION,
          },
          { ...TRANSACTION_CHARGEBACK, label: 'Chargeback transaction' },
          {
            ...TRANSACTION_CANCELLATION,
            label: 'Cancel transaction',
          },
        ]
      : [
          {
            ...DOWNLOAD_STATEMENT,
            key: DOWNLOAD_STATEMENT_ACTION,
          },
          ...(isInboundTransaction(record)
            ? [{ ...REQUEST_OPS_TRANSFER, key: REQUEST_OPS_TRANSFER_ACTION }]
            : []),
          ...fraudReportActions(record),
        ];
  },
};

const actionsColumns = [
  {
    key: CARD_DATA_CHARGED_BACK.name,
    fields: [
      CARD_DATA_CHARGED_BACK,
      CARD_DATA_CANCELLED,
      CARD_DATA_MARKED_AS_FRAUD,
      ACTIONS_FIELD,
    ],
  },
];

export const columns = [
  {
    label: 'Transaction',
    style: {
      width: '50%',
    },
    subColumns: transactionColumns,
  },
  {
    sectionKey: TRANSACTIONS_CLEARING_SECTION_KEY,
    label: 'Clearing',
    style: {
      width: '13%',
    },
    subColumns: clearingColumns,
  },
  {
    label: 'Billing',
    style: {
      width: '13%',
    },
    subColumns: billingColumns,
  },
  {
    style: {
      width: '17%',
    },
    label: 'Other information',
    subColumns: otherInformationColumns,
  },
  {
    style: {
      width: '7%',
    },
    label: '',
    subColumns: actionsColumns,
  },
];
