import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled('div')(
  ({ theme: { spacings, colors }, centerText }) => css`
    display: flex;
    justify-content: center;
    margin: ${spacings.tera} ${spacings.giga};

    ${centerText &&
    `
      height: 100%;
      align-items: center;
    `}

    > p {
      color: ${colors.n500};
    }
  `
);
