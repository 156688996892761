import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';

export const StyledButton = styled(Button)(
  ({ colors, theme: { spacings, colors: palette } }) => css`
    width: 100%;
    height: ${spacings.tera};
    padding: ${spacings.bit} 0;
    margin: ${spacings.bit} 0;

    background-color: ${palette[colors.primary]};
    color: ${palette[colors.text]};
    border-color: ${palette[colors.borderColor]};

    &:hover {
      border-color: ${palette[colors.borderColor]};
    }
  `
);

StyledButton.defaultProps = {
  colors: {
    text: 'bodyColor',
  },
};

StyledButton.propTypes = {
  colors: PropTypes.object,
};
