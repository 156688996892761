import React from 'react';
import { PATHS, PAGE_TITLES } from 'variables'; // eslint-disable-line
import { ReactComponent as AccountCard } from 'assets/account-card-module.svg';
import { APPS, SECTIONS } from './permissions';

export const MODULES = {
  ACCOUNT_AND_CARD: {
    title: 'Account and Card',
    image: <AccountCard />,
    sections: {
      ACTIVATION: {
        title: PAGE_TITLES.ACTIVATION.heading,
        link: PATHS.activation,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardActivation,
        },
      },
      ACCOUNT: {
        title: PAGE_TITLES.ACCOUNT.heading,
        link: PATHS.account,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardOverview,
        },
      },
      TRANSACTIONS: {
        title: PAGE_TITLES.TRANSACTIONS.heading,
        link: PATHS.transactions,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardTransactions,
        },
      },
      LIMITS: {
        title: PAGE_TITLES.LIMITS.heading,
        link: PATHS.limits,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardLimits,
        },
      },
      DIRECT_DEBITS: {
        title: PAGE_TITLES.DIRECT_DEBITS.heading,
        link: PATHS.direct_debits,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardDirectDebits,
        },
      },
      SCHEDULED_PAYMENTS: {
        title: PAGE_TITLES.SCHEDULED_PAYMENTS.heading,
        link: PATHS.scheduled_payments,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardScheduledPayments,
        },
      },
      ACTIVITY: {
        title: PAGE_TITLES.ACTIVITY_LOG.heading,
        link: PATHS.activity,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardActivityLog,
        },
      },
      DEVICES: {
        title: PAGE_TITLES.DEVICES.heading,
        link: PATHS.devices,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardScaDevices,
        },
      },
      CHALLENGES: {
        title: PAGE_TITLES.CHALLENGES.heading,
        link: PATHS.challenges,
        permissions: {
          app: APPS.merchant,
          section: SECTIONS[APPS.merchant].accountcardPsdChallenges,
        },
      },
    },
  },
};
