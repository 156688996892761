import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Button, Headline, spacing } from '@sumup/circuit-ui';
import { Alert, ArrowLeft } from '@sumup/icons';
import { trim } from 'lodash';
import { MANDATE_STATUS_ACTIVE, TYPE_LABEL } from '../constants';
import { CancelDirectDebitMandateButton } from './CancelDirectDebitMandateButton';
import { MandateDetailsCard } from './MandateDetailsCard';
import { UpcomingPaymentCard } from './UpcomingPaymentCard';
import { MandateTransactionHistory } from './MandateTransactionHistory';

const CardsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacings.bit} ${theme.spacings.mega};
  `
);

const Header = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacings.mega};
  `
);

const StyledCancelMandateButton = styled(CancelDirectDebitMandateButton)(
  ({ theme }) => css`
    padding: ${theme.spacings.kilo} ${theme.spacings.giga};
  `
);

const EXPONENT = 2;

const MandateDetails = ({
  mandate,
  upcomingPayment,
  transactionHistory,
  onHistorySort,
  onHistoryPageChange,
  onHistoryPageSizeChange,
  onCancelMandateConfirm,
  onAllClick,
}) => {
  const { id, frequency, status, beneficiary = {} } = mandate;
  const {
    name: accountName,
    account_number: accountNumber,
    sort_code: sortCode,
    iban,
  } = beneficiary;

  const showDivider = accountNumber && sortCode;
  const frequencyLabel = TYPE_LABEL[frequency];

  return (
    <>
      <StyledButton css={spacing({ bottom: 'mega' })} onClick={onAllClick}>
        <ArrowLeft size="16" />
        <Body
          noMargin
          size="one"
          variant="highlight"
          css={spacing({ left: 'bit' })}
        >
          All
        </Body>
      </StyledButton>
      <Header>
        <div>
          <Headline
            noMargin
            as="h1"
            size="two"
            css={spacing({ bottom: 'bit' })}
          >
            {frequencyLabel}
            {frequencyLabel ? ' d' : 'D'}irect debit to {trim(accountName)}
          </Headline>
          {accountNumber || sortCode ? (
            <Body noMargin size="one">
              {sortCode}
              {showDivider ? ' · ' : ''}
              {accountNumber}
            </Body>
          ) : (
            <Body noMargin size="one">
              {iban}
            </Body>
          )}
        </div>
        {status === MANDATE_STATUS_ACTIVE && (
          <StyledCancelMandateButton
            id={id}
            icon={<Alert />}
            onConfirm={onCancelMandateConfirm}
          />
        )}
      </Header>
      <CardsWrapper>
        <MandateDetailsCard mandate={mandate} fullWidth={!upcomingPayment} />
        {upcomingPayment ? (
          <UpcomingPaymentCard payment={upcomingPayment} exponent={EXPONENT} />
        ) : null}
      </CardsWrapper>
      <MandateTransactionHistory
        {...transactionHistory}
        onSort={onHistorySort}
        onPageChange={onHistoryPageChange}
        onPageSizeChange={onHistoryPageSizeChange}
      />
    </>
  );
};

MandateDetails.defaultProps = {
  upcomingPayment: null,
};

MandateDetails.propTypes = {
  mandate: PropTypes.object.isRequired,
  upcomingPayment: PropTypes.object,
  transactionHistory: PropTypes.object.isRequired,
  onHistorySort: PropTypes.func.isRequired,
  onHistoryPageChange: PropTypes.func.isRequired,
  onHistoryPageSizeChange: PropTypes.func.isRequired,
  onCancelMandateConfirm: PropTypes.func.isRequired,
  onAllClick: PropTypes.func.isRequired,
};

export default MandateDetails;
