import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { clickableCellStyles } from 'components/DataGrid/DataGridStyled';

export const StyledAmountCell = styled('div')(
  ({ theme: { spacings, colors }, clickable, centered }) => css`
    display: flex;
    flex-direction: row;

    & > p:first-of-type {
      margin-right: ${spacings.bit};
    }

    ${clickable && clickableCellStyles({ colors })}

    ${centered &&
    `
      display: flex;
      justify-content: center;
    `}
  `
);
