import {
  PATHS,
  APP_TITLE,
  APPS,
  SECTIONS as SECTIONS_BY_MODULE,
} from 'variables';

export const PAGE_TITLES = {
  MATCH_CLEARINGS: {
    title: `Match clearings | ${APP_TITLE}`,
    heading: 'Match clearings',
  },
  ADJUST_AUTHORIZATIONS: {
    title: `Adjust authoriations | ${APP_TITLE}`,
    heading: 'Adjust authorizations',
  },
  CANCEL_AUTHORIZATIONS: {
    title: `Cancel authorizations | ${APP_TITLE}`,
    heading: 'Cancel authorizations',
  },
};

export const SECTIONS = {
  [PATHS.match_clearings]: {
    key: PATHS.match_clearings,
    title: PAGE_TITLES.MATCH_CLEARINGS.heading,
    link: PATHS.match_clearings,
    permissions: {
      app: APPS.card_reconciliation,
      section: SECTIONS_BY_MODULE[APPS.card_reconciliation].clearings,
    },
  },
  [PATHS.adjust_authorizations]: {
    key: PATHS.adjust_authorizations,
    title: PAGE_TITLES.ADJUST_AUTHORIZATIONS.heading,
    link: PATHS.adjust_authorizations,
    permissions: {
      app: APPS.card_reconciliation,
      section: SECTIONS_BY_MODULE[APPS.card_reconciliation].authorizations,
    },
  },
  [PATHS.CANCEL_AUTHORIZATIONS]: {
    key: PATHS.cancel_authorizations,
    title: PAGE_TITLES.CANCEL_AUTHORIZATIONS.heading,
    link: PATHS.cancel_authorizations,
    permissions: {
      app: APPS.card_reconciliation,
      section: SECTIONS_BY_MODULE[APPS.card_reconciliation].clearings,
    },
  },
};
