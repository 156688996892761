import React from 'react';
import PropTypes from 'prop-types';
import { Button, useModal } from '@sumup/circuit-ui';
import RequestTransferModal from 'components/Account/components/BusinessAccount/components/RequestTransferModal';
import { FIELD_NAMES as REQUEST_TRANSFER_FIELD_NAMES } from 'components/Account/components/BusinessAccount/components/RequestTransferModal/constants';
import {
  ADDITIONAL_INFO,
  AMOUNT,
  BENEFICIARY,
  COMMENT,
  RECIPIENT_ACCOUNT_NUMBER,
  RECIPIENT_IBAN,
  RECIPIENT_NAME,
  RECIPIENT_SORT_CODE,
  REFERENCE,
  SENDER_ACCOUNT_ID,
  SENDER_CLIENT_CODE,
  SHOW_BENEFICIARY,
  SOURCE,
  TRANSFER_ID,
} from 'components/OpsTransfers/fieldsConfig';
import { CURRENCY_CODES } from 'variables';
import { initiateOpsTransfer } from 'api';

export const InitiateButton = ({ transfer, updateOpsTransfers }) => {
  const amount = transfer[AMOUNT.name];
  const beneficiary = transfer[BENEFICIARY.name];
  const accountHolder = beneficiary[RECIPIENT_NAME.name];
  const accountNumber = beneficiary[RECIPIENT_ACCOUNT_NUMBER.name];
  const sortCode = beneficiary[RECIPIENT_SORT_CODE.name];
  const iban = beneficiary[RECIPIENT_IBAN.name];
  const reference = transfer[REFERENCE.name];
  const additionalInfo = transfer[ADDITIONAL_INFO.name];
  const comment = transfer[COMMENT.name];
  const showBeneficiary = transfer[SHOW_BENEFICIARY.name];
  const source = transfer[SOURCE.name];
  const transferId = transfer[TRANSFER_ID.name];
  const accountId = source[SENDER_ACCOUNT_ID.name];
  const ukTransfer = !!(accountNumber && sortCode);
  const currency = ukTransfer ? CURRENCY_CODES.GBP : CURRENCY_CODES.EUR;
  const clientId = (transfer[SOURCE.name] || {})[SENDER_CLIENT_CODE.name];

  const initialValues = {
    [REQUEST_TRANSFER_FIELD_NAMES.name]: accountHolder,
    [REQUEST_TRANSFER_FIELD_NAMES.amount]: amount ? amount.toString() : null,
    ...(ukTransfer
      ? {
          [REQUEST_TRANSFER_FIELD_NAMES.sort_code]: sortCode,
          [REQUEST_TRANSFER_FIELD_NAMES.account_number]: accountNumber,
        }
      : {
          [REQUEST_TRANSFER_FIELD_NAMES.iban]: iban,
        }),
    [REQUEST_TRANSFER_FIELD_NAMES.reference]: reference,
    [REQUEST_TRANSFER_FIELD_NAMES.additional_info]: additionalInfo,
    [REQUEST_TRANSFER_FIELD_NAMES.comment]: comment,
    [REQUEST_TRANSFER_FIELD_NAMES.show_beneficiary]: showBeneficiary,
  };

  const { setModal } = useModal();

  const handleClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <RequestTransferModal
          heading="Initiate transfer request"
          initialValues={initialValues}
          ukTransfer={ukTransfer}
          accountId={accountId}
          currency={currency}
          clientId={clientId}
          transferId={transferId}
          submitSuccessNotificationHeadline="Transfer request initiated"
          submitErrorNotificationHeadline="Couldn't initiate transfer request"
          submitErrorNotificationBody="Please try again later."
          submitButtonLabel="Initiate"
          successHandler={updateOpsTransfers}
          onSubmit={initiateOpsTransfer}
          onClose={onClose}
        />
      ),
    });
  };

  return (
    <Button noMargin variant="primary" size="kilo" onClick={handleClick}>
      Initiate
    </Button>
  );
};

InitiateButton.propTypes = {
  transfer: PropTypes.object.isRequired,
  updateOpsTransfers: PropTypes.func.isRequired,
};
