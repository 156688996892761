import React from 'react';
import { useParams } from 'react-router-dom';
import { getUserActivity } from 'services/activityLogData';
import Loading from 'components/Loading';
import { PAGE_TITLES } from 'variables';
import Error from 'components/Error';
import { useApiCallOnLoad } from 'hooks';
import { activityLogReducer } from './activityLogReducer';
import { filterActivityLog } from './activityLogActions';
import { ActivityLog } from './components/ActivityLog';
import { ACTIVITY_LOG_NON_NUMERIC_FIELDS_MAP } from './columns';

const initialState = {
  loading: true,
  activity: [],
  filteredActivity: [],
  filterApplied: false,
  personalProfile: {},
};

const getUserActivityWrapped = (clientId, httpClient) =>
  getUserActivity({ clientId, httpClient });

const ActivityLogData = () => {
  const { clientId } = useParams();

  const [state, dispatch] = useApiCallOnLoad(
    getUserActivityWrapped,
    clientId,
    activityLogReducer,
    initialState
  );

  const { loading, error, filteredActivity, filterApplied } = state;

  const handleSearch = (value) => {
    dispatch(filterActivityLog(value));
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      {loading ? (
        <Loading />
      ) : (
        <ActivityLog
          heading={PAGE_TITLES.ACTIVITY_LOG.heading}
          searchPlaceholder="Search by Actor, Source, Event, or Details"
          activityLog={filteredActivity}
          nonNumericFields={ACTIVITY_LOG_NON_NUMERIC_FIELDS_MAP}
          noResultsText={
            filterApplied
              ? 'No events are matching the search query'
              : 'No events have been logged yet'
          }
          onSearch={handleSearch}
        />
      )}
    </>
  );
};

export default ActivityLogData;
