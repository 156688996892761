import keyMirror from 'key-mirror-nested';

export const COUNTRIES = keyMirror({
  AR: null,
  AT: null,
  AU: null,
  BE: null,
  BG: null,
  BR: null,
  CA: null,
  CH: null,
  CL: null,
  CO: null,
  CY: null,
  CZ: null,
  DE: null,
  DK: null,
  EE: null,
  ES: null,
  FI: null,
  FR: null,
  GB: null,
  GR: null,
  HR: null,
  HU: null,
  IE: null,
  IT: null,
  LT: null,
  LU: null,
  LV: null,
  MT: null,
  NL: null,
  NO: null,
  PE: null,
  PL: null,
  PT: null,
  RO: null,
  SE: null,
  SI: null,
  SK: null,
  SL: null,
  US: null,
});

export const COUNTRY_NAMES = {
  [COUNTRIES.AR]: 'Argentina',
  [COUNTRIES.AT]: 'Austria',
  [COUNTRIES.AU]: 'Australia',
  [COUNTRIES.BE]: 'Belgium',
  [COUNTRIES.BG]: 'Bulgaria',
  [COUNTRIES.BR]: 'Brazil',
  [COUNTRIES.CA]: 'Canada',
  [COUNTRIES.CH]: 'Switzerland',
  [COUNTRIES.CL]: 'Chile',
  [COUNTRIES.CO]: 'Colombia',
  [COUNTRIES.CY]: 'Cyprus',
  [COUNTRIES.CZ]: 'Czech Republic',
  [COUNTRIES.DE]: 'Germany',
  [COUNTRIES.DK]: 'Denmark',
  [COUNTRIES.EE]: 'Estonia',
  [COUNTRIES.ES]: 'Spain',
  [COUNTRIES.FI]: 'Finland',
  [COUNTRIES.FR]: 'France',
  [COUNTRIES.GB]: 'United Kingdom',
  [COUNTRIES.GR]: 'Greece',
  [COUNTRIES.HR]: 'Croatia',
  [COUNTRIES.HU]: 'Hungary',
  [COUNTRIES.IE]: 'Ireland',
  [COUNTRIES.IT]: 'Italy',
  [COUNTRIES.LT]: 'Lithuania',
  [COUNTRIES.LU]: 'Luxembourg',
  [COUNTRIES.LV]: 'Latvia',
  [COUNTRIES.MT]: 'Malta',
  [COUNTRIES.NL]: 'Netherlands',
  [COUNTRIES.NO]: 'Norway',
  [COUNTRIES.PE]: 'Peru',
  [COUNTRIES.PL]: 'Poland',
  [COUNTRIES.PT]: 'Portugal',
  [COUNTRIES.RO]: 'Romania',
  [COUNTRIES.SE]: 'Sweden',
  [COUNTRIES.SI]: 'Slovenia',
  [COUNTRIES.SK]: 'Slovakia',
  [COUNTRIES.US]: 'United States',
};
