import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledPopoverOptionTitle = styled('p')(
  ({ theme, color }) => css`
    display: flex;
    align-items: center;
    ${color && `color: ${theme.colors[color]}`};
  `
);
