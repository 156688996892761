import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { includes, split } from 'lodash/fp';
import { Body, SearchInput, useModal } from '@sumup/circuit-ui';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { DATA_SELECTORS, PATHS } from 'variables';
import Search from 'components/Search';
import { hasMerchantAccess } from 'services/permissions';
import AuthContext from 'context/auth';
import TextSeparator from 'components/TextSeparator';
import {
  TopBarBlock,
  TopBarLeft,
  TopBarRight,
  LogOut,
  SearchInputWrapper,
  Separator,
  TrimmedText,
} from './TopBarStyled';

const TopBar = ({ onLogout, user }) => {
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const [searchVisible, setSearchVisible] = useState(true);
  const location = useLocation();
  const history = useHistory();

  const { setModal, removeModal } = useModal();

  const hasSearch =
    hasMerchantAccess(permissions) &&
    includes(split('/', location.pathname)[1], PATHS);

  const handleSearchResultClick = () => {
    removeModal();
    setSearchVisible(true);
  };

  const handleSearchModalClose = () => {
    setSearchVisible(true);
  };

  const openSearchModal = () => {
    setModal({
      css: css`
        padding: 20px !important;

        > button {
          visibility: hidden;
        }
      `,
      children: () => (
        <div>
          <Search focused onResultClick={handleSearchResultClick} />
        </div>
      ),
      onClose: handleSearchModalClose,
      closeButtonLabel: 'Close',
    });
  };

  const handleSearchInputClick = () => {
    openSearchModal();
    setSearchVisible(false);
  };

  return (
    <TopBarBlock>
      <TopBarLeft onClick={() => history.push('/')}>
        <Logo />
        <Separator noMargin size="two">
          /
        </Separator>
        <Body variant="highlight" noMargin size="two">
          Bank Backoffice
        </Body>
      </TopBarLeft>
      {hasSearch && searchVisible && (
        <SearchInputWrapper>
          <SearchInput
            noMargin
            placeholder="Merchant/Consumer ID, Email or Phone; Transaction ID"
            label="Merchant/Consumer ID, Email or Phone; Transaction ID"
            hideLabel
            clearLabel="Clear"
            onClick={handleSearchInputClick}
          />
        </SearchInputWrapper>
      )}
      <TopBarRight>
        <TrimmedText noMargin size="two">
          Welcome, {user}!
        </TrimmedText>
        <TextSeparator noMargin size="two">
          ·
        </TextSeparator>
        <LogOut
          variant="highlight"
          size="two"
          onClick={onLogout}
          data-testid={DATA_SELECTORS.LOG_OUT_BUTTON}
          noMargin
        >
          Sign Out
        </LogOut>
      </TopBarRight>
    </TopBarBlock>
  );
};

TopBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
};

export default TopBar;
