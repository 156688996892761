import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AuthContext from 'context/auth';

const Public = ({ component: Component, path, title, render, restricted }) => {
  const {
    state: { isAuthenticated },
  } = useContext(AuthContext);

  return (
    <Route
      render={() =>
        isAuthenticated && restricted ? (
          <Redirect to="/" />
        ) : (
          <Route path={path}>
            <Page component={Component} title={title} render={render} />
          </Route>
        )
      }
    />
  );
};

Public.defaultProps = {
  title: '',
  render: '',
};

Public.propTypes = {
  component: PropTypes.func.isRequired,
  restricted: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  render: PropTypes.string,
};

export default Public;
