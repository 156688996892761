import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Popover, spacing } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import { map, values } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyledCard } from 'components/Transactions/TransactionsStyled';
import {
  FILTERS_MIN_HEIGHT,
  TRANSACTION_ORIGIN_ICON_SIZE_LARGE,
  TRANSACTION_ORIGIN_ICON_SIZE_SMALL,
} from 'variables';
import { TRANSACTION_ORIGIN_FILTER_OPTIONS } from '../../constants';

const PopoverComponent = styled(StyledCard)(
  ({ theme: { spacings, colors } }) => css`
    display: flex;
    align-items: center;
    min-height: ${FILTERS_MIN_HEIGHT}px;

    svg {
      margin: auto ${spacings.byte};
      color: ${colors.n500};
    }
  `
);

const PopoverOption = styled('div')(
  ({ disabled }) => css`
    display: flex;
    flex-direction: row;
    cursor: pointer;

    ${disabled &&
    `
      opacity: 0.5;
      cursor: default;
    `}
  `
);

const StyledPopover = styled(Popover)`
  button {
    &:focus {
      box-shadow: none;
    }
  }
`;

const OriginIcon = styled('p')(
  ({ theme, color, size = TRANSACTION_ORIGIN_ICON_SIZE_LARGE }) => css`
    width: ${size}px;
    height: ${size}px;
    padding: 0;
    background: ${color};
    border-radius: ${theme.borderRadius.circle};
  `
);

const SelectedOrigin = styled('p')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const TransactionOriginDropdownFilter = ({
  origin: selectedOrigin,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOrigin = ({ disabled, origin }) => {
    if (!disabled) {
      setIsOpen(false);
      onChange(origin);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const popoverActions = map(
    values(TRANSACTION_ORIGIN_FILTER_OPTIONS),
    ({ value, label, color }) => ({
      children: (
        <PopoverOption key={value} disabled={value === selectedOrigin}>
          <OriginIcon color={color} size={TRANSACTION_ORIGIN_ICON_SIZE_LARGE} />
          <Body noMargin size="two" css={spacing({ left: 'mega' })}>
            {label}
          </Body>
        </PopoverOption>
      ),
      onClick: () =>
        handleSelectOrigin({
          disabled: value === selectedOrigin,
          origin: value,
        }),
    })
  );

  const popoverComponent = () => {
    const { color, label } =
      TRANSACTION_ORIGIN_FILTER_OPTIONS[selectedOrigin] || {};

    return (
      <PopoverComponent
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Body size="two" noMargin>
          Transactions:
        </Body>
        <SelectedOrigin>
          <OriginIcon color={color} size={TRANSACTION_ORIGIN_ICON_SIZE_SMALL} />
          <Body
            size="two"
            variant="highlight"
            noMargin
            css={spacing({ left: 'byte' })}
          >
            {label}
          </Body>
        </SelectedOrigin>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </PopoverComponent>
    );
  };

  return (
    <StyledPopover
      isOpen={isOpen}
      component={popoverComponent}
      actions={popoverActions}
      onToggle={handleToggle}
    />
  );
};

TransactionOriginDropdownFilter.propTypes = {
  origin: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TransactionOriginDropdownFilter;
