import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ContentWrapper = styled('div')`
  position: absolute;
  top: 70px;
`;

const Popover = ({ isOpen, pivot, content, style, onOutsideClick }) => {
  const contentRef = useRef();

  const handleMouseDown = useCallback(
    (e) => {
      if (
        (contentRef || {}).current &&
        !contentRef.current.contains(e.target)
      ) {
        onOutsideClick(e);
      }
    },
    [onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('scroll', handleMouseDown);
  }, [handleMouseDown]);

  return (
    <div>
      {pivot}
      {isOpen && (
        <ContentWrapper ref={contentRef} style={style}>
          {content}
        </ContentWrapper>
      )}
    </div>
  );
};

Popover.defaultProps = {
  style: {},
};

Popover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  pivot: PropTypes.node.isRequired,
  content: PropTypes.element.isRequired,
  style: PropTypes.object,
  onOutsideClick: PropTypes.func.isRequired,
};

export default Popover;
