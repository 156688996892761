import React, { useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Headline } from '@sumup/circuit-ui';
import { filter } from 'lodash';
import { PAGE_TITLES } from 'variables';
import Loading from 'components/Loading';
import Error from 'components/Error';
import { useHttpClient } from 'hooks';
import { getAccountDetails, getUserActivity, toggleDirectDebit } from 'api';
import { loadError, loadSuccess, setLoading } from 'actions';
import ClientDataContext from 'context/clientData';
import { accountReducer } from 'components/Account/accountReducer';
import DirectDebitMandates from 'components/DirectDebitMandates';
import { directDebitsReducer } from './directDebitsReducer';
import DirectDebitsToggle from './components/DirectDebitsToggle';
import {
  setDirectDebitsEnabledError,
  setDirectDebitsEnabledLoading,
  setDirectDebitsEnabledSuccess,
  setDirectDebitsEvents,
} from './directDebitsActions';
import { ACTIVITY_LOG_DD_EVENTS } from './constants';

const initialState = {
  loading: true,
  error: null,
  directDebitsEnabledLoading: false,
  directDebitsEvents: [],
};

const DirectDebits = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();

  const [state, dispatch] = useReducer(directDebitsReducer, initialState);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const [accountState = {}] = useReducer(accountReducer, {
    accountData: clientData,
  });

  const { accountData = {} } = accountState;
  const { country } = accountData;
  const ukTransfer = country === 'GB';

  useEffect(() => {
    dispatch(setLoading(true));

    getUserActivity({ clientId, httpClient })
      .then(({ data: { client_activity_log: events = [] } }) => {
        const directDebitsEvents = filter(
          events,
          ({ type }) => ACTIVITY_LOG_DD_EVENTS[type]
        );

        dispatch(setDirectDebitsEvents({ directDebitsEvents }));

        return getAccountDetails(httpClient, { merchantCode: clientId });
      })
      .catch(() => getAccountDetails(httpClient, { merchantCode: clientId }))
      .then((accountDetails) => dispatch(loadSuccess(accountDetails)))
      .catch((error) => dispatch(loadError(error)));
  }, [clientId, httpClient]);

  const {
    loading,
    error,
    directDebitsEnabled,
    directDebitsEnabledLoading,
    hasBusinessAccount,
    accountId,
    directDebitsEvents,
  } = state;

  const handleToggleDirectDebits = ({ comment }) => {
    dispatch(setDirectDebitsEnabledLoading(true));

    return toggleDirectDebit({
      httpClient,
      accountId,
      enableDD: !directDebitsEnabled,
      comment,
    })
      .then(({ data }) => {
        dispatch(setDirectDebitsEnabledSuccess(data));

        return getUserActivity({ clientId, httpClient });
      })
      .then(({ data: { client_activity_log: events = [] } }) => {
        dispatch(
          setDirectDebitsEvents({
            directDebitsEvents: filter(
              events,
              ({ type }) => ACTIVITY_LOG_DD_EVENTS[type]
            ),
          })
        );
      })
      .catch(() => {
        dispatch(setDirectDebitsEnabledError());
      });
  };

  if (loading) {
    return <Loading />;
  }

  return ukTransfer ? (
    <DirectDebitMandates />
  ) : (
    <>
      {error && <Error>{error}</Error>}
      <Headline noMargin as="h2" size="two">
        {PAGE_TITLES.DIRECT_DEBITS.heading}
      </Headline>
      <DirectDebitsToggle
        clientId={clientId}
        hasBusinessAccount={hasBusinessAccount}
        directDebitsEnabled={directDebitsEnabled}
        directDebitsEnabledLoading={directDebitsEnabledLoading}
        directDebitsEvents={directDebitsEvents}
        toggleDirectDebits={handleToggleDirectDebits}
      />
    </>
  );
};

export default DirectDebits;
