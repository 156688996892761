import React from 'react';
import { Alert, Confirm, PaidOut } from '@sumup/icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const DIRECT_DEBIT_MANDATES_FIRST_PAGE = 1;

export const MANDATE_STATUS_ACTIVE = 'ACTIVE';
export const MANDATE_STATUS_CANCELLED = 'CANCELLED';
export const MANDATE_STATUS_COMPLETED = 'COMPLETED';
export const MANDATE_STATUS_REJECTED = 'REJECTED';

export const DD_STATUS_PENDING = 'PENDING';
export const DD_STATUS_PROCESSING = 'PROCESSING';
export const DD_STATUS_COMPLETED = 'COMPLETED';
export const DD_STATUS_FAILED = 'FAILED';
export const DD_STATUS_CANCELLED = 'CANCELLED';

export const MANDATE_HISTORY_STATUSES = [
  DD_STATUS_PROCESSING,
  DD_STATUS_COMPLETED,
  DD_STATUS_FAILED,
  DD_STATUS_CANCELLED,
];

export const STATUS_ACTIVE = {
  value: MANDATE_STATUS_ACTIVE,
  label: 'Active',
};

const STATUS_ARCHIVED = {
  value: 'ARCHIVED',
  label: 'Archived',
};

export const STATUS_FILTER_OPTIONS = [
  {
    value: STATUS_ACTIVE.value,
    label: STATUS_ACTIVE.label,
  },
  {
    value: STATUS_ARCHIVED.value,
    label: STATUS_ARCHIVED.label,
  },
];

export const TYPE_ALL = {
  value: 'ALL',
  label: 'All',
};

export const STATUS_FILTER_MAP = {
  [STATUS_ACTIVE.value]: true,
  [STATUS_ARCHIVED.value]: false,
};

export const TYPE_LABEL = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  QUARTERLY: 'Quarterly',
  FORTNIGHTLY: 'Fortnightly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
};

const IconSuccessful = styled(Confirm)(
  ({ theme }) => css`
    color: ${theme.colors.confirm};
  `
);

const IconUnsuccessful = styled(Alert)(
  ({ theme }) => css`
    color: ${theme.colors.alert};
  `
);

export const STATUS_MAP = {
  [MANDATE_STATUS_ACTIVE]: {
    label: 'Active',
    icon: <IconSuccessful size="16" />,
  },
  [MANDATE_STATUS_COMPLETED]: {
    label: 'Completed',
    icon: <PaidOut size="16" />,
  },
  [MANDATE_STATUS_CANCELLED]: {
    label: 'Cancelled',
    icon: <IconUnsuccessful size="16" />,
  },
  [MANDATE_STATUS_REJECTED]: {
    label: 'Rejected',
    icon: <IconUnsuccessful size="16" />,
  },
};

export const DD_STATUS_BADGE_MAP = {
  [DD_STATUS_PROCESSING]: {
    name: DD_STATUS_PROCESSING,
    label: 'Processing',
    background: '#1A1A1A',
  },
  [DD_STATUS_COMPLETED]: {
    name: DD_STATUS_COMPLETED,
    label: 'Successful',
    background: '#138849',
  },
  [DD_STATUS_FAILED]: {
    name: DD_STATUS_FAILED,
    label: 'Unsuccessful',
    background: '#D23F47',
  },
  [DD_STATUS_CANCELLED]: {
    name: DD_STATUS_CANCELLED,
    label: 'Unsuccessful',
    background: '#D23F47',
  },
};

export const MANDATE_HISTORY_PAGE_SIZE = 10;
