import {
  getPermissionsFromPaths,
  pluralize,
} from 'components/UserAdministration/userAdministrationService';
import { find, map } from 'lodash';
import { actionTypes, ERROR_MESSAGE } from 'variables';
import { initialState } from './state';

export const userAdministrationUserGroupsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: { data = {} },
      } = action;

      const groups = map(data.groups, ({ code, count }) => ({
        text: code,
        subText: [`${count} ${pluralize('user', count)} assigned`],
        count,
      }));

      const { selectedGroup } = state;

      return {
        ...state,
        groups,
        groupsLoading: false,
        selectedGroup: groups.length
          ? {
              ...groups[0],
              newName: groups[0].text,
            }
          : selectedGroup,
        error: null,
      };
    }
    case actionTypes.SET_GROUPS_LOADING: {
      const {
        payload: { groupsLoading },
      } = action;

      return {
        ...state,
        groupsLoading,
        error: null,
      };
    }
    case actionTypes.SET_GROUP_LOADING: {
      const {
        payload: { groupLoading },
      } = action;

      return {
        ...state,
        groupLoading,
        error: null,
      };
    }
    case actionTypes.SELECT_GROUP: {
      const {
        payload: { name },
      } = action;

      const { groups } = state;
      const newSelectedGroup = find(groups, ({ text }) => text === name) || {};

      return {
        ...state,
        selectedGroup: {
          ...newSelectedGroup,
          newName: newSelectedGroup.text,
        },
        error: null,
      };
    }
    case actionTypes.SET_NEW_GROUP_NAME: {
      const {
        payload: { name },
      } = action;

      const { newGroup } = state;

      return {
        ...state,
        newGroup: {
          ...newGroup,
          name,
        },
      };
    }
    case actionTypes.SET_NEW_GROUP_APP: {
      const {
        payload: { app },
      } = action;

      const { newGroup } = state;
      const {
        newGroup: { permissions },
      } = initialState;

      return {
        ...state,
        newGroup: {
          ...newGroup,
          permissions,
          app,
        },
      };
    }
    case actionTypes.LOAD_APPS_SUCCESS: {
      const {
        payload: {
          data: { apps },
        },
      } = action;

      return {
        ...state,
        apps,
        error: null,
      };
    }
    case actionTypes.CLEAR_NEW_GROUP_DATA: {
      const { newGroup } = initialState;

      return {
        ...state,
        newGroup,
        error: null,
      };
    }
    case actionTypes.SET_NEW_GROUP_MODULES: {
      const {
        payload: { modules },
      } = action;

      const { newGroup } = state;
      const { permissions } = newGroup;

      return {
        ...state,
        newGroup: {
          ...newGroup,
          permissions: {
            ...permissions,
            modules,
          },
        },
      };
    }
    case actionTypes.SET_NEW_GROUP_SECTIONS: {
      const {
        payload: { sections },
      } = action;

      const { newGroup } = state;
      const { permissions } = newGroup;

      return {
        ...state,
        newGroup: {
          ...newGroup,
          permissions: {
            ...permissions,
            sections,
          },
        },
      };
    }
    case actionTypes.SET_NEW_GROUP_ACTIONS: {
      const {
        payload: { actions },
      } = action;

      const { newGroup } = state;
      const { permissions } = newGroup;

      return {
        ...state,
        newGroup: {
          ...newGroup,
          permissions: {
            ...permissions,
            actions,
          },
        },
      };
    }
    case actionTypes.SET_SELECTED_GROUP_MODULES: {
      const {
        payload: { modules },
      } = action;

      const { selectedGroup } = state;
      const { permissions } = selectedGroup;

      return {
        ...state,
        selectedGroup: {
          ...selectedGroup,
          permissions: {
            ...permissions,
            modules,
          },
        },
      };
    }
    case actionTypes.SET_SELECTED_GROUP_SECTIONS: {
      const {
        payload: { sections },
      } = action;

      const { selectedGroup } = state;
      const { permissions } = selectedGroup;

      return {
        ...state,
        selectedGroup: {
          ...selectedGroup,
          permissions: {
            ...permissions,
            sections,
          },
        },
      };
    }
    case actionTypes.SET_SELECTED_GROUP_ACTIONS: {
      const {
        payload: { actions },
      } = action;

      const { selectedGroup } = state;
      const { permissions } = selectedGroup;

      return {
        ...state,
        selectedGroup: {
          ...selectedGroup,
          permissions: {
            ...permissions,
            actions,
          },
        },
      };
    }
    case actionTypes.SET_SELECTED_GROUP_NEW_NAME: {
      const {
        payload: { name },
      } = action;

      const { selectedGroup } = state;

      return {
        ...state,
        selectedGroup: {
          ...selectedGroup,
          newName: name,
        },
      };
    }
    case actionTypes.LOAD_GROUP_SUCCESS: {
      const {
        payload: { data = {} },
      } = action;

      const { selectedGroup } = state;
      const { app_code: app, name: text, permissions } = data;
      const selectedGroupPermissions = getPermissionsFromPaths({
        paths: permissions,
      });

      return {
        ...state,
        groupLoading: false,
        selectedGroup: {
          ...selectedGroup,
          app,
          text,
          permissions: selectedGroupPermissions,
        },
        error: null,
      };
    }
    case actionTypes.LOAD_GROUP_ERROR: {
      return {
        ...state,
        groupLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.LOAD_ERROR:
    case actionTypes.LOAD_APPS_ERROR: {
      return {
        ...state,
        groupsLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.CREATE_GROUP_ERROR:
    case actionTypes.UPDATE_GROUP_ERROR:
    case actionTypes.DELETE_GROUP_ERROR: {
      return {
        ...state,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_ERROR: {
      const {
        payload: { error },
      } = action;

      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
};
