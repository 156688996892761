import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, Pagination } from '@sumup/circuit-ui';

export const TransactionsCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

export const StyledCard = styled(Card)(
  ({ theme: { spacings, colors, borderWidth, borderRadius }, disabled }) => css`
    display: flex;
    flex-direction: row;
    min-height: 20px;
    max-height: ${spacings.tera};
    padding: 0;
    border-radius: ${borderRadius.byte};
    border: ${borderWidth.kilo} solid ${colors.n200};
    margin: 0 ${spacings.bit};
    cursor: pointer;

    > p {
      margin: 6px 0;

      :first-of-type {
        color: ${colors.n700};
        margin: auto ${spacings.byte};
      }
    }

    div {
      display: flex;
      align-items: center;
      padding: 6px ${spacings.byte};
      margin-bottom: 0 !important;
      border-left: ${borderWidth.kilo} solid ${colors.n200};
    }

    ${disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
  `
);

export const Filters = styled('div')(
  ({ theme, collapsed }) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${theme.spacings.kilo};
    justify-content: ${collapsed ? 'flex-end' : 'space-between'};

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `
);

export const StyledPagination = styled(Pagination)`
  padding-bottom: 0;
`;
