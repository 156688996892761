import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EDIT_DELETE_BUTTONS_MAX_HEIGHT } from 'components/UserAdministration/constants';
import { Input } from '@sumup/circuit-ui';
import { TOP_BAR_HEIGHT } from 'variables';

export const Section = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    // Navigation height: around 110px
    // Page padding: 2 * 40px
    height: calc(100vh - ${110 + 40 * 2 + TOP_BAR_HEIGHT}px);
    width: 100%;

    background: ${theme.colors.white};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.borderRadius.bit};
  `
);

export const Header = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 80px;
    padding: ${theme.spacings.mega} 20px;
    border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
  `
);

export const Actions = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-left: auto;

    > button {
      display: flex;
      align-items: center;
      max-height: ${EDIT_DELETE_BUTTONS_MAX_HEIGHT}px;

      :first-of-type {
        margin-right: ${theme.spacings.byte};
      }
    }
  `
);

export const SubHeading = styled('div')`
  display: flex;
  flex-direction: row;

  p {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CircleButton = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${EDIT_DELETE_BUTTONS_MAX_HEIGHT}px;
    min-width: ${EDIT_DELETE_BUTTONS_MAX_HEIGHT}px;
    border: ${theme.borderWidth.kilo} solid ${theme.colors.n500};
    border-radius: ${theme.borderRadius.circle};
    cursor: pointer;

    > svg > path {
      fill: ${theme.colors.n700};
    }
  `
);

export const PopoverOption = styled('div')(
  ({ theme, color }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors[color]};
    cursor: pointer;

    > svg {
      margin-right: ${theme.spacings.kilo};
    }
  `
);

export const StyledInput = styled(Input)(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.typography.headline.two.fontSize};
    line-height: ${theme.typography.headline.two.lineHeight};
  `
);
