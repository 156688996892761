import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

const CARD_MAX_HEIGHT = 320;
const CARD_HEADING_HEIGHT = 46;

export const StyledCard = styled('div')(
  ({ theme, inner, popover }) => css`
    background: ${theme.colors.n100};
    display: ${inner ? 'none' : 'flex'};
    flex-direction: column;
    flex: 1;
    border-radius: ${theme.borderRadius.bit};

    ${popover
      ? `
      width: 400px;
      max-height: ${CARD_MAX_HEIGHT}px;
      margin-top: -6px;
      margin-left: ${theme.spacings.bit};
    `
      : `
      margin: 0 ${theme.spacings.byte};
    `}
  `
);

export const StyledCardHeading = styled('div')(
  ({ theme }) => css`
    border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    padding: ${theme.spacings.kilo};
    width: 100%;
    height: ${CARD_HEADING_HEIGHT}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

export const StyledText = styled(Body)`
  ${({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${colors.p500};
  `}
`;

export const StyledCardContent = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacings.kilo};
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: ${CARD_MAX_HEIGHT - CARD_HEADING_HEIGHT}px;
    overflow: auto;
  `
);

export const StyledCardRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin-bottom: ${theme.spacings.bit};

    p,
    strong {
      display: flex;
      flex: 1;
      word-break: break-word;

      &:nth-of-type(1) {
        flex-basis: 35%;
        margin-right: ${theme.spacings.byte};
      }
      &:nth-of-type(2) {
        flex-basis: 65%;
      }
    }
  `
);
