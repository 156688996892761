import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Card = styled('div')(
  ({ theme, fullWidth }) => css`
    display: flex;
    flex-direction: column;
    width: ${fullWidth ? '100%' : '49.5%'};
    border: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    border-radius: ${theme.borderRadius.byte};

    > div {
      padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    }
  `
);

export const CardContent = styled('div')(
  ({ theme, flexDirection }) => css`
    display: flex;
    flex-direction: ${flexDirection};
    border-top: ${theme.borderWidth.kilo} solid ${theme.colors.n200};

    ${flexDirection === 'column' &&
    `
      height: 100%;
      align-items: center;
      justify-content: center;
    `}
  `
);
