import React from 'react';
import PropTypes from 'prop-types';
import { Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import DataGrid from '../../DataGrid';
import { columns } from '../columns';
import Search from './Search';

export const HeadingWrapper = styled('div')`
  display: flex;
  align-items: center;

  > label {
    margin-left: auto;

    > div > svg {
      display: flex;
      align-items: center;
      max-height: 40px;
    }

    button {
      display: flex;
      align-items: center;
      max-height: 40px;
    }
  }
`;

export const ActivityLog = ({
  heading,
  searchPlaceholder,
  activityLog,
  sortable,
  nonNumericFields,
  noResultsText,
  onSearch,
}) => (
  <>
    <HeadingWrapper>
      <Headline noMargin as="h2" size="two">
        {heading}
      </Headline>
      <Search placeholder={searchPlaceholder} onSearch={onSearch} />
    </HeadingWrapper>
    <DataGrid
      columns={columns}
      dataSource={activityLog}
      sortable={sortable}
      nonNumericFields={nonNumericFields}
      noResultsText={noResultsText}
    />
  </>
);

ActivityLog.defaultProps = {
  heading: '',
  searchPlaceholder: '',
  activityLog: [],
  sortable: false,
  nonNumericFields: {},
  noResultsText: null,
};

ActivityLog.propTypes = {
  heading: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  activityLog: PropTypes.array,
  sortable: PropTypes.bool,
  nonNumericFields: PropTypes.object,
  noResultsText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};
