import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_FIELD_TYPES, FIELD_TYPES } from 'variables';
import DateCell from 'components/DataGrid/components/DateCell';
import StatusBadgeCell from 'components/DataGrid/components/StatusBadgeCell';
import CompositeAmountCell from 'components/DataGrid/components/CompositeAmountCell';
import CopyableCell from 'components/CopyableCell';
import StatusCell from '../components/StatusCell';
import { StyledEmptyCell } from './cellStyled';

export const CellFactory = ({
  renderAs,
  value,
  rowData,
  dispatch,
  selected,
  matched,
  ...props
}) => {
  if (value == null) {
    return <StyledEmptyCell>—</StyledEmptyCell>;
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({ value, record: rowData });
  }

  switch (render) {
    case FIELD_TYPES.DATE_TIME:
      return <DateCell {...props} value={value} centered />;
    case CUSTOM_FIELD_TYPES.STATUS_BADGE:
      return <StatusBadgeCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT:
      return <CompositeAmountCell {...props} value={value} centered />;
    case CUSTOM_FIELD_TYPES.STATUS:
      return <StatusCell selected={selected} matched={matched} />;
    default:
      return <CopyableCell {...props} value={value} centered />;
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  rowData: {},
  dispatch: () => {},
  selected: false,
  matched: false,
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  rowData: PropTypes.object,
  dispatch: PropTypes.func,
  selected: PropTypes.bool,
  matched: PropTypes.bool,
};
