import { APPS, PAGE_TITLES, PATHS, SECTIONS } from 'variables';

export const NBA_BUNDLING_SECTIONS = {
  [PATHS.batch]: {
    key: PATHS.batch,
    title: PAGE_TITLES.NBA_BUNDLING_BATCH_ENROLMENT.title,
    link: PATHS.batch,
    permissions: {
      app: APPS.mass_actions,
      section: SECTIONS[APPS.mass_actions].actions,
    },
  },
};
