import React, { useCallback, useEffect, useState } from 'react';
import { Headline, Spinner } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import DataGrid from 'components/DataGrid';
import PropTypes from 'prop-types';
import { ERROR_MESSAGE } from 'variables';
import Error from 'components/Error';
import { getOpsTransferHistory } from 'api';
import { useHttpClient } from 'hooks';

const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 800px;
`;

const HistoryModal = ({ id, clientId, paymentId, columns }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);

  const httpClient = useHttpClient();

  const loadWalletTokens = useCallback(
    () =>
      getOpsTransferHistory(httpClient, { id })
        .then(({ data }) => {
          setHistory(data);
          setLoading(false);
        })
        .catch(() => {
          setError(ERROR_MESSAGE);
          setLoading(false);
        }),
    [httpClient, id]
  );

  useEffect(() => {
    setLoading(true);
    loadWalletTokens();
  }, [loadWalletTokens]);

  return (
    <Wrapper>
      <Headline noMargin size="two">
        History
      </Headline>
      {error && <Error>{error}</Error>}
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!loading && (
        <DataGrid
          clientId={clientId}
          paymentId={paymentId}
          columns={columns}
          dataSource={history}
        />
      )}
    </Wrapper>
  );
};

HistoryModal.propTypes = {
  id: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};

export default HistoryModal;
