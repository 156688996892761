import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@sumup/circuit-ui';
import { ReactComponent as RefreshIcon } from './refresh.svg';

const TransactionsRefresh = ({ onFiltersApply }) => (
  <IconButton label="Refresh" onClick={onFiltersApply} size="kilo">
    <RefreshIcon />
  </IconButton>
);

TransactionsRefresh.propTypes = {
  onFiltersApply: PropTypes.func.isRequired,
};

export default TransactionsRefresh;
