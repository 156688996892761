import { actionTypes } from 'variables';

export const setPageSize = ({ size }) => ({
  type: actionTypes.SET_AUTHORIZATIONS_PAGE_SIZE,
  payload: { size },
});

export const setPage = ({ page }) => ({
  type: actionTypes.SET_AUTHORIZATIONS_PAGE,
  payload: { page },
});

export const setFilter = ({ field, value }) => ({
  type: actionTypes.SET_AUTHORIZATIONS_FILTER,
  payload: { field, value },
});

export const setFilterDateRange = (datePeriod) => ({
  type: actionTypes.SET_AUTHORIZATIONS_DATE_RANGE_FILTER,
  payload: { datePeriod },
});

export const updateAuthorizationsForAdjustmentSuccess = ({
  authorizationsForAdjustmentUpdated,
}) => ({
  type: actionTypes.UPDATE_AUTHORIZATIONS_FOR_ADJUSTMENT_SUCCESS,
  payload: { authorizationsForAdjustmentUpdated },
});

export const applyFilters = () => ({
  type: actionTypes.APPLY_AUTHORIZATIONS_FILTERS,
});

export const clearFilters = () => ({
  type: actionTypes.CLEAR_AUTHORIZATIONS_FILTERS,
});

export const setIgnoreAuthorizationLoading = (authorizationAdjustmentId) => ({
  type: actionTypes.SET_IGNORE_AUTHORIZATION_LOADING,
  payload: { authorizationAdjustmentId },
});

export const setAdjustAuthorizationLoading = (authorizationAdjustmentId) => ({
  type: actionTypes.SET_ADJUST_AUTHORIZATION_LOADING,
  payload: { authorizationAdjustmentId },
});

export const removeIgnoreAuthorizationLoading = (
  authorizationAdjustmentId
) => ({
  type: actionTypes.REMOVE_IGNORE_AUTHORIZATION_LOADING,
  payload: { authorizationAdjustmentId },
});

export const removeAdjustAuthorizationLoading = (
  authorizationAdjustmentId
) => ({
  type: actionTypes.REMOVE_ADJUST_AUTHORIZATION_LOADING,
  payload: { authorizationAdjustmentId },
});
