import React, { useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from '@sumup/circuit-ui';
import { filter, toLower } from 'lodash';
import { useHttpClient } from 'hooks';
import {
  setCardStatus,
  clearCardPinsAttempts,
  createBankAccount,
  setPsdStatus,
  issueCard,
  getActiveCards,
  getPrimaryCard,
} from 'services/clientData';
import {
  lockCardForAudit,
  transferToBank,
  renameClient,
  updateClient,
  getAccountById,
  unblockBusinessAccount,
  blockBusinessAccount,
  closeBusinessAccount,
  replaceCard,
  getClient,
} from 'api';
import { loadError, loadSuccess, setLoading } from 'actions';
import {
  ACCOUNT_STATUS_MAP,
  APPS,
  CARD_TYPES,
  ACTIVE_CARD_STATUSES,
  PAYMENTS_BLOCKED_STATUS,
  RESPONSE_STATUS_FAILED,
  REPLACE_REASONS,
  MULTIPLE_CARDS_TYPES,
} from 'variables';
import AuthContext from 'context/auth';
import ClientDataContext from 'context/clientData';
import { hasActionAccess } from 'services/permissions';
import { getMerchantPageData } from 'services/merchantPageData';
import { accountReducer } from './accountReducer';
import {
  setPrimaryCardStatusSuccess,
  setReplacementCardStatusSuccess,
  setPrimaryCardStatusError,
  setReplacementCardStatusError,
  clearCardPinsAttemptsSuccess,
  clearCardPinsAttemptsError,
  setPrimaryPsdStatusLoading,
  setReplacementPsdStatusLoading,
  setCreateBankAccountLoading,
  createBankAccountSuccess,
  createBankAccountError,
  setPrimaryPsdStatusSuccess,
  setPrimaryPsdStatusError,
  setReplacementPsdStatusError,
  setReplacementPsdStatusSuccess,
  issueCardSuccess,
  replaceCardSuccess,
  issueCardError,
  replaceCardError,
  setLockCardForAuditLoading,
  lockCardForAuditError,
  setIssueCardLoading,
  setReplaceCardLoading,
  setTransferToBankLoading,
  setTransferToBankError,
  setBalanceLoading,
  setAccountStatusLoading,
  setAccountStatus,
  setAccountStatusError,
  setPrimaryCardStatusLoading,
  setReplacementCardStatusLoading,
} from './accountActions';
import BusinessAccount from './components/BusinessAccount';
import ActiveCards from './components/ActiveCards';
import InactiveCards from './components/InactiveCards';
import { initialState } from './state';
import { columns as walletTokenModalColumns } from './components/WalletTokensModal/columns';
import WalletTokensModal from './components/WalletTokensModal';
import IssueCardModal from './components/ActiveCards/components/IssueCardModal';

const CHANGE_ACCOUNT_STATUS = {
  [ACCOUNT_STATUS_MAP.ACTIVE]: unblockBusinessAccount,
  [ACCOUNT_STATUS_MAP.BLOCKED]: blockBusinessAccount,
  [ACCOUNT_STATUS_MAP.CLOSED]: closeBusinessAccount,
};

const Account = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();

  const {
    state: { clientData, clientDataLoading },
  } = useContext(ClientDataContext);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const { setModal, removeModal } = useModal();

  const [state, dispatch] = useReducer(accountReducer, {
    ...initialState,
    accountData: clientData,
    accountDataLoading: clientDataLoading,
    activeCards: getActiveCards(clientData),
  });

  const {
    accountData,
    activeCards,
    issueCardLoading,
    replaceCardLoading,
    createBankAccountLoading,
    createBankAccountError: bankAccountCreationError,
    primaryPsdStatusLoading,
    replacementPsdStatusLoading,
    lockCardForAuditLoading,
    primaryCardStatusLoading,
    replacementCardStatusLoading,
    issueCardFail,
    replaceCardFail,
    transferToBankLoading,
    transferToBankError,
    balanceLoading,
    accountStatus,
    accountStatusLoading,
    accountStatusError,
  } = state;

  const { cardholder_name: currentCardholderName } = accountData || {};

  const { expiring_soon: expiringSoon } = getPrimaryCard(activeCards) || {};

  const {
    cards,
    address,
    account_contract_status: accountContractStatus,
    personal_profile: personalProfile,
  } = accountData || {};

  const { bank_account_identifier: accountId } = accountData;

  useEffect(() => {
    if (accountId) {
      getAccountById(httpClient, {
        accountId,
      })
        .then(({ data }) => {
          dispatch(setAccountStatus(data));
          dispatch(setAccountStatusLoading(false));
        })
        .catch(() => {
          dispatch(setAccountStatusLoading(false));
        });
    }
  }, [httpClient, accountId]);

  const handlePsdStatusChange = ({ status, token, type }) => {
    dispatch(
      (type === MULTIPLE_CARDS_TYPES.primary &&
        setPrimaryPsdStatusLoading(true)) ||
        (type === MULTIPLE_CARDS_TYPES.replacement &&
          setReplacementPsdStatusLoading(true))
    );

    setPsdStatus({ status, token, httpClient })
      .then(({ data }) => {
        dispatch(
          (type === MULTIPLE_CARDS_TYPES.primary &&
            setPrimaryPsdStatusSuccess({ data, activeCards })) ||
            (type === MULTIPLE_CARDS_TYPES.replacement &&
              setReplacementPsdStatusSuccess({ data, activeCards }))
        );
      })
      .catch((err) =>
        dispatch(
          (type === MULTIPLE_CARDS_TYPES.primary &&
            setPrimaryPsdStatusError(err)) ||
            (type === MULTIPLE_CARDS_TYPES.replacement &&
              setReplacementPsdStatusError(err))
        )
      );
  };

  const handleClearPinAttempts = ({ cardToken }) => {
    dispatch(setLoading(true));

    clearCardPinsAttempts({ cardId: cardToken, httpClient })
      .then((data) => dispatch(clearCardPinsAttemptsSuccess(data)))
      .catch((err) => dispatch(clearCardPinsAttemptsError(err)));
  };

  const handleCardStatusChange = ({ token, status, comment, type }) => {
    dispatch(
      (type === MULTIPLE_CARDS_TYPES.primary &&
        setPrimaryCardStatusLoading(true)) ||
        (type === MULTIPLE_CARDS_TYPES.replacement &&
          setReplacementCardStatusLoading(true))
    );

    return setCardStatus({
      clientId,
      cardId: token,
      status,
      comment,
      httpClient,
    })
      .then((data) => {
        dispatch(setPrimaryCardStatusLoading(false));
        dispatch(setReplacementCardStatusLoading(false));
        dispatch(
          (type === MULTIPLE_CARDS_TYPES.primary &&
            setPrimaryCardStatusSuccess(data)) ||
            (type === MULTIPLE_CARDS_TYPES.replacement &&
              setReplacementCardStatusSuccess(data))
        );
      })
      .catch((err) => {
        dispatch(
          (type === MULTIPLE_CARDS_TYPES.primary &&
            setPrimaryCardStatusLoading(false)) ||
            (type === MULTIPLE_CARDS_TYPES.replacement &&
              setReplacementCardStatusLoading(false))
        );
        dispatch(
          (type === MULTIPLE_CARDS_TYPES.primary &&
            setPrimaryCardStatusError(err)) ||
            (type === MULTIPLE_CARDS_TYPES.replacement &&
              setReplacementCardStatusError(err))
        );
      });
  };

  const handleIssueCard = ({
    virtualCardSelected,
    cardholderName,
    newAddress,
  }) => {
    dispatch(issueCardError(false));
    dispatch(setIssueCardLoading(true));

    const { country } = accountData;
    const language = toLower(country);
    const { mobile_phone: mobilePhone } = personalProfile || {};

    let handler = renameClient({
      httpClient,
      clientId,
      name: cardholderName,
    });

    if (!virtualCardSelected) {
      handler = handler.then(() =>
        updateClient({
          httpClient,
          clientId,
          address: newAddress || address,
          mobilePhone,
        })
      );
    }

    return handler
      .then(() =>
        issueCard({
          clientId,
          language,
          httpClient,
          cardType: virtualCardSelected
            ? CARD_TYPES.virtual
            : CARD_TYPES.physical,
        })
      )
      .then((data) => {
        dispatch(issueCardSuccess(data));
      })
      .catch(() => {
        dispatch(issueCardError(true));
      });
  };

  const handleCreateBankAccount = () => {
    dispatch(setCreateBankAccountLoading(true));

    createBankAccount({ clientId, address, httpClient })
      .then((data) => dispatch(createBankAccountSuccess(data)))
      .catch((err) => dispatch(createBankAccountError(err)));
  };

  const handleLockCardForAudit = ({ cardToken, action, comment }) => {
    dispatch(setLockCardForAuditLoading(true));

    lockCardForAudit({
      clientId,
      cardToken,
      action,
      comment,
      httpClient,
    })
      .then(() => getMerchantPageData({ clientId, httpClient }))
      .then((data) => {
        dispatch(loadSuccess(data));
        dispatch(setLockCardForAuditLoading(false));
      })
      .catch((error) => {
        dispatch(setLockCardForAuditLoading(false));
        dispatch(lockCardForAuditError(error));
      });
  };

  const hasActionAccessWrapped = ({ action }) =>
    hasActionAccess({ permissions, app: APPS.merchant, action });

  const handleTransferToBank = ({ type, amount, comment }) => {
    dispatch(setTransferToBankLoading(true));

    transferToBank({
      httpClient,
      clientId,
      type,
      amount: amount === '' ? undefined : amount * 100,
      comment: comment === '' ? undefined : comment,
    })
      .then(({ data = {} }) => {
        const { status } = data;

        if (status === RESPONSE_STATUS_FAILED) {
          dispatch(setTransferToBankError());
        } else {
          dispatch(setTransferToBankLoading(false));
          dispatch(setLoading(true));
          dispatch(setBalanceLoading(true));

          return getMerchantPageData({ clientId, httpClient })
            .then((clientDetails) => {
              dispatch(loadSuccess(clientDetails));
              dispatch(setBalanceLoading(false));
            })
            .catch(() => {
              dispatch(loadError());
              dispatch(setBalanceLoading(false));
            });
        }

        return true;
      })
      .catch(() => {
        dispatch(setTransferToBankError());
      });
  };

  const handleReplaceCard = ({
    virtualCardSelected,
    cardholderName,
    newAddress,
    replaceReason,
  }) => {
    dispatch(replaceCardError(false));
    dispatch(setReplaceCardLoading(true));
    const card =
      activeCards &&
      activeCards.length === 1 &&
      activeCards[0].is_primary === false
        ? activeCards[0]
        : getPrimaryCard(activeCards);
    const { token } = card || {};

    if (cardholderName !== currentCardholderName) {
      return renameClient({
        httpClient,
        clientId,
        name: cardholderName,
      }).then(() => {
        replaceCard({
          replaceReason: expiringSoon
            ? REPLACE_REASONS.EXPIRING
            : replaceReason,
          httpClient,
          clientId,
          cardId: token,
          cardType: virtualCardSelected
            ? CARD_TYPES.virtual
            : CARD_TYPES.physical,
          country: address.country,
          city: newAddress?.city || address.city,
          addressLine1: newAddress?.address_line_1 || address.address_line_1,
          addressLine2: newAddress?.address_line_2 || address.address_line_2,
          postCode: newAddress?.post_code || address.post_code,
        })
          .then(() => {
            getClient({ clientId, httpClient })
              .then((data) => {
                dispatch(replaceCardSuccess(data));
              })
              .catch(() => {
                dispatch(replaceCardError(true));
              });
          })
          .catch(() => {
            dispatch(replaceCardError(true));
          });
      });
    }

    return replaceCard({
      httpClient,
      clientId,
      cardId: token,
      cardType: virtualCardSelected ? CARD_TYPES.virtual : CARD_TYPES.physical,
      country: address.country,
      city: newAddress?.city || address.city,
      addressLine1: newAddress?.address_line_1 || address.address_line_1,
      addressLine2: newAddress?.address_line_2 || address.address_line_2,
      postCode: newAddress?.post_code || address.post_code,
      replaceReason: expiringSoon ? REPLACE_REASONS.EXPIRING : replaceReason,
    })
      .then(() => {
        getClient({ clientId, httpClient })
          .then((data) => {
            dispatch(replaceCardSuccess(data));
          })
          .catch(() => {
            dispatch(replaceCardError(true));
          });
      })
      .catch(() => {
        dispatch(replaceCardError(true));
      });
  };

  const paymentsBlocked = accountContractStatus === PAYMENTS_BLOCKED_STATUS;

  const handleWalletTokensModalOpen = ({ cardId, cardToken }) => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => removeModal,
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <WalletTokensModal
          cardId={cardId}
          cardToken={cardToken}
          clientId={clientId}
          columns={walletTokenModalColumns}
          hasActionAccess={hasActionAccessWrapped}
          paymentsBlocked={paymentsBlocked}
          onClose={onClose}
        />
      ),
    });
  };

  const handleReplaceModalOpen = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <IssueCardModal
          address={address}
          personalProfile={personalProfile}
          onClose={onClose}
          onReplaceCard={handleReplaceCard}
          cardReplace
          expiringSoon={expiringSoon}
        />
      ),
      closeButtonLabel: 'Close',
      onClose: () => {},
    });
  };

  const handleAccountStatusChange = ({ status, comment }) => {
    dispatch(setAccountStatusLoading(true));

    return CHANGE_ACCOUNT_STATUS[status](httpClient, { accountId, comment })
      .then(({ data }) => {
        dispatch(setAccountStatusLoading(false));
        dispatch(setAccountStatus(data));
      })
      .catch(() => {
        dispatch(setAccountStatusError());
      });
  };

  const inactiveCards = filter(
    cards || [],
    ({ status }) => !ACTIVE_CARD_STATUSES[status]
  );

  return (
    <>
      {accountData && (
        <>
          <BusinessAccount
            accountData={accountData}
            loading={createBankAccountLoading}
            error={
              transferToBankError ||
              bankAccountCreationError ||
              accountStatusError
            }
            balanceLoading={balanceLoading}
            accountStatus={accountStatus}
            hasActionAccess={hasActionAccessWrapped}
            transferToBankLoading={transferToBankLoading}
            accountStatusLoading={accountStatusLoading}
            onCreateBankAccount={handleCreateBankAccount}
            onTransferToBank={handleTransferToBank}
            onAccountStatusChange={handleAccountStatusChange}
          />
          <ActiveCards
            isLoading={issueCardLoading}
            activeCards={activeCards}
            onCardStatusChange={handleCardStatusChange}
            onIssueCard={handleIssueCard}
            onClearPinAttempts={handleClearPinAttempts}
            clientId={clientId}
            onPsdStatusChange={handlePsdStatusChange}
            primaryPsdStatusLoading={primaryPsdStatusLoading}
            replacementPsdStatusLoading={replacementPsdStatusLoading}
            address={address}
            personalProfile={personalProfile}
            paymentsBlocked={paymentsBlocked}
            onLockCardForAudit={handleLockCardForAudit}
            lockCardForAuditLoading={lockCardForAuditLoading}
            primaryCardStatusLoading={primaryCardStatusLoading}
            replacementCardStatusLoading={replacementCardStatusLoading}
            issueCardFail={issueCardFail}
            replaceCardFail={replaceCardFail}
            permissions={permissions}
            hasActionAccess={hasActionAccessWrapped}
            onWalletTokensModalOpen={handleWalletTokensModalOpen}
            onReplaceModalOpen={handleReplaceModalOpen}
            replaceCardLoading={replaceCardLoading}
          />
          <InactiveCards
            inactiveCards={inactiveCards}
            onWalletTokensModalOpen={handleWalletTokensModalOpen}
          />
        </>
      )}
    </>
  );
};

export default Account;
