import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Body } from '@sumup/circuit-ui';
import EmptyState from 'components/EmptyState';
import { filter, map, size } from 'lodash';
import ListItem from 'components/UserAdministration/components/ListItem';
import { SpinnerWrapper } from 'components/UserAdministration/UserAdministrationStyled';

const UserGroupsList = ({
  loading,
  readonly,
  groups,
  selectedGroups,
  onGroupClick,
}) => {
  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (readonly && !size(groups)) {
    <EmptyState text="No groups have been assigned yet" centerText />;
  }

  const handleClick = ({ code }) => {
    const newSelectedGroups = { ...selectedGroups };
    newSelectedGroups[code] = !selectedGroups[code];

    onGroupClick({ groups: newSelectedGroups });
  };

  const groupsToShow = filter(
    groups,
    ({ code }) => !readonly || selectedGroups[code]
  );

  return map(groupsToShow, ({ code }) => (
    <ListItem
      key={code}
      readonly={readonly}
      checked={selectedGroups[code]}
      onClick={() => handleClick({ code })}
    >
      <Body noMargin variant="highlight" size="one">
        {code}
      </Body>
    </ListItem>
  ));
};

UserGroupsList.defaultProps = {
  loading: true,
  readonly: true,
  groups: [],
  selectedGroups: {},
  onGroupClick: () => {},
};

UserGroupsList.propTypes = {
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  groups: PropTypes.array,
  selectedGroups: PropTypes.object,
  onGroupClick: PropTypes.func,
};

export default UserGroupsList;
