import React from 'react';
import PropTypes from 'prop-types';
import { Item, StyledCheckmark, StyledCheckbox } from './ListItemStyled';

const ListItem = ({ readonly, children, style, checked, onClick }) => {
  const handleClick = () => {
    onClick(!checked);
  };

  return (
    <Item readonly={readonly} style={style} onClick={handleClick}>
      {readonly ? (
        <StyledCheckmark size="16" />
      ) : (
        <StyledCheckbox
          noMargin
          checked={checked}
          onClickCapture={handleClick}
        />
      )}
      {children}
    </Item>
  );
};

ListItem.defaultProps = {
  readonly: true,
  children: [],
  style: {},
  checked: false,
  onClick: () => {},
};

ListItem.propTypes = {
  readonly: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ListItem;
