import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { DEFAULT_FRACTION_DIGITS } from 'variables';
import { StyledAmountCell } from './CompositeAmountCellStyled';

const CompositeAmountCell = ({
  value: { amount, currency },
  fractionDigits,
  clickable,
  centered,
  formatNumber,
}) => {
  let formattedAmount = parseFloat(formatNumber(amount / 100)).toFixed(
    fractionDigits
  );

  if (amount > 0) {
    formattedAmount = `+${formattedAmount}`;
  }

  return (
    <StyledAmountCell clickable={clickable} centered={centered}>
      <Body noMargin size="two">
        {(amount || amount === 0) && `${formattedAmount} ${currency}`}
      </Body>
    </StyledAmountCell>
  );
};

CompositeAmountCell.defaultProps = {
  value: {},
  fractionDigits: DEFAULT_FRACTION_DIGITS,
  clickable: false,
  centered: false,
  formatNumber: (number) => number,
};

CompositeAmountCell.propTypes = {
  value: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string,
  }),
  fractionDigits: PropTypes.number,
  clickable: PropTypes.bool,
  centered: PropTypes.bool,
  formatNumber: PropTypes.func,
};

export default CompositeAmountCell;
