import React from 'react';
import PropTypes from 'prop-types';
import { ApplePay, GooglePay } from '@sumup/icons';
import { WALLETS } from 'variables';
import { ReactComponent as DefaultWallet } from 'assets/wallet.svg';

const WALLET_ICON_MAP = {
  [WALLETS.APPLE_PAY]: <ApplePay />,
  [WALLETS.GOOGLE_PAY]: <GooglePay />,
};

const WalletCell = ({ value, style }) => (
  <div style={style}>{WALLET_ICON_MAP[value] || <DefaultWallet />}</div>
);

WalletCell.defaultProps = {
  value: '',
  style: {},
};

WalletCell.propTypes = {
  value: PropTypes.string,
  style: PropTypes.object,
};

export default WalletCell;
