import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  DateInput,
  Input,
  TextArea,
  CurrencyInput,
  Button,
  spacing,
  Body,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { map, keys } from 'lodash';
import { BANK_TRX_FRAUD_TYPE_MAP } from 'components/Transactions/constants';
import {
  FRAUD_REPORT_REFERENCE_MAX_CHARACTERS,
  FRAUD_REPORT_DETAILS_MAX_CHARACTERS,
  EMPTY_FIELD,
} from 'variables/validationMessages';
import { EN_GB_LOCALE } from 'variables';
import { ReactComponent as CopyPaste } from 'assets/copy-paste.svg';
import { Confirm } from '@sumup/icons';
import FraudStatus from './components/FraudStatus/FraudStatus';

const Content = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    > label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      :not(:last-of-type) {
        margin-bottom: ${theme.spacings.giga};
      }

      > div {
        width: 100%;
      }
    }
  `
);

const Column = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    :not(:last-of-type) {
      margin-right: ${theme.spacings.tera};
    }

    > label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      :not(:last-of-type) {
        margin-bottom: ${theme.spacings.giga};
        margin-right: ${theme.spacings.giga};
      }

      > div {
        width: 100%;
      }
    }
  `
);

const CopyCommentSection = styled(Body)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: ${theme.spacings.tera};
  `
);

const ButtonCopy = styled(Button)(
  ({ theme }) => css`
    > span > svg {
      margin-right: ${theme.spacings.byte};
    }
  `
);

const Copied = styled('div')(
  ({ theme }) => css`
    color: ${theme.colors.g700};

    > svg {
      margin-right: ${theme.spacings.bit};
      position: relative;
      top: 2px;
    }
  `
);

export const FraudReportModalContent = ({
  record,
  type,
  subtype,
  date,
  status,
  reference,
  referenceInvalid,
  recoveredAmount,
  recoveredAmountInvalid,
  details,
  detailsInvalid,
  subtypeMap,
  copied,
  onFraudTypeChange,
  onFraudSubTypeChange,
  onDateChange,
  onStatusChange,
  onRecoveredAmountChange,
  onReferenceChange,
  onDetailsChange,
  onCommentCopy,
}) => (
  <Content>
    <Column>
      <Select
        label="Fraud type"
        value={type}
        onChange={onFraudTypeChange}
        noMargin
        required
      >
        {map(keys(BANK_TRX_FRAUD_TYPE_MAP), (key) => (
          <option key={key} value={BANK_TRX_FRAUD_TYPE_MAP[key]}>
            {BANK_TRX_FRAUD_TYPE_MAP[key]}
          </option>
        ))}
      </Select>
      <Select
        label="Fraud subtype"
        value={subtype}
        onChange={onFraudSubTypeChange}
        noMargin
      >
        {map(subtypeMap, (item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </Select>
      <DateInput
        label="Date"
        noMargin
        value={date}
        onChange={onDateChange}
        required
        locale={EN_GB_LOCALE}
      />
      <FraudStatus fraudStatus={status} onFraudStatusChange={onStatusChange} />
      <CurrencyInput
        label="Recovered amount"
        value={recoveredAmount}
        noMargin
        currency={record.total_amount.currency}
        onChange={onRecoveredAmountChange}
        invalid={recoveredAmountInvalid}
        validationHint={recoveredAmountInvalid && EMPTY_FIELD}
      />
    </Column>
    <Column>
      <Input
        label="Reference"
        value={reference}
        optionalLabel="Optional"
        noMargin
        onChange={onReferenceChange}
        invalid={referenceInvalid}
        validationHint={
          referenceInvalid && FRAUD_REPORT_REFERENCE_MAX_CHARACTERS
        }
      />
      <TextArea
        label="Details"
        value={details}
        optionalLabel="Optional"
        noMargin
        rows="11"
        onChange={onDetailsChange}
        invalid={detailsInvalid}
        validationHint={detailsInvalid && FRAUD_REPORT_DETAILS_MAX_CHARACTERS}
      />
      <CopyCommentSection noMargin css={spacing({ top: 'giga' })}>
        {copied ? (
          <Copied>
            <Confirm size="16" />
            Copied!
          </Copied>
        ) : (
          <ButtonCopy variant="secondary" size="kilo" onClick={onCommentCopy}>
            <CopyPaste /> Generate and copy comment
          </ButtonCopy>
        )}
      </CopyCommentSection>
    </Column>
  </Content>
);

FraudReportModalContent.defaultProps = {
  reference: null,
  details: null,
  copied: false,
};

FraudReportModalContent.propTypes = {
  record: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  recoveredAmount: PropTypes.number.isRequired,
  recoveredAmountInvalid: PropTypes.bool.isRequired,
  reference: PropTypes.string,
  referenceInvalid: PropTypes.bool.isRequired,
  details: PropTypes.string,
  detailsInvalid: PropTypes.bool.isRequired,
  subtypeMap: PropTypes.object.isRequired,
  copied: PropTypes.bool,
  onFraudTypeChange: PropTypes.func.isRequired,
  onFraudSubTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onRecoveredAmountChange: PropTypes.func.isRequired,
  onReferenceChange: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
  onCommentCopy: PropTypes.func.isRequired,
};
