import { last, split } from 'lodash';

const THOUSAND_SEPARATOR_DIGITS = 3;

export const formatStringNumber = (value = '') => {
  if (value == null || typeof value !== 'string') {
    return '';
  }

  let amount = value;

  const commaIndex = amount.indexOf(',');
  const periodIndex = amount.indexOf('.');
  const multipleSeparators = commaIndex > -1 && periodIndex > -1;

  if (multipleSeparators) {
    if (commaIndex > periodIndex) {
      amount = value.replace(/\./g, '');
      amount = amount.replace(/,/g, '.');
    } else {
      amount = amount.replace(/,/g, '');
    }
  } else {
    if (commaIndex > -1) {
      const valueParts = split(value, ',');

      if (last(valueParts).length === THOUSAND_SEPARATOR_DIGITS) {
        amount = value.replace(/,/g, '');
      } else {
        amount = value.replace(/,/g, '.');
      }
    }

    if (periodIndex > -1) {
      const valueParts = split(value, '.');

      if (last(valueParts).length === THOUSAND_SEPARATOR_DIGITS) {
        amount = value.replace(/\./g, '');
      }
    }
  }

  return amount;
};
