import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { WRAPPER_WIDTH } from 'variables';
import {
  StyledCardContent,
  StyledCardHeading,
} from '../../MerchantNavigationStyled';

export const Content = styled('div')(
  ({ theme: { spacings, mq } }) => css`
    display: flex;
    width: 100%;
    padding: ${spacings.byte} ${spacings.mega};
    ${mq.giga} {
      max-width: calc(100% - 200px);
      padding: ${spacings.byte} ${spacings.mega} ${spacings.byte} 0;
    }
  `
);

export const ContentColumn = styled(StyledCardContent)(
  ({ theme: { spacings } }) => css`
    padding: ${spacings.byte} 2px;
  `
);

export const NavigationCardHeading = styled(StyledCardHeading)`
  border: 0;
`;

export const StyledNavigationCards = styled('div')(
  ({ theme: { spacings } }) => css`
    display: flex;
    justify-content: space-between;
    max-width: calc(${WRAPPER_WIDTH - 80}px);
    padding: 0 ${spacings.peta};
    margin: ${spacings.peta} auto 0;
  `
);
