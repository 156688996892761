import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Body,
  spacing,
  TextArea,
  useNotificationToast,
} from '@sumup/circuit-ui';
import Modal from 'components/Modal';
import { useHttpClient } from 'hooks';
import { cancelSchedule, skipNextPayment } from 'api';
import { trim } from 'lodash';
import { PAYMENT_SCHEDULE_TYPE_ONE_OFF } from '../constants';

const TextAreaWrapper = styled('div')`
  width: 100%;
  text-align: left;

  textarea {
    height: 96px;
  }
`;

const COMMENT_MAX_LENGTH = 500;
const COMMENT_MAX_LENGTH_MESSAGE = `Please use no more than ${COMMENT_MAX_LENGTH} characters`;

export const CancelUpcomingPaymentModal = ({
  type,
  finalPayment,
  paymentRequestId,
  accountId,
  setLoading,
  onConfirm,
  onClose,
}) => {
  const [comment, setComment] = useState();
  const [commentValidationHint, setCommentValidationHint] = useState();

  const { setToast } = useNotificationToast();

  const httpClient = useHttpClient();

  const validateComment = (value = '') => {
    const hint =
      value.length > COMMENT_MAX_LENGTH ? COMMENT_MAX_LENGTH_MESSAGE : '';

    setCommentValidationHint(hint);

    return hint;
  };

  const handleCommentChange = ({ target: { value } }) => {
    setComment(trim(value));
    validateComment(value);
  };

  const handleCancelPaymentConfirm = () =>
    type === PAYMENT_SCHEDULE_TYPE_ONE_OFF
      ? cancelSchedule(httpClient, { id: paymentRequestId, comment })
      : skipNextPayment(httpClient, {
          id: paymentRequestId,
          accountId,
          comment,
        });

  return (
    <Modal
      modalInfo={{
        width: 420,
        title: finalPayment ? 'Cancel final payment' : 'Cancel payment',
        confirmText: 'Cancel payment',
        cancelText: 'Keep',
        confirmButtonProps: {
          destructive: true,
        },
        centered: true,
        disabled: !comment || !!commentValidationHint,
        content: (
          <>
            <Body noMargin size="one" css={spacing({ bottom: 'mega' })}>
              {finalPayment
                ? 'This will complete the series of scheduled payments. The merchant can find a record of the payments in their archive.'
                : 'A record of the payment will be added to the archive of the merchant.'}
            </Body>
            <TextAreaWrapper>
              <TextArea
                noMargin
                label="Comment"
                placeholder="Enter value"
                required
                validationHint={commentValidationHint}
                invalid={!!commentValidationHint}
                onChange={handleCommentChange}
              />
            </TextAreaWrapper>
          </>
        ),
        onConfirm: () => {
          setLoading(true);

          return handleCancelPaymentConfirm()
            .then(onConfirm)
            .then(() => {
              setLoading(false);

              setToast({
                variant: 'confirm',
                headline: 'Payment cancelled successfully',
              });
            })
            .catch(() => {
              setLoading(false);
            });
        },
      }}
      onClose={(e) => {
        setComment(null);
        return onClose(e);
      }}
    />
  );
};

CancelUpcomingPaymentModal.defaultProps = {
  finalPayment: false,
};

CancelUpcomingPaymentModal.propTypes = {
  type: PropTypes.string.isRequired,
  finalPayment: PropTypes.bool,
  paymentRequestId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
