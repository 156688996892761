import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

const AuthorizationDifferenceStyled = styled(Body)(
  ({ positive, theme: { colors } }) => css`
    ${positive ? `color: ${colors.g700}` : `color: ${colors.r500}`}
  `
);

const AuthorizationDifference = ({ value: { formattedAmount, plain } }) => {
  const positive = plain > 0;

  return (
    <AuthorizationDifferenceStyled positive={positive} size="two" noMargin>
      {positive ? `+${formattedAmount}` : `${formattedAmount}`}
    </AuthorizationDifferenceStyled>
  );
};

AuthorizationDifference.propTypes = {
  value: PropTypes.shape({
    formattedAmount: PropTypes.string.isRequired,
    plain: PropTypes.number.isRequired,
  }).isRequired,
};

export default AuthorizationDifference;
