import { actionTypes } from 'variables';

export const setPageSize = ({ size }) => ({
  type: actionTypes.SET_CANCEL_AUTHORIZATIONS_PAGE_SIZE,
  payload: { size },
});

export const setPage = ({ page }) => ({
  type: actionTypes.SET_CANCEL_AUTHORIZATIONS_PAGE,
  payload: { page },
});

export const setFilter = ({ field, value }) => ({
  type: actionTypes.SET_CANCEL_AUTHORIZATIONS_FILTER,
  payload: { field, value },
});

export const applyFilters = () => ({
  type: actionTypes.APPLY_CANCEL_AUTHORIZATIONS_FILTERS,
});

export const clearFilters = () => ({
  type: actionTypes.CLEAR_CANCEL_AUTHORIZATIONS_FILTERS,
});

export const setFilterDate = (date) => ({
  type: actionTypes.SET_CANCEL_AUTHORIZATIONS_DATE_FILTER,
  payload: { date },
});

export const sortCancelAuthorizations = ({ name, order }) => ({
  type: actionTypes.SORT_CANCEL_AUTHORIZATIONS,
  payload: { name, order },
});
