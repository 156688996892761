import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, RadioButtonGroup, spacing } from '@sumup/circuit-ui';
import { size, split } from 'lodash';
import Modal from 'components/Modal';
import { MODALS } from 'variables';
import { isEmpty } from 'services/common';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const AmountRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-bottom: ${theme.spacings.byte};
    }
  `
);

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > div {
      width: 100%;
    }
  }

  > label {
    width: 100%;
  }
`;

const FULL_AMOUNT_OPTION = 'FULL';
const PARTIAL_AMOUNT_OPTION = 'PARTIAL';
const VALID_PARTIAL_AMOUNT_DECIMALS_COUNT = 2;
const MIN_PARTIAL_AMOUNT = 0.01;

const getOptions = (amount) => [
  {
    value: FULL_AMOUNT_OPTION,
    label: `Full amount of ${amount}`,
  },
  {
    value: PARTIAL_AMOUNT_OPTION,
    label: 'Partial amount',
  },
];

const TransferToBankModal = ({
  fullAmount,
  formattedFullAmount,
  onTransfer,
  onClose,
}) => {
  const [selectedOption, setSelectedOption] = useState(FULL_AMOUNT_OPTION);
  const [partialAmount, setPartialAmount] = useState('');
  const [partialAmountValid, setPartialAmountValid] = useState(true);
  const [comment, setComment] = useState('');

  const handleSelectOption = ({ target: { value } }) => {
    setSelectedOption(value);

    if (value === FULL_AMOUNT_OPTION) {
      setPartialAmount('');
      setPartialAmountValid(true);
    }
  };

  const handlePartialAmountChange = ({ target: { value } }) => {
    setPartialAmount(value);

    const [, decimals] = split(value, '.') || [];
    const parsedValue = Number(value);

    setPartialAmountValid(
      parsedValue > 0 &&
        parsedValue * 100 <= fullAmount &&
        size(decimals) <= VALID_PARTIAL_AMOUNT_DECIMALS_COUNT
    );
  };

  const handleCommentChange = ({ target: { value } }) => setComment(value);

  const handleConfirm = () =>
    onTransfer({ type: selectedOption, amount: partialAmount, comment });

  const handleClose = () => {
    onClose();
    setSelectedOption(FULL_AMOUNT_OPTION);
    setPartialAmount('');
    setComment('');
  };

  const handleScroll = (e) => {
    if (e) {
      if (e.target) {
        e.target.blur();
      }

      e.preventDefault();
    }
  };

  const transferEnabled =
    selectedOption === FULL_AMOUNT_OPTION ||
    (partialAmountValid && !isEmpty(partialAmount));

  return (
    <Modal
      modalInfo={{
        ...MODALS.TRANSFER_TO_BANK,
        content: (
          <ContentWrapper>
            <AmountRadioButtonGroup
              value={selectedOption}
              label="Select full or partial amount"
              hideLabel
              options={getOptions(formattedFullAmount)}
              onChange={handleSelectOption}
            />
            {selectedOption === PARTIAL_AMOUNT_OPTION && (
              <Input
                noMargin
                min={MIN_PARTIAL_AMOUNT}
                step={0.01}
                type="number"
                placeholder={`Amount (${MIN_PARTIAL_AMOUNT} - ${formattedFullAmount})`}
                invalid={!partialAmountValid}
                label="Partial amount"
                hideLabel
                onChange={handlePartialAmountChange}
                onWheel={handleScroll}
              />
            )}
            <Input
              noMargin
              css={spacing({ top: 'mega', bottom: 'byte' })}
              placeholder="Comment (optional)"
              label="Comment"
              hideLabel
              onChange={handleCommentChange}
            />
          </ContentWrapper>
        ),
        disabled: !transferEnabled,
        onConfirm: handleConfirm,
      }}
      onClose={handleClose}
    />
  );
};

TransferToBankModal.propTypes = {
  fullAmount: PropTypes.number.isRequired,
  formattedFullAmount: PropTypes.string.isRequired,
  onTransfer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TransferToBankModal;
