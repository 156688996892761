import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Headline } from '@sumup/circuit-ui';
import { map, reduce } from 'lodash';
import buildLink from 'services/buildLink';
import AuthContext from 'context/auth';
import { hasSectionAccess } from 'services/permissions';
import {
  StyledNavigation,
  NavigationContent,
  Tabs,
  StyledLink,
} from './NavigationStyled';
import BackButton from './components/BackButton';

const Navigation = ({ render, heading, link, sections }) => {
  const history = useHistory();
  const [activeSection, setActiveSection] = useState(render);
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const visibleSections = reduce(
    sections,
    (accumulator, { permissions: { app, section }, ...currSection }) =>
      hasSectionAccess({
        permissions,
        app,
        section,
      })
        ? [...accumulator, currSection]
        : accumulator,
    []
  );

  return (
    <>
      <StyledNavigation>
        <BackButton onClick={() => history.push('/')} />
        <NavigationContent>
          <div>
            <Headline noMargin as="h2" size="two">
              {heading}
            </Headline>
          </div>
          <Tabs>
            {map(visibleSections, ({ key, title, link: segment }) => (
              <StyledLink
                key={key}
                selected={key === activeSection}
                onClick={() => setActiveSection(key)}
                to={buildLink({
                  type: link,
                  segment,
                })}
              >
                {title}
              </StyledLink>
            ))}
          </Tabs>
        </NavigationContent>
      </StyledNavigation>
    </>
  );
};

Navigation.propTypes = {
  render: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  sections: PropTypes.object.isRequired,
};

export default Navigation;
