import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const IndicatorDot = styled('div')(
  ({ theme }) => css`
    width: ${theme.spacings.byte};
    height: ${theme.spacings.byte};
    background: ${theme.colors.p500};
    border-radius: 50%;
  `
);
