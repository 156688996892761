import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { NAVIGATION_MAX_HEIGHT, TOP_BAR_HEIGHT } from 'variables';

export const NavigationComponent = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-height: ${NAVIGATION_MAX_HEIGHT};
    background: ${theme.colors.white};
    position: sticky;
    top: ${TOP_BAR_HEIGHT}px;
    z-index: 10;
  `
);

export const StyledNavigation = styled(NavigationComponent)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const NavigationContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div:first-of-type {
      border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    }

    h2 {
      margin: ${theme.spacings.mega} 20px;
    }
  `
);

export const Tabs = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const StyledLink = styled(Link)(
  ({ theme, selected }) => css`
    margin: ${theme.spacings.kilo} 0 0 20px;
    padding-bottom: ${theme.spacings.kilo};
    cursor: pointer;
    text-decoration: none;

    ${selected &&
    `
      box-shadow: inset 0px -2px 0px ${theme.colors.p500};
    `}

    ${selected
      ? `
          color: ${theme.colors.n900};
        `
      : `
          color: ${theme.colors.n700};
        `}
  `
);
