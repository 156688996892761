import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import {
  CARD_DATA_FIELD,
  CARD_DATA_RESPONSE,
  TRANSACTION_STATUS,
} from 'components/Transaction/fieldsConfig';
import {
  APPROVED_RESPONSE_CODE,
  RESPONSES_MAP,
} from 'components/Transactions/constants';
import Tooltip from 'components/Tooltip';
import { StyledBadge, TooltipContent } from './StyledBadge';

const StatusBadge = ({ value, rowData, disabled }) => {
  const { background, color, label } = value || {
    background: '#E343AC',
    label: (rowData[TRANSACTION_STATUS.name] || {}).value,
  };

  const responseValue =
    (rowData[CARD_DATA_FIELD.name] || {})[CARD_DATA_RESPONSE.name] || '';
  const { code: responseCode, label: responseLabel } =
    RESPONSES_MAP[responseValue] || {};

  return (
    <div>
      {responseCode && responseCode !== APPROVED_RESPONSE_CODE ? (
        <Tooltip
          position="top"
          pivot={
            <StyledBadge
              background={background}
              color={color}
              disabled={disabled}
            >
              <Body noMargin size="two" variant="highlight">
                {label} ({responseCode})
              </Body>
            </StyledBadge>
          }
          content={<TooltipContent>{responseLabel}</TooltipContent>}
        />
      ) : (
        <StyledBadge background={background} color={color} disabled={disabled}>
          <Body noMargin size="two" variant="highlight">
            {label}
          </Body>
        </StyledBadge>
      )}
    </div>
  );
};

StatusBadge.defaultProps = {
  value: null,
  disabled: false,
};

StatusBadge.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    background: PropTypes.string,
  }),
  rowData: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default StatusBadge;
