import { ERROR_MESSAGE, actionTypes } from 'variables';

export const directDebitsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: { content = [] },
        },
      } = action;

      const {
        direct_debits_enabled: directDebitsEnabled,
        iban,
        account_id: accountId,
      } = content[0] || {};

      return {
        ...state,
        loading: false,
        directDebitsEnabled,
        hasBusinessAccount: !!iban,
        accountId,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_DIRECT_DEBITS_ENABLED_SUCCESS: {
      const {
        payload: { data = {} },
      } = action;

      const { direct_debits_enabled: directDebitsEnabled } = data;

      return {
        ...state,
        directDebitsEnabled,
        directDebitsEnabledLoading: false,
        error: null,
      };
    }
    case actionTypes.SET_DIRECT_DEBITS_ENABLED_ERROR: {
      return {
        ...state,
        directDebitsEnabledLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_DIRECT_DEBITS_ENABLED_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        directDebitsEnabledLoading: loading,
      };
    }
    case actionTypes.SET_DIRECT_DEBITS_EVENTS: {
      const {
        payload: { directDebitsEvents },
      } = action;

      return {
        ...state,
        directDebitsEvents,
      };
    }
    default:
      return state;
  }
};
