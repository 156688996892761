import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import Empty from 'components/Empty';
import moment from 'moment';
import { DATE_FORMATS } from 'variables';
import {
  StyledText,
  StyledCard,
  StyledCardHeading,
  StyledCardContent,
  StyledCardRow,
} from '../../ClientCardsStyled';

const BankCard = ({ inner, popover, soapUrl, bankDetails }) => {
  const {
    account_holder_name: accountHolderName,
    account_number: accountNumber,
    bank_code: bankCode,
    bank_name: bankName,
    created_at: createdAt,
    iban,
    id,
    status,
    swift,
  } = bankDetails;

  return (
    <StyledCard inner={inner} popover={popover}>
      <StyledCardHeading>
        <Body noMargin variant="highlight" size="one">
          Third-party Bank Details
        </Body>
        <StyledText
          noMargin
          variant="highlight"
          size="two"
          onClick={() => soapUrl && window.open(soapUrl)}
        >
          Edit in SOAP <ChevronRight />
        </StyledText>
      </StyledCardHeading>
      <StyledCardContent>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            ID
          </Body>
          <Body noMargin size="two">
            {id || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Account name
          </Body>
          <Body noMargin size="two">
            {accountHolderName || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Account number
          </Body>
          <Body noMargin size="two">
            {accountNumber || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Account status
          </Body>
          <Body noMargin size="two">
            {status || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Bank name
          </Body>
          <Body noMargin size="two">
            {bankName || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Bank code
          </Body>
          <Body noMargin size="two">
            {bankCode || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Creation date
          </Body>
          <Body noMargin size="two">
            {createdAt ? (
              moment(createdAt).format(
                DATE_FORMATS.SHORT_MONTH_DAY_YEAR_SHORT_TIME
              )
            ) : (
              <Empty>—</Empty>
            )}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            IBAN
          </Body>
          <Body noMargin size="two">
            {iban || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
        <StyledCardRow>
          <Body noMargin variant="highlight" size="two">
            Swift code
          </Body>
          <Body noMargin size="two">
            {swift || <Empty>—</Empty>}
          </Body>
        </StyledCardRow>
      </StyledCardContent>
    </StyledCard>
  );
};

BankCard.defaultProps = {
  inner: false,
  popover: false,
  soapUrl: '',
  bankDetails: {},
};

BankCard.propTypes = {
  inner: PropTypes.bool,
  popover: PropTypes.bool,
  soapUrl: PropTypes.string,
  bankDetails: PropTypes.object,
};

export default BankCard;
