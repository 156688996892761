import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import moment from 'moment';
import { Body, Button, Headline, spacing } from '@sumup/circuit-ui';
import { ArrowLeft } from '@sumup/icons';
import { getCurrentDate } from 'services/dateTime';
import {
  PAYMENT_SCHEDULE_TYPE_ONE_OFF,
  SCHEDULE_STATUS_ACTIVE,
  TYPE_LABEL,
} from '../constants';
import { UpcomingPaymentCard } from './UpcomingPaymentCard';
import { ScheduleDetailsCard } from './ScheduleDetailsCard';
import { CancelScheduleButton } from './CancelScheduleButton';
import { ScheduleTransactionHistory } from './ScheduleTransactionHistory';

const CardsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacings.bit} ${theme.spacings.mega};
  `
);

const Header = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacings.mega};
  `
);

const EXPONENT = 2;

const ScheduleDetails = ({
  scheduledPayment,
  transactionHistory,
  onCancelPaymentConfirm,
  onCancelScheduleConfirm,
  onAllClick,
  onHistorySort,
  onHistoryPageChange,
  onHistoryPageSizeChange,
}) => {
  const { id, schedule = {}, beneficiary = {} } = scheduledPayment;
  const {
    type,
    status,
    next_due: nextDue,
    next_payment_due: nextPaymentDue,
  } = schedule;
  const {
    account_name: accountName,
    account_number: accountNumber,
    sort_code: sortCode,
    iban,
  } = beneficiary;

  const nextDueDate = nextPaymentDue || nextDue;
  const showDivider = accountNumber && sortCode;

  const today = getCurrentDate();
  const hasUpcomingPayment =
    schedule.status === SCHEDULE_STATUS_ACTIVE &&
    nextDueDate &&
    !moment(nextDueDate).isBefore(today);

  return (
    <>
      <StyledButton css={spacing({ bottom: 'mega' })} onClick={onAllClick}>
        <ArrowLeft size="16" />
        <Body
          noMargin
          size="one"
          variant="highlight"
          css={spacing({ left: 'bit' })}
        >
          All
        </Body>
      </StyledButton>
      <Header>
        <div>
          {TYPE_LABEL[type] ? (
            <Headline
              noMargin
              as="h1"
              size="two"
              css={spacing({ bottom: 'bit' })}
            >
              {TYPE_LABEL[type]} payment to {accountName}
            </Headline>
          ) : null}
          {accountNumber || sortCode ? (
            <Body noMargin size="one">
              {sortCode}
              {showDivider ? ' · ' : ''}
              {accountNumber}
            </Body>
          ) : (
            <Body noMargin size="one">
              {iban}
            </Body>
          )}
        </div>
        {status === SCHEDULE_STATUS_ACTIVE &&
          type !== PAYMENT_SCHEDULE_TYPE_ONE_OFF && (
            <CancelScheduleButton id={id} onConfirm={onCancelScheduleConfirm} />
          )}
      </Header>
      <CardsWrapper>
        <ScheduleDetailsCard
          scheduledPayment={scheduledPayment}
          exponent={EXPONENT}
          fullWidth={!hasUpcomingPayment}
        />
        {hasUpcomingPayment ? (
          <UpcomingPaymentCard
            scheduledPayment={scheduledPayment}
            exponent={EXPONENT}
            onCancelPaymentConfirm={onCancelPaymentConfirm}
          />
        ) : null}
      </CardsWrapper>
      <ScheduleTransactionHistory
        {...transactionHistory}
        onSort={onHistorySort}
        onPageChange={onHistoryPageChange}
        onPageSizeChange={onHistoryPageSizeChange}
      />
    </>
  );
};

ScheduleDetails.propTypes = {
  scheduledPayment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    schedule: PropTypes.object.isRequired,
    beneficiary: PropTypes.object.isRequired,
  }).isRequired,
  transactionHistory: PropTypes.object.isRequired,
  onCancelPaymentConfirm: PropTypes.func.isRequired,
  onCancelScheduleConfirm: PropTypes.func.isRequired,
  onAllClick: PropTypes.func.isRequired,
  onHistorySort: PropTypes.func.isRequired,
  onHistoryPageChange: PropTypes.func.isRequired,
  onHistoryPageSizeChange: PropTypes.func.isRequired,
};

export default ScheduleDetails;
