import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Button, spacing, useModal } from '@sumup/circuit-ui';
import { Alert } from '@sumup/icons';
import { ACTIONS, APPS } from 'variables';
import { hasActionAccess } from 'services/permissions';
import AuthContext from 'context/auth';
import { CancelScheduleModal } from './CancelScheduleModal';

export const CancelScheduleButton = ({ id, onConfirm }) => {
  const [loading, setLoading] = useState(false);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const { setModal } = useModal();

  const handleCancelSchedule = () => {
    setModal({
      children: ({ onClose }) => (
        <CancelScheduleModal
          id={id}
          setLoading={setLoading}
          onConfirm={onConfirm}
          onClose={onClose}
        />
      ),
      closeButtonLabel: 'Close',
    });
  };

  return hasActionAccess({
    permissions,
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardScheduledPaymentsCancelSchedule,
  }) ? (
    <Button
      noMargin
      destructive
      variant="primary"
      isLoading={loading}
      loadingLabel="Cancelling payment..."
      onClick={handleCancelSchedule}
    >
      <Alert />
      <Body
        noMargin
        size="one"
        variant="highlight"
        css={spacing({ left: 'byte' })}
      >
        Cancel schedule
      </Body>
    </Button>
  ) : null;
};

CancelScheduleButton.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
