import React from 'react';
import { Spinner } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const SpinnerStyled = styled(Spinner)(
  ({ theme }) => css`
    position: fixed;
    left: calc(50% - ${theme.spacings.kilo});
    top: calc(50% - ${theme.spacings.kilo});
    z-index: 1000;
  `
);

const Loading = () => <SpinnerStyled />;

export default Loading;
