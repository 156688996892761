import React from 'react';
import { formatCurrency } from '@sumup/intl';
import {
  CUSTOM_FIELD_TYPES,
  DATE_FORMATS,
  FIELD_TYPES,
  EN_GB_LOCALE,
} from 'variables';
import moment from 'moment';
import { TRANSACTIONS_CLEARING_SECTION_KEY } from './constants';

export const CREATED_AT = {
  label: 'Date',
  name: 'transactionDate',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

export const DESCRIPTION = {
  label: 'Description',
  name: 'description',
  getValue: ({ transaction: { description } }) => description,
};

export const CLEARING_DETAILS = {
  name: 'clearingDetails',
};

export const ACTION_COLUMNS = {
  name: 'clearingActions',
};

export const AUTH_IDENTIFIER = {
  label: 'Auth',
  name: 'authenticationCode',
  copyable: true,
  cropText: true,
};

export const RETRIEVAL_REFERENCE_NUMBER = {
  label: 'RRN',
  name: 'retrievalReferenceNumber',
  copyable: true,
  cropText: true,
};

export const CARD_NUMBER = {
  label: 'Card',
  name: 'cardIdNumber',
  copyable: true,
  cropText: true,
};

export const CARD_DATA_CLEARING_AMOUNT = {
  label: 'Amount',
  name: 'originalReconciliationData',
  getValue: ({ originalReconciliationData: { amount, currency } }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const BILLING_AMOUNT = {
  label: 'Amount',
  name: 'amountInBillingCurrency',
  getValue: ({
    transaction: {
      amountInBillingCurrency: { amount, currency },
    },
  }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const STATUS = {
  name: 'status',
  label: '',
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
};

export const CARD_DATA_CLEARING_BILLING_AMOUNT = {
  label: 'Local amount',
  name: 'amountInLocalCurrency',
  getValue: ({
    transaction: {
      amountInLocalCurrency: { amount, currency },
    },
  }) => {
    const sign = (amount && '-') || '';

    return `${sign}${formatCurrency(amount / 100, EN_GB_LOCALE, currency)}`;
  },
};

export const CARD_DATA_MCC_DESCRIPTION = {
  name: 'merchant_category_code_description',
};

export const MERCHANT_DETAILS = {
  label: 'Name / Location',
  name: 'details',
  getValue: ({ merchant: { location, name } }) => `${name} ${location}`,
};

export const TRANSACTION_ORIGIN = {
  name: 'origin',
};

export const CARD_DATA_RESPONSE = {
  name: 'response',
};

export const CARD_DATA_CARD_TOKEN = {
  name: 'card_token',
};

const clearingsColumns = [
  {
    key: CREATED_AT.name,
    fields: [
      {
        ...CREATED_AT,
      },
    ],
    style: {
      maxWidth: 105,
    },
  },
  {
    key: CLEARING_DETAILS.name,
    fields: [
      {
        ...CARD_NUMBER,
        prefix: <span>C:</span>,
        fullLabel: CARD_NUMBER.label,
      },
      {
        ...RETRIEVAL_REFERENCE_NUMBER,
        prefix: <span>R:</span>,
        fullLabel: RETRIEVAL_REFERENCE_NUMBER.label,
      },
      {
        ...AUTH_IDENTIFIER,
        prefix: <span>A:</span>,
        fullLabel: AUTH_IDENTIFIER.label,
      },
    ],
    style: {
      minWidth: 135,
    },
  },

  {
    key: DESCRIPTION.name,
    fields: [
      {
        ...DESCRIPTION,
      },
    ],
    style: {
      minWidth: 135,
    },
  },
];

const clearingColumns = [
  {
    key: CARD_DATA_CLEARING_AMOUNT.name,
    fields: [{ ...CARD_DATA_CLEARING_AMOUNT }],
  },
];

const billingColumns = [
  {
    key: BILLING_AMOUNT.name,
    fields: [{ ...BILLING_AMOUNT }],
  },
  {
    key: CARD_DATA_CLEARING_BILLING_AMOUNT.name,
    fields: [{ ...CARD_DATA_CLEARING_BILLING_AMOUNT }],
  },
];

const merchantColumns = [
  {
    key: MERCHANT_DETAILS.name,
    fields: [{ ...MERCHANT_DETAILS }],
  },
];

const statusColumns = [
  {
    key: ACTION_COLUMNS.name,
    fields: [{ ...STATUS }],
  },
];

export const columns = [
  {
    label: 'Clearing data',
    style: {
      width: '40%',
    },
    subColumns: clearingsColumns,
  },
  {
    label: 'Billing',
    style: {
      width: '20%',
    },
    subColumns: billingColumns,
  },
  {
    sectionKey: TRANSACTIONS_CLEARING_SECTION_KEY,
    label: 'Clearing',
    style: {
      width: '10%',
    },
    subColumns: clearingColumns,
  },
  {
    label: 'Merchant',
    style: {
      width: '30%',
    },
    subColumns: merchantColumns,
  },
  {
    style: {
      width: '7%',
    },
    label: '',
    subColumns: statusColumns,
  },
];
