import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import { CUSTOM_FIELD_TYPES, FIELD_TYPES } from 'variables';
import { CUSTOM_FIELD_NAMES } from 'components/Transactions/constants';
import DateCell from 'components/DataGrid/components/DateCell';
import StatusBadgeCell from 'components/DataGrid/components/StatusBadgeCell';
import CompositeAmountCell from 'components/DataGrid/components/CompositeAmountCell';
import CopyableCell from 'components/CopyableCell';
import BodyCell from 'components/DataGrid/components/BodyCell';
import CurrencyConversionCell from '../components/CurrencyConversionCell';
import EntryModeCell from '../components/EntryModeCell';
import MerchantCategoryCell from '../components/MerchantCategoryCell';
import PopoverCell from '../components/PopoverCell';
import { StyledEmptyCell } from './cellStyled';
import MerchantDetailsCell from '../components/MerchantDetailsCell';

export const CellFactory = ({
  renderAs,
  value,
  rowData,
  dispatch,
  hasActionAccess,
  hasSectionAccess,
  popoverOptions,
  minorUnitsBalance,
  onMarkAsPotentialFraud,
  onCancelTransaction,
  onChargebackTransaction,
  onMarkBankTransactionFraud,
  onEditBankTransactionFraud,
  onUnmarkBankTransactionFraud,
  ...props
}) => {
  if (value == null) {
    return <StyledEmptyCell>—</StyledEmptyCell>;
  }

  let render = renderAs;
  if (typeof renderAs === 'function') {
    render = renderAs({ value, record: rowData });
  }

  switch (render) {
    case FIELD_TYPES.DATE_TIME:
      return <DateCell {...props} value={value} centered />;
    case CUSTOM_FIELD_TYPES.STATUS_BADGE:
      return <StatusBadgeCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT:
      return <CompositeAmountCell {...props} value={value} centered />;
    case CUSTOM_FIELD_TYPES.CURRENCY_CONVERSION:
      return (
        <CurrencyConversionCell
          {...props}
          value={value}
          rowData={rowData}
          centered
        />
      );
    case CUSTOM_FIELD_TYPES.ENTRY_MODE:
      return <EntryModeCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.MCC:
      return (
        <MerchantCategoryCell {...props} value={value} rowData={rowData} />
      );
    case CUSTOM_FIELD_TYPES.MCC_DESCRIPTION:
      return <MerchantDetailsCell {...props} value={value} rowData={rowData} />;
    case CUSTOM_FIELD_TYPES.POPOVER:
      return some(popoverOptions(rowData), ({ name, permissions }) => {
        if (name === CUSTOM_FIELD_NAMES.TRANSACTION_STATEMENT) {
          return hasSectionAccess(permissions);
        }

        return hasActionAccess(permissions);
      }) ? (
        <PopoverCell
          {...props}
          value={value}
          rowData={rowData}
          popoverOptions={popoverOptions(rowData)}
          hasActionAccess={hasActionAccess}
          hasSectionAccess={hasSectionAccess}
          balance={minorUnitsBalance}
          onMarkAsPotentialFraud={onMarkAsPotentialFraud}
          onCancelTransaction={onCancelTransaction}
          onChargebackTransaction={onChargebackTransaction}
          onMarkBankTransactionFraud={onMarkBankTransactionFraud}
          onEditBankTransactionFraud={onEditBankTransactionFraud}
          onUnmarkBankTransactionFraud={onUnmarkBankTransactionFraud}
        />
      ) : null;
    case CUSTOM_FIELD_TYPES.TEXT:
      return <BodyCell {...props} value={value} rowData={rowData} />;
    default:
      return <CopyableCell {...props} value={value} centered />;
  }
};

CellFactory.defaultProps = {
  renderAs: '',
  value: '',
  rowData: {},
  popoverOptions: () => {},
  onMarkAsPotentialFraud: () => {},
  onCancelTransaction: () => {},
  onChargebackTransaction: () => {},
  onMarkBankTransactionFraud: () => {},
  onEditBankTransactionFraud: () => {},
  onUnmarkBankTransactionFraud: () => {},
};

CellFactory.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  rowData: PropTypes.object,
  popoverOptions: PropTypes.func,
  minorUnitsBalance: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  onMarkAsPotentialFraud: PropTypes.func,
  onCancelTransaction: PropTypes.func,
  onChargebackTransaction: PropTypes.func,
  onMarkBankTransactionFraud: PropTypes.func,
  onEditBankTransactionFraud: PropTypes.func,
  onUnmarkBankTransactionFraud: PropTypes.func,
};
