import { DATE_PERIOD_FIVE_DAYS } from 'variables';
import {
  DATE_PERIOD_OPTIONS,
  CLEARINGS_GRID_FIRST_PAGE,
  CARD_TOKEN_FILTER,
  TRANSACTION_RRN_FILTER,
  TRANSACTION_AUTH_CODE_FILTER,
} from './constants';

export const initialState = {
  loading: true,
  error: null,
  size: 10,
  currentPage: CLEARINGS_GRID_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  unmatchedClearings: [],
  cardAuthorizations: [],
  filtersApplied: false,
  applyFiltersEnabled: false,
  datePeriod: DATE_PERIOD_OPTIONS[DATE_PERIOD_FIVE_DAYS],
  filters: {
    [CARD_TOKEN_FILTER]: '',
    [TRANSACTION_RRN_FILTER]: '',
    [TRANSACTION_AUTH_CODE_FILTER]: '',
  },
};
