const ONBOARDING_STATUS_BADGE_COLOR_MAP = {
  ONBOARDED: 'confirm',
  NOT_ONBOARDED: 'n200',
};

const VERIFICATION_STATUS_BADGE_COLOR_MAP = {
  PENDING: 'n200',
  IN_PROGRESS: 'n200',
  REJECTED: 'alert',
  VERIFIED: 'confirm',
};

const RISK_LEVEL_BADGE_COLOR_MAP = {
  LOW: 'confirm',
  MEDIUM: 'notify',
  HIGH: 'alert',
};

export const MERCHANT_ACCOUNT_STATUS_BADGE_COLOR_MAP = {
  ACTIVE: 'confirm',
  SUSPENDED: 'notify',
  BLOCKED: 'notify',
  CLOSED: 'alert',
};

export const NAV_BADGE_COLOR_MAP = {
  ...ONBOARDING_STATUS_BADGE_COLOR_MAP,
  ...VERIFICATION_STATUS_BADGE_COLOR_MAP,
  ...RISK_LEVEL_BADGE_COLOR_MAP,
  ...MERCHANT_ACCOUNT_STATUS_BADGE_COLOR_MAP,
  UNKNOWN: 'white',
};

export const ONBOARDING_STATUS_BADGE_LABEL_MAP = {
  ONBOARDED: 'Onboarded',
  NOT_ONBOARDED: 'Not onboarded',
  UNKNOWN: 'Unknown onboarding',
};

export const MERCHANT_ACCOUNT_STATUS_BADGE_LABEL_MAP = {
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
  BLOCKED: 'Blocked',
  CLOSED: 'Closed',
};

export const VERIFICATION_STATUS_BADGE_LABEL_MAP = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  REJECTED: 'Rejected',
  VERIFIED: 'Verified',
  UNKNOWN: 'Unknown',
};
