import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Headline } from '@sumup/circuit-ui';
import { ACTIONS, APPS, PAGE_TITLES } from 'variables';
import DateFilter from 'components/DateFilter';
import { useWindowDimensions } from 'hooks';
import Loading from 'components/Loading';
import TransactionsGrid from './components/TransactionsGrid';
import {
  TransactionsCount,
  Filters,
  StyledPagination,
} from './TransactionsStyled';
import TransactionOriginFilter, {
  TransactionOriginDropdownFilter,
} from './components/TransactionOriginFilter';
import FiltersPicker from './components/FiltersPicker';
import PageSizeDropdown from '../Account/components/PageSizeDropdown';
import Export from './components/Export';
import {
  DATE_PERIOD_OPTIONS,
  TRANSACTION_FILTERS_COMPACT_WINDOW_MAX_WIDTH,
} from './constants';
import TransactionDirectionFilter, {
  TransactionDirectionDropdownFilter,
} from './components/TransactionDirectionFilter';
import TransactionsRefresh from './components/TransactionsRefresh';

export const Transactions = ({
  loading,
  columns,
  transactions,
  currentPage,
  pageSize,
  totalPages,
  totalCount,
  datePeriod,
  txOriginFilter,
  txDirectionFilter,
  filters,
  filtersApplied,
  transactionStatuses,
  transactionTypes,
  sort,
  clientId,
  applyFiltersEnabled,
  onDateRangeChange,
  onTransactionOriginFilterChange,
  onTransactionDirectionFilterChange,
  onPageChange,
  onFilterChange,
  onFiltersApply,
  onFiltersClear,
  onPageSizeChange,
  onSort,
  dispatch,
  hasActionAccess,
  hasSectionAccess,
  onMarkAsPotentialFraud,
  onCancelTransaction,
  onChargebackTransaction,
  onMarkBankTransactionFraud,
  onEditBankTransactionFraud,
  onUnmarkBankTransactionFraud,
  onExport,
}) => {
  const [filtersCollapsed, setFiltersCollapsed] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > TRANSACTION_FILTERS_COMPACT_WINDOW_MAX_WIDTH) {
      setFiltersCollapsed(false);
    } else {
      setFiltersCollapsed(true);
    }
  }, [filtersCollapsed, width]);

  return (
    <>
      <Headline noMargin as="h2" size="two">
        {PAGE_TITLES.TRANSACTIONS.heading}
        {totalCount != null && (
          <TransactionsCount>({totalCount})</TransactionsCount>
        )}
        <TransactionsRefresh onFiltersApply={onFiltersApply} />
      </Headline>
      <Filters collapsed={filtersCollapsed}>
        {!filtersCollapsed && (
          <div>
            <TransactionOriginFilter
              filter={txOriginFilter}
              onChange={onTransactionOriginFilterChange}
            />
            <TransactionDirectionFilter
              filter={txDirectionFilter}
              onChange={onTransactionDirectionFilterChange}
            />
          </div>
        )}
        <div>
          {filtersCollapsed && (
            <>
              <TransactionOriginDropdownFilter
                origin={txOriginFilter}
                onChange={onTransactionOriginFilterChange}
              />
              <TransactionDirectionDropdownFilter
                direction={txDirectionFilter}
                onChange={onTransactionDirectionFilterChange}
              />
            </>
          )}
          <DateFilter
            datePeriod={datePeriod}
            onRangeChange={onDateRangeChange}
            datePeriodOptions={DATE_PERIOD_OPTIONS}
          />
          <PageSizeDropdown size={pageSize} onSizeChange={onPageSizeChange} />
          <FiltersPicker
            filters={filters}
            filtersApplied={filtersApplied}
            transactionStatuses={transactionStatuses}
            transactionTypes={transactionTypes}
            applyFiltersEnabled={applyFiltersEnabled}
            onFilterChange={onFilterChange}
            onFiltersApply={onFiltersApply}
            onFiltersClear={onFiltersClear}
          />
          {hasActionAccess({
            action: ACTIONS[APPS.merchant].accountcardTransactionsExport,
          }) && (
            <Export
              filtersApplied={filtersApplied}
              disabled={!transactions.length}
              collapsed={filtersCollapsed}
              onExport={onExport}
            />
          )}
        </div>
      </Filters>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TransactionsGrid
            columns={columns}
            transactions={transactions}
            sort={sort}
            filtersApplied={filtersApplied}
            clientId={clientId}
            onSort={onSort}
            dispatch={dispatch}
            hasActionAccess={hasActionAccess}
            hasSectionAccess={hasSectionAccess}
            onMarkAsPotentialFraud={onMarkAsPotentialFraud}
            onCancelTransaction={onCancelTransaction}
            onChargebackTransaction={onChargebackTransaction}
            onMarkBankTransactionFraud={onMarkBankTransactionFraud}
            onEditBankTransactionFraud={onEditBankTransactionFraud}
            onUnmarkBankTransactionFraud={onUnmarkBankTransactionFraud}
          />
          <StyledPagination
            label="Pagination"
            previousLabel="Previous page"
            nextLabel="Next page"
            totalPages={totalPages}
            totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

Transactions.defaultProps = {
  transactions: [],
  clientId: '',
  totalCount: null,
};

Transactions.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  transactions: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  datePeriod: PropTypes.object.isRequired,
  txOriginFilter: PropTypes.string.isRequired,
  txDirectionFilter: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
  transactionStatuses: PropTypes.array.isRequired,
  transactionTypes: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  clientId: PropTypes.string,
  applyFiltersEnabled: PropTypes.bool.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  onTransactionOriginFilterChange: PropTypes.func.isRequired,
  onTransactionDirectionFilterChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  onMarkAsPotentialFraud: PropTypes.func.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  onChargebackTransaction: PropTypes.func.isRequired,
  onMarkBankTransactionFraud: PropTypes.func.isRequired,
  onEditBankTransactionFraud: PropTypes.func.isRequired,
  onUnmarkBankTransactionFraud: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};
