import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Spinner, Body } from '@sumup/circuit-ui';
import { TOP_BAR_HEIGHT } from 'variables';

export const Section = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 320px;

    // Navigation height: around 110px
    // Page padding: 2 * 40px
    height: calc(100vh - ${110 + 40 * 2 + TOP_BAR_HEIGHT}px);
    margin-right: 20px;
    background: ${theme.colors.white};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.borderRadius.bit};
  `
);

export const InputWrapper = styled('div')(
  ({ theme, divider }) => css`
    padding: ${theme.spacings.kilo};
    ${divider &&
    `border-top: ${theme.borderWidth.kilo} solid ${theme.colors.n200};`}

    input {
      max-height: 32px;
    }
  `
);

export const ListItems = styled('div')`
  overflow: overlay;
`;

const activeListItemStyles = ({ theme }) => css`
  cursor: pointer;
  background: ${theme.colors.p500};

  p,
  strong {
    color: ${theme.colors.white};
  }
`;

export const ListItem = styled('div')(
  ({ theme, selected }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    border-top: ${theme.borderWidth.kilo} solid ${theme.colors.n200};

    :hover,
    :focus,
    :active {
      ${activeListItemStyles({ theme })};
    }

    ${selected && activeListItemStyles({ theme })};

    svg {
      margin-left: auto;
      color: ${theme.colors.n500};
    }
  `
);

export const ListItemInfo = styled(Body)(
  ({ theme, selected }) => css`
    color: ${selected ? theme.colors.white : theme.colors.n700};
  `
);

export const SpinnerStyled = styled(Spinner)(
  ({ theme }) => css`
    margin: ${theme.spacings.kilo} auto;
  `
);
