import moment from 'moment';
import { CUSTOM_FIELD_TYPES, DATE_FORMATS, FIELD_TYPES } from 'variables';
import {
  DD_STATUS_BADGE_MAP,
  DD_STATUS_CANCELLED,
  DD_STATUS_FAILED,
} from './constants';

const NARROW_CELL_MAX_WIDTH = '180px';

export const DATE = {
  label: 'Settlement date',
  name: 'settlement_date',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) => moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR),
  centered: true,
  sortable: true,
  style: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
};

const STATUS = {
  label: 'Status',
  name: 'status',
  renderAs: CUSTOM_FIELD_TYPES.STATUS_BADGE,
  getValue: ({ status }) => DD_STATUS_BADGE_MAP[status] || null,
  centered: true,
  style: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
  cellStyle: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
};

const REFERENCE = {
  label: 'Reference',
  name: 'reference',
  centered: true,
};

const CURRENCY = {
  name: 'currency',
};

const AMOUNT = {
  label: 'Amount',
  name: 'amount',
  renderAs: CUSTOM_FIELD_TYPES.AMOUNT,
  currencyField: CURRENCY,
  // Amount of 10 comes as 1000 from BE
  formatNumber: (number) => number / 100,
  getTextStyles: ({ status }) =>
    status === DD_STATUS_FAILED || status === DD_STATUS_CANCELLED
      ? {
          variant: 'subtle',
          style: {
            textDecoration: 'line-through',
          },
        }
      : {},
  centered: true,
  style: {
    maxWidth: NARROW_CELL_MAX_WIDTH,
  },
};

export const columns = [
  {
    ...DATE,
    active: true,
  },
  STATUS,
  REFERENCE,
  AMOUNT,
];

export const MANDATE_HISTORY_NON_NUMERIC_FIELDS_MAP = {
  [DATE.name]: true,
};
