import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Input, spacing } from '@sumup/circuit-ui';
import Modal from 'components/Modal';
import { MODALS } from 'variables';

const MAX_COMMENT_LENGTH = 500;

export const ToggleDirectDebitsModal = ({
  directDebitsEnabled,
  onClose,
  onConfirm,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [commentInvalid, setCommentInvalid] = useState(false);

  const comment = useRef(null);

  const setComment = (value) => {
    if (comment) {
      comment.current = value;
    }
  };

  useEffect(() => {
    setComment(null);
  }, []);

  const handleCommentChange = ({ target: { value } }) => {
    setComment(value);
  };

  const handleCommentFocus = () => {
    setCommentInvalid(false);
    setDisabled(false);
  };

  const handleCommentBlur = () => {
    const { current: value } = comment || {};
    const invalid = value && value.length > MAX_COMMENT_LENGTH;

    setCommentInvalid(invalid);
    setDisabled(invalid || !value);
  };

  const handleClose = (e) => {
    setComment(null);
    onClose(e);
  };

  const handleConfirm = () => {
    onConfirm({ comment: (comment || {}).current });
  };

  return (
    <Modal
      modalInfo={{
        disabled,
        ...(directDebitsEnabled
          ? {
              ...MODALS.DISABLE_DIRECT_DEBITS,
              content: (
                <>
                  <Body noMargin size="one" css={spacing({ top: 'kilo' })}>
                    The merchant will stop paying creditors by direct debit. All
                    current and future direct debit payments will be affected.
                    The merchant will have to make other arrangements with
                    creditors since the contract they have with them will not be
                    affected.
                  </Body>
                  <Input
                    placeholder="Comment (optional)"
                    label="Comment"
                    hideLabel
                    css={spacing({ top: 'giga', bottom: 'byte' })}
                    noMargin
                    invalid={commentInvalid}
                    validationHint={
                      commentInvalid &&
                      `The maximum length of ${MAX_COMMENT_LENGTH} characters has been exceeded.`
                    }
                    onChange={handleCommentChange}
                    onFocus={handleCommentFocus}
                    onBlur={handleCommentBlur}
                  />
                </>
              ),
            }
          : MODALS.ENABLE_DIRECT_DEBITS),
        onConfirm: handleConfirm,
      }}
      onClose={handleClose}
    />
  );
};

ToggleDirectDebitsModal.propTypes = {
  directDebitsEnabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
