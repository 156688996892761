import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledGrid = styled('div')`
  ${({ theme: { mq, colors, borderRadius } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    ${mq.untilKilo} {
      flex-direction: row;
    }

    background: ${colors.white};
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    border-radius: ${borderRadius.bit};
    width: 100%;
  `}
`;

const StyledRow = styled('div')`
  ${({
    theme: { spacings, colors, mq, borderWidth },
    disabled,
    modified,
    centered,
  }) => css`
    display: flex;
    flex-direction: row;
    min-height: 40px;
    overflow-wrap: break-word;
    color: ${colors.n900};
    ${modified && `background: ${colors.b100};`}
    ${disabled &&
    `
      &:not(:hover) {
        opacity: 0.5;
        cursor: default;
      }
    `}

    ${mq.untilKilo} {
      flex-direction: column;
    }

    > div {
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 1;
      flex-basis: 0;
      padding: ${spacings.kilo} ${spacings.byte};
      border-bottom: ${borderWidth.kilo} solid ${colors.n200};
      text-overflow: ellipsis;
      min-width: 0px;
      word-break: break-word;
      align-items: center;

      svg {
        min-width: ${spacings.mega};
      }

      ${mq.giga} {
        padding: ${spacings.kilo};

        ${!centered &&
        `
        &:first-of-type {
          padding-left: ${spacings.giga};
        }
      `}
      }
    }

    div {
      box-sizing: border-box;
    }
  `}
`;

export const StyledBodyRow = styled(StyledRow)`
  ${({ theme: { colors }, error }) => css`
    &:hover {
      background: ${colors.b100};
    }
    ${error &&
    `
      background: ${colors.r100};
      position: relative;
      &:hover {
        background: ${colors.r100};
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        background: ${colors.r500};
      }
    `}

    > div {
      :not(:last-of-type) {
        border-right: 1px solid ${colors.n200};
      }
    }
  `}
`;

export const StyledHeaderRow = styled(StyledRow)`
  ${({ theme: { spacings, colors, mq } }) => css`
    div {
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 1;
      flex-basis: 0;
      height: 40px;
      min-width: 0px;
      padding: ${spacings.kilo} ${spacings.byte};
      border-bottom: 2px solid ${colors.n200};
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      position: relative;
      align-items: center;
      color: ${colors.n700};

      svg {
        position: absolute;
        width: 20px;
        right: 0px;
      }

      ${mq.giga} {
        padding: ${spacings.kilo};
      }
    }
  `}
`;

export const clickableCellStyles = (theme) => css`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.colors.p500};
`;
