import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Body,
  Popover as CircuitPopover,
  spacing,
  useModal,
} from '@sumup/circuit-ui';
import { More } from '@sumup/icons';
import { ReactComponent as Ellipsis } from 'assets/ellipsis.svg';
import { ReactComponent as Statement } from 'assets/statement.svg';
import DownloadAccountStatementModal from 'components/DownloadStatementModal/DownloadAccountStatementModal';

const Popover = styled(CircuitPopover)(
  ({ theme }) => css`
    button {
      :focus {
        box-shadow: none;
      }
    }

    > div {
      margin: ${theme.spacings.bit} 0 0 -30px;
    }
  `
);

const PopoverOption = styled('div')`
  display: flex;
`;

const CircleButton = styled(More)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-top: ${theme.spacings.bit};
    padding: ${theme.spacings.bit};
    border: ${theme.borderWidth.kilo} solid ${theme.colors.n500};
    border-radius: ${theme.borderRadius.circle};
    cursor: pointer;
    transform: rotate(90deg);

    svg {
      path {
        fill: ${theme.colors.n700};
      }
    }
  `
);

const AccountPopover = ({ accountId }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { setModal } = useModal();

  const handleActionClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <DownloadAccountStatementModal
          accountId={accountId}
          onClose={onClose}
        />
      ),
    });
  };

  const actions = [
    {
      children: (
        <PopoverOption>
          <Statement />
          <Body
            noMargin
            size="one"
            key="download-statement"
            css={spacing({ left: 'mega' })}
          >
            Download statement
          </Body>
        </PopoverOption>
      ),
      onClick: handleActionClick,
    },
  ];

  const handleButtonClick = (e) => {
    if (e.preventDefault && e.stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }

    setPopoverOpen((opened) => !opened);
  };

  const component = () => (
    <CircleButton onClick={handleButtonClick}>
      <Ellipsis />
    </CircleButton>
  );

  const togglePopover = () => setPopoverOpen((opened) => !opened);

  return (
    <Popover
      placement="bottom-end"
      fallbackPlacements={['bottom']}
      isOpen={popoverOpen}
      actions={actions}
      component={component}
      onToggle={togglePopover}
    />
  );
};

AccountPopover.defaultProps = {
  accountId: '',
};

AccountPopover.propTypes = {
  accountId: PropTypes.string,
};

export default AccountPopover;
