import React, { useState } from 'react';
import { Headline, Pagination, useNotificationModal } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { isEmpty, filter } from 'lodash';
import {
  CLEARING_TYPE_MATCH,
  CLEARING_TYPE_OFFLINE_TRANSACTION,
} from 'variables';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DateFilter from 'components/DateFilter';
import { loadError } from 'actions';
import Loading from 'components/Loading';
import PageSizeDropdown from '../Account/components/PageSizeDropdown';
import FiltersPicker from './components/FiltersPicker';
import ClearingsGrid from './components/ClearingsGrid';
import {
  DATE_PERIOD_OPTIONS,
  UNMATCHED_CLEARINGS,
  AUTHORIZATIONS_COLUMN_WIDTH,
} from './constants';
import Authorizations from './components/Authorizations';

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  max-width: calc(100% - ${AUTHORIZATIONS_COLUMN_WIDTH});
`;

export const Filters = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

export const UnmatchedClearingsCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

const Clearings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledPagination = styled(Pagination)`
  padding-bottom: 0;
`;

export const MatchClearings = ({
  size,
  onPageSizeChange,
  datePeriod,
  onDateRangeChange,
  filters,
  filtersApplied,
  applyFiltersEnabled,
  onFilterChange,
  onFiltersApply,
  onFiltersClear,
  onGetCardAuthorizations,
  columns,
  unmatchedClearings,
  cardAuthorizations,
  onMatchAuthorization,
  dispatch,
  loading,
  totalPages,
  currentPage,
  onPageChange,
  authorizationsTotalPages,
  authorizationsPageNumber,
}) => {
  const [selectedClearing, setSelectedClearing] = useState(null);
  const [selectedAuthorization, setSelectedAuthorization] = useState(null);
  const [matchedClearings, setMatchedClearings] = useState({});
  const [authorizationsLoading, setAuthorizationsLoading] = useState(false);

  const { setModal } = useNotificationModal();

  const handleClickAuthorization = ({ id }) => setSelectedAuthorization(id);

  const handleClickClearing = ({ unmatchedClearing, row }) => {
    setAuthorizationsLoading(true);
    setSelectedAuthorization(null);
    setSelectedClearing(row);
    onGetCardAuthorizations({
      cardToken: unmatchedClearing.cardIdNumber,
      unmatchedClearingDate: unmatchedClearing.transactionDate,
    }).then(() => setAuthorizationsLoading(false));
  };

  const handleSubmitMatching = ({ offline = false }) => {
    const payload = {
      authorizationId: offline ? undefined : selectedAuthorization,
      clearingIds: [selectedClearing.id],
      type: offline ? CLEARING_TYPE_OFFLINE_TRANSACTION : CLEARING_TYPE_MATCH,
    };

    onMatchAuthorization(payload)
      .then(() => {
        const { id: selectedClearingId } = selectedClearing;
        const [matchedItem] = filter(
          unmatchedClearings,
          ({ id: matchedClearingId }) =>
            matchedClearingId === selectedClearingId
        );
        const matchedClearingIndex = unmatchedClearings.indexOf(matchedItem);
        const nextClearing = unmatchedClearings[matchedClearingIndex + 1];
        if (nextClearing) {
          handleClickClearing({
            unmatchedClearing: nextClearing,
            row: nextClearing,
          });
        }

        setMatchedClearings({
          ...matchedClearings,
          [selectedClearingId]: selectedClearingId,
        });
      })
      .catch((error) =>
        error?.response?.data?.message
          ? setModal({
              headline: offline
                ? "Can't insert an offline transaction"
                : "Can't match manually",
              body: error.response.data.message,
              actions: {
                primary: {
                  children: 'OK',
                },
              },
            })
          : dispatch(loadError())
      );
  };

  return (
    <>
      <Header>
        <Headline noMargin as="h2" size="two">
          {UNMATCHED_CLEARINGS}
          {!isEmpty(unmatchedClearings) && (
            <UnmatchedClearingsCount>
              ({unmatchedClearings.length})
            </UnmatchedClearingsCount>
          )}
        </Headline>
        {loading && <Loading />}
        <Filters>
          <DateFilter
            datePeriod={datePeriod}
            onRangeChange={onDateRangeChange}
            datePeriodOptions={DATE_PERIOD_OPTIONS}
          />
          <PageSizeDropdown size={size} onSizeChange={onPageSizeChange} />
          <FiltersPicker
            filters={filters}
            filtersApplied={filtersApplied}
            applyFiltersEnabled={applyFiltersEnabled}
            onFilterChange={onFilterChange}
            onFiltersApply={onFiltersApply}
            onFiltersClear={onFiltersClear}
          />
        </Filters>
      </Header>
      <Clearings>
        <ClearingsGrid
          columns={columns}
          unmatchedClearings={unmatchedClearings}
          filtersApplied={filtersApplied}
          cardAuthorizations={cardAuthorizations}
          onMatchAuthorization={onMatchAuthorization}
          dispatch={dispatch}
          onClickClearing={handleClickClearing}
          selectedClearing={selectedClearing}
          loading={loading}
          matchedClearings={matchedClearings}
        />
        <Authorizations
          cardAuthorizations={cardAuthorizations}
          selectedAuthorization={selectedAuthorization}
          onClickAuthorization={handleClickAuthorization}
          onSubmitMatching={handleSubmitMatching}
          authorizationsLoading={authorizationsLoading}
          selectedClearing={selectedClearing}
          authorizationsTotalPages={authorizationsTotalPages}
          authorizationsPageNumber={authorizationsPageNumber}
          onGetCardAuthorizations={onGetCardAuthorizations}
        />
      </Clearings>
      <StyledPagination
        label="Pagination"
        previousLabel="Previous page"
        nextLabel="Next page"
        totalPages={totalPages}
        totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </>
  );
};

MatchClearings.defaultProps = {
  authorizationsPageNumber: 1,
  authorizationsTotalPages: 0,
};

MatchClearings.propTypes = {
  size: PropTypes.number.isRequired,
  datePeriod: PropTypes.object.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
  applyFiltersEnabled: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  onGetCardAuthorizations: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  unmatchedClearings: PropTypes.array.isRequired,
  cardAuthorizations: PropTypes.array.isRequired,
  onMatchAuthorization: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  authorizationsTotalPages: PropTypes.number,
  authorizationsPageNumber: PropTypes.number,
};
