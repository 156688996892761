import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { filter, find, slice } from 'lodash';
import { PAGE_TITLES as TITLES, PATHS } from 'variables';
import { Bank } from '@sumup/icons';
import Search from 'components/Search';
import AuthContext from 'context/auth';
import {
  hasAdministrationAccess,
  hasMassActionsAccess,
  hasMerchantAccess,
  hasReportsAccess,
  hasSectionAccess,
  hasReconciliationAccess,
  hasOpsTransfersAccess,
} from 'services/permissions';
import { SECTIONS } from 'components/UserAdministration/constants';
import Welcome from 'components/Welcome';
import { ReactComponent as AdministrationIcon } from 'assets/administration.svg';
import { ReactComponent as ReportsIcon } from 'assets/file.svg';
import { ReactComponent as MassActionsIcon } from 'assets/mass-actions.svg';
import { ReactComponent as ReconciliationIcon } from '../../assets/reconciliation.svg';
import { ReactComponent as OperationalTransfersIcon } from '../../assets/operational-transfers.svg';
import {
  IconWrapper,
  Applications,
  StyledArrowRight,
  Wrapper,
  ApplicationHeadingWrapper,
} from './StyledAdmin';

const WELCOME_SECTION_PADDING = '0px';
const WELCOME_SECTION_MARGIN = '40px';
const SEARCH_STYLES = {
  marginTop: 40,
};

const ApplicationsWrapper = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: 372px;
    align-content: center;
    z-index: -1;

    > div:not(:last-of-type) {
      margin-bottom: ${theme.spacings.mega};
    }
  `
);

const Admin = () => {
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const firstVisibleSection =
    find(SECTIONS, ({ permissions: { app, section } }) =>
      hasSectionAccess({ permissions, app, section })
    ) || {};

  const applications = filter(
    [
      hasReconciliationAccess(permissions) ? (
        <Link
          to={`/${PATHS.reconciliation}/${PATHS.match_clearings}`}
          key="recon-app"
        >
          <Card>
            <IconWrapper>
              <ReconciliationIcon />
            </IconWrapper>
            <ApplicationHeadingWrapper>
              <Headline noMargin as="h2" size="three">
                {TITLES.RECONCILIATION.heading}
              </Headline>
            </ApplicationHeadingWrapper>
            <StyledArrowRight size="16" />
          </Card>
        </Link>
      ) : null,
      hasReportsAccess(permissions) ? (
        <Link to={`${PATHS.mc_report}/${PATHS.fraud}`} key="reports-app">
          <Card>
            <IconWrapper>
              <ReportsIcon />
            </IconWrapper>
            <ApplicationHeadingWrapper>
              <Headline noMargin as="h2" size="three">
                {TITLES.REPORTS.heading}
              </Headline>
            </ApplicationHeadingWrapper>
            <StyledArrowRight size="16" />
          </Card>
        </Link>
      ) : null,
      hasAdministrationAccess(permissions) ? (
        <Link
          to={`/${PATHS.user_administration}/${firstVisibleSection.link}`}
          key="administration-app"
        >
          <Card>
            <IconWrapper>
              <AdministrationIcon />
            </IconWrapper>
            <ApplicationHeadingWrapper>
              <Headline noMargin as="h2" size="three">
                {TITLES.USER_ADMINISTRATION.heading}
              </Headline>
            </ApplicationHeadingWrapper>
            <StyledArrowRight size="16" />
          </Card>
        </Link>
      ) : null,
      hasMassActionsAccess(permissions) ? (
        <Link
          to={`/${PATHS.mass_actions}/${PATHS.actions}`}
          key="mass-actions-app"
        >
          <Card>
            <IconWrapper>
              <MassActionsIcon />
            </IconWrapper>
            <ApplicationHeadingWrapper>
              <Headline noMargin as="h2" size="three">
                {TITLES.MASS_ACTIONS.heading}
              </Headline>
            </ApplicationHeadingWrapper>
            <StyledArrowRight size="16" />
          </Card>
        </Link>
      ) : null,
      hasOpsTransfersAccess(permissions) ? (
        <Link
          to={`/${PATHS.ops_transfers}/${PATHS.requests}`}
          key="ops-transfers-app"
        >
          <Card>
            <IconWrapper>
              <OperationalTransfersIcon />
            </IconWrapper>
            <ApplicationHeadingWrapper>
              <Headline noMargin as="h2" size="three">
                {TITLES.OPS_TRANSFERS.heading}
              </Headline>
            </ApplicationHeadingWrapper>
            <StyledArrowRight size="16" />
          </Card>
        </Link>
      ) : null,
      <Link to={`/${PATHS.bundling}/${PATHS.batch}`} key="nba-bundling">
        <Card>
          <IconWrapper>
            <Bank color="white" />
          </IconWrapper>
          <ApplicationHeadingWrapper>
            <Headline noMargin as="h2" size="three">
              {TITLES.NBA_BUNDLING.heading}
            </Headline>
          </ApplicationHeadingWrapper>
          <StyledArrowRight size="16" />
        </Card>
      </Link>,
    ],
    (application) => application != null
  );

  return (
    <Wrapper>
      <Welcome
        padding={WELCOME_SECTION_PADDING}
        margin={WELCOME_SECTION_MARGIN}
      />
      {hasMerchantAccess(permissions) && <Search styles={SEARCH_STYLES} />}
      <ApplicationsWrapper>
        <Applications>{slice(applications, 0, 3)}</Applications>
        <Applications>{slice(applications, 3)}</Applications>
      </ApplicationsWrapper>
    </Wrapper>
  );
};

export default Admin;
