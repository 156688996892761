import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Body,
  Popover as CircuitPopover,
  useModal,
  Hr,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { More } from '@sumup/icons';
import { useHttpClient } from 'hooks';
import { map, size } from 'lodash';
import { ReactComponent as Ellipsis } from 'assets/ellipsis.svg';
import {
  CANCEL_REQUEST_OPTION_KEY,
  REJECT_REQUEST_OPTION_KEY,
  SENDER_CLIENT_CODE,
  SOURCE,
} from 'components/OpsTransfers/fieldsConfig';

const MORE_BUTTON_HEIGHT = 32;

const TRANSFER_REJECTION_KEYS = {
  [CANCEL_REQUEST_OPTION_KEY]: true,
  [REJECT_REQUEST_OPTION_KEY]: true,
};

const ComponentWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CircleButton = styled(More)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${MORE_BUTTON_HEIGHT}px;
    width: ${MORE_BUTTON_HEIGHT}px;
    padding: 6px;
    margin-top: ${theme.spacings.bit};
    border: ${theme.borderWidth.kilo} solid ${theme.colors.n500};
    border-radius: ${theme.borderRadius.circle};
    cursor: pointer;
  `
);

const PopoverOption = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  svg {
    max-width: 24px;
    margin-right: 18px;
  }

  p {
    display: flex;
    align-items: center;
  }
`;

const POPOVER_WIDTH = 260;

const DIVIDER_WIDTH = 228;

const Popover = styled(CircuitPopover)`
  button {
    width: ${POPOVER_WIDTH}px;

    :focus {
      box-shadow: none;
    }
  }

  > div {
    margin: 6px 0 0 -230px;
  }
`;

const StyledHr = styled(Hr)(
  ({ theme }) => css`
    width: ${DIVIDER_WIDTH}px;
    margin-bottom: -${theme.spacings.kilo};
  `
);

export const PopoverCell = ({
  rowData,
  popoverOptions,
  updateOpsTransfers,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const httpClient = useHttpClient();

  const { setModal } = useModal();

  const handlePopoverOptionClick = ({ getModal }) => {
    const clientId = (rowData[SOURCE.name] || {})[SENDER_CLIENT_CODE.name];

    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      children: ({ onClose }) =>
        getModal &&
        getModal({
          transfer: rowData,
          clientId,
          updateOpsTransfers,
          onClose,
        }),
    });
  };

  const togglePopover = () => setPopoverOpen((opened) => !opened);

  const component = (props) => (
    <ComponentWrapper>
      <CircleButton onClick={togglePopover} {...props}>
        <Ellipsis />
      </CircleButton>
    </ComponentWrapper>
  );

  const getActions = () =>
    map(popoverOptions, ({ key, label, title, action, ...option }, index) => {
      const hasMultipleOptions = size(popoverOptions) > 1;
      const showDivider =
        TRANSFER_REJECTION_KEYS[(popoverOptions[index + 1] || {}).key] &&
        hasMultipleOptions;

      return {
        children: (
          <PopoverOption key={label}>
            <Body noMargin size="one">
              {title}
            </Body>
            {showDivider && <StyledHr />}
          </PopoverOption>
        ),
        onClick: () => {
          if (option.onClick) {
            return option.onClick(httpClient, rowData);
          }

          return handlePopoverOptionClick(option);
        },
      };
    });

  return (
    <Popover
      placement="bottom"
      fallbackPlacements={['bottom']}
      isOpen={popoverOpen}
      actions={getActions()}
      component={component}
      onToggle={togglePopover}
    />
  );
};

PopoverCell.defaultProps = {
  rowData: {},
};

PopoverCell.propTypes = {
  popoverOptions: PropTypes.array.isRequired,
  rowData: PropTypes.object,
  updateOpsTransfers: PropTypes.func.isRequired,
};
