import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Pagination } from '@sumup/circuit-ui';
import { PAGE_TITLES } from 'variables';
import Loading from 'components/Loading';
import PageSizeDropdown from 'components/Account/components/PageSizeDropdown';
import OpsTransfersGrid from './OpsTransfersGrid';
import RadioButtonFilter from '../Account/components/RadioButtonFilter';
import { CURRENCY_FILTER_OPTIONS, STATUS_FILTER_OPTIONS } from './constants';

const TransfersCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

const StyledPagination = styled(Pagination)`
  padding-bottom: 0;
`;

const Filters = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacings.mega};
    height: 32px;

    * {
      max-height: 32px;
    }

    > div:not(:first-of-type) {
      margin-left: ${theme.spacings.byte};
    }

    > div:last-of-type {
      margin-left: auto;
    }
  `
);

export const OpsTransfers = ({
  loading,
  columns,
  opsTransfers,
  currentPage,
  totalPages,
  totalCount,
  pageSize,
  sort,
  user,
  statusFilter,
  currencyFilter,
  filtersApplied,
  dispatch,
  hasActionAccess,
  hasSectionAccess,
  updateOpsTransfers,
  onPageChange,
  onPageSizeChange,
  onSort,
  onStatusFilterChange,
  onCurrencyFilterChange,
}) => {
  return (
    <>
      <Headline noMargin as="h2" size="two">
        {PAGE_TITLES.OPS_TRANSFERS.requestsPage}
        {totalCount != null && <TransfersCount>({totalCount})</TransfersCount>}
      </Headline>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Filters>
            <RadioButtonFilter
              selectedValue={statusFilter}
              label="Display:"
              options={STATUS_FILTER_OPTIONS}
              onChange={onStatusFilterChange}
            />
            <RadioButtonFilter
              selectedValue={currencyFilter}
              label="Currency:"
              options={CURRENCY_FILTER_OPTIONS}
              onChange={onCurrencyFilterChange}
            />
            <PageSizeDropdown size={pageSize} onSizeChange={onPageSizeChange} />
          </Filters>
          <OpsTransfersGrid
            columns={columns}
            opsTransfers={opsTransfers}
            sort={sort}
            user={user}
            filtersApplied={filtersApplied}
            onSort={onSort}
            dispatch={dispatch}
            hasActionAccess={hasActionAccess}
            hasSectionAccess={hasSectionAccess}
            updateOpsTransfers={updateOpsTransfers}
          />
          <StyledPagination
            label="Pagination"
            previousLabel="Previous page"
            nextLabel="Next page"
            totalPages={totalPages}
            totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

OpsTransfers.defaultProps = {
  opsTransfers: [],
  totalCount: null,
};

OpsTransfers.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  opsTransfers: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  sort: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
  currencyFilter: PropTypes.string.isRequired,
  statusFilter: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  updateOpsTransfers: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  onCurrencyFilterChange: PropTypes.func.isRequired,
};
