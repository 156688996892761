import React, { useCallback, useContext, useRef, useState } from 'react';
import AuthContext from 'context/auth';
import ClientDataContext from 'context/clientData';
import { hasActionAccess, hasSectionAccess } from 'services/permissions';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import moment from 'moment';
import { getScheduleTransactionHistory, getSchedulesByType } from 'api';
import Error from 'components/Error';
import { loadError, loadSuccess, setLoading } from 'actions';
import { APPS, DATE_FORMATS } from 'variables';
import { scheduledPaymentsReducer } from './scheduledPaymentsReducer';
import { initialState } from './state';
import ScheduledPayments from './ScheduledPayments';
import {
  SCHEDULE_STATUS_CANCELLED,
  SORT_BY_MAP,
  SORT_DIRECTION_MAP,
  STATUS_FILTER_MAP,
  TYPE_FILTER_MAP,
} from './constants';
import {
  clearScheduleTransactionHistory,
  loadScheduleTransactionHistorySuccess,
  setPage,
  setPageSize,
  setScheduleHistoryPage,
  setScheduleHistoryPageSize,
  setScheduleTransactionHistoryLoading,
  setSelectedScheduledPayment,
  setStatusFilter,
  setTypeFilter,
  sortScheduleHistory,
} from './scheduledPaymentsActions';
import { columns } from './columns';
import ScheduleDetails from './components/ScheduleDetails';

const ScheduledPaymentsData = () => {
  const [selectedScheduleCancelled, setSelectedScheduleCancelled] =
    useState(false);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const { bank_account_identifier: accountId } = clientData || {};

  const scrollPosition = useRef('');

  const setScrollPosition = (value) => {
    scrollPosition.current = value;
  };

  const httpClient = useHttpClient();

  const sortBy = initialState.sort.fieldName;

  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getSchedulesByType(httpClient, {
          accountId,
          page: initialState.currentPage,
          size: initialState.pageSize,
          statuses: STATUS_FILTER_MAP[initialState.statusFilter],
          types: TYPE_FILTER_MAP[initialState.typeFilter],
          sortBy,
          sortDirection: initialState.sort[sortBy],
        }),
      [httpClient, sortBy, accountId]
    ),
    null,
    scheduledPaymentsReducer,
    {
      ...initialState,
      pageSize: initialState.pageSize,
    }
  );

  const {
    loading,
    error,
    scheduledPayments,
    totalPages,
    totalCount,
    pageSize,
    currentPage,
    sort = {},
    statusFilter,
    typeFilter,
    selectedScheduledPayment,
    transactionHistory,
    transactionHistoryLoadingForId,
  } = state;

  const loadScheduleHistory = ({
    scheduledPaymentId,
    sort: historySort = {},
    page,
    size,
    successHandler,
    errorHandler,
  }) => {
    const sortField = historySort?.name || transactionHistory.sort.fieldName;
    const sortDirection =
      historySort?.order || transactionHistory.sort[sortField];

    return getScheduleTransactionHistory(httpClient, {
      id: scheduledPaymentId,
      page: page || transactionHistory.currentPage,
      size: size || transactionHistory.pageSize,
      sortBy: sortField,
      sortDirection,
    })
      .then((data) => {
        dispatch(loadScheduleTransactionHistorySuccess(data));

        if (successHandler) {
          successHandler();
        }
      })
      .catch(() => {
        if (errorHandler) {
          errorHandler();
        }
      });
  };

  const hasActionAccessWrapped = ({ app = APPS.ops_transfers, action }) =>
    hasActionAccess({ permissions, app, action });

  const hasSectionAccessWrapped = ({ app = APPS.ops_transfers, section }) =>
    hasSectionAccess({ permissions, app, section });

  const updateScheduledPayments = (args, successHandler) => {
    dispatch(setLoading(true));

    return getSchedulesByType(httpClient, args)
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          return successHandler();
        }

        return Promise.resolve();
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const reloadScheduledPayments = () =>
    updateScheduledPayments({
      accountId,
      page: currentPage,
      size: pageSize,
      statuses: STATUS_FILTER_MAP[statusFilter],
      types: TYPE_FILTER_MAP[typeFilter],
      sortBy: SORT_BY_MAP[statusFilter],
      sortDirection: SORT_DIRECTION_MAP[statusFilter],
    });

  const handlePageChange = (page) => {
    updateScheduledPayments(
      {
        page,
        accountId,
        size: pageSize,
        statuses: STATUS_FILTER_MAP[statusFilter],
        types: TYPE_FILTER_MAP[typeFilter],
        sortBy: SORT_BY_MAP[statusFilter],
        sortDirection: SORT_DIRECTION_MAP[statusFilter],
      },
      () => {
        dispatch(setPage({ page }));
        setScrollPosition(0);
      }
    );
  };

  const handlePageSizeChange = (size) => {
    const { currentPage: page } = initialState;

    updateScheduledPayments(
      {
        size,
        page,
        accountId,
        statuses: STATUS_FILTER_MAP[statusFilter],
        types: TYPE_FILTER_MAP[typeFilter],
        sortBy: SORT_BY_MAP[statusFilter],
        sortDirection: SORT_DIRECTION_MAP[statusFilter],
      },
      () => {
        dispatch(setPageSize({ size }));
        setScrollPosition(0);
      }
    );
  };

  const handleStatusFilterChange = (status) => {
    const { currentPage: page } = initialState;

    updateScheduledPayments(
      {
        size: pageSize,
        page,
        accountId,
        statuses: STATUS_FILTER_MAP[status],
        types: TYPE_FILTER_MAP[typeFilter],
        sortBy: SORT_BY_MAP[status],
        sortDirection: SORT_DIRECTION_MAP[status],
      },
      () => dispatch(setStatusFilter({ status }))
    );
  };

  const handleTypeFilterChange = (type) => {
    const { currentPage: page } = initialState;

    updateScheduledPayments(
      {
        size: pageSize,
        page,
        accountId,
        statuses: STATUS_FILTER_MAP[statusFilter],
        types: TYPE_FILTER_MAP[type],
        sortBy: SORT_BY_MAP[statusFilter],
        sortDirection: SORT_DIRECTION_MAP[statusFilter],
      },
      () => dispatch(setTypeFilter({ type }))
    );
  };

  const handleAllClick = () => {
    dispatch(setSelectedScheduledPayment(null));
    dispatch(clearScheduleTransactionHistory());

    if (selectedScheduleCancelled) {
      setSelectedScheduleCancelled(false);
      reloadScheduledPayments();
    }
  };

  const handleCancelScheduleConfirm = () => {
    setSelectedScheduleCancelled(true);
    dispatch(
      setSelectedScheduledPayment({
        ...selectedScheduledPayment,
        schedule: {
          ...selectedScheduledPayment?.schedule,
          status: SCHEDULE_STATUS_CANCELLED,
          cancelled_at: moment().format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR),
        },
      })
    );
  };

  const handleViewDetailsClick = (scheduledPayment) => {
    dispatch(setScheduleTransactionHistoryLoading(scheduledPayment?.id));

    const successHandler = () =>
      dispatch(setSelectedScheduledPayment(scheduledPayment));

    const errorHandler = () => {
      dispatch(setSelectedScheduledPayment(scheduledPayment));
      dispatch(setScheduleTransactionHistoryLoading(null));
    };

    loadScheduleHistory({
      scheduledPaymentId: scheduledPayment.id,
      successHandler,
      errorHandler,
    });
  };

  const handleHistorySort = ({ name, order }) => {
    const successHandler = () => dispatch(sortScheduleHistory({ name, order }));

    loadScheduleHistory({
      scheduledPaymentId: selectedScheduledPayment.id,
      sort: { name, order },
      successHandler,
    });
  };

  const handleHistoryPageChange = (page) => {
    const successHandler = () => dispatch(setScheduleHistoryPage(page));

    loadScheduleHistory({
      scheduledPaymentId: selectedScheduledPayment.id,
      page,
      successHandler,
    });
  };

  const handleHistoryPageSizeChange = (size) => {
    const { currentPage: page } = initialState;

    const successHandler = () => dispatch(setScheduleHistoryPageSize(size));

    loadScheduleHistory({
      scheduledPaymentId: selectedScheduledPayment.id,
      page,
      size,
      successHandler,
    });
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      {selectedScheduledPayment ? (
        <ScheduleDetails
          scheduledPayment={selectedScheduledPayment}
          transactionHistory={transactionHistory}
          onCancelPaymentConfirm={reloadScheduledPayments}
          onCancelScheduleConfirm={handleCancelScheduleConfirm}
          onAllClick={handleAllClick}
          onHistorySort={handleHistorySort}
          onHistoryPageChange={handleHistoryPageChange}
          onHistoryPageSizeChange={handleHistoryPageSizeChange}
        />
      ) : (
        <ScheduledPayments
          loading={loading}
          columns={columns}
          scheduledPayments={scheduledPayments}
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          pageSize={pageSize}
          sort={sort}
          statusFilter={statusFilter}
          typeFilter={typeFilter}
          transactionHistoryLoadingForId={transactionHistoryLoadingForId}
          dispatch={dispatch}
          hasActionAccess={hasActionAccessWrapped}
          hasSectionAccess={hasSectionAccessWrapped}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onStatusFilterChange={handleStatusFilterChange}
          onTypeFilterChange={handleTypeFilterChange}
          reloadScheduledPayments={reloadScheduledPayments}
          onViewDetailsClick={handleViewDetailsClick}
        />
      )}
    </>
  );
};

export default ScheduledPaymentsData;
