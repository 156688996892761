import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH } from 'variables';

export const TooltipPivot = styled('div')(
  ({ theme: { spacings } }) => css`
    display: flex;
    justify-content: center;

    svg {
      align-self: center;
      margin: 0 ${spacings.bit} 0 0;
    }
  `
);

export const TooltipContent = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: ${TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH}px;
  text-align: left;
`;

export const StyledCell = styled('div')(
  ({ centered }) => css`
    ${centered && 'text-align: center;'};
  `
);
