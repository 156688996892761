import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, spacing } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { size } from 'lodash';
import Modal from 'components/Modal';
import { ACCOUNT_STATUS_MAP, MODALS } from 'variables';
import Status from 'components/Status';

const StyledStatus = styled(Status)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacings.byte};
  `
);

const MAX_COMMENT_LENGTH = 500;

export const AccountStatusModal = ({ status, onClose, onConfirm }) => {
  const [disabled, setDisabled] = useState(true);
  const [commentInvalid, setCommentInvalid] = useState(false);

  const comment = useRef(null);

  const setComment = (value) => {
    if (comment) {
      comment.current = value;
    }
  };

  useEffect(() => {
    setComment(null);
  }, []);

  const handleCommentChange = ({ target: { value } }) => {
    setComment(value);
    setDisabled(!value);
  };

  const handleCommentFocus = () => {
    const { current: value } = comment || {};

    setCommentInvalid(false);
    setDisabled(!value);
  };

  const handleCommentBlur = () => {
    const { current: value } = comment || {};
    const invalid = size(value) > MAX_COMMENT_LENGTH;

    setCommentInvalid(invalid);
    setDisabled(invalid || !value);
  };

  const handleClose = (e) => {
    setComment(null);
    onClose(e);
  };

  const modalInfo =
    status === ACCOUNT_STATUS_MAP.CLOSED
      ? MODALS.CLOSE_BUSINESS_ACCOUNT
      : MODALS.CHANGE_ACCOUNT_STATUS;

  return (
    <Modal
      modalInfo={{
        ...modalInfo,
        disabled,
        content: (
          <>
            {modalInfo.content || (
              <div>
                The status of the BA will be changed to:
                <StyledStatus value={status} />
              </div>
            )}
            <Input
              placeholder="Comment"
              label="Comment"
              hideLabel
              css={spacing({ top: 'giga' })}
              noMargin
              invalid={commentInvalid}
              validationHint={
                commentInvalid &&
                'The maximum length of 500 characters has been exceeded.'
              }
              onChange={handleCommentChange}
              onFocus={handleCommentFocus}
              onBlur={handleCommentBlur}
            />
          </>
        ),
        onConfirm: () =>
          onConfirm({ status, comment: (comment || {}).current }),
      }}
      onClose={handleClose}
    />
  );
};

AccountStatusModal.propTypes = {
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
