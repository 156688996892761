import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { UnmarkFraudReportModalContent } from './UnmarkFraudReportModalContent';

const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const HeadLineStyled = styled(Headline)`
  text-align: center;
`;

const Body = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.spacings.giga};
    margin-bottom: ${theme.spacings.giga};
  `
);

const ButtonGroupStyled = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
`;

const UnmarkFraudReportModal = ({
  reportId,
  lastModified,
  onConfirm,
  onClose,
}) => {
  const [comment, setComment] = useState(false);

  const handleCommentGenerate = ({ target: { value } }) => {
    setComment(value);
  };

  const onConfirmHandler = () => {
    onConfirm({
      reportId,
      comment,
      lastModified,
    });
  };

  const handleClick = (e) => {
    onConfirmHandler();
    onClose(e);
  };

  return (
    <ModalWrapper>
      <HeadLineStyled as="h1" size="three" noMargin>
        Confirm you would like to unmark this transaction as fraud:
      </HeadLineStyled>
      <Body noMargin>
        <UnmarkFraudReportModalContent
          onCommentGenerate={handleCommentGenerate}
        />
      </Body>
      <ButtonGroupStyled
        actions={{
          primary: {
            disabled: !comment,
            onClick: handleClick,
            children: 'Unmark fraud',
          },
          secondary: {
            onClick: onClose,
            children: 'Cancel',
          },
        }}
        align="right"
      />
    </ModalWrapper>
  );
};

UnmarkFraudReportModal.propTypes = {
  reportId: PropTypes.string.isRequired,
  lastModified: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UnmarkFraudReportModal;
