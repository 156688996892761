import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import { MarkAsFraudModalContent } from './MarkAsFraudModalContent';

const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 16px;

  & > div {
    display: flex;
    align-items: center;
  }
`;

const Header = styled('div')`
  max-width: 90%;
`;

const Body = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${theme.spacings.giga};
  `
);

const Footer = styled('div')`
  display: flex;
  justify-content: center;
`;

const MarkAsFraudModal = ({ onConfirm, onClose }) => {
  const [confirmationView, setConfirmationView] = useState(false);
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);

  const fraudTypeCodeRef = useRef({});
  const fraudSubtypeCodeRef = useRef({});

  const handleFraudTypeChange = ({ target: { value } }) => {
    fraudTypeCodeRef.current = value;

    setConfirmButtonEnabled(value && !isEmpty(fraudSubtypeCodeRef.current));
  };

  const handleFraudSubtypeChange = ({ target: { value } }) => {
    fraudSubtypeCodeRef.current = value;

    setConfirmButtonEnabled(value && !isEmpty(fraudTypeCodeRef.current));
  };

  return (
    <ModalWrapper>
      <Header>
        <Headline as="h1" size="three" noMargin>
          {confirmationView
            ? 'Confirm you would like to mark this transaction as potential fraud:'
            : 'Report confirmed fraud'}
        </Headline>
      </Header>
      <Body noMargin>
        {confirmationView ? null : (
          <MarkAsFraudModalContent
            onFraudTypeChange={handleFraudTypeChange}
            onFraudSubtypeChange={handleFraudSubtypeChange}
          />
        )}
      </Body>
      <Footer>
        <ButtonGroup
          actions={{
            primary: {
              destructive: true,
              disabled: !confirmButtonEnabled,
              onClick: (e) => {
                if (confirmationView) {
                  onConfirm({
                    fraudTypeCode: fraudTypeCodeRef.current,
                    fraudSubtypeCode: fraudSubtypeCodeRef.current,
                  });

                  onClose(e);
                } else {
                  setConfirmationView(true);
                }
              },
              children: confirmationView
                ? 'Report confirmed fraud'
                : 'Continue',
            },
            secondary: {
              onClick: onClose,
              children: 'Cancel',
            },
          }}
        />
      </Footer>
    </ModalWrapper>
  );
};

MarkAsFraudModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MarkAsFraudModal;
