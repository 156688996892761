import { actionTypes, ERROR_MESSAGE } from 'variables';

export const devicesListReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: { client_settings: devices },
        },
      } = action;

      return {
        ...state,
        loading: false,
        devices,
      };
    }

    case actionTypes.SET_SCA_DEVICES: {
      const {
        payload: { devices },
      } = action;

      return {
        ...state,
        loading: false,
        devices,
      };
    }

    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    default:
      return state;
  }
};
