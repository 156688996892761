import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Info, Close } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import { DATA_SELECTORS } from 'variables';
import { StyledError } from '../../StyledSearch';

const SearchError = ({ error, query, onClear }) => (
  <StyledError
    error={error}
    warning={query}
    data-testid={DATA_SELECTORS.ERROR_CONTAINER}
  >
    {error && (
      <>
        <Alert size="16" />
        <Body noMargin variant="highlight" size="one">
          {error}
        </Body>
      </>
    )}
    {query && (
      <>
        <Info size="16" />
        <Body noMargin variant="highlight" size="one">
          No matches found for &quot;{query}&rdquo;
        </Body>
      </>
    )}
    <Close
      size="16"
      onClick={onClear}
      data-testid={DATA_SELECTORS.HIDE_ERROR}
    />
  </StyledError>
);

SearchError.defaultProps = {
  error: '',
  query: '',
};

SearchError.propTypes = {
  error: PropTypes.string,
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClear: PropTypes.func.isRequired,
};

export default SearchError;
