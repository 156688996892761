import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationInline, spacing } from '@sumup/circuit-ui';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { map, values } from 'lodash/fp';
import { saveClientLimits } from 'api';
import Loading from 'components/Loading';
import { ACTIONS, APPS, PAGE_TITLES } from 'variables';
import AuthContext from 'context/auth';
import { hasActionAccess } from 'services/permissions';
import { getClientDetailsWithLimits } from 'services/clientData';
import DataGrid from '../DataGrid';
import { limitsColumns } from './limitsColumns';
import { limitsReducer } from './limitsReducer';
import { setClientLimits, setLoading } from './limitsActions';
import {
  StyledHeading,
  DataGridSplit,
  StyledLoadingButton,
} from './LimitsStyled';

const initialState = { loading: true };
const getClientDetailsWrapped = (clientId, httpClient) =>
  getClientDetailsWithLimits({ clientId, httpClient });

const Limits = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();
  const [saveButton, setSaveButton] = useState(false);
  const [error, setError] = useState(false);
  const initialLoad = useRef(true);
  const {
    state: { permissions },
  } = useContext(AuthContext);

  useEffect(() => {
    initialLoad.current = false;
  }, []);

  const [{ clientLimits, loading }, dispatch] = useApiCallOnLoad(
    getClientDetailsWrapped,
    clientId,
    limitsReducer,
    initialState
  );

  const handleSetClientLimits = () => {
    setSaveButton(false);
    setError(false);
    dispatch(setLoading(true));
    saveClientLimits({ clientId, limits: clientLimits, httpClient })
      .then(() => {
        const clientLimitsClone = [...clientLimits];
        map((record) => {
          const rec = record;
          rec.modified = false;
        }, clientLimitsClone);
        dispatch(setClientLimits(clientLimitsClone));
      })
      .catch(() => {
        setError(true);
        dispatch(setLoading(false));
        setSaveButton(true);
      });
  };

  const handleClientLimitsChange = () => {
    setSaveButton(true);
    map((limit) => {
      map((value) => {
        if (value === '') {
          setSaveButton(false);
        }
      }, values(limit));
    }, clientLimits);
  };

  const hasEditRights = hasActionAccess({
    permissions,
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardLimitsEdit,
  });

  return (
    <>
      {loading && initialLoad.current && <Loading />}
      {clientLimits && (
        <>
          <StyledHeading as="h3" size="one" noMargin>
            {PAGE_TITLES.LIMITS.heading}
            {hasEditRights && (
              <StyledLoadingButton
                variant="primary"
                disabled={!saveButton}
                onClick={handleSetClientLimits}
                isLoading={loading}
                loadingLabel="Loading"
              >
                Save changes
              </StyledLoadingButton>
            )}
          </StyledHeading>
          {error && (
            <NotificationInline
              variant="danger"
              size="one"
              css={spacing({ left: 'mega' })}
            >
              Limits update failed.
            </NotificationInline>
          )}
          <DataGridSplit>
            <DataGrid
              columns={limitsColumns}
              dataSource={[...clientLimits].splice(0, 8)}
              showModified
              showFailure
              onDisplayErrors={handleClientLimitsChange}
              errorsVisible
              disabled={!hasEditRights}
            />
            <DataGrid
              columns={[...limitsColumns].splice(0, 2)}
              dataSource={[...clientLimits].splice(8, 2)}
              showModified
              showFailure
              onDisplayErrors={handleClientLimitsChange}
              errorsVisible
              disabled={!hasEditRights}
            />
          </DataGridSplit>
        </>
      )}
    </>
  );
};

export default Limits;
