import { actionTypes } from 'variables';

export const loadSuccess = (data) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: data,
});

export const loadError = (error) => ({
  type: actionTypes.LOAD_ERROR,
  payload: { error },
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: { loading },
});

export const setError = (error) => ({
  type: actionTypes.SET_ERROR,
  payload: { error },
});
