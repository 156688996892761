import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Card, Popover } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import { map } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  PAGE_SIZE_DROPDOWN_MIN_WIDTH,
  FILTERS_MIN_HEIGHT,
  PAGE_SIZE_OPTIONS,
} from 'variables';

const PageSizePopoverComponent = styled(Card)(
  ({ theme: { spacings, colors, borderWidth, borderRadius }, disabled }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0 ${spacings.bit};
    border: ${borderWidth.kilo} solid ${colors.n200};
    border-radius: ${borderRadius.byte};
    width: fit-content;
    min-height: ${FILTERS_MIN_HEIGHT}px;
    min-width: ${PAGE_SIZE_DROPDOWN_MIN_WIDTH}px;
    cursor: pointer;

    svg {
      margin: auto ${spacings.byte};
      color: ${colors.n500};
    }

    > p {
      margin: 6px 0;

      :first-of-type {
        margin: auto ${spacings.byte};
      }
    }

    ${disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
  `
);

const StyledText = styled(Body)(
  ({ theme: { colors }, disabled }) => css`
    ${disabled
      ? `
      color: ${colors.n300};
    `
      : `
      cursor: pointer;
    `}
  `
);

const StyledPopover = styled(Popover)`
  button {
    &:focus {
      box-shadow: none;
    }
  }
`;

const PageSizeDropdown = ({ size: selectedSize, onSizeChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectPageSize = ({ disabled, size }) => {
    if (!disabled) {
      setIsOpen(false);
      onSizeChange(size);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const popoverActions = map(PAGE_SIZE_OPTIONS, (size) => ({
    children: (
      <StyledText
        key={size}
        noMargin
        size="two"
        disabled={size === selectedSize}
      >
        {size} per page
      </StyledText>
    ),
    onClick: () =>
      handleSelectPageSize({
        disabled: size === selectedSize,
        size,
      }),
  }));

  const popoverComponent = () => (
    <PageSizePopoverComponent
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(true);
      }}
    >
      <Body size="two" noMargin variant="subtle">
        Page size:
      </Body>
      <Body size="two" variant="highlight" noMargin>
        {selectedSize}
      </Body>
      {isOpen ? <ChevronUp /> : <ChevronDown />}
    </PageSizePopoverComponent>
  );

  return (
    <StyledPopover
      isOpen={isOpen}
      component={popoverComponent}
      actions={popoverActions}
      onToggle={handleToggle}
    />
  );
};

PageSizeDropdown.propTypes = {
  size: PropTypes.number.isRequired,
  onSizeChange: PropTypes.func.isRequired,
};

export default PageSizeDropdown;
