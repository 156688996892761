import { find, orderBy, reduce, size } from 'lodash';

export const validateFields = (fields, validationRules = {}) => {
  if (!size(fields)) {
    return true;
  }

  const validatedFields = reduce(
    validationRules,
    (accumulator, value, fieldName) => {
      if (!value) {
        return accumulator;
      }

      const { rules, hints, priorities } = value;
      let orderedRules = rules;

      if (size(priorities)) {
        orderedRules = orderBy(rules, priorities);
      }

      return {
        ...accumulator,
        [fieldName]: reduce(
          orderedRules,
          (fieldsValidations, rule) => [
            ...fieldsValidations,
            ...reduce(
              rule,
              (fieldValidation, validationFunction, ruleName) => {
                const invalid = !validationFunction(fields[fieldName]);

                return [
                  ...fieldValidation,
                  {
                    ruleName,
                    invalid,
                    validationHint: invalid ? hints[ruleName] : '',
                  },
                ];
              },
              []
            ),
          ],
          []
        ),
      };
    },
    {}
  );

  return reduce(
    validatedFields,
    (accumulator, fieldValidations, fieldName) => {
      const invalidField = find(fieldValidations, ({ invalid }) => invalid);

      return {
        ...accumulator,
        [fieldName]: invalidField
          ? {
              invalid: invalidField.invalid,
              validationHint: invalidField.validationHint,
            }
          : {
              invalid: false,
            },
      };
    },
    {}
  );
};
