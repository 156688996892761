import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge } from '@sumup/circuit-ui';
import {
  TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH,
  TRANSACTION_TOOLTIP_CONTENT_MAX_WIDTH,
} from 'variables';

export const StyledBadge = styled(Badge)(
  ({ theme: { spacings, colors }, background, color, disabled }) => css`
    height: ${spacings.giga};
    color: ${color || colors.bodyBg};
    background: ${background};

    p {
      align-items: center;
    }

    ${disabled &&
    `
      opacity: 0.5;
    `}
  `
);

export const TooltipContent = styled('div')`
  min-width: ${TRANSACTION_TOOLTIP_CONTENT_MIN_WIDTH}px;
  max-width: ${TRANSACTION_TOOLTIP_CONTENT_MAX_WIDTH}px;
`;
