import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Pagination, spacing } from '@sumup/circuit-ui';
import { PAGE_TITLES } from 'variables';
import Loading from 'components/Loading';
import PageSizeDropdown from 'components/Account/components/PageSizeDropdown';
import DataGrid from 'components/DataGrid';
import { SCHEDULED_PAYMENTS_NON_NUMERIC_FIELDS_MAP } from './columns';
import { ScheduledPaymentsTypeFilter } from './components/ScheduledPaymentsTypeFilter';
import { StatusFilter } from './components/StatusFilter';

const TotalCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

const Filters = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacings.mega};
    height: 32px;

    * {
      max-height: 32px;
    }

    > div {
      display: flex;
    }

    > div:not(:first-of-type) {
      margin-left: ${theme.spacings.byte};
    }

    > div:last-of-type {
      margin-left: auto;
    }
  `
);

const ScheduledPayments = ({
  loading,
  columns,
  scheduledPayments,
  currentPage,
  totalPages,
  totalCount,
  pageSize,
  statusFilter,
  typeFilter,
  transactionHistoryLoadingForId,
  onPageChange,
  onPageSizeChange,
  onStatusFilterChange,
  onTypeFilterChange,
  reloadScheduledPayments,
  onViewDetailsClick,
}) => (
  <>
    <Headline noMargin as="h2" size="two">
      {PAGE_TITLES.SCHEDULED_PAYMENTS.heading}
      {totalCount != null && <TotalCount>({totalCount})</TotalCount>}
    </Headline>
    {loading ? (
      <Loading />
    ) : (
      <>
        <Filters>
          <div>
            <ScheduledPaymentsTypeFilter
              filter={typeFilter}
              onChange={onTypeFilterChange}
            />
            <StatusFilter
              filter={statusFilter}
              onChange={onStatusFilterChange}
            />
          </div>
          <PageSizeDropdown size={pageSize} onSizeChange={onPageSizeChange} />
        </Filters>
        <DataGrid
          columns={columns}
          dataSource={scheduledPayments}
          sortable
          centered
          nonNumericFields={SCHEDULED_PAYMENTS_NON_NUMERIC_FIELDS_MAP}
          noResultsText="No scheduled payments yet"
          scheduleHistoryLoadingForId={transactionHistoryLoadingForId}
          reloadScheduledPayments={reloadScheduledPayments}
          onViewScheduleDetailsClick={onViewDetailsClick}
        />
        <Pagination
          css={spacing({ top: 'kilo' })}
          label="Pagination"
          previousLabel="Previous page"
          nextLabel="Next page"
          totalPages={totalPages}
          totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
          currentPage={currentPage}
          onChange={onPageChange}
        />
      </>
    )}
  </>
);

ScheduledPayments.defaultProps = {
  scheduledPayments: [],
  totalCount: null,
  transactionHistoryLoadingForId: null,
};

ScheduledPayments.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  scheduledPayments: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  statusFilter: PropTypes.string.isRequired,
  typeFilter: PropTypes.string.isRequired,
  transactionHistoryLoadingForId: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  onTypeFilterChange: PropTypes.func.isRequired,
  reloadScheduledPayments: PropTypes.func.isRequired,
  onViewDetailsClick: PropTypes.func.isRequired,
};

export default ScheduledPayments;
