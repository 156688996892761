import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_FIELD_TYPES, DATA_SELECTORS } from 'variables';
import { StyledButton } from './StyledButton';

const buttonColors = {
  [CUSTOM_FIELD_TYPES.BUTTON_WARNING]: {
    text: 'alert',
    borderColor: 'alert',
  },
};

const Button = ({ value, type, onClick, disabled }) => (
  <>
    <StyledButton
      data-testid={DATA_SELECTORS.TRANSACTION_BUTTON}
      disabled={disabled}
      colors={buttonColors[type]}
      onClick={onClick}
    >
      {value}
    </StyledButton>
  </>
);

Button.defaultProps = {
  value: '',
  type: '',
  disabled: false,
};

Button.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Button;
