import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Conversion } from 'components/Transactions/assets/currency-conversion.svg';
import Tooltip from 'components/Tooltip';
import {
  CURRENCY_CONVERSION_DEFAULT_VALUE,
  CURRENCY_CONVERSION_FRACTION_DIGITS,
  TRANSACTIONS_GRID_TOOLTIP_PADDING,
} from 'variables';
import { Body } from '@sumup/circuit-ui';
import {
  BILLING_AMOUNT,
  TOTAL_AMOUNT,
} from 'components/Transaction/fieldsConfig';
import {
  TooltipPivot,
  TooltipContent,
  StyledCell,
} from './CurrencyConversionCellStyled';
import Card from '../Card';

const tooltipStyle = {
  padding: TRANSACTIONS_GRID_TOOLTIP_PADDING,
};

const CurrencyConversionCell = ({
  value,
  style,
  disabled,
  rowData,
  centered,
}) => {
  const formattedValue =
    value || value === 0
      ? parseFloat(value).toFixed(CURRENCY_CONVERSION_FRACTION_DIGITS)
      : value;

  return (
    <StyledCell style={style} centered={centered}>
      {Number(value) !== CURRENCY_CONVERSION_DEFAULT_VALUE && (
        <Card disabled={disabled}>
          <Tooltip
            position="top"
            pivot={
              <TooltipPivot>
                <Conversion />
                {formattedValue}
              </TooltipPivot>
            }
            content={
              <TooltipContent>
                Conversion rate:
                <Body noMargin variant="highlight" size="two">
                  {rowData[TOTAL_AMOUNT.name].amount / 100}
                  {rowData[TOTAL_AMOUNT.name].currency} &times; {formattedValue}{' '}
                  = {rowData[BILLING_AMOUNT.name].amount / 100}
                  {rowData[BILLING_AMOUNT.name].currency}
                </Body>
              </TooltipContent>
            }
            tooltipStyle={tooltipStyle}
          />
        </Card>
      )}
    </StyledCell>
  );
};

CurrencyConversionCell.defaultProps = {
  value: CURRENCY_CONVERSION_DEFAULT_VALUE.toString(),
  style: {},
  disabled: false,
  centered: false,
};

CurrencyConversionCell.propTypes = {
  value: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  rowData: PropTypes.object.isRequired,
  centered: PropTypes.bool,
};

export default CurrencyConversionCell;
