import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RadioButtonGroup } from '@sumup/circuit-ui';
import {
  BANK_TRANSACTION_ORIGIN,
  CARD_TRANSACTION_ORIGIN,
  DIRECT_DEBIT_TRANSACTION_ORIGIN,
  TRANSACTION_ORIGIN_FILTER_OPTIONS,
} from 'components/Transactions/constants';

const { color: bankTxColor } =
  TRANSACTION_ORIGIN_FILTER_OPTIONS[BANK_TRANSACTION_ORIGIN];

const { color: cardTxColor } =
  TRANSACTION_ORIGIN_FILTER_OPTIONS[CARD_TRANSACTION_ORIGIN];

const { color: ddTxColor } =
  TRANSACTION_ORIGIN_FILTER_OPTIONS[DIRECT_DEBIT_TRANSACTION_ORIGIN];

export const StyledRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    display: flex;
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};

    > div:nth-of-type(2) > label {
      :before {
        border-color: ${bankTxColor};
      }

      :after {
        background-color: ${bankTxColor};
      }
    }

    > div:nth-of-type(3) > label {
      :before {
        border-color: ${cardTxColor};
      }

      :after {
        background-color: ${cardTxColor};
      }
    }

    > div:nth-of-type(4) > label {
      :before {
        border-color: ${ddTxColor};
      }

      :after {
        background-color: ${ddTxColor};
      }
    }
  `
);
