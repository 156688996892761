import { actionTypes } from 'variables';

export const setDirectDebitsEnabledSuccess = (data) => ({
  type: actionTypes.SET_DIRECT_DEBITS_ENABLED_SUCCESS,
  payload: { data },
});

export const setDirectDebitsEnabledError = () => ({
  type: actionTypes.SET_DIRECT_DEBITS_ENABLED_ERROR,
});

export const setDirectDebitsEnabledLoading = (loading) => ({
  type: actionTypes.SET_DIRECT_DEBITS_ENABLED_LOADING,
  payload: { loading },
});

export const setDirectDebitsEvents = ({ directDebitsEvents }) => ({
  type: actionTypes.SET_DIRECT_DEBITS_EVENTS,
  payload: { directDebitsEvents },
});
