import React from 'react';
import PropTypes from 'prop-types';
import { NotificationInline, spacing } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const ErrorStyled = styled(NotificationInline)(
  ({ theme }) => css`
    border-color: ${theme.colors.r700};
    color: ${theme.colors.r700};
    font-style: normal;
  `
);

const Error = ({ children }) => (
  <ErrorStyled
    variant="alert"
    body={children}
    css={spacing({ bottom: 'giga' })}
  />
);

Error.propTypes = {
  children: PropTypes.node,
};

Error.defaultProps = {
  children: [],
};

export default Error;
