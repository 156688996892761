import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ENTER_KEY_CODE,
  PATHS,
  ERROR_MESSAGE,
  DATA_SELECTORS,
} from 'variables';
import { search } from 'api';
import { isEmpty, size } from 'lodash';
import getSearchType from 'services/getSearchType';
import { useHttpClient } from 'hooks';
import {
  SearchBox,
  StyledSearchInput,
  StyledLoadingButton,
} from './StyledSearch';
import SearchResults from './components/SearchResults';
import SearchError from './components/SearchError';

const Search = ({ focused, styles, onResultClick }) => {
  const [query, setQuery] = useState('');
  const [notFound, setNotFound] = useState('');
  const [results, setResults] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const searchInputRef = useRef();
  const httpClient = useHttpClient();

  const handleChange = ({ target: { value } }) => {
    setQuery(value);
    setDisabled(!value);
  };

  const handleSearch = () => {
    setError('');
    setNotFound(false);
    setDisabled(true);
    setLoading(true);

    search(encodeURIComponent(query), httpClient)
      .then(({ data }) => {
        if (isEmpty(data)) {
          setNotFound(true);
        }

        setLoading(false);
        setError('');

        if (getSearchType({ data }) === PATHS.transactions) {
          const [transaction] = data.transactions;
          const { transaction_identifier: txId, client_code: clientId } =
            transaction || {};

          history.push(
            `/${PATHS.client}/${clientId}/${PATHS.transactions}/${txId}`
          );

          onResultClick();
        } else {
          const { clients } = data;

          if (size(clients) === 1) {
            const [{ merchant_code: merchantCode }] = clients;
            history.push(`/${PATHS.client}/${merchantCode}`);

            onResultClick();
          } else {
            setResults(data.clients);
            setDisabled(false);
          }
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        setDisabled(false);

        if (response && response.status === 404) {
          setNotFound(true);
        } else {
          setError(ERROR_MESSAGE);
        }
      });
  };

  const handleClearSearch = () => {
    setNotFound(false);
    setError('');
    setQuery('');
    setDisabled(true);
  };

  const handleKeyUp = ({ keyCode }) => {
    setError('');
    setNotFound(false);

    if (query) {
      if (keyCode === ENTER_KEY_CODE) {
        handleSearch();
      }
    } else {
      setResults('');
    }
  };

  const handleClearInput = () => {
    setQuery('');
    setResults('');
    setDisabled(true);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [focused]);

  return (
    <div style={styles}>
      <SearchBox>
        <StyledSearchInput
          noMargin
          data-testid={DATA_SELECTORS.ADMIN_SEARCH_INPUT}
          placeholder="Merchant/Consumer ID, Email or Phone; Transaction ID"
          onChange={handleChange}
          onFocus={() => setNotFound(false)}
          onKeyUp={handleKeyUp}
          value={query}
          ref={searchInputRef}
          label="Search by merchant ID, transaction ID, or email"
          hideLabel
          clearLabel="Clear search value"
          onClear={handleClearInput}
        />
        <StyledLoadingButton
          data-testid={DATA_SELECTORS.ADMIN_SEARCH_BUTTON}
          variant="primary"
          isLoading={loading}
          loadingLabel="Loading"
          disabled={disabled}
          onClick={handleSearch}
        >
          Search
        </StyledLoadingButton>
      </SearchBox>
      {results && !loading && (
        <SearchResults results={results} onResultClick={onResultClick} />
      )}
      {(error || notFound) && (
        <SearchError
          error={error}
          query={notFound && query}
          onClear={handleClearSearch}
        />
      )}
    </div>
  );
};

Search.defaultProps = {
  focused: false,
  styles: {},
  onResultClick: () => {},
};

Search.propTypes = {
  focused: PropTypes.bool,
  styles: PropTypes.object,
  onResultClick: PropTypes.func,
};

export default Search;
