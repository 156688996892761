import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Empty = styled('span')`
  ${({ theme: { colors } }) => css`
    color: ${colors.n300};
  `}
`;

export default Empty;
