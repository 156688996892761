import React from 'react';
import PropTypes from 'prop-types';
import { Headline } from '@sumup/circuit-ui';
import { PAGE_TITLES } from 'variables';
import DataGrid from '../DataGrid';

export const DevicesList = ({
  columns,
  devices,
  nonNumericFields,
  sortable,
  noResultsText,
  clientId,
  onDeleteDevice,
}) => (
  <>
    <Headline noMargin as="h2" size="two">
      {PAGE_TITLES.DEVICES.heading}
    </Headline>
    <DataGrid
      columns={columns}
      dataSource={devices}
      nonNumericFields={nonNumericFields}
      sortable={sortable}
      noResultsText={noResultsText}
      clientId={clientId}
      onDeleteDevice={onDeleteDevice}
    />
  </>
);

DevicesList.defaultProps = {
  nonNumericFields: {},
  sortable: false,
  noResultsText: null,
};

DevicesList.propTypes = {
  columns: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  nonNumericFields: PropTypes.object,
  sortable: PropTypes.bool,
  noResultsText: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  onDeleteDevice: PropTypes.func.isRequired,
};
