import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Card = styled('div')`
  ${({ theme: { spacings, colors } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 520px;
    height: 60px;
    padding: ${spacings.bit};
    background: ${colors.white};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  `}
`;

export default Card;
