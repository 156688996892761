import { WALLET_TOKEN_STATUSES } from 'variables';
import {
  CREATION_DATE,
  STATUS,
} from 'components/Account/components/WalletTokensModal/columns';

export const sortWalletTokens = ({ tokensList = [] }) =>
  tokensList.sort((currentToken, nextToken) => {
    if (
      currentToken[STATUS.name] !== nextToken[STATUS.name] &&
      (currentToken[STATUS.name] === WALLET_TOKEN_STATUSES.DEACTIVATED.label ||
        nextToken[STATUS.name] === WALLET_TOKEN_STATUSES.DEACTIVATED.label)
    ) {
      return currentToken[STATUS.name] ===
        WALLET_TOKEN_STATUSES.DEACTIVATED.label
        ? 1
        : -1;
    }

    return (
      new Date(nextToken[CREATION_DATE.name]) -
      new Date(currentToken[CREATION_DATE.name])
    );
  });
