import {
  PATHS,
  APP_TITLE,
  APPS,
  SECTIONS as SECTIONS_BY_MODULE,
} from 'variables';

const PAGE_TITLES = {
  FRAUD: {
    title: `Mastercard fraud report | ${APP_TITLE}`,
    heading: 'Mastercard fraud report',
  },
  BANK_FRAUD: {
    title: `Bank fraud report | ${APP_TITLE}`,
    heading: 'Bank fraud report',
  },
};

export const SECTIONS = {
  [PATHS.fraud]: {
    key: PATHS.fraud,
    title: PAGE_TITLES.FRAUD.heading,
    link: PATHS.fraud,
    permissions: {
      app: APPS.reports,
      section: SECTIONS_BY_MODULE[APPS.reports].mcReport,
    },
  },
  [PATHS.bank_fraud]: {
    key: PATHS.bank_fraud,
    title: PAGE_TITLES.BANK_FRAUD.heading,
    link: PATHS.bank_fraud,
    permissions: {
      app: APPS.reports,
      section: SECTIONS_BY_MODULE[APPS.reports].mcReport,
    },
  },
};
