import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Tooltip from 'components/Tooltip';
import {
  TRANSACTION_FRAUD_REPORT,
  TOTAL_AMOUNT,
  TRANSACTION_FRAUD_REPORT_TYPE,
  TRANSACTION_FRAUD_REPORT_SUBTYPE,
  TRANSACTION_FRAUD_REPORT_STATUS,
  TRANSACTION_FRAUD_REPORT_RECOVERED_AMOUNT,
  TRANSACTION_FRAUD_REPORT_REFERENCE,
} from 'components/Transaction/fieldsConfig';
import CopyableCell from 'components/CopyableCell';

const TooltipContent = styled('div')(
  ({ theme }) => css`
    font-size: ${theme.typography.body.two.fontSize};
    width: 300px;
    display: block;
    text-align: left;
  `
);

const Header = styled(Body)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.kilo};
  `
);

const Name = styled('span')(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
  `
);

const MerchantDetailsCell = ({ value, rowData, ...props }) => {
  const report = rowData[TRANSACTION_FRAUD_REPORT.name];
  const totalAmount = rowData[TOTAL_AMOUNT.name];

  const cell = <CopyableCell {...props} value={value} centered />;

  if (!report) {
    return cell;
  }

  return (
    <Tooltip
      position="top"
      content={
        <TooltipContent>
          <Header noMargin size="two">
            Fraudulent transaction:
          </Header>
          <Body noMargin size="two">
            <Name>Type:</Name> {report[TRANSACTION_FRAUD_REPORT_TYPE.name]}
          </Body>
          <Body noMargin size="two">
            <Name>Subtype:</Name>{' '}
            {report[TRANSACTION_FRAUD_REPORT_SUBTYPE.name]}
          </Body>
          <Body noMargin size="two">
            <Name>Status:</Name> {report[TRANSACTION_FRAUD_REPORT_STATUS.name]}
          </Body>
          <Body noMargin size="two">
            <Name>Recovered amount:</Name>
            {totalAmount.currency}&nbsp;
            {parseFloat(
              report[TRANSACTION_FRAUD_REPORT_RECOVERED_AMOUNT.name] / 100
            ).toFixed(2)}
          </Body>
          <Body noMargin size="two">
            <Name>Reference:</Name>{' '}
            {report[TRANSACTION_FRAUD_REPORT_REFERENCE.name]}
          </Body>
        </TooltipContent>
      }
      pivot={cell}
    />
  );
};

MerchantDetailsCell.defaultProps = {
  value: '',
  rowData: {},
};

MerchantDetailsCell.propTypes = {
  value: PropTypes.any,
  rowData: PropTypes.object,
};

export default MerchantDetailsCell;
