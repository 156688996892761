export const initialState = {
  groupsLoading: true,
  groupLoading: false,
  groups: [],
  selectedGroup: {
    text: '',
    access_to: '',
    app: '',
    permissions: { modules: {}, sections: {}, actions: {} },
    newName: '',
  },
  newGroup: {
    name: 'New group',
    app: {},
    permissions: { modules: {}, sections: {}, actions: {} },
  },
  apps: [],
  error: null,
};
