import { getBankFraudReport } from 'api';
import AuthContext from 'context/auth';
import { useHttpClient } from 'hooks';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { createObjectUrlCsv } from 'services/download';
import { hasActionAccess } from 'services/permissions';
import { APPS, DATE_FORMATS } from 'variables';
import BankFraudReport from './BankFraudReport';

const formatDate = (date) =>
  moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY);

const BankFraudReportData = () => {
  const httpClient = useHttpClient();

  const [href, setHref] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const csvLink = useRef();
  const {
    state: { permissions },
  } = useContext(AuthContext);

  useEffect(() => {
    if (href) {
      csvLink.current.click();
    }
  }, [href]);

  const hasActionAccessWrapped = ({ action }) =>
    hasActionAccess({ permissions, app: APPS.reports, action });

  const handleSelectDateRange = ({ startDate: fromDate, endDate: toDate }) => {
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  const handleDownloadButtonClick = () => {
    setLoading(true);

    getBankFraudReport({
      httpClient,
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
    })
      .then(({ data }) => {
        setLoading(false);
        setHref(createObjectUrlCsv(data));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <BankFraudReport
      hasActionAccess={hasActionAccessWrapped}
      startDate={startDate}
      endDate={endDate}
      href={href}
      csvLink={csvLink}
      loading={loading}
      onDatesChange={handleSelectDateRange}
      onDownloadButtonClick={handleDownloadButtonClick}
    />
  );
};

export default BankFraudReportData;
