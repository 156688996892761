import React from 'react';
import { Body, Button, spacing } from '@sumup/circuit-ui';
import moment from 'moment';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { View } from '@sumup/icons';
import { CUSTOM_FIELD_TYPES, DATE_FORMATS, FIELD_TYPES } from 'variables';
import Empty from 'components/Empty';
import { getCurrentDate } from 'services/dateTime';
import {
  TYPE_ONE_OFF,
  SCHEDULE_STATUS_ACTIVE,
  SCHEDULE_STATUS_COMPLETED,
  SCHEDULE_STATUS_CANCELLED,
  TYPE_LABEL,
} from './constants';
import { CancelUpcomingPaymentButton } from './components/CancelUpcomingPaymentButton';
import { getScheduledPaymentStatus } from './service';

const RECIPIENT_CELL_WIDTH = 230;
const STATUS_CELL_WIDTH = 160;

const RecipientCellWrapper = styled('div')`
  display: flex;
  flex-basis: unset !important;
  justify-content: center;
  max-width: ${RECIPIENT_CELL_WIDTH}px;
  text-align: center;
`;

const InactiveScheduleFinalDateCell = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacings.bit} ${theme.spacings.mega};

    svg {
      width: 16px;
      height: 16px;
    }
  `
);

const StatusCell = styled('div')`
  display: flex;
  justify-content: center;
  max-width: ${STATUS_CELL_WIDTH}px;
`;

const CenteredCell = styled('div')`
  display: flex;
  justify-content: center;
`;

export const CREATED_AT = {
  label: 'Created at',
  name: 'created_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  style: {
    maxWidth: 130,
  },
  centered: true,
};

export const SCHEDULE = {
  name: 'schedule',
};

export const TYPE = {
  label: 'Type',
  name: 'type',
  getValue: (record, fieldName) =>
    record[SCHEDULE.name][fieldName]
      ? TYPE_LABEL[record[SCHEDULE.name][fieldName]]
      : '',
  style: {
    maxWidth: 100,
  },
  centered: true,
};

const STATUS = {
  label: 'Status',
  name: 'status',
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (record = {}) => {
    const { label, icon } = getScheduledPaymentStatus(record);

    return label ? (
      <StatusCell>
        {icon}
        <Body
          noMargin
          size="two"
          variant="highlight"
          css={spacing({ left: 'bit' })}
        >
          {label}
        </Body>
      </StatusCell>
    ) : null;
  },
  style: {
    maxWidth: STATUS_CELL_WIDTH,
  },
  centered: true,
};

const IBAN = {
  name: 'iban',
};

const ACCOUNT_NUMBER = {
  name: 'account_number',
};

const SORT_CODE = {
  name: 'sort_code',
};

const BENEFICIARY = {
  name: 'beneficiary',
};

const RECIPIENT = {
  label: 'Recipient',
  name: 'account_name',
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (record, fieldName) => {
    const beneficiary = record[BENEFICIARY.name];

    return (
      <RecipientCellWrapper>
        <div>
          <Body noMargin size="two">
            {beneficiary[fieldName]}
          </Body>
          <Body noMargin size="two">
            {beneficiary[IBAN.name] ||
              `${beneficiary[SORT_CODE.name]} - ${
                beneficiary[ACCOUNT_NUMBER.name]
              }`}
          </Body>
        </div>
      </RecipientCellWrapper>
    );
  },
  style: {
    minWidth: RECIPIENT_CELL_WIDTH,
    maxWidth: RECIPIENT_CELL_WIDTH,
  },
  centered: true,
};

const REFERENCE = {
  label: 'Reference',
  name: 'reference',
  style: {
    minWidth: 190,
  },
  centered: true,
};

const CURRENCY = {
  name: 'currency',
};

const AMOUNT = {
  label: 'Amount',
  name: 'amount',
  currencyField: CURRENCY,
  renderAs: CUSTOM_FIELD_TYPES.AMOUNT,
  getValue: (record, fieldName) => parseFloat(record[fieldName]) / 100,
  centered: true,
  style: {
    maxWidth: 150,
  },
};

const VIEW_DETAILS = {
  label: '',
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (
    record,
    fieldName,
    { scheduleHistoryLoadingForId, onViewScheduleDetailsClick }
  ) => {
    const handleViewDetailsClick = () => {
      onViewScheduleDetailsClick(record);
    };

    return (
      <CenteredCell>
        <StyledButton
          isLoading={scheduleHistoryLoadingForId === record?.id}
          loadingLabel="Loading schedule history..."
          onClick={handleViewDetailsClick}
        >
          <View />
          <Body
            noMargin
            size="one"
            variant="highlight"
            css={spacing({ left: 'bit' })}
          >
            View details
          </Body>
        </StyledButton>
      </CenteredCell>
    );
  },
};

const getMadeOnCell = (date) => (
  <CenteredCell>
    <InactiveScheduleFinalDateCell>
      <Body noMargin size="two">
        Made on:
      </Body>
      {date ? (
        <Body noMargin size="two" variant="highlight">
          {moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
        </Body>
      ) : (
        <Empty>—</Empty>
      )}
    </InactiveScheduleFinalDateCell>
  </CenteredCell>
);

export const columns = [
  {
    ...CREATED_AT,
    active: true,
  },
  TYPE,
  STATUS,
  RECIPIENT,
  REFERENCE,
  AMOUNT,
  {
    label: '',
    type: FIELD_TYPES.DATE_TIME,
    renderAs: CUSTOM_FIELD_TYPES.CELL,
    headerBorder: 'none',
    getValue: (record, fieldName, { reloadScheduledPayments }) => {
      const schedule = record[SCHEDULE.name];

      if (!schedule) {
        return null;
      }

      if (schedule.status === SCHEDULE_STATUS_ACTIVE) {
        const nextPaymentDue = moment(
          schedule.next_payment_due || schedule.next_due
        );

        return nextPaymentDue.isBefore(getCurrentDate()) ? (
          getMadeOnCell(nextPaymentDue)
        ) : (
          <CenteredCell>
            <CancelUpcomingPaymentButton
              schedule={record[SCHEDULE.name]}
              paymentRequestId={record.id}
              onConfirm={reloadScheduledPayments}
            />
          </CenteredCell>
        );
      }

      if (schedule.status === SCHEDULE_STATUS_CANCELLED) {
        return (
          <CenteredCell>
            <InactiveScheduleFinalDateCell>
              <Body noMargin size="two">
                Cancelled at:
              </Body>
              {schedule.cancelled_at ? (
                <Body noMargin size="two" variant="highlight">
                  {moment(schedule.cancelled_at).format(
                    DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                  )}
                </Body>
              ) : (
                <Empty>—</Empty>
              )}
            </InactiveScheduleFinalDateCell>
          </CenteredCell>
        );
      }

      if (schedule.type === TYPE_ONE_OFF.value) {
        return getMadeOnCell(schedule.start_date);
      }

      if (schedule.status === SCHEDULE_STATUS_COMPLETED) {
        return (
          <CenteredCell>
            <InactiveScheduleFinalDateCell>
              <Body noMargin size="two">
                Ended on:
              </Body>
              {schedule.end_date ? (
                <Body noMargin size="two" variant="highlight">
                  {moment(schedule.end_date).format(
                    DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                  )}
                </Body>
              ) : (
                <Empty>—</Empty>
              )}
            </InactiveScheduleFinalDateCell>
          </CenteredCell>
        );
      }

      return null;
    },
  },
  VIEW_DETAILS,
];

export const SCHEDULED_PAYMENTS_NON_NUMERIC_FIELDS_MAP = {
  [CREATED_AT.name]: true,
};
