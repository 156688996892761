import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Success = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    svg {
      margin-right: ${spacings.byte};

      path {
        fill: ${colors.confirm};
      }
    }

    div {
      &:first-of-type {
        position: relative;
        left: auto;
        margin-right: ${spacings.byte};
        background: ${colors.confirm};
      }
    }
  `
);

export const Neutral = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    color: ${colors.n700};

    svg {
      max-width: 16px;
      margin-right: ${spacings.byte};

      path {
        fill: ${colors.n700};
      }
    }
  `
);

export const Danger = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    color: ${colors.alert};

    svg {
      margin-right: ${spacings.byte};

      path {
        fill: ${colors.alert};
      }
    }

    div {
      &:first-of-type {
        position: relative;
        left: auto;
        margin-right: ${spacings.byte};
        background: ${colors.alert};
      }
    }
  `
);

export const Warning = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    svg {
      margin-right: ${spacings.byte};

      path {
        fill: ${colors.notify};
      }
    }
  `
);

export const Final = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    color: ${colors.black};

    svg {
      max-width: 16px;
      margin-right: ${spacings.byte};

      path {
        fill: ${colors.black};
      }
    }
  `
);
