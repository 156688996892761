import React from 'react';
import Status from 'components/Status';
import { map } from 'lodash';
import styled from '@emotion/styled';
import { POPOVER_DIVIDER } from 'variables';

const PopoverOption = styled(Status)`
  display: flex;
`;

const INACTIVE_CELL_STYLES = { opacity: '.3', cursor: 'default' };
const ACTIVE_CELL_STYLES = { opacity: '1', cursor: 'pointer' };

export const getStatusPopoverOptions = ({
  statuses = [],
  statusesMap = {},
  onClick,
  isOptionDisabled,
}) =>
  map(statuses, (status) => {
    const disabledCell = isOptionDisabled(status);
    return status === POPOVER_DIVIDER
      ? {
          type: POPOVER_DIVIDER,
        }
      : {
          children: (
            <PopoverOption
              key={status}
              value={statusesMap[status]}
              style={disabledCell ? INACTIVE_CELL_STYLES : ACTIVE_CELL_STYLES}
            />
          ),
          onClick: () => (disabledCell ? {} : onClick({ status })),
        };
  });
