import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Headline, Pagination, spacing } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import DataGrid from 'components/DataGrid';
import PageSizeDropdown from 'components/Account/components/PageSizeDropdown';
import { columns } from '../scheduleHistoryColumns';
import { SCHEDULED_PAYMENTS_NON_NUMERIC_FIELDS_MAP } from '../columns';

const Wrapper = styled('div')(
  ({ theme }) => `
    padding: ${theme.spacings.tera} 0;
  `
);

const Heading = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ScheduleTransactionHistory = ({
  transactions,
  totalPages,
  currentPage,
  sort,
  pageSize,
  onSort,
  onPageChange,
  onPageSizeChange,
}) => {
  const { clientId } = useParams();

  return (
    <Wrapper>
      <Heading>
        <Headline as="h3" size="three" noMargin>
          Transaction history
        </Headline>
        <PageSizeDropdown size={pageSize} onSizeChange={onPageSizeChange} />
      </Heading>
      <DataGrid
        clientId={clientId}
        columns={columns}
        dataSource={transactions}
        nonNumericFields={SCHEDULED_PAYMENTS_NON_NUMERIC_FIELDS_MAP}
        noResultsText="No direct debits yet"
        sortable
        centered
        sort={sort}
        onSort={onSort}
      />
      <Pagination
        css={spacing({ top: 'kilo' })}
        label="Mandate transaction history pagination"
        previousLabel="Previous page"
        nextLabel="Next page"
        totalPages={totalPages}
        totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </Wrapper>
  );
};

ScheduleTransactionHistory.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  sort: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};
