export const SEARCH_FIELDS_TRANSACTION = [
  'transaction_identifier',
  'transaction_type',
  'rrn',
];

export const SEARCH_FIELDS_ACTIVITY_LOG = [
  'actor',
  'source',
  'type',
  'details',
  'comment',
];

export const SEARCH_FIELDS_CHALLENGES_ONLINE = [
  'inserted_at',
  'updated_at',
  'expiry_date',
  'merchant',
  'amount',
  'status',
];

export const SEARCH_FIELDS_CHALLENGES_BANK = [
  'inserted_at',
  'updated_at',
  'expiry_date',
  'recipient',
  'amount',
  'status',
];

export const SEARCH_FIELDS_DEVICES = [
  'device_uuid',
  'device_token',
  'inserted_at',
  'updated_at',
];
