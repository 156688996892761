import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Popover, Button } from '@sumup/circuit-ui';
import { Download as CircuitDownload } from '@sumup/icons';
import { ReactComponent as Download } from 'assets/download.svg';
import { FILE_EXTENSIONS, FILTERS_MIN_HEIGHT } from 'variables';

const { PDF, CSV } = FILE_EXTENSIONS;

const DownloadButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    max-height: ${FILTERS_MIN_HEIGHT}px;
    margin-left: ${theme.spacings.kilo};
  `
);

const CollapsedDownloadButton = styled(CircuitDownload)(
  ({ theme }) => css`
    min-width: ${FILTERS_MIN_HEIGHT}px;
    min-height: ${FILTERS_MIN_HEIGHT}px;
    margin-left: ${theme.spacings.kilo};
    color: ${theme.colors.p500};
    cursor: pointer;
  `
);

const PopoverOption = styled('div')`
  display: flex;
  align-items: center;
`;

const PopoverOptionText = styled(Body)`
  padding: 0 20px;
`;

const PopoverExplanation = styled(Body)(
  ({ theme }) => css`
    max-width: 260px;
    padding: ${theme.spacings.mega} 0 0 0;
    border-top: ${theme.borderWidth.kilo} solid ${theme.colors.n300};
    color: ${theme.colors.n500};
  `
);

const Export = ({ filtersApplied, disabled, collapsed, onExport }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePdfExport = () => {
    onExport(PDF);
  };

  const handleCsvExport = () => {
    onExport(CSV);
  };

  const handleTogglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const popoverComponent = () =>
    collapsed ? (
      <CollapsedDownloadButton
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setPopoverOpen(true);
        }}
      />
    ) : (
      <DownloadButton
        variant="primary"
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setPopoverOpen(true);
        }}
      >
        <Body noMargin variant="highlight" size="one">
          Download
        </Body>
      </DownloadButton>
    );

  const popoverActions = [
    {
      children: (
        <PopoverOption>
          <Download />
          <PopoverOptionText noMargin size="one">
            PDF document
          </PopoverOptionText>
        </PopoverOption>
      ),
      onClick: handlePdfExport,
    },
    {
      children: (
        <PopoverOption>
          <Download />
          <PopoverOptionText noMargin size="one">
            CSV document
          </PopoverOptionText>
        </PopoverOption>
      ),
      onClick: handleCsvExport,
    },
    {
      children: (
        <PopoverExplanation noMargin size="two">
          The downloaded report will include all transactions from the selected
          period{filtersApplied ? ' that match the applied filters' : ''}.
        </PopoverExplanation>
      ),
      onClick: () => {},
    },
  ];

  return (
    <Popover
      placement="bottom"
      isOpen={popoverOpen}
      actions={popoverActions}
      component={popoverComponent}
      onToggle={handleTogglePopover}
    />
  );
};

Export.defaultProps = {
  filtersApplied: false,
};

Export.propTypes = {
  filtersApplied: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default Export;
