import React from 'react';
import { Body } from '@sumup/circuit-ui';
import moment from 'moment';
import {
  ACTIONS,
  APPS,
  CUSTOM_FIELD_TYPES,
  DATE_FORMATS,
  DATE_GRID_COLUMN_MAX_WIDTH,
  FIELD_TYPES,
  WALLET_TOKENS_CONFIRMATION_MODAL_WIDTH,
  WALLET_TOKEN_STATUSES,
} from 'variables';
import Status from 'components/Status';
import { updateWalletToken } from 'api';
import { UpdateTokenModalContent } from '../../AccountStyled';

const { ACTIVE, DEACTIVATED, SUSPENDED } = WALLET_TOKEN_STATUSES;

const TOKEN_NUMBER = {
  name: 'tokenNumber',
  prefix: <span>DPAN: </span>,
};

const TUR_DPAN = {
  label: 'Token Unique Reference / DPAN',
  name: 'tokenUniqueReference',
  prefix: <span>TUR: </span>,
  fields: [TOKEN_NUMBER],
  style: {
    minWidth: 302,
  },
};

const WALLET = {
  label: 'Wallet',
  name: 'walletID',
  renderAs: CUSTOM_FIELD_TYPES.WALLET,
  style: {
    maxWidth: 90,
  },
};

const DEVICE_NAME = {
  label: 'Device name',
  name: 'deviceName',
};

const DEVICE_TYPE = {
  label: 'Device type',
  name: 'deviceType',
};

export const CREATION_DATE = {
  label: 'Created on',
  name: 'tokenCreationDate',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) => moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR),
  style: {
    maxWidth: DATE_GRID_COLUMN_MAX_WIDTH,
  },
};

const EXPIRY_DATE = {
  label: 'Exp. date',
  name: 'tokenExpiryDate',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date = '') => `${date.substr(2, 2)}/20${date.substr(0, 2)}`,
  style: {
    maxWidth: 100,
  },
};

export const STATUS = {
  label: 'Status',
  name: 'tokenStatus',
  renderAs: ({ value, hasActionAccess, paymentsBlocked }) =>
    value === DEACTIVATED.label || !hasActionAccess || paymentsBlocked
      ? CUSTOM_FIELD_TYPES.STATUS
      : CUSTOM_FIELD_TYPES.POPOVER,
  getPopoverOptions: ({
    clientId,
    cardToken,
    tokenUniqueReference,
    value,
    httpClient,
  }) => [
    {
      disabled: value === ACTIVE.label,
      label: ACTIVE.label,
      modalInfo: {
        width: WALLET_TOKENS_CONFIRMATION_MODAL_WIDTH,
        title: 'Change token status?',
        confirmText: 'Change',
        content: (
          <UpdateTokenModalContent>
            <Body noMargin size="one">
              The status will be changed to:
            </Body>
            <Status value={ACTIVE.label} />
          </UpdateTokenModalContent>
        ),
        onConfirm: (successHandler) =>
          updateWalletToken({
            clientId,
            cardToken,
            tokenUniqueReference,
            tokenStatus: ACTIVE.action,
            httpClient,
          }).then(successHandler()),
      },
    },
    {
      disabled: value === SUSPENDED.label,
      label: SUSPENDED.label,
      modalInfo: {
        width: WALLET_TOKENS_CONFIRMATION_MODAL_WIDTH,
        title: 'Change token status?',
        confirmText: 'Change',
        content: (
          <UpdateTokenModalContent>
            <Body noMargin size="one">
              The status will be changed to:
            </Body>
            <Status value={SUSPENDED.label} />
          </UpdateTokenModalContent>
        ),
        onConfirm: (successHandler) =>
          updateWalletToken({
            clientId,
            cardToken,
            tokenUniqueReference,
            tokenStatus: SUSPENDED.action,
            httpClient,
          }).then(successHandler()),
      },
    },
  ],
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardOverviewEditWallet,
  },
};

const DEACTIVATE = {
  label: '',
  name: '',
  value: DEACTIVATED.action,
  renderAs: ({ record = {}, paymentsBlocked }) =>
    record[STATUS.name] === DEACTIVATED.label || paymentsBlocked
      ? CUSTOM_FIELD_TYPES.EMPTY
      : CUSTOM_FIELD_TYPES.BUTTON_WARNING,
  modalInfo: {
    width: WALLET_TOKENS_CONFIRMATION_MODAL_WIDTH,
    title: 'Deactivate this wallet token?',
    confirmText: 'Deactivate',
    confirmButtonProps: {
      destructive: true,
    },
    content: (
      <div>
        <Body noMargin size="one">
          This action is final and irreversible.
        </Body>
      </div>
    ),
    onConfirm: ({
      clientId,
      cardToken,
      tokenUniqueReference,
      httpClient,
      successHandler,
    }) =>
      updateWalletToken({
        clientId,
        cardToken,
        tokenUniqueReference,
        tokenStatus: DEACTIVATED.action,
        httpClient,
      }).then(successHandler()),
  },
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardOverviewEditWallet,
  },
};

export const columns = [
  TUR_DPAN,
  WALLET,
  DEVICE_NAME,
  DEVICE_TYPE,
  CREATION_DATE,
  EXPIRY_DATE,
  STATUS,
  DEACTIVATE,
];
