import React, { useContext } from 'react';
import { Button, useModal } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import AuthContext from 'context/auth';
import { ACTIONS, APPS } from 'variables';
import { hasActionAccess } from 'services/permissions';
import styled from '@emotion/styled';
import DeleteDeviceModal from './components/DeleteDeviceModal';

const DeleteDeviceStyled = styled.div`
  display: flex;
  justify-content: center;
`;

const DeleteScaDevice = ({
  value: deviceUuid,
  clientId,
  onDeleteDevice,
  mfaEnabled,
  mfaSessionExists,
}) => {
  const { setModal } = useModal();
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const hasDeleteRights = hasActionAccess({
    permissions,
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardScaDevicesDelete,
  });
  const deleteButtonEnabled =
    (mfaEnabled || mfaSessionExists) && hasDeleteRights;

  const handleDeleteDevice = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <DeleteDeviceModal
          deviceUuid={deviceUuid}
          onClose={onClose}
          clientId={clientId}
          onDeleteDevice={onDeleteDevice}
        />
      ),
    });
  };

  return (
    <DeleteDeviceStyled>
      <Button
        size="small"
        destructive
        variant="primary"
        disabled={!deleteButtonEnabled}
        onClick={handleDeleteDevice}
      >
        Delete
      </Button>
    </DeleteDeviceStyled>
  );
};

DeleteScaDevice.propTypes = {
  value: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  onDeleteDevice: PropTypes.func.isRequired,
  mfaEnabled: PropTypes.bool.isRequired,
  mfaSessionExists: PropTypes.bool.isRequired,
};

export default DeleteScaDevice;
