import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Badge, Headline } from '@sumup/circuit-ui';
import { NAVIGATION_HEADING_PADDING_TOP_BOTTOM } from 'variables';
import { NAV_BADGE_COLOR_MAP } from '../../constants';

export const Header = styled('div')`
  display: flex;
  flex: 1;
`;

export const HeaderContentWrapper = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const HeaderContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin: ${NAVIGATION_HEADING_PADDING_TOP_BOTTOM}px ${theme.spacings.peta}
      ${NAVIGATION_HEADING_PADDING_TOP_BOTTOM}px 20px;
    flex-direction: column;
  `
);

export const StyledHeading = styled(Headline)`
  margin-right: 10px;
`;

export const HeadingWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const SubHeadingWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    color: ${theme.colors.n700};
  `
);

export const StyledBadge = styled(Badge)(
  ({ theme, bgColor }) => css`
    display: flex;
    align-items: center;
    margin: 2px;
    height: 40px;
    padding: ${theme.spacings.bit} ${theme.spacings.kilo};
    background: ${theme.colors[bgColor]};
    color: ${theme.colors.white};

    ${bgColor === NAV_BADGE_COLOR_MAP.UNKNOWN &&
    `
      color: ${theme.colors.n500};
      border: ${theme.borderWidth.kilo} dashed ${theme.colors.n300};
    `}

    @media only screen and (max-width: 1500px) {
      strong {
        font-size: 12px;
      }
    }
  `
);

export const RightSizeClientCards = styled('div')(
  ({ theme }) => css`
    ${theme.mq.tera} {
      margin-left: auto;
    }
  `
);

export const ModulePickerSections = styled('div')(
  ({ theme }) => css`
    display: flex;
    padding: 0 ${theme.spacings.byte};
  `
);
