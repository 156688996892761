import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Headline, Popover, Body } from '@sumup/circuit-ui';
import { ReactComponent as More } from 'assets/ellipsis.svg';
import { map } from 'lodash';
import {
  Section,
  Header,
  Actions,
  SubHeading,
  CircleButton,
  PopoverOption,
  StyledInput,
} from './DetailsSectionStyled';

const DetailsSection = ({
  text,
  subText,
  primaryButtonLabel,
  primaryButtonDisabled,
  cancelButtonLabel,
  actions,
  readonly,
  inputValue,
  inputLabel,
  children,
  editTextEnabled,
  showHeader,
  hasEditRights,
  onInputChange,
  onPrimaryButtonClick,
  onCancelButtonClick,
}) => {
  const [actionsPopoverOpen, setActionsPopoverOpen] = useState(false);

  const popoverActions = map(
    actions,
    ({ label, icon, color, onClick }, index) => ({
      children: (
        <PopoverOption key={`${label}-${index}`} color={color}>
          {icon}
          <Body noMargin size="one">
            {label}
          </Body>
        </PopoverOption>
      ),
      onClick,
    })
  );

  const handlePopoverButtonClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    setActionsPopoverOpen(true);
  };

  const popoverComponent = () => (
    <CircleButton onClick={handlePopoverButtonClick}>
      <More />
    </CircleButton>
  );

  return (
    <Section>
      <Header>
        {showHeader && editTextEnabled && (
          <StyledInput
            noMargin
            value={inputValue}
            label={inputLabel}
            hideLabel
            onChange={onInputChange}
          />
        )}
        {showHeader && !editTextEnabled && (
          <div>
            <Headline noMargin as="h2" size="two">
              {text}
            </Headline>
            <SubHeading>
              {map(subText, ({ text: subTextItemText, variant, index }) => (
                <Body
                  noMargin
                  variant={variant}
                  key={`${subTextItemText}-${index}`}
                  size="two"
                >
                  {subTextItemText}&nbsp;
                </Body>
              ))}
            </SubHeading>
          </div>
        )}
        {showHeader && hasEditRights && (
          <Actions>
            <Button
              variant="primary"
              disabled={primaryButtonDisabled}
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonLabel}
            </Button>
            {readonly ? (
              <Popover
                isOpen={actionsPopoverOpen}
                actions={popoverActions}
                component={popoverComponent}
                onToggle={() => setActionsPopoverOpen(!actionsPopoverOpen)}
              />
            ) : (
              <Button onClick={onCancelButtonClick}>{cancelButtonLabel}</Button>
            )}
          </Actions>
        )}
      </Header>
      {children}
    </Section>
  );
};

DetailsSection.defaultProps = {
  text: '',
  subText: [],
  primaryButtonLabel: 'Edit',
  primaryButtonDisabled: false,
  cancelButtonLabel: 'Cancel',
  actions: [],
  readonly: true,
  inputValue: '',
  inputLabel: '',
  children: [],
  editTextEnabled: true,
  showHeader: true,
  hasEditRights: false,
  onInputChange: () => {},
  onPrimaryButtonClick: () => {},
  onCancelButtonClick: () => {},
};

DetailsSection.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.array,
  primaryButtonLabel: PropTypes.string,
  primaryButtonDisabled: PropTypes.bool,
  cancelButtonLabel: PropTypes.string,
  actions: PropTypes.array,
  readonly: PropTypes.bool,
  inputValue: PropTypes.string,
  inputLabel: PropTypes.string,
  children: PropTypes.node,
  editTextEnabled: PropTypes.bool,
  showHeader: PropTypes.bool,
  hasEditRights: PropTypes.bool,
  onInputChange: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
};

export default DetailsSection;
