import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { Wrapper } from './EmptyStateStyled';

const EmptyState = ({ text, centerText }) => (
  <Wrapper centerText={centerText}>
    <Body noMargin size="one">
      {text}
    </Body>
  </Wrapper>
);

EmptyState.defaultProps = {
  centerText: false,
};

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
  centerText: PropTypes.bool,
};

export default EmptyState;
