import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

const TextSeparator = styled(Body)`
  ${({ theme: { spacings }, margin }) => css`
    margin: 0 ${margin ? spacings[margin] : spacings.byte};
  `}
`;

export default TextSeparator;
