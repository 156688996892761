import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { getUserScaDevices, deleteScaDevice } from 'api';
import Error from 'components/Error';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { STATUS_CODES } from 'variables';
import { columns, DEVICES_NON_NUMERIC_FIELDS_MAP } from './columns';
import { DevicesList } from './DevicesList';
import { devicesListReducer } from './devicesListReducer';
import { setScaDevices } from './devicesListActions';

const initialState = { loading: true, devices: [] };
const getUserScaDevicesWrapped = (clientId, httpClient) =>
  getUserScaDevices({ clientId, httpClient });

const DevicesListData = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();

  const [{ loading, error, devices }, dispatch] = useApiCallOnLoad(
    getUserScaDevicesWrapped,
    clientId,
    devicesListReducer,
    initialState
  );

  const updateDeviceProps = (device, props) => ({
    ...device,
    ...props,
  });

  const handleDeleteDeviceSuccess = ({
    deviceUuid,
    biometrics = false,
    mfaEnabled = false,
    pushEnabled = false,
    mfaSessionExists = false,
    onClose,
  }) => {
    const devicesUpdated = devices.map((device) => {
      if (device.device_uuid === deviceUuid) {
        return updateDeviceProps(device, {
          biometrics,
          mfa_enabled: mfaEnabled,
          push_enabled: pushEnabled,
          mfa_session_exists: mfaSessionExists,
        });
      }

      return device;
    });

    onClose();
    dispatch(setScaDevices(devicesUpdated));
  };

  const handleDeleteDeviceError = ({ deviceUuid, onClose }) => {
    const devicesUpdated = devices.map((device) => {
      if (device.device_uuid === deviceUuid) {
        return updateDeviceProps(device, { error: true });
      }

      return device;
    });

    onClose();
    dispatch(setScaDevices(devicesUpdated));
  };

  const handleDeleteDevice = (deviceUuid, onClose) => {
    deleteScaDevice({ httpClient, deviceUuid, clientId })
      .then(
        ({
          data: {
            biometrics,
            mfa_enabled: mfaEnabled,
            push_enabled: pushEnabled,
            mfa_session_exists: mfaSessionExists,
          },
        }) => {
          handleDeleteDeviceSuccess({
            deviceUuid,
            biometrics,
            mfaEnabled,
            pushEnabled,
            mfaSessionExists,
            onClose,
          });
        }
      )
      .catch(({ response: { status } }) => {
        if (status === STATUS_CODES.CONFLICT) {
          handleDeleteDeviceSuccess({ deviceUuid, onClose });
        } else {
          handleDeleteDeviceError({ deviceUuid, onClose });
        }
      });
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      {loading ? (
        <Loading />
      ) : (
        <>
          <DevicesList
            columns={columns}
            devices={devices}
            nonNumericFields={DEVICES_NON_NUMERIC_FIELDS_MAP}
            noResultsText="No SCA-enabled devices yet"
            clientId={clientId}
            onDeleteDevice={handleDeleteDevice}
          />
        </>
      )}
    </>
  );
};

export default DevicesListData;
