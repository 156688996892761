import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';
import { Confirm } from '@sumup/icons';
import { replace, toString } from 'lodash';
import { copyToClipboard } from 'services/common';
import Tooltip from 'components/Tooltip';

const StyledText = styled(Body)(
  ({ theme: { spacings, fontWeight }, copyable, cropText }) => css`
    span:first-of-type {
      margin-right: ${spacings.bit};
      font-weight: ${fontWeight.bold};
    }

    ${copyable && 'cursor: copy;'}

    ${cropText &&
    `
      display: block;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  `
);

const StyledCell = styled('div')(
  ({ centered, cropText }) => css`
    ${centered && 'text-align: center;'}

    ${cropText &&
    `
      text-align: center;
      width: 100%;
      display: block;
    `}
  `
);

const TooltipContent = styled('div')`
  width: 100%;
  display: block;
  text-align: left;
`;

const GrayedOutText = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.n500};
  `
);

const SuccessText = styled(Body)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.confirm};

    > svg {
      margin: 0 ${theme.spacings.bit} 0 0;

      path {
        fill: ${theme.colors.confirm};
      }
    }
  `
);

const tooltipStyle = {
  minWidth: 170,
};

const CopyableCell = ({
  value,
  fullLabel: label,
  style,
  textStyle,
  prefix: prefixComponent,
  centered,
  copyable,
  cropText,
}) => {
  const [copied, setCopied] = useState(false);

  const handleClick = ({ target: { textContent } }) => {
    if (textContent) {
      const { children: prefix } = (prefixComponent || {}).props || {};
      const text = prefix ? replace(textContent, prefix, '') : textContent;

      copyToClipboard(text);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1200);
    }
  };

  const cellContent = (
    <StyledText
      noMargin
      size="two"
      style={textStyle}
      copyable={copyable}
      cropText={cropText}
      value={value}
      onClick={handleClick}
    >
      {value ? prefixComponent : ''}
      {toString(value)}
    </StyledText>
  );

  return (
    <StyledCell style={style} centered={centered} cropText={cropText}>
      <div>
        {copyable ? (
          <Tooltip
            position="top"
            pivot={cellContent}
            content={
              <TooltipContent>
                {label && (
                  <Body noMargin size="two">
                    {label}:
                  </Body>
                )}
                <Body noMargin variant="highlight" size="two">
                  {value}
                </Body>
                {copied ? (
                  <SuccessText noMargin size="two">
                    <Confirm size="16" />
                    Copied!
                  </SuccessText>
                ) : (
                  <GrayedOutText noMargin size="two">
                    Click to copy
                  </GrayedOutText>
                )}
              </TooltipContent>
            }
            tooltipStyle={tooltipStyle}
          />
        ) : (
          cellContent
        )}
      </div>
    </StyledCell>
  );
};

CopyableCell.defaultProps = {
  value: '',
  fullLabel: '',
  style: {},
  textStyle: {},
  prefix: '',
  centered: false,
  copyable: false,
  cropText: false,
};

CopyableCell.propTypes = {
  value: PropTypes.any,
  fullLabel: PropTypes.string,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  prefix: PropTypes.any,
  centered: PropTypes.bool,
  copyable: PropTypes.bool,
  cropText: PropTypes.bool,
};

export default CopyableCell;
