import { APPS } from 'variables';

export const initialState = {
  isAuthenticated: false,
  loading: true,
  user: null,
  permissions: {
    [APPS.merchant]: {
      sections: {},
      actions: {},
    },
    [APPS.administration]: {
      sections: {},
      actions: {},
    },
    [APPS.reports]: {
      sections: {},
      actions: {},
    },
  },
  sessionExpired: false,
};
