import React from 'react';
import PropTypes from 'prop-types';
import { Input, Body } from '@sumup/circuit-ui';
import { ReactComponent as PlusFilled } from 'assets/plus-filled.svg';
import { debounce, map } from 'lodash';
import { DEBOUNCE_TIMEOUT_LONG } from 'variables';
import { ChevronRight } from '@sumup/icons';
import { ButtonSuccess } from 'components/UserAdministration/UserAdministrationStyled';
import {
  Section,
  InputWrapper,
  ListItems,
  ListItem,
  SpinnerStyled,
  ListItemInfo,
} from './ListSectionStyled';

const ListSection = ({
  buttonIcon,
  buttonLabel,
  hasSearch,
  searchPlaceholder,
  items,
  itemsLoading,
  selectedItem,
  selectedItemRef,
  hasEditRights,
  onItemClick,
  onSearch,
  onButtonClick,
}) => {
  const handleSearch = debounce(({ target: { value } }) => {
    onSearch({ value });
  }, DEBOUNCE_TIMEOUT_LONG);

  return (
    <Section>
      {hasEditRights && (
        <ButtonSuccess onClick={onButtonClick}>
          {buttonIcon}
          {buttonLabel}
        </ButtonSuccess>
      )}
      {hasSearch && (
        <InputWrapper divider={hasEditRights}>
          <Input
            noMargin
            placeholder={searchPlaceholder}
            label={searchPlaceholder}
            hideLabel
            onKeyUp={handleSearch}
          />
        </InputWrapper>
      )}
      <ListItems>
        {itemsLoading ? (
          <SpinnerStyled />
        ) : (
          map(items, ({ text, subText = [], count }) => (
            <ListItem
              key={text}
              selected={text === selectedItem.text}
              ref={text === selectedItem.text ? selectedItemRef : null}
              onClick={() => onItemClick({ text, count })}
            >
              <div>
                <Body noMargin variant="highlight" size="one">
                  {text}
                </Body>
                <ListItemInfo noMargin size="two">
                  {map(
                    subText,
                    (subTextItem, index) =>
                      `${index === 0 ? '' : ' · '}${subTextItem}`
                  )}
                </ListItemInfo>
              </div>
              <ChevronRight />
            </ListItem>
          ))
        )}
      </ListItems>
    </Section>
  );
};

ListSection.defaultProps = {
  buttonIcon: <PlusFilled />,
  buttonLabel: '',
  hasSearch: false,
  searchPlaceholder: '',
  items: [],
  itemsLoading: true,
  selectedItem: {},
  selectedItemRef: null,
  hasEditRights: false,
  onItemClick: () => {},
  onSearch: () => '',
};

ListSection.propTypes = {
  buttonIcon: PropTypes.element,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  hasSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  items: PropTypes.array,
  itemsLoading: PropTypes.bool,
  selectedItem: PropTypes.object,
  selectedItemRef: PropTypes.object,
  hasEditRights: PropTypes.bool,
  onItemClick: PropTypes.func,
  onSearch: PropTypes.func,
};

export default ListSection;
