export const initialState = {
  usersLoading: true,
  groupsLoading: false,
  userGroupsLoading: true,
  users: [],
  groups: [],
  filteredUsers: [],
  selectedUser: {
    text: '',
    added_by: '',
    groups: {},
  },
  error: null,
};
