import { trim } from 'lodash';
import {
  ACCOUNT_NUMBER_REGEX,
  EEA_SEPA_COUNTRIES,
  EUR_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH,
  IBAN_LETTER_DICTIONARY,
  IBAN_REGEX,
  SORT_CODE_REGEX,
  UK_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH,
} from 'variables';

export const matchesRegex = (value, regex) => regex.test(value);

const stripSpaces = (value) => value.replace(/\s+/g, '');

const rearrangeIban = (value) => value.substring(4) + value.substring(0, 4);

const replaceLettersWithDigits = (value) =>
  value.replace(/[A-Z]/g, (match) => IBAN_LETTER_DICTIONARY[match]);

/**
 * Based on:
 * https://en.wikipedia.org/wiki/International_Bank_Account_Number#Modulo_operation_on_IBAN
 */
const pieceWiseModule = (value, modRemainder = '', divisor = 97) => {
  if (!value) {
    return modRemainder;
  }

  // Determine how many digits to take for the dividend
  const digits = modRemainder ? 7 : 9;

  // Determine whether to take digits off of intString or use the entire string.
  const slice = Math.min(digits, value.length);

  // Compute the dividend from modRemainder and substring of integerString
  const dividend = Number(modRemainder + value.substring(0, slice));

  // Compute the modRemainder
  const nextRemainder = dividend % divisor;

  // Reduce the value by what we used for the dividend
  const nextValue = value.substring(slice);

  return pieceWiseModule(nextValue, nextRemainder);
};

export const validateSortCode = (value) =>
  value && SORT_CODE_REGEX.test(trim(value));

export const validateAccountNumber = (value) =>
  value && ACCOUNT_NUMBER_REGEX.test(trim(value));

export const validateIban = (value) => {
  if (!value || !IBAN_REGEX.test(value.replace(/\s+/g, ''))) {
    return false;
  }

  return pieceWiseModule(
    replaceLettersWithDigits(rearrangeIban(stripSpaces(value.toUpperCase())))
  );
};

export const validateEeaSepaIban = (value) => {
  if (!value) {
    return false;
  }

  const countryPrefix = stripSpaces(value).slice(0, 2).toUpperCase();

  return EEA_SEPA_COUNTRIES[countryPrefix];
};

export const normalizeAmount = (value) => {
  if (!value || !value.length) {
    return value;
  }

  const matches = value.match(/[^\d](\d{1,2})$/) || [];
  const [, decimals] = matches;

  const digits = value.replace(/[^\d]/g, '');

  if (digits === '') {
    return '0.00';
  }

  const integers =
    decimals === undefined ? digits : digits.slice(0, -decimals.length);
  const numberString = `${integers}.${decimals}`;

  return parseFloat(numberString);
};

export const validatePaymentReferenceMaxLength = (value, ukTransfer) =>
  !value ||
  (ukTransfer
    ? value.length <= UK_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH
    : value.length <= EUR_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH);
