import React from 'react';
import PropTypes from 'prop-types';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import {
  Card,
  CardContent,
} from 'components/ScheduledPayments/components/ScheduleDetailsStyled';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Info } from '@sumup/icons';
import moment from 'moment';
import { DATE_FORMATS } from 'variables';

const InfoCard = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacings.kilo} ${theme.spacings.mega};
    width: 100%;
    background: ${theme.colors.n100};
    border-radius: ${theme.borderRadius.byte};
  `
);

const Content = styled(CardContent)(
  ({ theme }) => css`
    padding: ${theme.spacings.giga} !important;
  `
);

export const UpcomingPaymentCard = ({ payment, exponent }) => {
  const {
    amount,
    currency,
    reference,
    settlement_date: settlementDate,
  } = payment;

  return (
    <Card fullWidth={false}>
      <div>
        <Body noMargin size="one" variant="highlight">
          Upcoming payment
        </Body>
      </div>
      <Content flexDirection="column">
        <Headline noMargin size="one">
          {amount / 10 ** exponent} {currency}
        </Headline>
        <Body noMargin size="one" css={spacing({ bottom: 'mega' })}>
          {moment(settlementDate).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
        </Body>
        <Body
          noMargin
          size="two"
          variant="subtle"
          css={spacing({ bottom: 'mega' })}
        >
          {reference}
        </Body>
        <InfoCard>
          <Info size="24" />
          <Body noMargin size="one" css={spacing({ left: 'mega' })}>
            The balance will be reserved at{' '}
            <Body noMargin size="one" variant="highlight">
              17:30 UTC{' '}
            </Body>
            one business day before the settlement date.
          </Body>
        </InfoCard>
      </Content>
    </Card>
  );
};

UpcomingPaymentCard.propTypes = {
  payment: PropTypes.object.isRequired,
  exponent: PropTypes.number.isRequired,
};
