import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { SORT_ORDER_DESCENDING } from 'variables';
import EmptyState from 'components/EmptyState';
import {
  Grid,
  HeaderWrapper,
  HeaderRow,
  BodyRowWrapper,
  BodyRowSection,
  BodyCell,
  ColumnTitle,
  SubColumnTitle,
  SubColumnTitleContent,
} from '../../Transactions/components/TransactionsGrid/TransactionsGridStyled';
import { CellFactory } from './factories/cell';
import { CREATED_AT, TRANSFER_ID } from '../fieldsConfig';
import SortableHeaderWrapper from '../../SortableHeaderWrapper';

const OpsTransfersGrid = ({
  columns,
  opsTransfers,
  sort,
  filtersApplied,
  user,
  onSort,
  dispatch,
  hasActionAccess,
  hasSectionAccess,
  updateOpsTransfers,
}) => {
  return (
    <Grid>
      <SortableHeaderWrapper
        columns={columns}
        sort={sort}
        onSort={onSort}
        HeaderWrapper={HeaderWrapper}
        HeaderRow={HeaderRow}
        ColumnTitle={ColumnTitle}
        SubColumnTitle={SubColumnTitle}
        SubColumnTitleContent={SubColumnTitleContent}
      />
      <div>
        {opsTransfers.length ? (
          map(opsTransfers, (transfer, index) => (
            <BodyRowWrapper key={`${transfer[TRANSFER_ID.name]}-${index}`}>
              {map(columns, ({ label, subColumns, style }, colIndex) => (
                <BodyRowSection
                  key={`body-row-section-${colIndex}-${label}`}
                  style={style}
                >
                  {map(
                    subColumns,
                    ({ key, style: colStyle, fields = [], centered }) => (
                      <BodyCell key={key} style={colStyle} centered={centered}>
                        {map(fields, ({ name, getValue, ...props }) => (
                          <CellFactory
                            {...props}
                            key={`${key}-${name}`}
                            value={
                              getValue
                                ? getValue(transfer, name)
                                : transfer[name]
                            }
                            rowData={transfer}
                            fieldName={name}
                            user={user}
                            dispatch={dispatch}
                            hasActionAccess={hasActionAccess}
                            hasSectionAccess={hasSectionAccess}
                            updateOpsTransfers={updateOpsTransfers}
                          />
                        ))}
                      </BodyCell>
                    )
                  )}
                </BodyRowSection>
              ))}
            </BodyRowWrapper>
          ))
        ) : (
          <EmptyState
            text={filtersApplied ? 'No matching transfers' : 'No transfers yet'}
          />
        )}
      </div>
    </Grid>
  );
};

OpsTransfersGrid.defaultProps = {
  opsTransfers: [],
  sort: {
    [CREATED_AT.name]: SORT_ORDER_DESCENDING,
    fieldName: CREATED_AT.name,
  },
};

OpsTransfersGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  opsTransfers: PropTypes.array,
  sort: PropTypes.object,
  filtersApplied: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  updateOpsTransfers: PropTypes.func.isRequired,
};

export default OpsTransfersGrid;
