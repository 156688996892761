import React from 'react';
import { css } from '@emotion/react';
import { Body, Button, spacing } from '@sumup/circuit-ui';
import moment from 'moment';
import styled from '@emotion/styled';
import { CUSTOM_FIELD_TYPES, DATE_FORMATS, FIELD_TYPES } from 'variables';
import {
  MANDATE_STATUS_ACTIVE,
  MANDATE_STATUS_CANCELLED,
  MANDATE_STATUS_REJECTED,
  STATUS_MAP,
  TYPE_LABEL,
} from './constants';
import { CancelDirectDebitMandateButton } from './components/CancelDirectDebitMandateButton';

const STATUS_CELL_WIDTH = 160;
const DETAILS_CELLS_WIDTH = 340;

const CreditorCell = styled('div')`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  text-align: center;
`;

const StatusCell = styled('div')`
  display: flex;
  justify-content: center;
  max-width: ${STATUS_CELL_WIDTH}px;
`;

const DetailsCells = styled('div')`
  min-width: ${DETAILS_CELLS_WIDTH}px;
  max-width: ${DETAILS_CELLS_WIDTH}px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
`;

const ViewDetailsButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacings.bit} ${theme.spacings.mega};
  `
);

const CREATED_AT = {
  label: 'Valid from',
  name: 'created_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  style: {
    maxWidth: 130,
  },
  centered: true,
  sortable: true,
};

const TYPE = {
  label: 'Type',
  name: 'frequency',
  getValue: ({ frequency }) => TYPE_LABEL[frequency] || null,
  style: {
    maxWidth: 130,
  },
  centered: true,
};

const STATUS = {
  label: 'Status',
  name: 'status',
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: ({ status }) => {
    const { label, icon } = STATUS_MAP[status] || {};

    return label ? (
      <StatusCell>
        {icon}
        <Body
          noMargin
          size="two"
          variant="highlight"
          css={spacing({ left: 'bit' })}
        >
          {label}
        </Body>
      </StatusCell>
    ) : null;
  },
  style: {
    maxWidth: STATUS_CELL_WIDTH,
  },
  centered: true,
  sortable: true,
};

const ACCOUNT_NUMBER = {
  name: 'account_number',
};

const SORT_CODE = {
  name: 'sort_code',
};

export const BENEFICIARY = {
  label: 'Creditor',
  name: 'beneficiary',
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  getValue: (record, fieldName) => {
    const beneficiary = record[fieldName];

    return (
      <CreditorCell>
        <Body noMargin size="two">
          {beneficiary?.name}
        </Body>
        <Body noMargin size="two">
          {`${beneficiary[SORT_CODE.name]} - ${
            beneficiary[ACCOUNT_NUMBER.name]
          }`}
        </Body>
      </CreditorCell>
    );
  },
  centered: true,
  sortable: true,
  sorted: true,
};

const ID = {
  label: 'ID/N',
  name: 'reference',
  style: {
    maxWidth: 210,
  },
  centered: true,
};

const DETAILS = {
  label: '',
  centered: true,
  renderAs: CUSTOM_FIELD_TYPES.CELL,
  headerBorder: 'none',
  getValue: (
    record,
    fieldName,
    { reloadMandates, mandateHistoryLoadingForId, onViewMandateDetailsClick }
  ) => {
    const {
      id,
      status,
      updated_at: updatedAt,
      cancelled_at: cancelledAt,
    } = record;
    const active = status === MANDATE_STATUS_ACTIVE;
    const isCancelled = status === MANDATE_STATUS_CANCELLED;
    const isRejected = status === MANDATE_STATUS_REJECTED;

    const handleViewDetailsClick = () => {
      onViewMandateDetailsClick(record);
    };

    return (
      <DetailsCells>
        <div>
          {active ? (
            <CancelDirectDebitMandateButton
              id={id}
              onConfirm={reloadMandates}
            />
          ) : (
            <>
              <Body noMargin size="two">
                {(isCancelled && 'Cancelled at') ||
                  (isRejected && 'Rejected at') ||
                  'Ended on'}
                :
              </Body>
              <Body noMargin size="two" variant="highlight">
                {moment(isCancelled ? cancelledAt : updatedAt).format(
                  DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                )}
              </Body>
            </>
          )}
        </div>
        <div>
          <ViewDetailsButton
            noMargin
            variant="secondary"
            isLoading={mandateHistoryLoadingForId === id}
            loadingLabel="Loading mandate details..."
            onClick={handleViewDetailsClick}
          >
            <Body noMargin size="one" variant="highlight">
              View details
            </Body>
          </ViewDetailsButton>
        </div>
      </DetailsCells>
    );
  },
  style: {
    minWidth: DETAILS_CELLS_WIDTH,
    maxWidth: DETAILS_CELLS_WIDTH,
  },
};

export const columns = [
  {
    ...CREATED_AT,
    active: true,
  },
  ID,
  TYPE,
  STATUS,
  BENEFICIARY,
  DETAILS,
];

export const MANDATES_NON_NUMERIC_FIELDS_MAP = {
  [CREATED_AT.name]: true,
};
