import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';
import { find, last, orderBy } from 'lodash';
import { ACTIONS, APPS, DEFAULT_CURRENCY_EXPONENT, PATHS } from 'variables';
import {
  STATUSES,
  TRY_AGAIN_LATER_MESSAGE,
} from 'components/OpsTransfers/constants';
import { getNameFromEmail } from 'services/common';
import {
  PAYMENT_ID,
  SENDER_CLIENT_CODE,
  SENDER_ID,
  STATUS,
  STATUS_CHANGES_FIELDS,
} from 'components/OpsTransfers/fieldsConfig';
import { ReactComponent as LinkIcon } from 'assets/link-to-page.svg';
import { approveOpsTransfer, preApproveOpsTransfer } from 'api';
import { InitiateButton } from './InitiateButton';
import { PreApproveAndAuthorizeButton } from './PreApproveAndAuthorizeButton';

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};
  `
);

const TextSubtle = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.n700};
  `
);

const TextDanger = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.r500};
  `
);

const TextSuccess = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.g700};
  `
);

const StatusActorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled('a')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacings.byte};
    color: ${theme.colors.p500};

    svg {
      margin-left: ${theme.spacings.bit};
    }
  `
);

export const NEXT_STATUS_COMPONENT = {
  // eslint-disable-next-line react/prop-types
  [STATUSES.REQUESTED]: ({ canInitiate, rowData, ...props }) =>
    canInitiate ? (
      <InitiateButton {...props} transfer={rowData} />
    ) : (
      <TextSubtle noMargin size="two" variant="highlight">
        Pending initiation
      </TextSubtle>
    ),
  // eslint-disable-next-line react/prop-types
  [STATUSES.INITIATED]: ({ canPreApprove, rowData, ...props }) =>
    canPreApprove ? (
      <PreApproveAndAuthorizeButton
        {...props}
        transfer={rowData}
        label="Pre-approve"
        modalProps={{
          title: 'Pre-approve transfer request?',
          explanation: 'The transfer will be sent for final authorization.',
          confirmButtonLabel: 'Pre-approve',
          successNotificationHeadline: 'Transfer request pre-approved',
          errorNotificationHeadline: 'Couldn’t pre-approve transfer request',
          errorNotificationBody: TRY_AGAIN_LATER_MESSAGE,
        }}
        onConfirm={preApproveOpsTransfer}
      />
    ) : (
      <TextSubtle noMargin size="two" variant="highlight">
        Pending pre-approval
      </TextSubtle>
    ),
  // eslint-disable-next-line react/prop-types
  [STATUSES.PRE_APPROVED]: ({ canAuthorize, rowData, ...props }) =>
    canAuthorize ? (
      <PreApproveAndAuthorizeButton
        {...props}
        transfer={rowData}
        label="Authorize"
        modalProps={{
          title: 'Authorize transfer request?',
          explanation: 'This action is final and irreversible.',
          confirmButtonLabel: 'Authorize',
          successNotificationHeadline: 'Transfer request authorized',
          successNotificationBody:
            'The transfer is on it’s way to the recipient.',
          errorNotificationHeadline: 'Couldn’t authorize transfer request',
          errorNotificationBody: TRY_AGAIN_LATER_MESSAGE,
        }}
        onConfirm={approveOpsTransfer}
      />
    ) : (
      <TextSubtle noMargin size="two" variant="highlight">
        Pending authorization
      </TextSubtle>
    ),
  // eslint-disable-next-line react/prop-types
  [STATUSES.APPROVED]: ({ actor }) => (
    <StatusActorWrapper>
      <TextSuccess noMargin size="two" variant="highlight">
        Transfer sent
      </TextSuccess>
      <Body noMargin size="two" variant="subtle">
        by {actor}
      </Body>
    </StatusActorWrapper>
  ),
  // eslint-disable-next-line react/prop-types
  [STATUSES.CANCELLED]: ({ actor }) => (
    <StatusActorWrapper>
      <TextDanger noMargin size="two" variant="highlight">
        Request cancelled
      </TextDanger>
      <Body noMargin size="two" variant="subtle">
        by {actor}
      </Body>
    </StatusActorWrapper>
  ),
  // eslint-disable-next-line react/prop-types
  [STATUSES.REJECTED]: ({ actor }) => (
    <StatusActorWrapper>
      <TextDanger noMargin size="two" variant="highlight">
        Request rejected
      </TextDanger>
      <Body noMargin size="two" variant="subtle">
        by {actor}
      </Body>
    </StatusActorWrapper>
  ),
};

const { requestsInitiate, requestsPreApproveAndAuthorize } =
  ACTIONS[APPS.ops_transfers];

export const NextStatusCell = ({
  value,
  rowData,
  exponent,
  user,
  hasActionAccess,
  updateOpsTransfers,
}) => {
  const canInitiate = hasActionAccess({ action: requestsInitiate });
  const canPreApproveAndAuthorize = hasActionAccess({
    action: requestsPreApproveAndAuthorize,
  });

  const statusChanges = STATUS_CHANGES_FIELDS.getValue(rowData);

  const { actor: initiator } =
    find(statusChanges, ({ status }) => status === STATUSES.INITIATED) || {};
  const { actor: preApprover } =
    find(statusChanges, ({ status }) => status === STATUSES.PRE_APPROVED) || {};

  const canPreApprove = canPreApproveAndAuthorize && initiator !== user;
  const canAuthorize =
    canPreApproveAndAuthorize && initiator !== user && preApprover !== user;

  const { status: lastStatus, actor } = last(orderBy(value, ['timestamp']));
  const paymentId = rowData[PAYMENT_ID.name];
  const clientId = SENDER_ID.getValue(rowData, SENDER_CLIENT_CODE.name);
  const status = rowData[STATUS.name];

  return (
    <Wrapper>
      {NEXT_STATUS_COMPONENT[lastStatus]({
        canInitiate,
        canPreApprove,
        canAuthorize,
        actor: getNameFromEmail(actor),
        rowData,
        exponent,
        updateOpsTransfers,
      })}
      {status === STATUSES.APPROVED && (
        <StyledLink
          target="_blank"
          rel="noreferrer"
          href={`/${PATHS.client}/${clientId}/${PATHS.transactions}?rrn=${paymentId}`}
        >
          View transfer <LinkIcon />
        </StyledLink>
      )}
    </Wrapper>
  );
};

NextStatusCell.defaultProps = {
  value: [],
  exponent: DEFAULT_CURRENCY_EXPONENT,
};

NextStatusCell.propTypes = {
  value: PropTypes.array,
  rowData: PropTypes.object.isRequired,
  exponent: PropTypes.number,
  user: PropTypes.string.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  updateOpsTransfers: PropTypes.func.isRequired,
};
