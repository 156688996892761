import { ERROR_MESSAGE, actionTypes } from 'variables';
import { DIRECT_DEBIT_MANDATES_FIRST_PAGE } from './constants';
import { initialState } from './state';

export const directDebitMandatesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: {
            total_pages: totalPages,
            total_elements: totalCount,
            content = [],
          },
        },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        mandates: content,
        totalPages,
        totalCount,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_DIRECT_DEBIT_MANDATES_PAGE: {
      const {
        payload: { page },
      } = action;

      const { totalPages } = state;

      return {
        ...state,
        currentPage: page > totalPages ? totalPages : page,
      };
    }
    case actionTypes.SET_DIRECT_DEBIT_MANDATES_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        currentPage: DIRECT_DEBIT_MANDATES_FIRST_PAGE,
        pageSize: size,
      };
    }
    case actionTypes.SET_DIRECT_DEBIT_MANDATES_STATUS_FILTER: {
      const {
        payload: { status },
      } = action;

      return {
        ...state,
        currentPage: DIRECT_DEBIT_MANDATES_FIRST_PAGE,
        statusFilter: status,
      };
    }
    case actionTypes.SORT_DIRECT_DEBIT_MANDATES: {
      const {
        payload: { name, order },
      } = action;

      return {
        ...state,
        sort: {
          [name]: order,
          fieldName: name,
        },
      };
    }
    case actionTypes.SET_SELECTED_MANDATE: {
      const {
        payload: { selectedMandate },
      } = action;

      return {
        ...state,
        selectedMandate,
      };
    }
    case actionTypes.SET_MANDATE_TRANSACTION_HISTORY_LOADING: {
      const {
        payload: { mandateId },
      } = action;

      return {
        ...state,
        transactionHistoryLoadingForId: mandateId,
      };
    }
    case actionTypes.LOAD_MANDATE_TRANSACTION_HISTORY_SUCCESS: {
      const {
        payload: {
          data: {
            total_pages: totalPages,
            total_elements: totalCount,
            content = [],
          },
        },
      } = action;

      return {
        ...state,
        transactionHistoryLoadingForId: null,
        transactionHistory: {
          ...state.transactionHistory,
          transactions: content,
          totalPages,
          totalCount,
        },
      };
    }
    case actionTypes.CLEAR_MANDATE_TRANSACTION_HISTORY: {
      return {
        ...state,
        transactionHistory: initialState.transactionHistory,
      };
    }
    case actionTypes.SORT_DIRECT_DEBIT_MANDATE_HISTORY: {
      const {
        payload: { name, order },
      } = action;

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          sort: {
            [name]: order,
            fieldName: name,
          },
        },
      };
    }
    case actionTypes.SET_MANDATE_HISTORY_PAGE: {
      const {
        payload: { page },
      } = action;

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          currentPage: page,
        },
      };
    }
    case actionTypes.SET_MANDATE_HISTORY_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          currentPage: DIRECT_DEBIT_MANDATES_FIRST_PAGE,
          pageSize: size,
        },
      };
    }
    case actionTypes.SET_MANDATE_UPCOMING_PAYMENT: {
      const {
        payload: { upcomingPayment },
      } = action;

      return {
        ...state,
        upcomingPayment,
      };
    }
    default:
      return state;
  }
};
