import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Popover } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import Status from 'components/Status';
import { BANK_TRX_FRAUD_STATUS_MAP, POPOVER_DIVIDER } from 'variables';
import { getStatusPopoverOptions } from 'components/Account/statusPopoverOptions';

const COMPONENT_MARGIN = 24;

const POPOVER_WIDTH = 400;

export const DATA_SELECTORS = {
  POPOVER_ARROW: 'POPOVER_ARROW',
  POPOVER_COMPONENT: 'POPOVER_COMPONENT',
};

const POPOVER_OPTIONS = [
  BANK_TRX_FRAUD_STATUS_MAP.PENDING,
  BANK_TRX_FRAUD_STATUS_MAP.SUCCESSFUL,
  POPOVER_DIVIDER,
  BANK_TRX_FRAUD_STATUS_MAP.REJECTED,
];

export const StatusPopoverComponent = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > p {
        font-size: ${theme.typography.body.one.fontSize};
      }
    }
    line-height: ${theme.typography.body.one.lineHeight};
    border-radius: ${theme.borderRadius.byte};
    border: ${theme.borderWidth.kilo} solid ${theme.colors.n500};
    padding: ${theme.spacings.kilo} ${theme.spacings.mega};
  `
);

export const Label = styled('div')(
  ({ theme }) => css`
    font-size: ${theme.typography.body.two.fontSize};
  `
);

export const FraudStatusWrapper = styled('div')`
  width: 100%;
  margin-bottom: ${COMPONENT_MARGIN}px;
  & > div {
    width: 100%;
  }
`;

export const PopoverStyled = styled(Popover)`
  & > div {
    width: ${POPOVER_WIDTH}px;
  }
`;

const FraudStatus = ({ fraudStatus, onFraudStatusChange }) => {
  const [statusPopoverOpen, setStatusPopoverOpen] = useState(false);

  const fraudStatusPopoverComponent = () => {
    const handleClick = (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }

      setStatusPopoverOpen(true);
    };

    return (
      <StatusPopoverComponent onClick={handleClick}>
        <Status value={fraudStatus} />
        {statusPopoverOpen ? (
          <ChevronUp data-testid={DATA_SELECTORS.POPOVER_ARROW} />
        ) : (
          <ChevronDown data-testid={DATA_SELECTORS.POPOVER_ARROW} />
        )}
      </StatusPopoverComponent>
    );
  };

  const popover = (
    <PopoverStyled
      data-testid={DATA_SELECTORS.POPOVER_COMPONENT}
      isOpen={statusPopoverOpen}
      placement="bottom"
      actions={getStatusPopoverOptions({
        statuses: POPOVER_OPTIONS,
        statusesMap: BANK_TRX_FRAUD_STATUS_MAP,
        onClick: onFraudStatusChange,
        isOptionDisabled: (status) => status === fraudStatus,
      })}
      component={fraudStatusPopoverComponent}
      onToggle={() => setStatusPopoverOpen(!statusPopoverOpen)}
    />
  );
  return (
    <FraudStatusWrapper>
      <Label>Status</Label>
      {popover}
    </FraudStatusWrapper>
  );
};

FraudStatus.propTypes = {
  fraudStatus: PropTypes.string.isRequired,
  onFraudStatusChange: PropTypes.func.isRequired,
};

export default FraudStatus;
