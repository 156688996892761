import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { Body, spacing, Spinner } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import moment from 'moment/moment';
import { filter } from 'lodash';
import keyMirror from 'key-mirror-nested';
import { Confirm } from '@sumup/icons';
import { useHttpClient } from '../../../../hooks';
import { getShippingInfo } from '../../../../api';
import {
  DATE_FORMATS,
  ERROR_MESSAGE,
  STATUS_CODES,
} from '../../../../variables';
import Error from '../../../Error';

const EVENT_CONSTANTS = keyMirror({
  SHIPPING: null,
  DELIVERY: null,
  SUCCESS: null,
  PENDING: null,
});

const ShippingInfoRow = styled('div')(
  ({ theme: { colors } }) => css`
    display: flex;
    position: relative;
    max-width: 480px;

    &:not(:last-child):after {
      position: absolute;
      width: 2px;
      display: block;
      content: '';
      height: calc(100% - 32px);
      background-color: ${colors.n200};
      top: 28px;
      left: 11px;
    }
  `
);

const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const StyledModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ShippingInfoWrapper = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    padding: ${spacings.kilo};
    background-color: ${colors.n100};
  `
);

const StyledText = styled(Body)(
  ({ theme: { spacings } }) => css`
    padding-bottom: ${spacings.byte};
  `
);

const FilledConfirm = styled(Confirm)(
  ({ theme: { spacings, colors } }) => css`
    flex-shrink: 0;
    path {
      fill: ${colors.confirm};
    }
    margin-right: ${spacings.kilo};
  `
);

const EmptyItem = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    width: 24px;
    height: 24px;
    background-color: ${colors.n100};
    border: 2px solid ${colors.n200};
    border-radius: 50%;
    margin-right: ${spacings.kilo};
  `
);

const formatShippingAddress = (address) => {
  return Object.values(address).filter(Boolean).join(', ');
};

const formatDate = (date) => {
  return moment(date).format(DATE_FORMATS.DAY_OF_WEEK_DAY_MONTH);
};
const getEventDate = (shippingInfo, type) => {
  const [{ expected_at: expectedAt, occurred_at: occurredAt, status }] = filter(
    shippingInfo.events,
    ({ event_type: eventType }) => eventType === type
  );
  const eventOccurred = status === EVENT_CONSTANTS.SUCCESS;
  const eventDate = eventOccurred ? occurredAt : expectedAt;
  return (eventOccurred ? '' : 'Expected ') + formatDate(eventDate);
};

const eventOccurred = (shippingInfo, type) => {
  const [{ status }] = filter(
    shippingInfo.events,
    ({ event_type: eventType }) => eventType === type
  );
  return status === EVENT_CONSTANTS.SUCCESS;
};

const ShippingInformationModal = ({ clientId, cardToken, cardId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const httpClient = useHttpClient();
  const [shippingInfo, setShippingInfo] = useState([]);

  const loadShippingInfo = useCallback(
    () =>
      getShippingInfo(httpClient, { clientId, cardToken })
        .then(({ data }) => {
          setShippingInfo(data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === STATUS_CODES.NOT_FOUND) {
            setError('No information available');
          } else {
            setError(ERROR_MESSAGE);
          }
          setLoading(false);
        }),
    [httpClient, cardToken, clientId]
  );

  useEffect(() => {
    setLoading(true);
    loadShippingInfo();
  }, [loadShippingInfo]);

  return (
    <StyledModalWrapper>
      <StyledText noMargin size="one" variant="highlight">
        Card {cardId} / Shipping Information
      </StyledText>
      {error && <Error>{error}</Error>}
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!loading && !error && (
        <ShippingInfoWrapper>
          <ShippingInfoRow>
            <FilledConfirm />
            <div>
              <Body variant="highlight" noMargin>
                Card ordered
              </Body>
              <Body
                data-testid="card-shipping-ordered-at"
                variant="subtle"
                css={spacing({ bottom: 'kilo' })}
                noMargin
              >
                {formatDate(shippingInfo.ordered_at)}
              </Body>
            </div>
          </ShippingInfoRow>
          <ShippingInfoRow>
            {eventOccurred(shippingInfo, EVENT_CONSTANTS.SHIPPING) ? (
              <FilledConfirm />
            ) : (
              <EmptyItem />
            )}
            <div>
              <Body
                {...(eventOccurred(shippingInfo, EVENT_CONSTANTS.SHIPPING) && {
                  variant: 'highlight',
                })}
                noMargin
              >
                Card sent
              </Body>
              <Body
                data-testid="card-shipping-sent-at"
                variant="subtle"
                css={spacing({ bottom: 'kilo' })}
                noMargin
              >
                {getEventDate(shippingInfo, EVENT_CONSTANTS.SHIPPING)}
              </Body>
              <Body variant="highlight" noMargin>
                Shipping address
              </Body>
              <Body
                data-testid="card-shipping-address"
                variant="subtle"
                css={spacing({ bottom: 'kilo' })}
                noMargin
              >
                {formatShippingAddress(shippingInfo.address)}
              </Body>
            </div>
          </ShippingInfoRow>
          <ShippingInfoRow>
            {eventOccurred(shippingInfo, EVENT_CONSTANTS.DELIVERY) ? (
              <FilledConfirm />
            ) : (
              <EmptyItem />
            )}
            <div>
              <Body
                {...(eventOccurred(shippingInfo, EVENT_CONSTANTS.DELIVERY) && {
                  variant: 'highlight',
                })}
                noMargin
              >
                Card delivered
              </Body>
              <Body
                data-testid="card-shipping-delivered-at"
                variant="subtle"
                noMargin
              >
                {getEventDate(shippingInfo, EVENT_CONSTANTS.DELIVERY)}
              </Body>
            </div>
          </ShippingInfoRow>
        </ShippingInfoWrapper>
      )}
    </StyledModalWrapper>
  );
};

ShippingInformationModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  cardToken: PropTypes.string.isRequired,
  cardId: PropTypes.number.isRequired,
};

export default ShippingInformationModal;
