import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Dropdown = styled('div')(
  ({ theme }) => css`
    display: flex;
    background: ${theme.colors.n100};
    font-weight: bold;
    padding: ${theme.spacings.byte} ${theme.spacings.bit};
    border-radius: ${theme.borderRadius.bit};
    margin: 0 ${theme.spacings.bit};
    align-items: center;
    cursor: pointer;
    word-break: initial;

    svg {
      color: ${theme.colors.n500};
      ${theme.mq.mega} {
        margin-left: ${theme.spacings.kilo};
      }
    }

    ${theme.mq.mega} {
      padding: ${theme.spacings.byte};
    }
  `
);

export default Dropdown;
