import formatExpiryDate from 'services/formatExpiryDate';
import { CUSTOM_FIELD_TYPES } from 'variables';

const CARD_TYPE = {
  label: 'Type',
  name: 'card_type',
  renderAs: CUSTOM_FIELD_TYPES.CARD_TYPE,
};

const CARDHOLDER_NAME = {
  label: 'Cardholder Name',
  name: 'cardholder_name',
};

const ISSUED_ON = {
  label: 'Issued on',
  name: 'activation_date',
};

export const PAN = {
  label: 'PAN',
  name: 'masked_pan',
};

const EXP_DATE = {
  label: 'Exp. date',
  name: 'expiry_date',
  style: {
    maxWidth: 120,
  },
  getValue: (record, name) =>
    record[name] ? formatExpiryDate(record[name]) : '',
};

const CARD_ID = {
  label: 'Card ID',
  name: 'id',
  style: {
    maxWidth: 120,
  },
};

export const CARD_TOKEN = {
  label: 'Card token',
  name: 'token',
};

const SOURCE = {
  label: 'Source',
  name: 'source',
};

const STATUS = {
  label: 'Status',
  name: 'status',
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
};

const MANAGE_WALLET_TOKENS = {
  label: '',
  name: '',
  renderAs: CUSTOM_FIELD_TYPES.BUTTON_LINK,
  getValue: () => 'Manage tokens',
  style: {
    maxWidth: 140,
  },
};

export const COLUMNS = [
  CARD_TYPE,
  CARDHOLDER_NAME,
  ISSUED_ON,
  PAN,
  EXP_DATE,
  CARD_ID,
  CARD_TOKEN,
  SOURCE,
  STATUS,
  MANAGE_WALLET_TOKENS,
];
