import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Spinner } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { UploadCloud as Icon } from '@sumup/icons';

export const DropZoneOverlay = styled('div')`
  ${({ theme: { colors, borderRadius } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 150px;
    border-radius: ${borderRadius.bit};
    background-image: url('${`data:image/svg+xml,%3csvg width="100%25" height="100%25" xmlns="http://www.w3.org/2000/svg"%3e%3crect width="100%25" height="100%25" fill="none" stroke="${encodeURIComponent(
      colors.n300
    )}" stroke-width="4" stroke-dasharray="6%2c 14" stroke-dashoffset="0" stroke-linecap="square"/%3e%3c/svg%3e`}');
    transition: all 0.1s ease-in-out;

    &.error {
      background-image: url('${`data:image/svg+xml,%3csvg width="100%25" height="100%25" xmlns="http://www.w3.org/2000/svg"%3e%3crect width="100%25" height="100%25" fill="none" stroke="${encodeURIComponent(
        colors.alert
      )}" stroke-width="4" stroke-dasharray="6%2c 14" stroke-dashoffset="0" stroke-linecap="square"/%3e%3c/svg%3e`}');
      color: ${colors.alert};
    }

    &.hover {
      background-color: ${colors.n100};
      background-image: url('${`data:image/svg+xml,%3csvg width="100%25" height="100%25" xmlns="http://www.w3.org/2000/svg"%3e%3crect width="100%25" height="100%25" fill="none" stroke="${encodeURIComponent(
        colors.p500
      )}" stroke-width="4" stroke-dasharray="6%2c 14" stroke-dashoffset="0" stroke-linecap="square"/%3e%3c/svg%3e`}');

      color: ${colors.p500};
    }
  `}
`;

const Input = styled('input')`
  width: 100%;
  height: auto;
  min-height: 150px;
  opacity: 0;
  position: relative;
  z-index: 2;
`;

const ContentContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Body)`
  ${({ theme: { colors, spacings } }) => css`
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    z-index: 1;

    &.error {
      position: absolute;
      left: 0;
      bottom: -${spacings.bit};
      transform: translateY(100%);
      color: ${colors.alert};
      text-align: left;
    }
  `}
`;

const UploadCloud = styled(Icon)`
  ${({ theme: { colors, iconSizes } }) => css`
    position: relative;
    width: ${iconSizes.giga};
    height: auto;
    text-align: center;
    z-index: 1;
    transition: all 0.1s ease-in-out;

    &.error {
      color: ${colors.alert};
    }

    &.hover {
      color: ${colors.p500};
    }
  `}
`;

export const FileInput = ({ loading, error, onChange }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <>
      <Input
        disabled={loading}
        accept=".csv"
        name="file"
        onChange={onChange}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        type="file"
      />
      <ContentContainer>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <UploadCloud
              className={classNames(
                isHovering && !loading && 'hover',
                !!error && 'error'
              )}
            />
            <StyledText noMargin>
              Click or drag and drop your file here
            </StyledText>
            {error && (
              <StyledText className="error" size="two" noMargin>
                {error}
              </StyledText>
            )}
          </>
        )}
      </ContentContainer>
      <DropZoneOverlay
        className={classNames(
          isHovering && !loading && 'hover',
          !!error && 'error'
        )}
      />
    </>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.element,
};

FileInput.defaultProps = {
  loading: false,
  error: null,
};
