import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { map, keys, values } from 'lodash/fp';
import buildLink from 'services/buildLink';
import restrictRoutes from 'services/restrictRoutes';
import {
  PATHS,
  MODULES,
  NAVIGATION_BACK_BUTTON_WIDTH,
  TOP_BAR_HEIGHT,
  NAVIGATION_HEADING_MAX_HEIGHT,
  NAVIGATION_HEADING_PADDING_TOP_BOTTOM,
  MODULE_PICKER_WIDTH,
  CLIENT_ONBOARD_STATUSES,
  NON_ONBOARDED_MERCHANT_ROUTES,
} from 'variables';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import AuthContext from 'context/auth';
import ClientDataContext from 'context/clientData';
import {
  ModulePickerWrapper,
  ModulePickerModalWrapper,
  Modules,
  Module,
  Sections,
  ArrowRight,
} from './ModulePickerStyled';
import {
  SectionLink,
  StyledCardIcon,
  StyledCardHeading,
} from '../../MerchantNavigationStyled';

// Temporary, until we resolve the CircuitUI issues
const showModulePicker = false;

const ModulePicker = ({
  activeModule,
  children,
  onboarded,
  clientId,
  onClick,
  onClose,
}) => {
  const [hoveredModule, setHoveredModule] = useState(activeModule);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  return (
    <>
      <ModulePickerWrapper>
        {showModulePicker && (
          <StyledCardHeading inner="true" onClick={onClick}>
            <StyledCardIcon inner="true">{activeModule.image}</StyledCardIcon>
            {activeModule.title}
            <ArrowRight />
          </StyledCardHeading>
        )}
        {children}
      </ModulePickerWrapper>
      <div
        onClose={onClose} // eslint-disable-line
        style={{
          content: {
            width: `${MODULE_PICKER_WIDTH}px`,
            marginTop:
              TOP_BAR_HEIGHT +
              NAVIGATION_HEADING_MAX_HEIGHT +
              2 * NAVIGATION_HEADING_PADDING_TOP_BOTTOM,
            marginLeft: NAVIGATION_BACK_BUTTON_WIDTH,
          },
        }}
      >
        {showModulePicker && (
          <ModulePickerModalWrapper>
            <Modules>
              {map(
                (appName) => (
                  <Module
                    key={appName}
                    bold
                    noMargin
                    hovered={hoveredModule.title === MODULES[appName].title}
                    onFocus={() => setHoveredModule(MODULES[appName])}
                    onMouseOver={() => setHoveredModule(MODULES[appName])}
                  >
                    <StyledCardIcon inner="true">
                      {activeModule.image}
                    </StyledCardIcon>
                    <Body noMargin size="one">
                      {MODULES[appName].title}
                    </Body>
                  </Module>
                ),
                keys(MODULES)
              )}
            </Modules>
            <Sections>
              {map(
                ({ title, link }) => (
                  <SectionLink
                    key={title}
                    disabled={
                      !NON_ONBOARDED_MERCHANT_ROUTES(clientData)[link] &&
                      onboarded !== CLIENT_ONBOARD_STATUSES.ONBOARDED
                    }
                    to={buildLink({
                      type: PATHS.client,
                      segment: PATHS[link],
                      id: clientId,
                    })}
                    onClick={onClose}
                  >
                    {title}
                    <ChevronRight />
                  </SectionLink>
                ),
                values(
                  restrictRoutes({
                    appName: hoveredModule,
                    status: onboarded,
                    permissions,
                    clientId,
                  })
                )
              )}
            </Sections>
          </ModulePickerModalWrapper>
        )}
      </div>
    </>
  );
};

ModulePicker.defaultProps = {
  children: '',
  onboarded: '',
};

ModulePicker.propTypes = {
  activeModule: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    sections: PropTypes.object.isRequired,
  }).isRequired,
  children: PropTypes.any,
  clientId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onboarded: PropTypes.any,
};

export default ModulePicker;
