import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { reduce } from 'lodash';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { useParams } from 'react-router-dom';
import {
  CANCEL_AUTHORIZATIONS_DATE_FILTER_COOKIE,
  CANCEL_AUTHORIZATIONS_PAGE_SIZE_COOKIE,
  DATE_FORMATS,
} from 'variables';
import { getAllAuthorizations } from 'api';
import { useNotificationModal } from '@sumup/circuit-ui';
import { loadSuccess, loadError, setLoading } from 'actions';
import Cookies from 'js-cookie';
import { CancelAuthorizations } from './CancelAuthorizations';
import { cancelAuthorizationsReducer } from './cancelAuthorizationsReducer';
import { initialState } from './state';
import { columns } from './fieldsConfig';
import {
  setPageSize,
  setPage,
  setFilter,
  applyFilters,
  clearFilters,
  setFilterDate,
  sortCancelAuthorizations,
} from './cancelAuthorizationsActions';
import { SORT_DIRECTION_MAP } from '../Transactions/constants';
import {
  BILLING_CURRENCY_FILTERS_ANY_OPTION,
  PRE_AUTH_FILTER_ANY_OPTION,
} from '../AdjustAuthorizations/constants';

const dateFilterCookie = Cookies.get(CANCEL_AUTHORIZATIONS_DATE_FILTER_COOKIE);
const pageSizeCookie = Cookies.get(CANCEL_AUTHORIZATIONS_PAGE_SIZE_COOKIE);

let initialDate = initialState.date;
let initialPageSize = initialState.size;

if (dateFilterCookie) {
  initialDate = dateFilterCookie;
} else {
  const { date } = initialState;
  Cookies.set(CANCEL_AUTHORIZATIONS_DATE_FILTER_COOKIE, date);
}

if (pageSizeCookie) {
  initialPageSize = pageSizeCookie;
} else {
  const { size } = initialState;
  Cookies.set(CANCEL_AUTHORIZATIONS_PAGE_SIZE_COOKIE, size);
}

const CancelAuthorizationsData = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();
  const { setModal: setNotificationModal } = useNotificationModal();
  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getAllAuthorizations({
          httpClient,
          page: initialState.currentPage,
          size: Number(initialPageSize),
          from: moment(initialDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          to: moment(initialDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        }),
      [httpClient]
    ),
    clientId,
    cancelAuthorizationsReducer,
    {
      ...initialState,
      date: moment(initialDate),
      size: Number(initialPageSize),
      filters: initialState.filters,
      filtersApplied: initialState.filtersApplied,
    }
  );

  const {
    authorizationsForCancellation,
    size,
    totalPages,
    currentPage,
    loading,
    filtersApplied,
    applyFiltersEnabled,
    date,
    filters,
    sort = {},
    error,
  } = state;

  const selectedFilters = reduce(
    filters,
    (accumulator, value, filter) => ({
      ...accumulator,
      ...(value !== BILLING_CURRENCY_FILTERS_ANY_OPTION.name &&
        value.name !== BILLING_CURRENCY_FILTERS_ANY_OPTION.name &&
        value !== PRE_AUTH_FILTER_ANY_OPTION.name &&
        value.name !== PRE_AUTH_FILTER_ANY_OPTION.name && {
          [filter]: value.name || value,
        }),
    }),
    {}
  );

  const { fieldName: sortBy } = sort;
  const sortDirection = SORT_DIRECTION_MAP[sort?.sortBy];

  useEffect(() => {
    if (error) {
      setNotificationModal({
        headline: error,
        actions: {
          primary: {
            children: 'OK',
          },
        },
      });
    }
  }, [error, setNotificationModal]);

  const updateAuthorizationsForCancellation = (args, successHandler) => {
    dispatch(setLoading(true));

    return getAllAuthorizations({
      httpClient,
      ...args,
    })
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          successHandler();
        }
      })
      .catch(() => dispatch(loadError()));
  };

  const handlePageSizeChange = (pageSize) => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForCancellation(
      {
        size: pageSize,
        page,
        from: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        sortDirection,
        sortBy,
        ...selectedFilters,
      },
      () => {
        dispatch(setPageSize({ size: pageSize }));

        Cookies.set(CANCEL_AUTHORIZATIONS_PAGE_SIZE_COOKIE, pageSize);
      }
    );
  };

  const handlePageChange = (page) => {
    updateAuthorizationsForCancellation(
      {
        size,
        page,
        from: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        sortDirection,
        sortBy,
        ...selectedFilters,
      },
      () => dispatch(setPage({ page }))
    );
  };

  const handleFilterChange = ({ field, value }) => {
    dispatch(setFilter({ field, value }));
  };

  const handleFiltersApply = () => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForCancellation(
      {
        size,
        page,
        from: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        sortDirection,
        sortBy,
        ...selectedFilters,
      },
      () => {
        dispatch(applyFilters());
      }
    );
  };

  const handleFiltersClear = () => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForCancellation(
      {
        size,
        page,
        from: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        sortDirection,
        sortBy,
      },
      () => {
        dispatch(clearFilters());
      }
    );
  };

  const handleDateChange = (dateFilter) => {
    dispatch(setFilterDate(dateFilter));

    if (dateFilter) {
      const { currentPage: page } = initialState;

      updateAuthorizationsForCancellation(
        {
          size,
          page,
          from: moment(dateFilter).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          to: moment(dateFilter).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          sortDirection,
          sortBy,
          ...selectedFilters,
        },
        () => {
          dispatch(setPage({ page }));

          Cookies.set(CANCEL_AUTHORIZATIONS_DATE_FILTER_COOKIE, dateFilter);
        }
      );
    }
  };

  const handleSort = ({ name, order }) => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForCancellation(
      {
        size,
        page,
        from: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(date).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        sortDirection: SORT_DIRECTION_MAP[order],
        sortBy: name,
        ...selectedFilters,
      },
      () => dispatch(sortCancelAuthorizations({ name, order }))
    );
  };

  return (
    <CancelAuthorizations
      authorizationsForCancellation={authorizationsForCancellation}
      columns={columns}
      onPageSizeChange={handlePageSizeChange}
      size={size}
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChange={handlePageChange}
      loading={loading}
      filtersApplied={filtersApplied}
      applyFiltersEnabled={applyFiltersEnabled}
      onFilterChange={handleFilterChange}
      onFiltersApply={handleFiltersApply}
      onFiltersClear={handleFiltersClear}
      filters={filters}
      date={date}
      onDateChange={handleDateChange}
      sort={sort}
      onSort={handleSort}
    />
  );
};

export default CancelAuthorizationsData;
