import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const TreeBottom = styled('div')`
  height: 80px;
`;

export const InnerItems = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div {
      > div {
        flex-grow: 1;
      }

      > svg {
        margin: 16px ${theme.spacings.kilo};
      }
    }
  `
);

export const InnerItemWrapper = styled('div')(
  ({ itemIndex }) => css`
    display: flex;

    ${itemIndex > 0 && 'margin-left: 38px'}
  `
);
