import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Content = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    > label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      :not(:last-of-type) {
        margin-bottom: ${theme.spacings.giga};
      }

      > div {
        width: 100%;
      }
    }
  `
);

export const UnmarkFraudReportModalContent = ({ onCommentGenerate }) => (
  <Content>
    <Input
      label="Comment"
      hideLabel
      placeholder="Comment"
      noMargin
      onChange={onCommentGenerate}
    />
  </Content>
);

UnmarkFraudReportModalContent.propTypes = {
  onCommentGenerate: PropTypes.func.isRequired,
};
