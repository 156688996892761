import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { DEFAULT_FRACTION_DIGITS } from 'variables';

const FloatCell = ({ value, fractionDigits }) => (
  <div>
    <Body noMargin size="two">
      {value && parseFloat(value).toFixed(fractionDigits)}
    </Body>
  </div>
);

FloatCell.defaultProps = {
  value: '',
  fractionDigits: DEFAULT_FRACTION_DIGITS,
};

FloatCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fractionDigits: PropTypes.number,
};

export default FloatCell;
