import { orderBy } from 'lodash';
import { SORT_ORDER_ASCENDING } from 'variables/globals';
import { isNumeric } from './common';

const sortGrid = ({
  data,
  name,
  order = SORT_ORDER_ASCENDING,
  nonNumericFields = {},
  getValue,
}) =>
  orderBy(
    data,
    [
      (record) => {
        const fieldValue = getValue ? getValue(record, name) : record[name];

        if (fieldValue === null) {
          return -1;
        }

        if (typeof fieldValue === 'object') {
          return fieldValue.label;
        }

        if (isNumeric(fieldValue) && !nonNumericFields[name]) {
          return parseFloat(fieldValue);
        }

        return fieldValue;
      },
    ],
    order
  );

export default sortGrid;
