import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { StyledCard } from '../../TransactionsStyled';
import {
  ALL_TRANSACTION_ORIGINS,
  BANK_TRANSACTION_ORIGIN,
  CARD_TRANSACTION_ORIGIN,
  DIRECT_DEBIT_TRANSACTION_ORIGIN,
  TRANSACTION_ORIGIN_FILTER_OPTIONS,
} from '../../constants';
import { StyledRadioButtonGroup } from './TransactionOriginFilterStyled';

const ALL = TRANSACTION_ORIGIN_FILTER_OPTIONS[ALL_TRANSACTION_ORIGINS];
const BANK = TRANSACTION_ORIGIN_FILTER_OPTIONS[BANK_TRANSACTION_ORIGIN];
const CARD = TRANSACTION_ORIGIN_FILTER_OPTIONS[CARD_TRANSACTION_ORIGIN];
const DIRECT_DEBIT =
  TRANSACTION_ORIGIN_FILTER_OPTIONS[DIRECT_DEBIT_TRANSACTION_ORIGIN];

const TransactionOriginFilter = ({ filter, onChange }) => {
  const options = [
    {
      value: ALL.value,
      label: ALL.label,
    },
    {
      value: BANK.value,
      label: BANK.label,
    },
    {
      value: CARD.value,
      label: CARD.label,
    },
    {
      value: DIRECT_DEBIT.value,
      label: DIRECT_DEBIT.label,
    },
  ];

  return (
    <>
      <StyledCard>
        <Body size="two" noMargin>
          Transactions:
        </Body>
        <StyledRadioButtonGroup
          value={filter}
          options={options}
          label="Select transaction origin filter"
          hideLabel
          onChange={({ target: { value } }) => onChange(value)}
        />
      </StyledCard>
    </>
  );
};

TransactionOriginFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TransactionOriginFilter;
