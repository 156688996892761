import { actionTypes } from 'variables';

export const setFilterDateRange = (datePeriod) => ({
  type: actionTypes.SET_TRANSACTIONS_DATE_RANGE_FILTER,
  payload: { datePeriod },
});

export const setPage = ({ page }) => ({
  type: actionTypes.SET_TRANSACTIONS_PAGE,
  payload: { page },
});

export const setTransactionOriginFilter = ({ origin }) => ({
  type: actionTypes.SET_TRANSACTION_ORIGIN_FILTER,
  payload: { origin },
});

export const setTransactionDirectionFilter = ({ direction }) => ({
  type: actionTypes.SET_TRANSACTION_DIRECTION_FILTER,
  payload: { direction },
});

export const setFilter = ({ field, value }) => ({
  type: actionTypes.SET_TRANSACTION_FILTER,
  payload: { field, value },
});

export const setPageSize = ({ size }) => ({
  type: actionTypes.SET_TRANSACTIONS_PAGE_SIZE,
  payload: { size },
});

export const applyFilters = () => ({
  type: actionTypes.APPLY_TRANSACTIONS_FILTERS,
});

export const clearFilters = () => ({
  type: actionTypes.CLEAR_TRANSACTIONS_FILTERS,
});

export const sortTransactions = ({ name, order }) => ({
  type: actionTypes.SORT_TRANSACTIONS,
  payload: { name, order },
});
