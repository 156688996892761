import { ERROR_MESSAGE, actionTypes } from 'variables';
import { filter, values, isEmpty } from 'lodash';
import {
  AUTHORIZATIONS_GRID_FIRST_PAGE,
  TRANSACTION_FILTERS_ANY_OPTION,
} from './constants';
import { initialState } from './state';

export const adjustAuthorizationsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }

    case actionTypes.SET_ADJUST_AUTHORIZATION_LOADING: {
      const {
        payload: { authorizationAdjustmentId },
      } = action;

      return {
        ...state,
        adjustAuthorizationLoading: [
          ...state.adjustAuthorizationLoading,
          authorizationAdjustmentId,
        ],
      };
    }

    case actionTypes.SET_IGNORE_AUTHORIZATION_LOADING: {
      const {
        payload: { authorizationAdjustmentId },
      } = action;

      return {
        ...state,
        ignoreAuthorizationLoading: [
          ...state.ignoreAuthorizationLoading,
          authorizationAdjustmentId,
        ],
      };
    }

    case actionTypes.REMOVE_ADJUST_AUTHORIZATION_LOADING: {
      const {
        payload: { authorizationAdjustmentId },
      } = action;

      return {
        ...state,
        adjustAuthorizationLoading: state.adjustAuthorizationLoading.filter(
          (item) => item !== authorizationAdjustmentId
        ),
      };
    }

    case actionTypes.REMOVE_IGNORE_AUTHORIZATION_LOADING: {
      const {
        payload: { authorizationAdjustmentId },
      } = action;

      return {
        ...state,
        ignoreAuthorizationLoading: state.ignoreAuthorizationLoading.filter(
          (item) => item !== authorizationAdjustmentId
        ),
      };
    }

    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: { content = [], totalSize, total_pages: totalPages },
        },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        authorizationsForAdjustment: content,
        totalSize,
        totalPages,
      };
    }

    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }

    case actionTypes.SET_AUTHORIZATIONS_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        currentPage: AUTHORIZATIONS_GRID_FIRST_PAGE,
        size,
      };
    }

    case actionTypes.SET_AUTHORIZATIONS_PAGE: {
      const {
        payload: { page },
      } = action;
      const { totalPages } = state;

      return {
        ...state,
        currentPage: page > totalPages ? totalPages : page,
      };
    }

    case actionTypes.SET_AUTHORIZATIONS_DATE_RANGE_FILTER: {
      const {
        payload: { datePeriod },
      } = action;

      return {
        ...state,
        datePeriod: {
          ...state.datePeriod,
          ...datePeriod,
        },
      };
    }

    case actionTypes.APPLY_AUTHORIZATIONS_FILTERS: {
      const { filters } = state;
      const selectedFilters = filter(
        values(filters),
        (selectedFilterValue) =>
          !isEmpty(selectedFilterValue) &&
          selectedFilterValue.name !== TRANSACTION_FILTERS_ANY_OPTION.name
      );

      return {
        ...state,
        currentPage: AUTHORIZATIONS_GRID_FIRST_PAGE,
        filtersApplied: !!selectedFilters.length,
        applyFiltersEnabled: false,
      };
    }

    case actionTypes.CLEAR_AUTHORIZATIONS_FILTERS: {
      const { filters } = initialState;

      return {
        ...state,
        currentPage: AUTHORIZATIONS_GRID_FIRST_PAGE,
        filters,
        filtersApplied: false,
        applyFiltersEnabled: false,
      };
    }

    case actionTypes.SET_AUTHORIZATIONS_FILTER: {
      const {
        payload: { field, value },
      } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          [field]: value,
        },
        applyFiltersEnabled: true,
      };
    }

    case actionTypes.UPDATE_AUTHORIZATIONS_FOR_ADJUSTMENT_SUCCESS: {
      const {
        payload: { authorizationsForAdjustmentUpdated },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        authorizationsForAdjustment: authorizationsForAdjustmentUpdated,
      };
    }

    default:
      return state;
  }
};
