import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Navigation from 'components/Navigation';
import { PATHS } from 'variables';
import { PageComponentWrapper } from './PageStyled';

const Page = ({
  title,
  component: PageComponent,
  render,
  componentStyle,
  heading,
  link,
  sections,
  user,
}) => {
  useEffect(() => {
    document.title = title;
  });

  return (
    <>
      {render !== '/' && render !== PATHS.login && (
        <Navigation
          render={render}
          heading={heading}
          link={link}
          sections={sections}
        />
      )}
      <PageComponentWrapper style={componentStyle}>
        <PageComponent user={user} />
      </PageComponentWrapper>
    </>
  );
};

Page.defaultProps = {
  component: () => null,
  componentStyle: {},
  user: null,
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.func,
  render: PropTypes.string.isRequired,
  componentStyle: PropTypes.object,
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  sections: PropTypes.object.isRequired,
  user: PropTypes.string,
};

export default Page;
