import { actionTypes } from 'variables';

export const setClientLimits = (clientLimits) => ({
  type: actionTypes.SET_CLIENT_LIMITS,
  payload: {
    clientLimits,
  },
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: { loading },
});
