import React from 'react';
import PropTypes from 'prop-types';
import { Body, Card, RadioButtonGroup } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Wrapper = styled(Card)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-radius: ${theme.borderRadius.byte};
    border-width: ${theme.borderWidth.kilo};
    width: fit-content;

    > p {
      display: flex;
      align-items: center;
    }

    fieldset {
      display: flex;
      flex-direction: row;
      font-size: ${theme.typography.body.two.fontSize};
      line-height: ${theme.typography.body.two.lineHeight};

      > div {
        display: flex;
        align-items: center;
        border-left: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
        padding: ${theme.spacings.byte} ${theme.spacings.byte}
          ${theme.spacings.byte} 0;

        > label {
          margin-left: ${theme.spacings.byte};
        }
      }
    }
  `
);

const Title = styled(Body)(
  ({ theme }) => css`
    padding: ${theme.spacings.byte};
  `
);

const RadioButtonFilter = ({ selectedValue, label, options, onChange }) => {
  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <Wrapper>
        <Title size="two" noMargin>
          {label}
        </Title>
        <RadioButtonGroup
          value={selectedValue}
          options={options}
          label={label}
          hideLabel
          onChange={handleChange}
        />
      </Wrapper>
    </>
  );
};

RadioButtonFilter.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonFilter;
