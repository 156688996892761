import { map, reduce } from 'lodash';
import { actionTypes, ERROR_MESSAGE } from 'variables';
import {
  filterUsers,
  pluralize,
} from 'components/UserAdministration/userAdministrationService';

export const userAdministrationUsersReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: { data = {} },
      } = action;

      const { selectedUser, searchTerm } = state;
      const users = map(data.users, ({ email, count }) => ({
        text: email,
        subText: [`Assigned to ${count} ${pluralize('group', count)}`],
      }));

      return {
        ...state,
        users,
        filteredUsers: filterUsers({ pattern: searchTerm, users }),
        selectedUser: users.length ? users[0] : selectedUser,
        usersLoading: false,
        error: null,
      };
    }
    case actionTypes.SELECT_USER: {
      const {
        payload: { email },
      } = action;

      return {
        ...state,
        selectedUser: {
          text: email,
        },
        error: null,
      };
    }
    case actionTypes.FILTER_USERS: {
      const {
        payload: { pattern },
      } = action;

      const { users } = state;

      return {
        ...state,
        searchTerm: pattern,
        filteredUsers: filterUsers({ pattern, users }),
      };
    }
    case actionTypes.SET_GROUPS_LOADING: {
      const {
        payload: { groupsLoading },
      } = action;

      return {
        ...state,
        groupsLoading,
      };
    }
    case actionTypes.LOAD_GROUPS_SUCCESS: {
      const {
        payload: {
          data: { groups },
        },
      } = action;

      return {
        ...state,
        groups,
        groupsLoading: false,
      };
    }
    case actionTypes.SET_USERS_LOADING: {
      const {
        payload: { usersLoading },
      } = action;

      return {
        ...state,
        usersLoading,
      };
    }
    case actionTypes.SET_USER_GROUPS_LOADING: {
      const {
        payload: { userGroupsLoading },
      } = action;

      return {
        ...state,
        userGroupsLoading,
      };
    }
    case actionTypes.LOAD_USER_GROUPS_SUCCESS: {
      const {
        payload: { data = {} },
      } = action;

      const { groups = [] } = data;
      const { selectedUser } = state;
      const selectedUserGroups = reduce(
        groups,
        (accumulator, group) => ({ ...accumulator, [group]: true }),
        {}
      );

      return {
        ...state,
        selectedUser: {
          ...selectedUser,
          groups: selectedUserGroups,
        },
        userGroupsLoading: false,
        error: null,
      };
    }
    case actionTypes.LOAD_USER_GROUPS_ERROR: {
      return {
        ...state,
        error: ERROR_MESSAGE,
        userGroupsLoading: false,
      };
    }
    case actionTypes.SET_SELECTED_USER_GROUPS: {
      const {
        payload: { groups },
      } = action;

      const { selectedUser } = state;

      return {
        ...state,
        selectedUser: {
          ...selectedUser,
          groups,
        },
      };
    }
    case actionTypes.LOAD_GROUPS_ERROR: {
      return {
        ...state,
        groupsLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        usersLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.CREATE_USER_ERROR:
    case actionTypes.UPDATE_USER_ERROR:
    case actionTypes.DELETE_USER_ERROR: {
      return {
        ...state,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_ERROR: {
      const {
        payload: { error },
      } = action;

      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
};
