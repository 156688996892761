export const CREATE_BUSINESS_ACCOUNT = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Create',
  title: 'Create business account?',
  width: 420,
};

export const ISSUE_CARD = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Issue card',
  title: 'Issue new card',
  width: 460,
};

export const REPLACE_CARD = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Replace card',
  title: 'Replace card',
  width: 460,
};

export const CHANGE_CARD_STATUS = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Change',
  title: 'Change card status?',
  width: 420,
};

export const PSD_STATUS_ENROLL = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Enroll',
  content:
    'The merchant would start receiving confirmation prompts on their mobile devices when making online payments.',
  title: 'Enroll for 3D Secure?',
  width: 420,
};

export const PSD_STATUS_UNENROLL = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Unenroll',
  content:
    'The merchant would stop receiving confirmation prompts on their mobile devices when making online payments.',
  title: 'Unenroll for 3D Secure?',
  width: 420,
};

export const PSD_STATUS_BLOCK = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Block',
  title: 'Temporarily block 3D Secure?',
  width: 420,
};

export const PSD_STATUS_UNBLOCK = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Unblock',
  title: 'Unblock 3D Secure?',
  width: 420,
};

export const TRANSFER_TO_BANK = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Transfer',
  title: 'Transfer to third-party account',
  width: 420,
};

export const CHANGE_ACCOUNT_STATUS = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Change',
  title: 'Change account status?',
  width: 420,
};

export const CLOSE_BUSINESS_ACCOUNT = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Close account',
  title: 'Close business account?',
  content: 'Please note that account closure is irreversible.',
  width: 420,
  confirmButtonProps: {
    destructive: true,
  },
};

export const ENABLE_DIRECT_DEBITS = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Enable',
  title: 'Enable direct debits?',
  content:
    'The merchant will be able to pay creditors from their Business Account by direct debit.',
  width: 420,
};

export const DISABLE_DIRECT_DEBITS = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Disable',
  title: 'Disable direct debits?',
  width: 420,
  confirmButtonProps: {
    destructive: true,
  },
};

export const ISSUE_A_RETURN = {
  cancelText: 'Cancel',
  centered: true,
  confirmText: 'Issue a return',
  title: 'Issue a return for this transaction?',
  width: 420,
  confirmButtonProps: {
    destructive: true,
  },
};
