import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SearchInput, Card, Button } from '@sumup/circuit-ui';
import { SEARCH_WIDTH } from 'variables';

export const SearchBox = styled(Card)`
  ${({ theme: { spacings, colors, borderRadius } }) => css`
    display: flex;
    flex-direction: row;
    height: 56px;
    width: ${SEARCH_WIDTH}px;
    padding: ${spacings.byte};
    border-radius: ${borderRadius.tera};

    > label {
      width: 100%;

      > div {
        * {
          height: 36px;
        }

        > svg:first-of-type {
          padding: 0 ${spacings.mega};

          path {
            fill: ${colors.n300};
          }
        }

        > button {
          padding: 0 ${spacings.mega} !important;
        }

        > input {
          padding: 6px ${spacings.exa};
        }
      }
    }
  `}
`;

export const StyledSearchInput = styled(SearchInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledError = styled(Card)(
  ({ theme, error, warning }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${SEARCH_WIDTH}px;
    margin-top: ${theme.spacings.byte};
    padding: ${theme.spacings.mega};
    justify-content: flex-start;

    svg {
      &:first-of-type {
        width: 24px;
        height: 24px;
        color: ${(error && theme.colors.alert) ||
        (warning && theme.colors.notify)};
        margin-right: ${theme.spacings.giga};
      }
      &:last-of-type {
        width: 16px;
        height: 16px;
        color: ${theme.colors.black};
        margin-left: auto;
        cursor: pointer;
      }
    }
  `
);

export const StyledLoadingButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: ${theme.spacings.byte};
  `
);
