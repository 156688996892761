import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, spacing, Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PATHS } from 'variables';

const StyledLink = styled.div(
  ({ theme: { typography } }) => css`
    display: flex;
    align-self: flex-start;
    a {
      font-size: ${typography.body.two.fontSize};
      line-height: ${typography.body.two.lineHeight};
    }
  `
);

const Link = ({
  value: {
    details: { authorizationCode = '', merchantId = '' },
    type,
  },
}) => {
  const { origin } = window.location;

  const linkText = merchantId;
  const linkHref =
    type === PATHS.client
      ? `${origin}/${PATHS.client}/${merchantId}`
      : `${origin}/${PATHS.client}/${merchantId}/${PATHS.transactions}?auth_code=${authorizationCode}`;

  return (
    <StyledLink>
      <Body
        variant="highlight"
        noMargin
        size="two"
        css={spacing({ right: 'bit' })}
      >
        M:{' '}
      </Body>
      <Anchor href={linkHref}>{linkText}</Anchor>
    </StyledLink>
  );
};

Link.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Link;
