import React from 'react';
import PropTypes from 'prop-types';
import { Body, ButtonGroup, spacing } from '@sumup/circuit-ui';
import { BUTTONS_ACTIONS } from 'components/AdjustAuthorizations/constants';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const AdjustAuthorizationModalContent = styled.div`
  max-width: 370px;
  text-align: center;
  margin: 0 auto;
`;

const StyledBody = styled(Body)(
  ({ theme }) => css`
    font-size: ${theme.typography.headline.three.fontSize};
    line-height: ${theme.typography.headline.three.lineHeight};
    font-weight: bold;
  `
);

const StyledButtonGroup = styled(ButtonGroup)(
  ({ theme, returnAction }) => css`
    button + button {
      ${returnAction
        ? `
          background: ${theme.colors.g700};
          border-color: ${theme.colors.g700};
          &:hover {
            background: ${theme.colors.g700};
            border-color: ${theme.colors.g700};
          }
        `
        : `
          background: ${theme.colors.r500};
          border-color: ${theme.colors.r500};
          &:hover {
            background: ${theme.colors.r500};
            border-color: ${theme.colors.r500};
          }
        `}
      color: ${theme.colors.white};
    }
  `
);

const AdjustAuthorizationModal = ({
  onClose,
  onConfirm,
  action,
  authorizationAdjustmentId,
  formattedAmount,
}) => {
  const returnAction = action === BUTTONS_ACTIONS.RETURN;

  return (
    <AdjustAuthorizationModalContent>
      <StyledBody noMargin css={spacing({ bottom: 'mega' })}>
        {returnAction
          ? `Return ${formattedAmount} to the merchant?`
          : `Charge merchant with ${formattedAmount} additionally?`}
      </StyledBody>
      <Body size="one" noMargin css={spacing({ bottom: 'mega' })}>
        {returnAction
          ? 'The amount will be added to their balance.'
          : 'The amount will be deducted from their balance.'}
      </Body>
      <StyledButtonGroup
        align="center"
        returnAction={returnAction}
        actions={{
          secondary: {
            onClick: onClose,
            children: 'Cancel',
          },
          primary: {
            onClick: () =>
              onConfirm({ authorizationAdjustmentId, action, onClose }),
            children: returnAction ? 'Return to merchant' : 'Charge merchant',
            variant: 'secondary',
            autoFocus: true,
          },
        }}
      />
    </AdjustAuthorizationModalContent>
  );
};

AdjustAuthorizationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  authorizationAdjustmentId: PropTypes.number.isRequired,
  formattedAmount: PropTypes.string.isRequired,
};

export default AdjustAuthorizationModal;
