import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Body,
  Button,
  Checkbox,
  Headline,
  Hr,
  Input,
  spacing,
  TextArea,
  useNotificationToast,
} from '@sumup/circuit-ui';
import { formatCurrency } from '@sumup/intl';
import { forEach, keys, round, some, trim, values } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useHttpClient } from 'hooks';
import {
  DEFAULT_CURRENCY_EXPONENT,
  EUR_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH,
  HYPHEN_KEY_CODE,
  UK_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH,
  EN_GB_LOCALE,
} from 'variables';
import { formatStringNumber } from 'services/formatNumber';
import { getClient } from 'api';
import { TRY_AGAIN_LATER_MESSAGE } from 'components/OpsTransfers/constants';
import { validateFields } from './service';
import {
  FIELD_NAMES,
  FIELD_PLACEHOLDER,
  FIELD_RULES,
  MODAL_MEGA_TO_GIGA_WIDTH,
  MODAL_WIDTH,
} from './constants';

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${MODAL_WIDTH}px;

    ${theme.mq.megaToGiga} {
      width: ${MODAL_MEGA_TO_GIGA_WIDTH}px;
    }
  `
);

const ContentWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin: ${theme.spacings.mega} 0;

    > div {
      width: 48%;

      :not(:last-of-type) {
        margin-right: ${theme.spacings.giga};
      }
    }

    ${theme.mq.megaToGiga} {
      flex-direction: column;
      overflow: auto;

      > div {
        width: 96%;
        margin: ${theme.spacings.byte} ${theme.spacings.bit};
      }
    }
  `
);

const RecipientInformationWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TransferInformationWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TransferInformation = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
    border-radius: ${theme.borderRadius.bit};
    background: ${theme.colors.n100};
  `
);

const TransferInformationContent = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  `
);

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledHr = styled(Hr)`
  margin: 0;
`;

const FieldWrapper = styled('div')(
  ({ theme, bottomMargin }) => css`
    margin-bottom: ${theme.spacings[bottomMargin] || theme.spacings.mega};
  `
);

const MIN_PARTIAL_AMOUNT = 0.01;

const RequestTransferModal = ({
  locale,
  accountId,
  ukTransfer,
  exponent,
  heading,
  initialValues,
  currency,
  clientId,
  transferId,
  submitSuccessNotificationHeadline,
  submitErrorNotificationHeadline,
  submitErrorNotificationBody,
  submitButtonLabel,
  successHandler,
  onSubmit,
  onClose,
  ...props
}) => {
  const [fieldsValidations, setFieldsValidations] = useState({});
  const [requestButtonClicked, setRequestButtonClicked] = useState(false);
  const [hideRecipientDetails, setHideRecipientDetails] = useState(
    initialValues && !(initialValues || {})[FIELD_NAMES.show_beneficiary]
  );
  const [requestButtonDisabled, setRequestButtonDisabled] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [balance, setBalance] = useState(props.balance);

  const httpClient = useHttpClient();

  const { setToast } = useNotificationToast();

  const fieldsRef = useRef();
  const setFieldsRef = (name, value) => {
    if (fieldsRef) {
      fieldsRef.current = {
        ...fieldsRef.current,
        [name]: value,
      };
    }
  };

  const formatAmount = useCallback(
    (value) => (value ? value / 10 ** exponent : undefined),
    [exponent]
  );

  useEffect(() => {
    if (initialValues) {
      forEach(keys(initialValues), (fieldName) => {
        let value = initialValues[fieldName];

        if (fieldName === FIELD_NAMES.amount) {
          value = formatAmount(value).toString();
        }

        setFieldsRef(fieldName, value);
      });
    }
  }, [initialValues, formatAmount]);

  useEffect(() => {
    setRequestButtonDisabled(
      some(values(fieldsValidations), ({ invalid }) => invalid)
    );
  }, [fieldsValidations]);

  useEffect(() => {
    const getBalance = async () => {
      const { data: clientData = {} } = await getClient({
        httpClient,
        clientId,
      });

      const { balance: clientBalance = {} } = clientData;

      if (clientBalance.balance != null) {
        setBalance(clientBalance.balance);
      }
    };

    if (clientId && balance == null) {
      getBalance();
    }
  }, [httpClient, balance, clientId]);

  const validate = (fieldsToValidate, submitButtonClicked) => {
    const validations = validateFields(
      fieldsToValidate,
      FIELD_RULES({ ukTransfer, balance, exponent })
    );

    if (submitButtonClicked) {
      setFieldsValidations(validations);
    }

    return validations;
  };

  const getFieldValues = (fields = {}) => ({
    [FIELD_NAMES.name]: fields[FIELD_NAMES.name],
    [FIELD_NAMES.sort_code]: fields[FIELD_NAMES.sort_code],
    [FIELD_NAMES.account_number]: fields[FIELD_NAMES.account_number],
    [FIELD_NAMES.iban]: fields[FIELD_NAMES.iban],
    [FIELD_NAMES.amount]: fields[FIELD_NAMES.amount],
    [FIELD_NAMES.reference]: fields[FIELD_NAMES.reference],
    [FIELD_NAMES.additional_info]: fields[FIELD_NAMES.additional_info],
    [FIELD_NAMES.comment]: fields[FIELD_NAMES.comment],
  });

  const handleFieldChange = (
    { target: { value } },
    fieldName,
    requestButtonIsClicked
  ) => {
    const trimmedValue = trim(value);
    setFieldsRef(fieldName, trimmedValue);

    validate(
      {
        ...getFieldValues(fieldsRef?.current),
        [fieldName]: trimmedValue,
      },
      requestButtonIsClicked
    );
  };

  const handleAmountKeyDown = (e) => {
    const { keyCode } = e;
    if (keyCode === HYPHEN_KEY_CODE) {
      e.preventDefault();
      e.stopPropagation();

      return false;
    }

    return true;
  };

  const handleRequestButtonClick = () => {
    const validations = validate(getFieldValues(fieldsRef?.current), true);

    const hasInvalidFields = some(
      values(validations),
      ({ invalid }) => invalid
    );

    setRequestButtonDisabled(hasInvalidFields);

    if (!hasInvalidFields) {
      setRequestLoading(true);

      const fields = fieldsRef?.current || {};
      const amount = fields[FIELD_NAMES.amount];

      onSubmit(httpClient, {
        uuid: transferId || uuidv4(),
        accountId,
        accountName: fields[FIELD_NAMES.name],
        accountNumber: fields[FIELD_NAMES.account_number],
        sortCode: fields[FIELD_NAMES.sort_code],
        iban: fields[FIELD_NAMES.iban],
        amount: round(parseFloat(formatStringNumber(amount)) * 10 ** exponent),
        reference: fields[FIELD_NAMES.reference],
        additionalInfo: fields[FIELD_NAMES.additional_info],
        comment: fields[FIELD_NAMES.comment],
        hideRecipientDetails,
      })
        .then(() => {
          setRequestLoading(false);
          onClose();

          if (successHandler) {
            return successHandler();
          }

          return Promise.resolve();
        })
        .then(() => {
          setToast({
            variant: 'confirm',
            headline: submitSuccessNotificationHeadline,
            body: '',
          });
        })
        .catch(() => {
          setRequestLoading(false);
          onClose();

          setToast({
            variant: 'alert',
            headline: submitErrorNotificationHeadline,
            body: submitErrorNotificationBody,
          });
        });
    } else if (!requestButtonClicked) {
      setRequestButtonClicked(true);
      setFieldsValidations(
        validateFields(
          getFieldValues(fieldsRef?.current),
          FIELD_RULES({ ukTransfer, balance, exponent })
        )
      );
    }
  };

  const handleHideRecipientDetailsChange = () => {
    setHideRecipientDetails(!hideRecipientDetails);
  };

  const handleScroll = (e) => {
    if (e) {
      if (e.target) {
        e.target.blur();
      }

      e.preventDefault();
    }
  };

  const referenceMaxCharacters = ukTransfer
    ? UK_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH
    : EUR_TRANSFER_PAYMENT_REFERENCE_MAX_LENGTH;

  return (
    <Wrapper>
      <Headline noMargin as="h3" size="three">
        {heading}
      </Headline>
      <ContentWrapper>
        <RecipientInformationWrapper>
          <Body
            noMargin
            size="one"
            variant="highlight"
            css={spacing({ bottom: 'byte' })}
          >
            Recipient
          </Body>
          <FieldWrapper>
            <Input
              {...(fieldsValidations
                ? fieldsValidations[FIELD_NAMES.name]
                : {})}
              noMargin
              label="Name"
              defaultValue={(initialValues || {})[FIELD_NAMES.name]}
              placeholder={FIELD_PLACEHOLDER}
              onChange={(e) =>
                handleFieldChange(e, FIELD_NAMES.name, requestButtonClicked)
              }
            />
          </FieldWrapper>
          {ukTransfer ? (
            <>
              <FieldWrapper>
                <Input
                  {...(fieldsValidations
                    ? fieldsValidations[FIELD_NAMES.sort_code]
                    : {})}
                  noMargin
                  label="Sort code"
                  defaultValue={(initialValues || {})[FIELD_NAMES.sort_code]}
                  placeholder={FIELD_PLACEHOLDER}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      FIELD_NAMES.sort_code,
                      requestButtonClicked
                    )
                  }
                />
              </FieldWrapper>
              <FieldWrapper>
                <Input
                  {...(fieldsValidations
                    ? fieldsValidations[FIELD_NAMES.account_number]
                    : {})}
                  noMargin
                  label="Account number"
                  defaultValue={
                    (initialValues || {})[FIELD_NAMES.account_number]
                  }
                  placeholder={FIELD_PLACEHOLDER}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      FIELD_NAMES.account_number,
                      requestButtonClicked
                    )
                  }
                />
              </FieldWrapper>
            </>
          ) : (
            <FieldWrapper>
              <Input
                {...(fieldsValidations
                  ? fieldsValidations[FIELD_NAMES.iban]
                  : {})}
                noMargin
                label="IBAN"
                defaultValue={(initialValues || {})[FIELD_NAMES.iban]}
                placeholder={FIELD_PLACEHOLDER}
                onChange={(e) =>
                  handleFieldChange(e, FIELD_NAMES.iban, requestButtonClicked)
                }
              />
            </FieldWrapper>
          )}
          <Checkbox
            noMargin
            value={hideRecipientDetails}
            checked={hideRecipientDetails}
            onChange={handleHideRecipientDetailsChange}
          >
            <Body noMargin>Hide recipient details</Body>
          </Checkbox>
        </RecipientInformationWrapper>
        <TransferInformationWrapper>
          <TransferInformation>
            <TransferInformationContent>
              <Body noMargin size="one" variant="highlight">
                Transfer information
              </Body>
            </TransferInformationContent>
            <StyledHr />
            <TransferInformationContent>
              <FieldWrapper>
                <Input
                  type="number"
                  {...(fieldsValidations
                    ? fieldsValidations[FIELD_NAMES.amount]
                    : {})}
                  noMargin
                  locale={locale}
                  placeholder={`Amount (${MIN_PARTIAL_AMOUNT} - ${formatCurrency(
                    balance / 10 ** exponent,
                    EN_GB_LOCALE,
                    currency
                  )})`}
                  label="Amount"
                  defaultValue={formatAmount(
                    (initialValues || {})[FIELD_NAMES.amount]
                  )}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      FIELD_NAMES.amount,
                      requestButtonClicked
                    )
                  }
                  onKeyDown={handleAmountKeyDown}
                  onWheel={handleScroll}
                />
              </FieldWrapper>
              {ukTransfer ? (
                <>
                  <FieldWrapper>
                    <Input
                      validationHint={`Maximum ${referenceMaxCharacters} characters`}
                      {...(fieldsValidations
                        ? fieldsValidations[FIELD_NAMES.reference]
                        : {})}
                      noMargin
                      label="Reference"
                      defaultValue={
                        (initialValues || {})[FIELD_NAMES.reference]
                      }
                      placeholder={FIELD_PLACEHOLDER}
                      onChange={(e) =>
                        handleFieldChange(
                          e,
                          FIELD_NAMES.reference,
                          requestButtonClicked
                        )
                      }
                    />
                  </FieldWrapper>
                  <FieldWrapper bottomMargin="byte">
                    <TextArea
                      {...(fieldsValidations
                        ? fieldsValidations[FIELD_NAMES.additional_info]
                        : {})}
                      noMargin
                      label="Additional info"
                      optionalLabel="Optional"
                      defaultValue={
                        (initialValues || {})[FIELD_NAMES.additional_info]
                      }
                      required={false}
                      placeholder={FIELD_PLACEHOLDER}
                      onChange={(e) =>
                        handleFieldChange(
                          e,
                          FIELD_NAMES.additional_info,
                          requestButtonClicked
                        )
                      }
                    />
                  </FieldWrapper>
                </>
              ) : (
                <>
                  <FieldWrapper bottomMargin="byte">
                    <TextArea
                      validationHint={`Maximum ${referenceMaxCharacters} characters`}
                      {...(fieldsValidations
                        ? fieldsValidations[FIELD_NAMES.reference]
                        : {})}
                      noMargin
                      label="Reference"
                      defaultValue={
                        (initialValues || {})[FIELD_NAMES.reference]
                      }
                      placeholder={FIELD_PLACEHOLDER}
                      onChange={(e) =>
                        handleFieldChange(
                          e,
                          FIELD_NAMES.reference,
                          requestButtonClicked
                        )
                      }
                    />
                  </FieldWrapper>
                </>
              )}
              {/* To do: counter */}
            </TransferInformationContent>
          </TransferInformation>
          <FieldWrapper>
            <TextArea
              {...(fieldsValidations
                ? fieldsValidations[FIELD_NAMES.comment]
                : {})}
              noMargin
              placeholder={FIELD_PLACEHOLDER}
              label="Comment"
              optionalLabel="Optional"
              required={false}
              css={spacing({ top: 'bit' })}
              onChange={(e) =>
                handleFieldChange(e, FIELD_NAMES.comment, requestButtonClicked)
              }
            />
          </FieldWrapper>
        </TransferInformationWrapper>
      </ContentWrapper>
      <ButtonsWrapper>
        <Button css={spacing({ right: 'mega' })} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={requestButtonDisabled}
          variant="primary"
          isLoading={requestLoading}
          loadingLabel="Loading"
          onClick={handleRequestButtonClick}
        >
          {submitButtonLabel}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

RequestTransferModal.defaultProps = {
  locale: EN_GB_LOCALE,
  ukTransfer: false,
  balance: null,
  initialValues: null,
  exponent: DEFAULT_CURRENCY_EXPONENT,
  clientId: null,
  transferId: null,
  heading: 'Request a transfer to another account',
  submitSuccessNotificationHeadline: 'Changes saved',
  submitErrorNotificationHeadline: 'Couldn’t save changes',
  submitErrorNotificationBody: TRY_AGAIN_LATER_MESSAGE,
  submitButtonLabel: 'Save changes',
  successHandler: null,
};

RequestTransferModal.propTypes = {
  locale: PropTypes.string,
  ukTransfer: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  balance: PropTypes.number,
  exponent: PropTypes.number,
  initialValues: PropTypes.object,
  currency: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  transferId: PropTypes.string,
  heading: PropTypes.string,
  submitSuccessNotificationHeadline: PropTypes.string,
  submitErrorNotificationHeadline: PropTypes.string,
  submitErrorNotificationBody: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  successHandler: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequestTransferModal;
