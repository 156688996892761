import { filter } from 'lodash';
import {
  MODULES,
  CLIENT_ONBOARD_STATUSES,
  PATHS,
  CONSUMER_ID_PREFIX,
} from 'variables';
import { hasSectionAccess } from './permissions';

const restrictRoutes = ({ appName = {}, status, permissions, clientId = '' }) =>
  filter(appName.sections || [], ({ link, permissions: { app, section } }) => {
    const isAccountAndCard =
      appName === MODULES.ACCOUNT_AND_CARD &&
      status === CLIENT_ONBOARD_STATUSES.ONBOARDED;
    const isActivation = isAccountAndCard && link === PATHS.activation;
    const isDirectDebits = isAccountAndCard && link === PATHS.direct_debits;
    const isConsumerId =
      clientId.substring(0, 1) === CONSUMER_ID_PREFIX &&
      clientId.indexOf('-') > 0;
    const directDebitsVisible = isDirectDebits && !isConsumerId;

    return (
      hasSectionAccess({ permissions, app, section }) &&
      (!isAccountAndCard || !isActivation) &&
      (!isDirectDebits || directDebitsVisible)
    );
  });

export default restrictRoutes;
