import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import moment from 'moment';
import { Body, spacing } from '@sumup/circuit-ui';
import { DATE_FORMATS } from 'variables';
import {
  PAYMENT_SCHEDULE_TYPE_ONE_OFF,
  SCHEDULE_STATUS_ACTIVE,
  TYPE_LABEL,
} from '../constants';
import { getScheduledPaymentStatus } from '../service';
import { Card, CardContent } from './ScheduleDetailsStyled';

const CardContentColumn = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 150px;

    > *:not(:first-child) {
      margin-top: ${theme.spacings.bit};
    }
  `
);

const StatusCell = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ScheduleDetailsCard = ({
  scheduledPayment,
  exponent,
  fullWidth,
}) => {
  const {
    id,
    created_at: createdAt,
    amount,
    currency,
    reference,
    schedule = {},
  } = scheduledPayment;
  const {
    type,
    status,
    start_date: startDate,
    end_date: endDate,
    cancelled_at: cancelledAt,
    next_due: nextDue,
    next_payment_due: nextPaymentDue,
  } = schedule;

  const { label: statusLabel, icon: statusIcon } = getScheduledPaymentStatus({
    schedule,
  });

  const nextDueDate = nextPaymentDue || nextDue;
  const showDueDate =
    type === PAYMENT_SCHEDULE_TYPE_ONE_OFF &&
    status === SCHEDULE_STATUS_ACTIVE &&
    nextDueDate != null;
  const showCancelledAt =
    status !== SCHEDULE_STATUS_ACTIVE && cancelledAt != null;
  const cancelledOneOff =
    showCancelledAt && type === PAYMENT_SCHEDULE_TYPE_ONE_OFF;
  const showMadeOn =
    type === PAYMENT_SCHEDULE_TYPE_ONE_OFF &&
    status !== SCHEDULE_STATUS_ACTIVE &&
    startDate != null &&
    cancelledAt == null;
  const showStartDate =
    startDate != null &&
    (type !== PAYMENT_SCHEDULE_TYPE_ONE_OFF || cancelledOneOff);
  const showEndDate =
    type !== PAYMENT_SCHEDULE_TYPE_ONE_OFF &&
    cancelledAt == null &&
    endDate != null;
  const formattedStartDate = startDate
    ? moment(startDate).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)
    : null;

  return (
    <Card fullWidth={fullWidth}>
      <div>
        <Body noMargin size="one" variant="highlight">
          Schedule information
        </Body>
      </div>
      <CardContent flexDirection="row">
        <CardContentColumn>
          {statusLabel ? (
            <Body noMargin size="two" variant="highlight">
              Status
            </Body>
          ) : null}
          <Body noMargin size="two" variant="highlight">
            ID
          </Body>
          {createdAt && (
            <Body noMargin size="two" variant="highlight">
              Created at
            </Body>
          )}
          {showDueDate && (
            <Body noMargin size="two" variant="highlight">
              Due date
            </Body>
          )}
          {showStartDate && (
            <Body noMargin size="two" variant="highlight">
              Start date
            </Body>
          )}
          {showEndDate && (
            <Body noMargin size="two" variant="highlight">
              End date
            </Body>
          )}
          {showMadeOn && (
            <Body noMargin size="two" variant="highlight">
              Made on
            </Body>
          )}
          {showCancelledAt && (
            <Body noMargin size="two" variant="highlight">
              Cancelled at
            </Body>
          )}
          <Body noMargin size="two" variant="highlight">
            Frequency
          </Body>
          <Body noMargin size="two" variant="highlight">
            Amount
          </Body>
          <Body noMargin size="two" variant="highlight">
            Reference
          </Body>
        </CardContentColumn>
        <CardContentColumn>
          {statusLabel ? (
            <StatusCell>
              {statusIcon}
              <Body
                noMargin
                size="two"
                variant="highlight"
                css={spacing({ left: 'bit' })}
              >
                {statusLabel}
              </Body>
            </StatusCell>
          ) : null}
          <Body noMargin size="two">
            {id}
          </Body>
          {createdAt && (
            <Body noMargin size="two">
              {moment(createdAt).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          {showDueDate && (
            <Body noMargin size="two">
              {moment(nextDueDate).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          {showStartDate && (
            <Body noMargin size="two">
              {formattedStartDate}
            </Body>
          )}
          {showEndDate && (
            <Body noMargin size="two">
              {moment(endDate).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          {showMadeOn && (
            <Body noMargin size="two">
              {formattedStartDate}
            </Body>
          )}
          {showCancelledAt && (
            <Body noMargin size="two">
              {moment(cancelledAt).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
            </Body>
          )}
          <Body noMargin size="two">
            {TYPE_LABEL[type]}
          </Body>
          <Body noMargin size="two">
            {amount / 10 ** exponent} {currency}
          </Body>
          <Body noMargin size="two">
            {reference}
          </Body>
        </CardContentColumn>
      </CardContent>
    </Card>
  );
};

ScheduleDetailsCard.defaultProps = {
  fullWidth: true,
};

ScheduleDetailsCard.propTypes = {
  scheduledPayment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    schedule: PropTypes.shape({
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      cancelled_at: PropTypes.string,
      next_due: PropTypes.string,
      next_payment_due: PropTypes.string,
    }),
  }).isRequired,
  exponent: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool,
};
