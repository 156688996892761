import { keys } from 'lodash';
import { APPS } from '../variables/permissions';

export const hasMerchantAccess = (permissions = {}) => {
  if (!keys(permissions)) {
    return false;
  }

  return (
    permissions[APPS.merchant] &&
    !!keys(permissions[APPS.merchant].sections).length
  );
};

export const hasAdministrationAccess = (permissions = {}) => {
  if (!keys(permissions)) {
    return false;
  }

  return (
    permissions[APPS.administration] &&
    !!keys(permissions[APPS.administration].sections).length
  );
};

export const hasReportsAccess = (permissions = {}) => {
  if (!keys(permissions)) {
    return false;
  }

  return (
    permissions[APPS.reports] &&
    !!keys(permissions[APPS.reports].sections).length
  );
};

export const hasMassActionsAccess = (permissions = {}) => {
  if (!keys(permissions)) {
    return false;
  }

  return (
    permissions[APPS.mass_actions] &&
    !!keys(permissions[APPS.mass_actions].sections).length
  );
};

export const hasOpsTransfersAccess = (permissions = {}) => {
  if (!keys(permissions)) {
    return false;
  }

  return (
    permissions[APPS.ops_transfers] &&
    !!keys(permissions[APPS.ops_transfers].sections).length
  );
};

export const hasSectionAccess = ({ permissions, app, section }) => {
  if (!permissions || !app || !section) {
    return false;
  }

  return (
    (permissions[app] || {}).sections && permissions[app].sections[section]
  );
};

export const hasActionAccess = ({ permissions, app, action }) => {
  if (!permissions || !app || !action) {
    return false;
  }

  return (permissions[app] || {}).actions && permissions[app].actions[action];
};

export const hasReconciliationAccess = (permissions = {}) => {
  if (!keys(permissions)) {
    return false;
  }

  return (
    permissions[APPS.card_reconciliation] &&
    !!keys(permissions[APPS.card_reconciliation].sections).length
  );
};
