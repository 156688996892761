import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import moment from 'moment';
import { DATE_FORMATS } from 'variables';

const DirectDebitsEnabledTimestamp = ({ date, directDebitsEnabled }) =>
  date ? (
    <div>
      <Body noMargin size="one" variant="subtle">
        {directDebitsEnabled ? 'ENABLED' : 'DISABLED'} ON:
      </Body>
      <Body noMargin size="one" variant="highlight">
        {moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR)}
      </Body>
    </div>
  ) : null;

DirectDebitsEnabledTimestamp.defaultProps = {
  date: '',
  directDebitsEnabled: null,
};

DirectDebitsEnabledTimestamp.propTypes = {
  date: PropTypes.string,
  directDebitsEnabled: PropTypes.bool,
};

export default DirectDebitsEnabledTimestamp;
