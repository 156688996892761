import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import buildLink from 'services/buildLink';
import { keys, map, values } from 'lodash';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import {
  PATHS,
  MODULES,
  NAVIGATION_CARDS_MAX_ROWS_COUNT,
  CLIENT_ONBOARD_STATUSES,
  NON_ONBOARDED_MERCHANT_ROUTES,
} from 'variables';
import restrictRoutes from 'services/restrictRoutes';
import ClientDataContext from 'context/clientData';
import AuthContext from 'context/auth';
import {
  Content,
  ContentColumn,
  NavigationCardHeading,
  StyledNavigationCards,
} from './NavigationCardsStyled';
import {
  SectionLink,
  StyledCard,
  StyledCardIcon,
} from '../../MerchantNavigationStyled';

const NavigationCards = ({ clientId, onboarded }) => {
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const renderContent = (sections) => (
    <ContentColumn>
      {map(sections, (section) => (
        <SectionLink
          key={section.title}
          disabled={
            !NON_ONBOARDED_MERCHANT_ROUTES(clientData)[section.link] &&
            onboarded !== CLIENT_ONBOARD_STATUSES.ONBOARDED
          }
          to={buildLink({
            type: PATHS.client,
            segment: PATHS[section.link],
            id: clientId,
          })}
        >
          <Body noMargin size="two" variant="highlight">
            {section.title}
          </Body>
          <ChevronRight />
        </SectionLink>
      ))}
    </ContentColumn>
  );

  return (
    <StyledNavigationCards>
      {map(keys(MODULES), (appName) => (
        <StyledCard key={appName}>
          <NavigationCardHeading bold noMargin>
            <StyledCardIcon>{MODULES[appName].image}</StyledCardIcon>
            {MODULES[appName].title}
          </NavigationCardHeading>
          <Content>
            {renderContent(
              values(
                restrictRoutes({
                  appName: MODULES[appName],
                  status: onboarded,
                  permissions,
                  clientId,
                })
              ).splice(0, NAVIGATION_CARDS_MAX_ROWS_COUNT)
            )}
            {renderContent(
              values(
                restrictRoutes({
                  appName: MODULES[appName],
                  status: onboarded,
                  permissions,
                  clientId,
                })
              ).splice(NAVIGATION_CARDS_MAX_ROWS_COUNT)
            )}
          </Content>
        </StyledCard>
      ))}
    </StyledNavigationCards>
  );
};

NavigationCards.defaultProps = {
  onboarded: '',
};

NavigationCards.propTypes = {
  clientId: PropTypes.string.isRequired,
  onboarded: PropTypes.string,
};

export default NavigationCards;
