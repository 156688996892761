import moment from 'moment';
import { CUSTOM_FIELD_TYPES, DATE_FORMATS, FIELD_TYPES } from 'variables';

const DATE_CREATED = {
  label: 'Created',
  name: 'inserted_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

const DATE_UPDATED = {
  label: 'Last updated',
  name: 'updated_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

const DATE_EXPIRY = {
  label: 'Expires',
  name: 'expiry_date',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

const RECIPIENT = {
  label: 'Recipient',
  name: 'recipient',
};

export const CURRENCY = {
  label: 'Merchant',
  name: 'currency',
};

const AMOUNT = {
  label: 'Amount',
  name: 'amount',
  renderAs: CUSTOM_FIELD_TYPES.AMOUNT,
  currencyField: CURRENCY,
  // Amount of 10 comes as 1000 from the server
  formatNumber: (number) => number / 100,
};

const STATUS = {
  label: 'Status',
  name: 'status',
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
};

const MERCHANT = {
  label: 'Merchant',
  name: 'merchant',
  style: {
    minWidth: 400,
  },
};

const BANK_ACCOUNT = {
  label: 'Bank account',
  name: 'bank_account_identifier',
};

export const COLUMNS_BANK = [
  DATE_CREATED,
  DATE_UPDATED,
  DATE_EXPIRY,
  RECIPIENT,
  BANK_ACCOUNT,
  AMOUNT,
  STATUS,
];

export const COLUMNS_ONLINE = [
  {
    ...DATE_CREATED,
    active: true,
  },
  DATE_UPDATED,
  DATE_EXPIRY,
  MERCHANT,
  AMOUNT,
  STATUS,
];

export const CHALLENGES_TYPES_ONLINE = {
  label: 'Online challenges',
  value: 'online',
};

export const CHALLENGES_TYPES_BANK = {
  label: 'Bank challenges',
  value: 'bank',
};

export const CHALLENGES_NON_NUMERIC_FIELDS_MAP = {
  [DATE_CREATED.name]: true,
  [DATE_UPDATED.name]: true,
  [DATE_EXPIRY.name]: true,
};
