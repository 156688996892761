import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Marker } from './assets/Marker.svg';
import { ReactComponent as MarkerActive } from './assets/MarkerActive.svg';
import { ReactComponent as Confirm } from './assets/Confirm.svg';

const StatusCell = ({ selected, matched }) =>
  (matched && <Confirm />) || (selected && <MarkerActive />) || <Marker />;

StatusCell.defaultProps = {
  matched: false,
};

StatusCell.propTypes = {
  selected: PropTypes.bool.isRequired,
  matched: PropTypes.bool,
};

export default StatusCell;
