import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, RadioButtonGroup } from '@sumup/circuit-ui';
import { StyledCard } from 'components/Transactions/TransactionsStyled';
import { STATUS_FILTER_OPTIONS } from '../constants';

const StyledRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    display: flex;
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};
  `
);

export const StatusFilter = ({ filter, onChange }) => (
  <StyledCard>
    <Body size="two" noMargin>
      Status:
    </Body>
    <StyledRadioButtonGroup
      value={filter}
      options={STATUS_FILTER_OPTIONS}
      label="Select status filter"
      hideLabel
      onChange={({ target: { value } }) => onChange(value)}
    />
  </StyledCard>
);

StatusFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
