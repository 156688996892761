import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { map, toString, keys } from 'lodash/fp';
import Tooltip from 'components/Tooltip';
import Indicator from 'components/Indicator';
import { StyledText } from './BodyCellStyled';

const Content = styled('div')(
  ({ centered }) =>
    centered &&
    `
      display: flex;
      justify-content: center;
      width: 100%;
    `
);

const BodyCell = ({
  fieldName,
  value,
  modified,
  tooltip,
  style,
  fields,
  prefix,
  textProps,
  rowData,
  centered,
  getPrefix,
}) => (
  <div style={style}>
    {modified && <Indicator />}
    <Content centered={centered}>
      <StyledText noMargin size="two" {...textProps}>
        {prefix || getPrefix(rowData, fieldName)}
        {toString(value)}
      </StyledText>
      {!!keys(fields).length &&
        map(
          (field) => (
            <StyledText
              noMargin
              size="two"
              key={`${fieldName}-${field.fieldName}`}
            >
              {field.prefix}
              {toString(field.value)}
            </StyledText>
          ),
          fields
        )}
    </Content>
    {tooltip && <Tooltip content={tooltip} />}
  </div>
);

BodyCell.defaultProps = {
  fieldName: '',
  value: '',
  tooltip: '',
  modified: false,
  style: {},
  fields: {},
  prefix: '',
  textProps: {},
  rowData: {},
  centered: false,
  getPrefix: () => '',
};

BodyCell.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.any,
  tooltip: PropTypes.string,
  modified: PropTypes.bool,
  style: PropTypes.object,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  prefix: PropTypes.any,
  textProps: PropTypes.object,
  rowData: PropTypes.object,
  centered: PropTypes.bool,
  getPrefix: PropTypes.func,
};

export default BodyCell;
