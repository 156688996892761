import { useEffect, useReducer } from 'react';
import { loadSuccess, loadError } from 'actions';
import { useHttpClient } from './useHttpClient';

export const useApiCallOnLoad = (
  apiCall,
  id,
  reducer,
  initialState,
  successApiCall
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const httpClient = useHttpClient();

  useEffect(() => {
    let mounted = true;

    apiCall(id, httpClient)
      .then((data) => {
        if (mounted) {
          dispatch(loadSuccess(data));
        }

        if (successApiCall) {
          return successApiCall(httpClient, dispatch, { id, data: data.data });
        }

        return null;
      })
      .catch((err) => {
        dispatch(loadError(err));
      });

    return () => {
      mounted = false;
    };
  }, [apiCall, id, httpClient, successApiCall]);

  return [state, dispatch];
};
