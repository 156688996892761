import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

const DeleteDeviceModalContent = styled.div`
  max-width: 480px;
  text-align: center;
`;

const DeleteDeviceModal = ({ deviceUuid, onClose, onDeleteDevice }) => {
  const [loading, setLoading] = useState(false);
  const handleDeleteDeviceConfirm = () => {
    setLoading(true);
    onDeleteDevice(deviceUuid, onClose);
  };

  return (
    <Modal
      modalInfo={{
        content: (
          <DeleteDeviceModalContent>
            <Body noMargin>
              Are you sure you would like to disable Strong Customer
              Authentication?
            </Body>
          </DeleteDeviceModalContent>
        ),
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        isLoading: loading,
        onConfirm: handleDeleteDeviceConfirm,
        confirmButtonProps: {
          destructive: true,
        },
      }}
      onClose={onClose}
    />
  );
};

DeleteDeviceModal.propTypes = {
  deviceUuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteDevice: PropTypes.func.isRequired,
};

export default DeleteDeviceModal;
