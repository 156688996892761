import { capitalize } from 'lodash';
import { CUSTOM_FIELD_TYPES } from 'variables';

const PAYMENT_TYPE = {
  label: 'Limit type',
  name: 'payment_type',
  textProps: {
    variant: 'highlight',
    size: 'one',
  },
  getValue: (record, name) =>
    record[name] ? capitalize(record[name]) : undefined,
};

const MAX_NUMBER = {
  label: 'Maximum number',
  name: 'max_number',
  renderAs: CUSTOM_FIELD_TYPES.INPUT,
};

const MAX_AMOUNT = {
  label: 'Maximum amount',
  name: 'max_amount',
  renderAs: CUSTOM_FIELD_TYPES.INPUT,
};

const MAX_SINGLE_AMOUNT = {
  label: 'Maximum single amount',
  name: 'max_single_amount',
  renderAs: CUSTOM_FIELD_TYPES.INPUT,
};

export const limitsColumns = [
  PAYMENT_TYPE,
  MAX_NUMBER,
  MAX_AMOUNT,
  MAX_SINGLE_AMOUNT,
];
