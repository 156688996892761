import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { DELETE_USER_MODAL_MAX_WIDTH } from 'components/UserAdministration/constants';
import Modal from 'components/Modal';
import { pluralize } from 'components/UserAdministration/userAdministrationService';

const DeleteGroupConfirmationModal = ({ usersCount, onClose, onConfirm }) => (
  <Modal
    modalInfo={{
      width: DELETE_USER_MODAL_MAX_WIDTH,
      title: 'Delete this user group?',
      confirmText: 'Delete group',
      cancelText: 'Cancel',
      confirmButtonProps: {
        destructive: true,
      },
      centered: true,
      content: (
        <Body noMargin size="one">
          {usersCount} {pluralize('user', usersCount)} will be affected and may
          lose their access.
        </Body>
      ),
      onConfirm,
    }}
    onClose={onClose}
  />
);

DeleteGroupConfirmationModal.defaultProps = {
  usersCount: 0,
};

DeleteGroupConfirmationModal.propTypes = {
  usersCount: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteGroupConfirmationModal;
