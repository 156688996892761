import React, { useContext, useRef, useState } from 'react';
import { Delete } from '@sumup/icons';
import { find, reduce } from 'lodash';
import {
  DetailsSectionBody,
  DetailsSectionSubHeading,
  PageContent,
} from 'components/UserAdministration/UserAdministrationStyled';
import {
  createUser,
  deleteUser,
  getGroups as getAllGroups,
  getGroupsByEmail as getUserGroups,
  getUsersWithGroups,
  updateUser,
} from 'api';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import Error from 'components/Error';
import { loadError, loadSuccess } from 'actions';
import { setError } from 'actions/commonActions';
import AuthContext from 'context/auth';
import { hasActionAccess } from 'services/permissions';
import { ACTIONS, APPS } from 'variables';
import { useModal } from '@sumup/circuit-ui';
import { initialState } from './state';
import ListSection from '../UserAdministration/components/ListSection';
import DetailsSection from '../UserAdministration/components/DetailsSection';
import { userAdministrationUsersReducer } from './userAdministrationUsersReducer';
import {
  filterUsers,
  loadGroupsSuccess,
  loadGroupsError,
  selectUser,
  setGroupsLoading,
  setUsersLoading,
  createUserError,
  deleteUserError,
  loadUserGroupsSuccess,
  loadUserGroupsError,
  setSelectedUserGroups,
  updateUserSuccess,
  updateUserError,
  setUserGroupsLoading,
} from './userAdministrationUsersActions';
import AddUserModal from './components/AddUserModal';
import DeleteUserConfirmationModal from './components/DeleteUserConfirmationModal';
import UserGroupsList from './components/UserGroupsList';

const getUsersWrapped = (id, httpClient) => getUsersWithGroups({ httpClient });
const getUserGroupsWrapped = (httpClient, dispatch, { data }) => {
  const { users = [{}] } = data || {};

  return getAllGroups({ httpClient })
    .catch((error) => {
      dispatch(loadGroupsError(error));
    })
    .then(({ data: groupsData }) => {
      dispatch(loadGroupsSuccess({ data: groupsData }));

      return getUserGroups({ httpClient, email: users[0].email });
    })
    .then(({ data: userData }) => {
      dispatch(loadUserGroupsSuccess({ data: userData }));
    })
    .catch((error) => {
      dispatch(loadUserGroupsError(error));
    });
};

const UserAdministrationUsers = () => {
  const httpClient = useHttpClient();
  const [readonly, setReadonlyMode] = useState(true);

  const selectedUserRef = useRef(null);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const { setModal, removeModal } = useModal();

  const hasEditRights = hasActionAccess({
    permissions,
    app: APPS.administration,
    action: ACTIONS[APPS.administration].usersEdit,
  });

  const [state, dispatch] = useApiCallOnLoad(
    getUsersWrapped,
    null,
    userAdministrationUsersReducer,
    initialState,
    getUserGroupsWrapped
  );

  const {
    filteredUsers,
    selectedUser: {
      text: selectedUserEmail,
      added_by: addedBy,
      groups: selectedUserGroups,
    },
    groups,
    userGroupsLoading,
    usersLoading,
    groupsLoading,
    error,
  } = state;

  const scrollToSelectedUser = () => {
    if (selectedUserRef && selectedUserRef.current) {
      selectedUserRef.current.scrollIntoView();
    }
  };

  const handleUserClick = ({ text: email }) => {
    dispatch(selectUser({ email }));
    dispatch(setUserGroupsLoading(true));

    getAllGroups({ httpClient })
      .catch((err) => {
        dispatch(loadGroupsError(err));
      })
      .then(({ data: groupsData }) => {
        dispatch(loadGroupsSuccess({ data: groupsData }));

        return getUserGroups({ httpClient, email });
      })
      .then(({ data: userData }) => {
        dispatch(loadUserGroupsSuccess({ data: userData }));
      })
      .catch((err) => {
        dispatch(loadUserGroupsError(err));
      });
  };

  const handleSearch = ({ value }) => {
    dispatch(filterUsers({ pattern: value }));
  };

  const handleAddUserModalConfirm = ({ email, selectedGroups }) => {
    removeModal();

    createUser({ httpClient, email, groups: selectedGroups })
      .catch((err) => {
        dispatch(createUserError(err));

        return Promise.reject();
      })
      .then(() => {
        dispatch(setUsersLoading(true));

        return getUsersWithGroups({ httpClient });
      })
      .catch((err) => {
        dispatch(loadError(err));

        return Promise.reject();
      })
      .then((data) => {
        dispatch(loadSuccess(data));
        dispatch(selectUser({ email }));
        scrollToSelectedUser();

        dispatch(setUserGroupsLoading(true));

        return getAllGroups({ httpClient });
      })
      .catch((err) => {
        dispatch(loadGroupsError(err));

        return Promise.reject();
      })
      .then(({ data: groupsData }) => {
        dispatch(loadGroupsSuccess({ data: groupsData }));

        return getUserGroups({ httpClient, email });
      })
      .then(({ data: userData }) => {
        dispatch(loadUserGroupsSuccess({ data: userData }));
      })
      .catch((err) => {
        dispatch(loadUserGroupsError(err));
      });
  };

  const openAddUserModal = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <AddUserModal
          groups={groups}
          groupsLoading={groupsLoading}
          onClose={onClose}
          onConfirm={handleAddUserModalConfirm}
        />
      ),
      onClose: () => {},
      closeButtonLabel: 'Close',
    });
  };

  const handleAddUserClick = () => {
    dispatch(setGroupsLoading(true));
    openAddUserModal();

    getAllGroups({ httpClient })
      .then(({ data }) => {
        dispatch(loadGroupsSuccess({ data }));
      })
      .catch((err) => {
        dispatch(loadGroupsError(err));
      });
  };

  const handleDeleteUserConfirm = () => {
    deleteUser({ httpClient, email: selectedUserEmail })
      .catch((err) => {
        dispatch(deleteUserError(err));

        return Promise.reject();
      })
      .then(() => {
        dispatch(setUsersLoading(true));

        return getUsersWithGroups({ httpClient });
      })
      .catch((err) => {
        dispatch(loadError(err));

        return Promise.reject();
      })
      .then((data) => {
        dispatch(loadSuccess(data));

        const user = find(
          filteredUsers,
          ({ text }) => text !== selectedUserEmail
        );

        const { users } = data.data;
        const email = user ? user.text : users[0].email;

        dispatch(selectUser({ email }));
        scrollToSelectedUser();
        dispatch(setUserGroupsLoading(true));

        return getUserGroups({ httpClient, email });
      })
      .catch((err) => {
        dispatch(loadUserGroupsError(err));

        return Promise.reject();
      })
      .then(({ data: userData }) => {
        dispatch(loadUserGroupsSuccess({ data: userData }));

        return getAllGroups({ httpClient });
      })
      .then(({ data: groupsData }) => {
        dispatch(loadGroupsSuccess({ data: groupsData }));
      })
      .catch((err) => {
        dispatch(loadGroupsError(err));
      });
  };

  const handlePrimaryButtonClick = () => {
    dispatch(setError(null));

    if (readonly) {
      setReadonlyMode(false);
    } else {
      updateUser({
        httpClient,
        email: selectedUserEmail,
        groups: reduce(
          selectedUserGroups,
          (accumulator, isSelected, group) =>
            isSelected ? [...accumulator, group] : accumulator,
          []
        ),
      })
        .catch((err) => {
          dispatch(updateUserError(err));

          return Promise.reject();
        })
        .then(() => {
          setReadonlyMode(true);
          dispatch(updateUserSuccess());
          dispatch(setUsersLoading(true));

          return getUsersWithGroups({ httpClient });
        })
        .catch((err) => {
          dispatch(loadError(err));

          return Promise.reject();
        })
        .then((data) => {
          dispatch(loadSuccess(data));
          dispatch(selectUser({ email: selectedUserEmail }));
          scrollToSelectedUser();
          dispatch(setUserGroupsLoading(true));

          return getAllGroups({ httpClient });
        })
        .catch((err) => {
          dispatch(loadGroupsError(err));

          return Promise.reject();
        })
        .then(({ data: groupsData }) => {
          dispatch(loadGroupsSuccess({ data: groupsData }));

          return getUserGroups({ httpClient, email: selectedUserEmail });
        })
        .then(({ data: userData }) => {
          dispatch(loadUserGroupsSuccess({ data: userData }));
        })
        .catch((err) => {
          dispatch(loadUserGroupsError(err));
        });
    }
  };

  const handleUserGroupClick = ({ groups: selectedGroups }) => {
    dispatch(setSelectedUserGroups({ groups: selectedGroups }));
  };

  const handleCancelButtonClick = () => {
    setReadonlyMode(true);
    dispatch(setUserGroupsLoading(true));

    getAllGroups({ httpClient })
      .catch((err) => {
        dispatch(loadGroupsError(err));
      })
      .then(({ data: groupsData }) => {
        dispatch(loadGroupsSuccess({ data: groupsData }));

        return getUserGroups({ httpClient, email: selectedUserEmail });
      })
      .then(({ data: userData }) => {
        dispatch(loadUserGroupsSuccess({ data: userData }));
      })
      .catch((err) => {
        dispatch(loadUserGroupsError(err));
      });
  };

  const openDeleteUserConfirmationModal = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <DeleteUserConfirmationModal
          onClose={onClose}
          onConfirm={handleDeleteUserConfirm}
        />
      ),
      onClose: () => {},
      closeButtonLabel: 'Close',
    });
  };

  const subText = addedBy
    ? [{ text: 'Added by' }, { text: addedBy, variant: 'highlight' }]
    : [];

  const actions = [
    {
      label: 'Delete user',
      icon: <Delete size="16" />,
      color: 'alert',
      onClick: openDeleteUserConfirmationModal,
    },
  ];

  return (
    <>
      <PageContent>
        <ListSection
          buttonLabel="Add new user"
          hasSearch
          searchPlaceholder="Search by email"
          items={filteredUsers}
          itemsLoading={usersLoading}
          selectedItem={{ text: selectedUserEmail }}
          selectedItemRef={selectedUserRef}
          hasEditRights={hasEditRights}
          onItemClick={handleUserClick}
          onSearch={handleSearch}
          onButtonClick={handleAddUserClick}
        />
        <DetailsSection
          text={selectedUserEmail}
          subText={subText}
          primaryButtonLabel={readonly ? 'Edit permissions' : 'Save changes'}
          actions={actions}
          readonly={readonly}
          editTextEnabled={false}
          inputLabel="User email"
          hasEditRights={hasEditRights}
          onPrimaryButtonClick={handlePrimaryButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
        >
          <DetailsSectionBody>
            {error && <Error>{error}</Error>}
            {!readonly && (
              <DetailsSectionSubHeading noMargin size="one">
                Select permissions:
              </DetailsSectionSubHeading>
            )}
            <UserGroupsList
              readonly={readonly}
              loading={groupsLoading || userGroupsLoading}
              groups={groups}
              selectedGroups={selectedUserGroups}
              onGroupClick={handleUserGroupClick}
            />
          </DetailsSectionBody>
        </DetailsSection>
      </PageContent>
    </>
  );
};

export default UserAdministrationUsers;
