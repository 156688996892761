import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Input, Body, Select } from '@sumup/circuit-ui';
import { debounce } from 'lodash/fp';
import { map } from 'lodash';
import { DEBOUNCE_TIMEOUT_SHORT } from 'variables';
import {
  TRANSACTION_AUTH_CODE_FILTER,
  TRANSACTION_ID_FILTER,
  TRANSACTION_DETAILS_FILTER,
  TRANSACTION_MCC_FILTER,
  TRANSACTION_RRN_FILTER,
  TRANSACTION_STATUS_FILTER,
  TRANSACTION_TYPE_FILTER,
  TRANSACTION_FILTERS_ANY_OPTION,
} from '../../constants';

const POPOVER_ITEM_MAX_WIDTH = 448;

const StyledButton = styled(Button)`
  width: ${POPOVER_ITEM_MAX_WIDTH}px;
`;

const StyledText = styled(Body)(
  ({ theme: { spacings } }) => css`
    align-self: flex-start;
    margin-bottom: ${spacings.bit};
  `
);

const StyledInput = styled(Input)`
  max-height: 40px;
`;

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    select {
      max-height: 40px;
      padding: ${theme.spacings.byte} ${theme.spacings.mega};
    }

    svg {
      margin-top: 18px;
    }
  `
);

const RowFilterWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${POPOVER_ITEM_MAX_WIDTH}px;

    > p {
      align-self: flex-start;
    }

    > label {
      margin: 0;

      > span {
        margin-bottom: ${theme.spacings.bit};
        float: left;
      }
    }
  `
);

const ColFilterWrapper = styled(RowFilterWrapper)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 216px;

    :first-of-type {
      margin-right: ${theme.spacings.mega};
    }

    > label {
      margin: 0;
    }
  `
);

const stopEventPropagation = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getFilters = ({
  filters,
  transactionStatuses,
  transactionTypes,
  applyFiltersEnabled,
  tokenFilterValue,
  rrnFilterValue,
  authFilterValue,
  mccFilterValue,
  detailsFilterValue,
  onTokenFilterValueChange,
  onRrnFilterValueChange,
  onAuthFilterValueChange,
  onMccFilterValueChange,
  onDetailsFilterValueChange,
  onFilterChange,
  onFiltersApply,
}) => [
  {
    children: (
      <>
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledText noMargin size="two">
            Token:
          </StyledText>
          <StyledInput
            placeholder="Enter value"
            label="Transaction ID"
            hideLabel
            value={tokenFilterValue}
            onChange={({ target: { value } }) =>
              onTokenFilterValueChange(value)
            }
            onKeyUp={debounce(
              DEBOUNCE_TIMEOUT_SHORT,
              ({ target: { value } }) => {
                onFilterChange({ field: TRANSACTION_ID_FILTER, value });
              }
            )}
            noMargin
          />
        </ColFilterWrapper>
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledSelect
            noMargin
            placeholder={TRANSACTION_FILTERS_ANY_OPTION.label}
            label="Transaction status:"
            value={filters[TRANSACTION_STATUS_FILTER]}
            onChange={({ target: { value } }) =>
              onFilterChange({
                field: TRANSACTION_STATUS_FILTER,
                value,
              })
            }
          >
            {map(transactionStatuses, ({ name, label }) => (
              <option key={name} value={name}>
                {label}
              </option>
            ))}
          </StyledSelect>
        </ColFilterWrapper>
      </>
    ),
  },
  {
    children: (
      <>
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledText noMargin size="two">
            RRN:
          </StyledText>
          <StyledInput
            placeholder="Enter value"
            label="Transaction RRN"
            hideLabel
            value={rrnFilterValue}
            onChange={({ target: { value } }) => onRrnFilterValueChange(value)}
            onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) =>
              onFilterChange({ field: TRANSACTION_RRN_FILTER, value })
            )}
            noMargin
          />
        </ColFilterWrapper>
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledSelect
            noMargin
            placeholder={TRANSACTION_FILTERS_ANY_OPTION.label}
            label="Transaction type:"
            value={filters[TRANSACTION_TYPE_FILTER]}
            onChange={({ target: { value } }) =>
              onFilterChange({
                field: TRANSACTION_TYPE_FILTER,
                value,
              })
            }
          >
            {map(transactionTypes, ({ name, label }) => (
              <option key={name} value={name}>
                {label}
              </option>
            ))}
          </StyledSelect>
        </ColFilterWrapper>
      </>
    ),
  },
  {
    children: (
      <>
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledText noMargin size="two">
            Auth code:
          </StyledText>
          <StyledInput
            placeholder="Enter value"
            label="Transaction authorization code"
            hideLabel
            value={authFilterValue}
            onChange={({ target: { value } }) => onAuthFilterValueChange(value)}
            onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) =>
              onFilterChange({ field: TRANSACTION_AUTH_CODE_FILTER, value })
            )}
            noMargin
          />
        </ColFilterWrapper>
        <ColFilterWrapper onClick={stopEventPropagation}>
          <StyledText noMargin size="two">
            MCC:
          </StyledText>
          <StyledInput
            placeholder="Enter value"
            label="Transaction MCC"
            hideLabel
            value={mccFilterValue}
            onChange={({ target: { value } }) => onMccFilterValueChange(value)}
            onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) =>
              onFilterChange({ field: TRANSACTION_MCC_FILTER, value })
            )}
            noMargin
          />
        </ColFilterWrapper>
      </>
    ),
  },
  {
    children: (
      <RowFilterWrapper onClick={stopEventPropagation}>
        <StyledText noMargin size="two">
          Details:
        </StyledText>
        <StyledInput
          placeholder="Enter value"
          label="Transaction details"
          hideLabel
          value={detailsFilterValue}
          onChange={({ target: { value } }) =>
            onDetailsFilterValueChange(value)
          }
          onKeyUp={debounce(DEBOUNCE_TIMEOUT_SHORT, ({ target: { value } }) =>
            onFilterChange({ field: TRANSACTION_DETAILS_FILTER, value })
          )}
          noMargin
        />
      </RowFilterWrapper>
    ),
  },
  {
    children: (
      <StyledButton disabled={!applyFiltersEnabled} variant="primary">
        Apply filters
      </StyledButton>
    ),
    onClick: onFiltersApply,
  },
];
