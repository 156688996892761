import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SpinnerWrapper } from 'components/UserAdministration/UserAdministrationStyled';
import { ADD_USER_MODAL_MAX_WIDTH } from 'components/UserAdministration/constants';

export const StyledModalWrapper = styled('div')(
  ({ theme }) => css`
    min-width: ${ADD_USER_MODAL_MAX_WIDTH}px;
    border-radius: 16px;

    header {
      display: flex;
      justify-content: center;
    }

    > label {
      margin: ${theme.spacings.giga} 0;
    }
  `
);

export const GroupsList = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacings.bit} 0;
    max-height: 45vh;
    overflow: overlay;
  `
);

export const Actions = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;

    button {
      margin: ${theme.spacings.kilo} ${theme.spacings.byte} 0;
    }
  `
);

export const StyledSpinnerWrapper = styled(SpinnerWrapper)`
  height: 25vh;
`;
