import React, { useCallback, useEffect, useState } from 'react';
import { Body, Spinner } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DataGrid from 'components/DataGrid';
import PropTypes from 'prop-types';
import {
  ERROR_MESSAGE,
  WALLET_TOKEN_STATUSES,
  WALLET_TOKENS_MODAL_MIN_HEIGHT,
  WALLET_TOKENS_MODAL_MIN_WIDTH,
} from 'variables';
import Error from 'components/Error';
import { getWalletTokens } from 'api';
import { useHttpClient } from 'hooks';
import { sortWalletTokens } from 'services/accountData';
import { STATUS } from './columns';

const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const StyledModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: ${WALLET_TOKENS_MODAL_MIN_WIDTH}px;
  min-height: ${WALLET_TOKENS_MODAL_MIN_HEIGHT}px;
`;

const StyledText = styled(Body)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacings.byte};
  `
);

const WalletTokensModal = ({
  columns,
  sortable,
  cardId,
  cardToken,
  clientId,
  paymentsBlocked,
  hasActionAccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [walletTokens, setWalletTokens] = useState([]);

  const httpClient = useHttpClient();

  const loadWalletTokens = useCallback(
    () =>
      getWalletTokens({ clientId, cardToken, httpClient })
        .then(({ data }) => {
          const { responseData = {} } = data;
          const { tokensList = [] } = responseData;

          setWalletTokens(sortWalletTokens({ tokensList }));
          setLoading(false);
        })
        .catch(() => {
          setError(ERROR_MESSAGE);
          setLoading(false);
        }),
    [httpClient, cardToken, clientId]
  );

  useEffect(() => {
    setLoading(true);
    loadWalletTokens();
  }, [loadWalletTokens]);

  const checkGridRowDisabled = ({ record }) =>
    record[STATUS.name] === WALLET_TOKEN_STATUSES.DEACTIVATED.label;

  const handleModalConfirmSuccess = () => {
    setLoading(true);

    // Status update takes a couple of seconds, so we need to wait a bit in order to show the new values
    setTimeout(() => {
      loadWalletTokens({ cardToken });
    }, 7000);
  };

  return (
    <StyledModalWrapper>
      <StyledText noMargin size="one" variant="highlight">
        Card {cardId} / Wallet tokens
      </StyledText>
      {error && <Error>{error}</Error>}
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!loading && (
        <DataGrid
          columns={columns}
          dataSource={walletTokens}
          sortable={sortable}
          clientId={clientId}
          activeCard={{ cardToken }}
          paymentsBlocked={paymentsBlocked}
          checkRowDisabled={checkGridRowDisabled}
          hasActionAccess={hasActionAccess}
          modalConfirmSuccess={handleModalConfirmSuccess}
        />
      )}
    </StyledModalWrapper>
  );
};

WalletTokensModal.defaultProps = {
  sortable: false,
  cardId: 12121,
  cardToken: '12121',
  paymentsBlocked: false,
};

WalletTokensModal.propTypes = {
  columns: PropTypes.array.isRequired,
  sortable: PropTypes.bool,
  cardId: PropTypes.number,
  cardToken: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  paymentsBlocked: PropTypes.bool,
  hasActionAccess: PropTypes.func.isRequired,
};

export default WalletTokensModal;
