import { SORT_ORDER_DESCENDING } from 'variables';
import {
  SCHEDULED_PAYMENTS_FIRST_PAGE,
  SCHEDULE_HISTORY_PAGE_SIZE,
  SORT_BY_MAP,
  SORT_DIRECTION_MAP,
  STATUS_ACTIVE,
  TYPE_ALL,
} from './constants';
import { DATE } from './scheduleHistoryColumns';

const sortBy = SORT_BY_MAP[STATUS_ACTIVE.value];

export const initialState = {
  loading: true,
  error: null,
  scheduledPayments: [],
  pageSize: 10,
  currentPage: SCHEDULED_PAYMENTS_FIRST_PAGE,
  totalPages: 1,
  totalCount: 0,
  statusFilter: STATUS_ACTIVE.value,
  typeFilter: TYPE_ALL.value,
  sort: {
    [sortBy]: SORT_DIRECTION_MAP[STATUS_ACTIVE.value],
    fieldName: sortBy,
  },
  selectedScheduledPayment: null,
  transactionHistoryLoadingForId: null,
  transactionHistory: {
    transactions: [],
    pageSize: SCHEDULE_HISTORY_PAGE_SIZE,
    currentPage: SCHEDULED_PAYMENTS_FIRST_PAGE,
    totalPages: 1,
    totalCount: 0,
    sort: {
      [DATE.name]: SORT_ORDER_DESCENDING,
      fieldName: DATE.name,
    },
  },
};
