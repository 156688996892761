import React from 'react';
import PropTypes from 'prop-types';
import { Body, RadioButtonGroup } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyledCard } from '../../TransactionsStyled';
import {
  ALL_TRANSACTION_DIRECTIONS,
  TRANSACTION_DIRECTION_CREDIT,
  TRANSACTION_DIRECTION_DEBIT,
  TRANSACTION_DIRECTION_FILTER_OPTIONS,
} from '../../constants';

export const StyledRadioButtonGroup = styled(RadioButtonGroup)(
  ({ theme }) => css`
    display: flex;
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};
  `
);

const ALL = TRANSACTION_DIRECTION_FILTER_OPTIONS[ALL_TRANSACTION_DIRECTIONS];
const CREDIT =
  TRANSACTION_DIRECTION_FILTER_OPTIONS[TRANSACTION_DIRECTION_CREDIT];
const DEBIT = TRANSACTION_DIRECTION_FILTER_OPTIONS[TRANSACTION_DIRECTION_DEBIT];

const TransactionDirectionFilter = ({ filter, onChange }) => {
  const options = [
    {
      value: ALL.value,
      label: ALL.label,
    },
    {
      value: CREDIT.value,
      label: CREDIT.label,
    },
    {
      value: DEBIT.value,
      label: DEBIT.label,
    },
  ];

  return (
    <StyledCard>
      <Body size="two" noMargin>
        Direction:
      </Body>
      <StyledRadioButtonGroup
        value={filter}
        options={options}
        label="Select transaction direction filter"
        hideLabel
        onChange={({ target: { value } }) => onChange(value)}
      />
    </StyledCard>
  );
};

TransactionDirectionFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TransactionDirectionFilter;
