import { actionTypes } from 'variables';

export const setPage = ({ page }) => ({
  type: actionTypes.SET_SCHEDULED_PAYMENTS_PAGE,
  payload: { page },
});

export const setPageSize = ({ size }) => ({
  type: actionTypes.SET_SCHEDULED_PAYMENTS_PAGE_SIZE,
  payload: { size },
});

export const setStatusFilter = ({ status }) => ({
  type: actionTypes.SET_SCHEDULED_PAYMENTS_STATUS_FILTER,
  payload: { status },
});

export const setTypeFilter = ({ type }) => ({
  type: actionTypes.SET_SCHEDULED_PAYMENTS_TYPE_FILTER,
  payload: { type },
});

export const setSelectedScheduledPayment = (selectedScheduledPayment) => ({
  type: actionTypes.SET_SELECTED_SCHEDULED_PAYMENT,
  payload: { selectedScheduledPayment },
});

export const loadScheduleTransactionHistorySuccess = (data) => ({
  type: actionTypes.LOAD_SCHEDULE_TRANSACTION_HISTORY_SUCCESS,
  payload: data,
});

export const clearScheduleTransactionHistory = () => ({
  type: actionTypes.CLEAR_SCHEDULE_TRANSACTION_HISTORY,
});

export const setScheduleTransactionHistoryLoading = (scheduledPaymentId) => ({
  type: actionTypes.SET_SCHEDULE_TRANSACTION_HISTORY_LOADING,
  payload: { scheduledPaymentId },
});

export const sortScheduleHistory = ({ name, order }) => ({
  type: actionTypes.SORT_SCHEDULE_HISTORY,
  payload: { name, order },
});

export const setScheduleHistoryPage = (page) => ({
  type: actionTypes.SET_SCHEDULE_HISTORY_PAGE,
  payload: { page },
});

export const setScheduleHistoryPageSize = (size) => ({
  type: actionTypes.SET_SCHEDULE_HISTORY_PAGE_SIZE,
  payload: { size },
});
