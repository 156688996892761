import {
  CARD_DATA_AUTH_CODE,
  CARD_DATA_CLEARING_BILLING_AMOUNT,
  CARD_DATA_CLEARING_CONVERSATION_RATE_BILLING,
  MERCHANT_DETAILS,
  RETRIEVAL_REFERENCE_NUMBER,
  CLEARING_DATE_FIELD,
} from 'components/Transaction/fieldsConfig';

export const columns = [
  { ...CLEARING_DATE_FIELD, sortable: true, active: true },
  {
    ...RETRIEVAL_REFERENCE_NUMBER,
    sortable: true,
  },
  {
    ...CARD_DATA_AUTH_CODE,
    sortable: true,
  },
  {
    label: 'Matching',
    getValue: () => 'Auto',
    sortable: true,
  },
  {
    ...CARD_DATA_CLEARING_BILLING_AMOUNT,
    label: 'Cleared amount',
    sortable: true,
  },
  {
    ...CARD_DATA_CLEARING_CONVERSATION_RATE_BILLING,
    label: 'Clearing rate',
    sortable: true,
  },
  {
    ...MERCHANT_DETAILS,
    sortable: true,
  },
];

export const CLEARINGS_NON_NUMERIC_FIELDS_MAP = {
  [CLEARING_DATE_FIELD.name]: true,
};
