import { CARD_ADMIN_V01_PATH } from 'variables/globals';

export const batchEnrol = async (httpClient, file) => {
  const formData = new FormData();
  formData.append('fileUpload', file);

  return httpClient.put(`${CARD_ADMIN_V01_PATH}/nba/enrol-batch`, formData, {
    headers: {
      'x-sumup-superuser-id': 'test@sumup.com',
      'Content-Type': 'multipart/form-data; boundary=\\r\\n',
    },
  });
};
