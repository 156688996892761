import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { reduce } from 'lodash';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { useNotificationModal, useModal } from '@sumup/circuit-ui';
import { useParams } from 'react-router-dom';
import {
  AUTHORIZATIONS_PAGE_SIZE_COOKIE,
  AUTHORIZATIONS_DATE_PERIOD_COOKIE,
  DATE_FORMATS,
} from 'variables';
import { adjustAuthorization, getAuthorizationsForAdjustment } from 'api';
import { loadSuccess, loadError, setLoading } from 'actions';
import Cookies from 'js-cookie';
import { setDatePeriodCookie } from 'services/transactions';
import { AdjustAuthorizations } from './AdjustAuthorizations';
import { adjustAuthorizationsReducer } from './adjustAuthorizationsReducer';
import { initialState } from './state';
import { columns } from './fieldsConfig';
import {
  setPageSize,
  setPage,
  setFilter,
  setFilterDateRange,
  updateAuthorizationsForAdjustmentSuccess,
  applyFilters,
  clearFilters,
  setIgnoreAuthorizationLoading,
  setAdjustAuthorizationLoading,
  removeIgnoreAuthorizationLoading,
  removeAdjustAuthorizationLoading,
} from './adjustAuthorizationsActions';
import {
  BUTTONS_ACTIONS,
  DATE_PERIOD_OPTIONS,
  PRE_AUTH_FILTER_ANY_OPTION,
} from './constants';
import AdjustAuthorizationModal from './components/AuthorizationsGrid/components/AdjustAuthorizationModal';

const datePeriodCookie = Cookies.get(AUTHORIZATIONS_DATE_PERIOD_COOKIE);
const pageSizeCookie = Cookies.get(AUTHORIZATIONS_PAGE_SIZE_COOKIE);

let initialDatePeriod = { ...initialState.datePeriod };
let initialPageSize = initialState.size;

if (datePeriodCookie) {
  const [name, startDate, endDate] = datePeriodCookie.split('_');

  initialDatePeriod = {
    name,
    startDate: moment(startDate, DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
    endDate: moment(endDate, DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
    ...DATE_PERIOD_OPTIONS[name],
  };
} else {
  const { datePeriod } = initialState;
  setDatePeriodCookie(datePeriod, AUTHORIZATIONS_DATE_PERIOD_COOKIE);
}

if (pageSizeCookie) {
  initialPageSize = pageSizeCookie;
} else {
  const { size } = initialState;
  Cookies.set(AUTHORIZATIONS_PAGE_SIZE_COOKIE, size);
}

const AdjustAuthorizationsData = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();
  const { setModal } = useModal();
  const { setModal: setNotificationModal } = useNotificationModal();
  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getAuthorizationsForAdjustment({
          httpClient,
          page: initialState.currentPage,
          size: Number(initialPageSize),
          from: moment(initialDatePeriod.startDate).format(
            DATE_FORMATS.YEAR_SHORT_MONTH_DAY
          ),
          to: moment(initialDatePeriod.endDate).format(
            DATE_FORMATS.YEAR_SHORT_MONTH_DAY
          ),
        }),
      [httpClient]
    ),
    clientId,
    adjustAuthorizationsReducer,
    {
      ...initialState,
      datePeriod: {
        ...initialState.datePeriod,
        ...initialDatePeriod,
      },
      size: Number(initialPageSize),
      filters: initialState.filters,
      filtersApplied: initialState.filtersApplied,
    }
  );

  const {
    authorizationsForAdjustment,
    size,
    totalPages,
    currentPage,
    loading,
    filtersApplied,
    applyFiltersEnabled,
    datePeriod = {},
    filters,
    ignoreAuthorizationLoading,
    adjustAuthorizationLoading,
    error,
  } = state;

  useEffect(() => {
    if (error) {
      setNotificationModal({
        headline: error,
        actions: {
          primary: {
            children: 'OK',
          },
        },
      });
    }
  }, [error, setNotificationModal]);

  const from = datePeriod.startDate;
  const to = datePeriod.endDate;

  const selectedFilters = reduce(
    filters,
    (accumulator, value, filter) => ({
      ...accumulator,
      ...(value !== PRE_AUTH_FILTER_ANY_OPTION.name &&
        value.name !== PRE_AUTH_FILTER_ANY_OPTION.name && {
          [filter]: value.name || value,
        }),
    }),
    {}
  );

  const updateAuthorizationsForAdjustment = (args, successHandler) => {
    dispatch(setLoading(true));

    return getAuthorizationsForAdjustment({
      httpClient,
      ...args,
    })
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          successHandler();
        }
      })
      .catch(() => dispatch(loadError()));
  };

  const handlePageSizeChange = (pageSize) => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForAdjustment(
      {
        size: pageSize,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        ...selectedFilters,
      },
      () => {
        dispatch(setPageSize({ size: pageSize }));

        Cookies.set(AUTHORIZATIONS_PAGE_SIZE_COOKIE, pageSize);
      }
    );
  };

  const handlePageChange = (page) => {
    updateAuthorizationsForAdjustment(
      {
        size,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        ...selectedFilters,
      },
      () => dispatch(setPage({ page }))
    );
  };

  const handleFilterChange = ({ field, value }) => {
    dispatch(setFilter({ field, value }));
  };

  const handleDateRangeChange = (dateRange) => {
    dispatch(setFilterDateRange(dateRange));

    const { name, startDate, endDate } = dateRange;

    if (startDate && endDate) {
      setDatePeriodCookie(
        { name, startDate, endDate },
        AUTHORIZATIONS_DATE_PERIOD_COOKIE
      );

      const { currentPage: page } = initialState;

      updateAuthorizationsForAdjustment(
        {
          size,
          page,
          from: moment(startDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          to: moment(endDate).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
          ...selectedFilters,
        },
        () => dispatch(setPage({ page }))
      );
    }
  };

  const updateAuthorizationProps = (authorization, props) => ({
    ...authorization,
    ...props,
  });

  const handleAdjustAuthorizationSuccess = ({
    authorizationAdjustmentId,
    action,
  }) => {
    const authorizationsForAdjustmentUpdated = authorizationsForAdjustment.map(
      (authorizationForAdjustment) => {
        if (
          authorizationForAdjustment.adjustmentId === authorizationAdjustmentId
        ) {
          return updateAuthorizationProps(authorizationForAdjustment, {
            adjusted: action,
            error: false,
          });
        }

        return authorizationForAdjustment;
      }
    );

    dispatch(
      updateAuthorizationsForAdjustmentSuccess({
        authorizationsForAdjustmentUpdated,
      })
    );
  };

  const handleAdjustAuthorizationError = ({ authorizationAdjustmentId }) => {
    const authorizationsForAdjustmentUpdated = authorizationsForAdjustment.map(
      (authorizationForAdjustment) => {
        if (
          authorizationForAdjustment.adjustmentId === authorizationAdjustmentId
        ) {
          return updateAuthorizationProps(authorizationForAdjustment, {
            error: true,
          });
        }

        return authorizationForAdjustment;
      }
    );

    dispatch(
      updateAuthorizationsForAdjustmentSuccess({
        authorizationsForAdjustmentUpdated,
      })
    );
  };

  const handleAdjustAuthorizationConfirm = ({
    authorizationAdjustmentId,
    action,
    onClose,
  }) => {
    if (action === BUTTONS_ACTIONS.IGNORE) {
      dispatch(setIgnoreAuthorizationLoading(authorizationAdjustmentId));
    } else {
      dispatch(setAdjustAuthorizationLoading(authorizationAdjustmentId));
      onClose();
    }

    return adjustAuthorization({
      httpClient,
      authorizationAdjustmentId,
      action,
    })
      .then(() => {
        handleAdjustAuthorizationSuccess({ authorizationAdjustmentId, action });
      })
      .catch(() => {
        handleAdjustAuthorizationError({ authorizationAdjustmentId });
      })
      .finally(() => {
        if (action === BUTTONS_ACTIONS.IGNORE) {
          dispatch(removeIgnoreAuthorizationLoading(authorizationAdjustmentId));
        } else {
          dispatch(removeAdjustAuthorizationLoading(authorizationAdjustmentId));
        }
      });
  };

  const handleAdjustAuthorizationModal = ({
    authorizationAdjustmentId,
    action,
    formattedAmount,
  }) => {
    setModal({
      closeButtonLabel: 'Close',
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <AdjustAuthorizationModal
          action={action}
          onClose={onClose}
          onConfirm={handleAdjustAuthorizationConfirm}
          authorizationAdjustmentId={authorizationAdjustmentId}
          formattedAmount={formattedAmount}
        />
      ),
    });
  };

  const handleFiltersApply = () => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForAdjustment(
      {
        size,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        ...selectedFilters,
      },
      () => {
        dispatch(applyFilters());
      }
    );
  };

  const handleFiltersClear = () => {
    const { currentPage: page } = initialState;

    updateAuthorizationsForAdjustment(
      {
        size,
        page,
        from: moment(from).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
        to: moment(to).format(DATE_FORMATS.YEAR_SHORT_MONTH_DAY),
      },
      () => {
        dispatch(clearFilters());
      }
    );
  };

  return (
    <>
      <AdjustAuthorizations
        authorizationsForAdjustment={authorizationsForAdjustment}
        columns={columns}
        onPageSizeChange={handlePageSizeChange}
        size={size}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        loading={loading}
        ignoreAuthorizationLoading={ignoreAuthorizationLoading}
        adjustAuthorizationLoading={adjustAuthorizationLoading}
        filtersApplied={filtersApplied}
        applyFiltersEnabled={applyFiltersEnabled}
        onFilterChange={handleFilterChange}
        onFiltersApply={handleFiltersApply}
        onFiltersClear={handleFiltersClear}
        filters={filters}
        datePeriod={datePeriod}
        onDateRangeChange={handleDateRangeChange}
        onAdjustAuthorizationModal={handleAdjustAuthorizationModal}
        onAdjustAuthorizationConfirm={handleAdjustAuthorizationConfirm}
      />
    </>
  );
};

export default AdjustAuthorizationsData;
