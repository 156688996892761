import React, { useContext, useMemo } from 'react';
import AuthContext from 'context/auth';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import {
  Body,
  Headline,
  Button,
  spacing,
  Spinner,
  useModal,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { hasActionAccess } from 'services/permissions';
import { ACTIONS, APPS } from 'variables';
import Modal from 'components/Modal';
import CardAuthorization from '../CardAuthorization';

const AuthorizationsScroll = styled.div(
  ({ theme }) => css`
    max-height: calc(100vh - 250px);
    overflow: auto;
    border-top: 1px solid ${theme.colors.n300};
    border-bottom: 1px solid ${theme.colors.n300};
    padding: ${theme.spacings.mega};
    width: 100%;
    display: flex;
    flex-direction: column;
  `
);

const AuthorizationsContainer = styled('div')`
  ${({ theme: { colors, spacings, borderRadius } }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    border-radius: ${borderRadius.bit};
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: ${spacings.mega} 0;
    max-height: calc(100vh - 250px);
    width: 300px;
    p:only-child {
      padding: ${spacings.mega};
      text-align: center;
    }
    button {
      overflow: visible;
    }
    position: sticky;
    top: 241px;
    margin: 0;
    left: calc(100% - 320px);
  `}
`;

const StyledSpinner = styled(Spinner)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CONFIRM_MODAL_WIDTH = 420;

const Authorizations = ({
  cardAuthorizations,
  selectedAuthorization,
  onClickAuthorization,
  onSubmitMatching,
  authorizationsLoading,
  selectedClearing,
  authorizationsTotalPages,
  authorizationsPageNumber,
  onGetCardAuthorizations,
}) => {
  const {
    state: { permissions },
  } = useContext(AuthContext);
  const matchAuthorizationDisabled = useMemo(
    () =>
      !hasActionAccess({
        permissions,
        app: APPS.card_reconciliation,
        action: ACTIONS[APPS.card_reconciliation].clearingsMatch,
      }),
    [permissions]
  );

  const showLoadMoreButton =
    authorizationsPageNumber !== authorizationsTotalPages;

  const { setModal } = useModal();

  const handlePopoverOptionClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            width: CONFIRM_MODAL_WIDTH,
            title: 'Insert offline transaction',
            content:
              'A new offline authorization will be created and matched with the selected unmatched clearing.',
            confirmText: 'Insert and match',
            cancelText: 'Cancel',
            centered: true,
            disabled: false,
            onConfirm: () =>
              onSubmitMatching({
                offline: true,
              }),
          }}
          onClose={onClose}
        />
      ),
    });
  };

  return (
    <AuthorizationsContainer>
      {(authorizationsLoading && <StyledSpinner size="kilo" />) ||
        (cardAuthorizations && cardAuthorizations.length && (
          <>
            <Headline size="three" noMargin css={spacing({ bottom: 'mega' })}>
              Select authorization
            </Headline>
            <AuthorizationsScroll>
              {map(cardAuthorizations, (authorization) => (
                <CardAuthorization
                  key={authorization.authorizationId}
                  authorization={authorization}
                  selected={
                    authorization.authorizationId === selectedAuthorization
                  }
                  onClickAuthorization={onClickAuthorization}
                />
              ))}
              {showLoadMoreButton && (
                <Button
                  variant="tertiary"
                  onClick={() =>
                    onGetCardAuthorizations({
                      page: authorizationsPageNumber + 1,
                      cardToken: selectedClearing.cardIdNumber,
                    })
                  }
                >
                  Load more
                </Button>
              )}
              <Button
                variant="tertiary"
                onClick={handlePopoverOptionClick}
                disabled={matchAuthorizationDisabled}
              >
                Insert offline transaction
              </Button>
            </AuthorizationsScroll>
            <Button
              variant="primary"
              size="giga"
              onClick={onSubmitMatching}
              css={spacing({ top: 'giga' })}
              disabled={!selectedAuthorization || matchAuthorizationDisabled}
            >
              Match authorization
            </Button>
          </>
        )) ||
        (selectedClearing && (
          <>
            <Body size="one" variant="subtle" noMargin>
              No authorizations for this clearing
            </Body>
            <Button
              variant="tertiary"
              onClick={handlePopoverOptionClick}
              disabled={matchAuthorizationDisabled}
            >
              Insert offline transaction
            </Button>
          </>
        )) || (
          <Body size="one" variant="subtle" noMargin>
            Select a clearing to match it to an authorization
          </Body>
        )}
    </AuthorizationsContainer>
  );
};

Authorizations.defaultProps = {
  selectedClearing: {},
  selectedAuthorization: 12,
  authorizationsPageNumber: 1,
  authorizationsTotalPages: 0,
};

Authorizations.propTypes = {
  cardAuthorizations: PropTypes.array.isRequired,
  selectedAuthorization: PropTypes.number,
  onClickAuthorization: PropTypes.func.isRequired,
  onSubmitMatching: PropTypes.func.isRequired,
  authorizationsLoading: PropTypes.bool.isRequired,
  selectedClearing: PropTypes.object,
  authorizationsTotalPages: PropTypes.number,
  authorizationsPageNumber: PropTypes.number,
  onGetCardAuthorizations: PropTypes.func.isRequired,
};

export default Authorizations;
