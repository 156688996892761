import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Confirm } from '@sumup/icons';
import { Checkbox } from '@sumup/circuit-ui';

export const Item = styled('div')(
  ({ theme, readonly }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacings.byte} 10px;
    margin: ${theme.spacings.bit} 0;
    background: ${theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};

    svg {
      margin-right: ${theme.spacings.kilo};
    }

    ${readonly
      ? `
        pointer-events: none;
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.n200};
        border-radius: ${theme.borderRadius.bit};
      `
      : 'cursor: pointer;'}
  `
);

export const StyledCheckmark = styled(Confirm)(
  ({ theme }) => css`
    color: ${theme.colors.confirm};
  `
);

export const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => css`
    label {
      padding: 0;
      margin-right: ${theme.spacings.giga};
      position: static;

      :before {
        position: relative;
      }
    }
  `
);
