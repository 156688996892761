import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

export const StyledText = styled(Body)(
  ({ theme: { fontWeight } }) => css`
    span {
      font-weight: ${fontWeight.bold};
    }
  `
);
