import { SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING } from 'variables';

export const SCHEDULED_PAYMENTS_FIRST_PAGE = 1;

export const SCHEDULE_STATUS_ACTIVE = 'ACTIVE';
export const SCHEDULE_STATUS_COMPLETED = 'COMPLETED';
export const SCHEDULE_STATUS_CANCELLED = 'CANCELLED';

export const PAYMENT_STATUS_CREATED = 'Created';
export const PAYMENT_STATUS_COMPLETED = 'Completed';
export const PAYMENT_STATUS_REJECTED = 'Rejected';
export const PAYMENT_STATUS_CANCELLED = 'Cancelled';
export const PAYMENT_STATUS_PENDING = 'Pending';

export const STATUS_ACTIVE = {
  value: SCHEDULE_STATUS_ACTIVE,
  label: 'Active',
};

const STATUS_ARCHIVED = {
  value: 'ARCHIVED',
  label: 'Archived',
};

export const STATUS_COMPLETED = {
  value: SCHEDULE_STATUS_COMPLETED,
  label: 'Completed',
};

export const STATUS_CANCELLED = {
  value: SCHEDULE_STATUS_CANCELLED,
  label: 'Cancelled',
};

export const ONE_OFF_SCHEDULE_STATUS_COMPLETED = {
  value: PAYMENT_STATUS_COMPLETED,
  label: 'Successful',
};

export const ONE_OFF_SCHEDULE_STATUS_REJECTED = {
  value: PAYMENT_STATUS_REJECTED,
  label: 'Unsuccessful',
};

export const STATUS_FILTER_OPTIONS = [
  {
    value: STATUS_ACTIVE.value,
    label: STATUS_ACTIVE.label,
  },
  {
    value: STATUS_ARCHIVED.value,
    label: STATUS_ARCHIVED.label,
  },
];

export const TYPE_ALL = {
  value: 'ALL',
  label: 'All',
};

export const STATUS_FILTER_MAP = {
  [STATUS_ACTIVE.value]: SCHEDULE_STATUS_ACTIVE,
  [STATUS_ARCHIVED.value]: `${SCHEDULE_STATUS_COMPLETED},${SCHEDULE_STATUS_CANCELLED}`,
};

export const PAYMENT_SCHEDULE_TYPE_ONE_OFF = 'ONE_OFF';
export const PAYMENT_SCHEDULE_TYPE_WEEKLY = 'WEEKLY';
export const PAYMENT_SCHEDULE_TYPE_MONTHLY = 'MONTHLY';

export const TYPE_ONE_OFF = {
  value: PAYMENT_SCHEDULE_TYPE_ONE_OFF,
  label: 'One time',
};

export const TYPE_WEEKLY = {
  value: PAYMENT_SCHEDULE_TYPE_WEEKLY,
  label: 'Weekly',
};

export const TYPE_MONTHLY = {
  value: PAYMENT_SCHEDULE_TYPE_MONTHLY,
  label: 'Monthly',
};

export const TYPE_FILTER_OPTIONS = [
  {
    value: TYPE_ALL.value,
    label: TYPE_ALL.label,
  },
  {
    value: TYPE_ONE_OFF.value,
    label: TYPE_ONE_OFF.label,
  },
  {
    value: TYPE_WEEKLY.value,
    label: TYPE_WEEKLY.label,
  },
  {
    value: TYPE_MONTHLY.value,
    label: TYPE_MONTHLY.label,
  },
];

export const TYPE_FILTER_MAP = {
  [TYPE_ALL.value]: `${PAYMENT_SCHEDULE_TYPE_ONE_OFF},${PAYMENT_SCHEDULE_TYPE_WEEKLY},${PAYMENT_SCHEDULE_TYPE_MONTHLY}`,
  [TYPE_ONE_OFF.value]: PAYMENT_SCHEDULE_TYPE_ONE_OFF,
  [TYPE_WEEKLY.value]: PAYMENT_SCHEDULE_TYPE_WEEKLY,
  [TYPE_MONTHLY.value]: PAYMENT_SCHEDULE_TYPE_MONTHLY,
};

export const SORT_BY_MAP = {
  [STATUS_ACTIVE.value]: 'next_payment_due',
  [STATUS_ARCHIVED.value]: 'updated_at',
};

export const SORT_DIRECTION_MAP = {
  [STATUS_ACTIVE.value]: SORT_ORDER_ASCENDING,
  [STATUS_ARCHIVED.value]: SORT_ORDER_DESCENDING,
};

export const TYPE_LABEL = {
  [TYPE_ONE_OFF.value]: TYPE_ONE_OFF.label,
  [TYPE_WEEKLY.value]: TYPE_WEEKLY.label,
  [TYPE_MONTHLY.value]: TYPE_MONTHLY.label,
};

export const SCHEDULE_HISTORY_PAGE_SIZE = 10;
