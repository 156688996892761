import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SingleDayPicker } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ONE_WEEK } from '../../../../variables';

const DayPickerWrapper = styled('div')(
  ({ theme: { spacings, typography } }) => css`
    div.SingleDatePicker {
      margin: 1px 2px;
    }

    div.SingleDatePickerInput {
      padding: 5px 8px;
      border: unset !important;
      box-shadow: 0 0 0 1px rgb(12 15 20 / 7%), 0 0 1px 0 rgb(12 15 20 / 7%);

      div.DateInput_1 {
        display: flex;
        align-items: center;
        margin: 0 ${spacings.byte};
        max-width: 72px;

        > input {
          min-width: 85px;
          font-size: ${typography.body.two.fontSize};
          line-height: ${typography.body.two.lineHeight};
        }
      }
    }
  `
);

const SingleDayFilter = ({ id, date, onDateChange }) => {
  const [focusedInput, setFocusedInput] = useState(false);

  const isOutsideRange = (day) => {
    return day > moment().subtract(ONE_WEEK, 'd');
  };

  return (
    <DayPickerWrapper>
      <SingleDayPicker
        id={id}
        placeholder="Select a day"
        date={date}
        onDateChange={onDateChange}
        focused={focusedInput}
        isOutsideRange={isOutsideRange}
        onFocusChange={({ focused }) => setFocusedInput(focused)}
      />
    </DayPickerWrapper>
  );
};

SingleDayFilter.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

export default SingleDayFilter;
