import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Select, SubHeadline, Body } from '@sumup/circuit-ui';
import { DETAILS_SECTION_PADDING } from './constants';

export const ButtonSuccess = styled(Button)(
  ({ theme }) => css`
    min-height: 48px;
    margin: ${theme.spacings.mega} ${theme.spacings.kilo};
    background: ${theme.colors.g700};
    color: ${theme.colors.white};

    :hover {
      background: ${theme.colors.g700};
    }

    svg {
      margin-right: 10px;
    }
  `
);

export const PageContent = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    margin: ${theme.spacings.mega} ${theme.spacings.kilo};
    background: ${theme.colors.g700};
    color: ${theme.colors.white};

    :hover {
      background: ${theme.colors.g700};
    }

    svg {
      margin-right: 10px;
    }
  `
);

export const StyledSelect = styled(Select)(
  ({ theme }) => css`
    margin-bottom: ${DETAILS_SECTION_PADDING}px;

    select {
      display: flex;
      max-height: 32px;
      padding: ${theme.spacings.bit} ${theme.spacings.kilo};
    }

    svg {
      padding: ${theme.spacings.bit} ${theme.spacings.kilo}
        ${theme.spacings.kilo} ${theme.spacings.kilo};
    }
  `
);

export const DetailsSectionSubHeading = styled(SubHeadline)`
  margin-bottom: 10px;
`;

export const DetailsSectionBodyHeadingWrapper = styled('div')`
  display: flex;

  div:first-of-type {
    display: flex;
    margin-left: auto;
  }
`;

export const DetailsSectionBody = styled('div')`
  padding: ${DETAILS_SECTION_PADDING}px;
  overflow: overlay;
`;

export const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

export const StyledText = styled(Body)(
  ({ theme }) => `
    color: ${theme.colors.p500};
    cursor: pointer;
  }
`
);
