import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { Confirm, Alert, Info, Time, Stopped } from '@sumup/icons';
import { capitalize } from 'lodash/fp';
import {
  STATUSES,
  CARD_STATUSES_MAPPED,
  STATE_LABELS,
  WALLET_TOKEN_STATUSES,
  ACCOUNT_STATUS_MAP,
  BANK_TRX_FRAUD_STATUS_MAP,
} from 'variables';
import { ReactComponent as NoEntry } from 'assets/no-entry.svg';
import Indicator from 'components/Indicator';
import { Success, Neutral, Danger, Warning, Final } from './StatusStyled';

const STATUS_SUCCESS = {
  [STATUSES.APPROVED]: true,
  [STATUSES.ACTIVE]: true,
  [CARD_STATUSES_MAPPED.ACTIVE]: true,
  [STATUSES.READY_FOR_REISSUING]: true,
  [CARD_STATUSES_MAPPED.READY_FOR_REISSUING]: true,
  [ACCOUNT_STATUS_MAP.ACTIVE]: true,
  [CARD_STATUSES_MAPPED.ACTIVE_NO_RENEWAL]: true,
  [BANK_TRX_FRAUD_STATUS_MAP.SUCCESSFUL]: true,
};

const STATUS_DANGER = {
  [STATUSES.LOST]: true,
  [CARD_STATUSES_MAPPED.LOST]: true,
  [STATUSES.BLOCKED_PERMANENTLY]: true,
  [CARD_STATUSES_MAPPED.BLOCKED_PERMANENTLY]: true,
  [STATUSES.STOLEN]: true,
  [CARD_STATUSES_MAPPED.STOLEN]: true,
  [STATUSES.CANCELLED]: true,
  [ACCOUNT_STATUS_MAP.CLOSED]: true,
  [BANK_TRX_FRAUD_STATUS_MAP.REJECTED]: true,
  [CARD_STATUSES_MAPPED.EXPIRED]: true,
};

const STATUS_WARNING = {
  [STATUSES.BLOCKED_TEMPORARILY]: true,
  [CARD_STATUSES_MAPPED.BLOCKED_TEMPORARILY]: true,
  [STATUSES.EXPIRED]: true,
  [WALLET_TOKEN_STATUSES.SUSPENDED.label]: true,
  [ACCOUNT_STATUS_MAP.BLOCKED]: true,
  [BANK_TRX_FRAUD_STATUS_MAP.PENDING]: true,
};

const Status = ({ value, style, variant, ...props }) => {
  const text = (
    <Body noMargin size="two" variant={variant}>
      {capitalize(value)}
    </Body>
  );

  return (
    (STATUS_SUCCESS[value] && (
      <Success style={style} {...props}>
        <Confirm size="16" />
        {text}
      </Success>
    )) ||
    (STATUS_DANGER[value] && (
      <Danger style={style} {...props}>
        <Alert size="16" />
        {text}
      </Danger>
    )) ||
    (STATUS_WARNING[value] && (
      <Warning style={style} {...props}>
        <Info size="16" />
        {text}
      </Warning>
    )) ||
    (value === STATUSES.PENDING && (
      <Neutral style={style} {...props}>
        <Time size="24" />
        {text}
      </Neutral>
    )) ||
    (value === CARD_STATUSES_MAPPED.REPLACED && (
      <Final style={style} {...props}>
        <Stopped size="16" />
        {text}
      </Final>
    )) ||
    ((value === STATUSES.REJECTED ||
      value === CARD_STATUSES_MAPPED.REJECTED) && (
      <Danger style={style} {...props}>
        <NoEntry />
        {text}
      </Danger>
    )) ||
    (value === WALLET_TOKEN_STATUSES.DEACTIVATED.label && (
      <Danger style={style} {...props}>
        <Indicator />
        {text}
      </Danger>
    )) ||
    (value && (
      <Success style={style} {...props}>
        <Indicator />
        <Body noMargin size="two">
          {STATE_LABELS.ENABLED}
        </Body>
      </Success>
    )) ||
    (!value && (
      <Danger style={style} {...props}>
        <Indicator />
        <Body noMargin size="two">
          {STATE_LABELS.DISABLED}
        </Body>
      </Danger>
    ))
  );
};

Status.defaultProps = {
  value: '',
  style: {},
  variant: null,
};

Status.propTypes = {
  value: PropTypes.any,
  style: PropTypes.object,
  variant: PropTypes.string,
};

export default Status;
