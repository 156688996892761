import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import { trim } from 'lodash';
import TextSeparator from 'components/TextSeparator';
import {
  StyledSearchResultsItem,
  SearchResultContent,
  StyledLink,
  SearchResultInfoRow,
} from './StyledSearchResults';

const SearchResultsItem = ({
  name,
  email,
  merchantCode,
  link,
  onResultClick,
}) => (
  <StyledLink to={link} onClick={onResultClick}>
    <StyledSearchResultsItem>
      <SearchResultContent>
        <Body noMargin variant="highlight" size="one">
          {name && <p>{trim(name)}</p>}
        </Body>
        <SearchResultInfoRow>
          <Body noMargin size="two">
            {email}
          </Body>
          <TextSeparator noMargin size="two" margin="bit">
            ·
          </TextSeparator>
          <Body noMargin size="two">
            {merchantCode}
          </Body>
        </SearchResultInfoRow>
      </SearchResultContent>
      <ChevronRight />
    </StyledSearchResultsItem>
  </StyledLink>
);

SearchResultsItem.defaultProps = {
  onResultClick: () => {},
  name: '',
};

SearchResultsItem.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  merchantCode: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onResultClick: PropTypes.func,
};

export default SearchResultsItem;
