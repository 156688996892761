import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BUTTONS_ACTIONS } from '../../../../constants';

const StyledBody = styled(Body)(
  ({ theme, type }) => css`
    text-align: center;
    span {
      color: ${theme.colors[type]};
      font-weight: bold;
    }
  `
);

const StyledButtonGroup = styled(ButtonGroup)(
  ({ theme: { spacings, colors }, positive }) => css`
    button {
      margin: ${spacings.bit} ${spacings.byte};
      &:not(:last-child) {
        margin: ${spacings.bit} ${spacings.byte};
      }
      & + button {
        ${positive
          ? `
          background: ${colors.g700};
          color: white;
          border-color: ${colors.g700};
          &:hover {
            background: ${colors.g700};
            border-color: ${colors.g700};
          }
        `
          : `
          background: ${colors.r500};
          color: white;
          border-color: ${colors.r500};
          &:hover {
            background: ${colors.r500};
            border-color: ${colors.r500};
          }
        `};
      }
    }
  `
);

const colorsMapped = {
  neutral: 'n700',
  alert: 'r500',
  confirm: 'g700',
};

const actionsMapped = (formattedAmount) => ({
  [BUTTONS_ACTIONS.IGNORE]: (
    <StyledBody type={colorsMapped.neutral} size="two" noMargin>
      Adjustment was <span>ignored</span>.
    </StyledBody>
  ),
  [BUTTONS_ACTIONS.CHARGE]: (
    <StyledBody type={colorsMapped.alert} size="two" noMargin>
      Merchant was <span>charged {formattedAmount}</span>.
    </StyledBody>
  ),
  [BUTTONS_ACTIONS.RETURN]: (
    <StyledBody type={colorsMapped.confirm} size="two" noMargin>
      <span>{formattedAmount} returned</span> to the merchant.
    </StyledBody>
  ),
});

const ButtonsGroup = ({
  value: { formattedAmount, amount },
  adjusted,
  ignoreAuthorizationLoading,
  adjustAuthorizationLoading,
  authorizationAdjustmentId,
  onAdjustAuthorizationModal,
  onAdjustAuthorizationConfirm,
}) => {
  const positive = amount > 0;

  return adjusted ? (
    <div>{actionsMapped(formattedAmount)[adjusted]}</div>
  ) : (
    <StyledButtonGroup
      positive={positive}
      align="center"
      actions={{
        primary: {
          children: positive ? 'Return' : 'Charge',
          onClick: () =>
            onAdjustAuthorizationModal({
              action: positive
                ? BUTTONS_ACTIONS.RETURN
                : BUTTONS_ACTIONS.CHARGE,
              authorizationAdjustmentId,
              formattedAmount,
            }),
          ...(positive && 'destructive'),
          size: 'kilo',
          disabled: ignoreAuthorizationLoading.includes(
            authorizationAdjustmentId
          ),
          isLoading: adjustAuthorizationLoading.includes(
            authorizationAdjustmentId
          ),
          loadingLabel: 'Loading',
        },
        secondary: {
          children: 'Ignore',
          onClick: () =>
            onAdjustAuthorizationConfirm({
              action: BUTTONS_ACTIONS.IGNORE,
              authorizationAdjustmentId,
            }),
          size: 'kilo',
          disabled: adjustAuthorizationLoading.includes(
            authorizationAdjustmentId
          ),
          isLoading: ignoreAuthorizationLoading.includes(
            authorizationAdjustmentId
          ),
          loadingLabel: 'Loading',
        },
      }}
    />
  );
};

ButtonsGroup.defaultProps = {
  adjusted: '',
};

ButtonsGroup.propTypes = {
  value: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    formattedAmount: PropTypes.string.isRequired,
  }).isRequired,
  ignoreAuthorizationLoading: PropTypes.array.isRequired,
  adjustAuthorizationLoading: PropTypes.array.isRequired,
  adjusted: PropTypes.string,
  onAdjustAuthorizationModal: PropTypes.func.isRequired,
  authorizationAdjustmentId: PropTypes.number.isRequired,
  onAdjustAuthorizationConfirm: PropTypes.func.isRequired,
};

export default ButtonsGroup;
