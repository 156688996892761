import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, SearchInput } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ENTER_KEY_CODE } from 'variables';

export const StyledSearchInput = styled(SearchInput)(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    width: 385px;
    max-height: 40px;
    background: ${colors.white};
  `
);

export const SearchButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    max-height: 40px;
    margin-left: ${theme.spacings.byte};
  `
);

export const Search = ({ searchPlaceholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchDisabled, setSearchDisabled] = useState(true);

  const handleInputKeyUp = ({ keyCode, target: { value } }) => {
    if (keyCode === ENTER_KEY_CODE) {
      onSearch(value);
    }
  };

  const handleInputClear = () => {
    setSearchTerm('');
    setSearchDisabled(true);
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearchTerm(value);
    setSearchDisabled(!value);
  };

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <>
      <StyledSearchInput
        noMargin
        placeholder={searchPlaceholder}
        label="Search in activity log"
        hideLabel
        value={searchTerm}
        clearLabel="Clear search value"
        onKeyUp={handleInputKeyUp}
        onChange={handleInputChange}
        onClear={handleInputClear}
      />
      <SearchButton
        variant="primary"
        disabled={searchDisabled}
        onClick={handleSearch}
      >
        Search
      </SearchButton>
    </>
  );
};

Search.defaultProps = {
  searchPlaceholder: '',
};

Search.propTypes = {
  searchPlaceholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

export default Search;
