import moment from 'moment';
import {
  DATE_FORMATS,
  FIELD_TYPES,
  CUSTOM_FIELD_TYPES,
  DATE_GRID_COLUMN_MAX_WIDTH,
  NARROW_GRID_COLUMN_MAX_WIDTH,
} from 'variables';

const DATE_CREATED = {
  label: 'Created',
  name: 'inserted_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  style: {
    maxWidth: DATE_GRID_COLUMN_MAX_WIDTH,
  },
};

const DATE_UPDATED = {
  label: 'Last updated',
  name: 'updated_at',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  style: {
    maxWidth: DATE_GRID_COLUMN_MAX_WIDTH,
  },
};

const DEVICE_UUID = {
  label: 'Device UUID',
  name: 'device_uuid',
};

const SCA = {
  label: 'SCA',
  name: 'mfa_enabled',
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
  style: {
    maxWidth: NARROW_GRID_COLUMN_MAX_WIDTH,
  },
};

const BIOMETRICS = {
  label: 'Biometrics',
  name: 'biometrics',
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
  style: {
    maxWidth: NARROW_GRID_COLUMN_MAX_WIDTH,
  },
};

const PUSH_NOTIFICATIONS = {
  label: 'Push notifications',
  name: 'push_enabled',
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
  style: {
    maxWidth: 150,
  },
};

const PUSH_DEVICE_TOKEN = {
  label: 'Push device token',
  name: 'device_token',
};

const DEVICE_MODEL = {
  label: 'Device model',
  name: 'device_model',
};

const DEVICE_NAME = {
  label: 'Device name',
  name: 'device_name',
};

const SDK_VERSION = {
  label: 'SDK version',
  name: 'sdk_version',
};

const SYSTEM_NAME = {
  label: 'System name',
  name: 'system_name',
};

const SYSTEM_VERSION = {
  label: 'System version',
  name: 'system_version',
};

const DELETE_BUTTON = {
  label: '',
  name: 'device_uuid',
  renderAs: CUSTOM_FIELD_TYPES.DELETE_SCA_BUTTON,
};

export const columns = [
  {
    ...DATE_CREATED,
    active: true,
  },
  DATE_UPDATED,
  DEVICE_UUID,
  SCA,
  BIOMETRICS,
  PUSH_NOTIFICATIONS,
  PUSH_DEVICE_TOKEN,
  SYSTEM_NAME,
  SYSTEM_VERSION,
  DEVICE_NAME,
  DEVICE_MODEL,
  SDK_VERSION,
  DELETE_BUTTON,
];

export const DEVICES_NON_NUMERIC_FIELDS_MAP = {
  [DATE_CREATED.name]: true,
  [DATE_UPDATED.name]: true,
};
