import React, { useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'context/auth';
import { hasActionAccess, hasSectionAccess } from 'services/permissions';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { getOpsTransfers } from 'api';
import Error from 'components/Error';
import { APPS } from 'variables';
import { loadError, loadSuccess, setLoading } from 'actions';
import { OpsTransfers } from './OpsTransfers';
import { opsTransferReducer } from './opsTransfersReducer';
import { initialState } from './state';
import { columns } from './fieldsConfig';
import {
  setPage,
  setStatusFilter,
  setCurrencyFilter,
  setPageSize,
  sortOpsTransfers,
} from './opsTransfersActions';
import { ALL_FILTERS_VALUE } from './constants';

const OpsTransfersData = ({ user }) => {
  const scrollPosition = useRef('');

  const setScrollPosition = (value) => {
    scrollPosition.current = value;
  };

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const httpClient = useHttpClient();

  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getOpsTransfers(httpClient, {
          page: initialState.currentPage,
          size: initialState.pageSize,
        }),
      [httpClient]
    ),
    null,
    opsTransferReducer,
    {
      ...initialState,
      pageSize: initialState.pageSize,
    }
  );

  const {
    loading,
    error,
    opsTransfers,
    pageSize,
    currentPage,
    totalPages,
    totalCount,
    sort = {},
    statusFilter,
    currencyFilter,
    filtersApplied,
  } = state;

  const { fieldName: sortBy } = sort;
  const sortDirection = sort[sortBy];

  const hasActionAccessWrapped = ({ app = APPS.ops_transfers, action }) =>
    hasActionAccess({ permissions, app, action });

  const hasSectionAccessWrapped = ({ app = APPS.ops_transfers, section }) =>
    hasSectionAccess({ permissions, app, section });

  const updateOpsTransfers = (args, successHandler) => {
    dispatch(setLoading(true));

    return getOpsTransfers(httpClient, args)
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          return successHandler();
        }

        return Promise.resolve();
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const handlePageChange = (page) => {
    updateOpsTransfers(
      {
        page,
        size: pageSize,
        status: statusFilter,
        currency: currencyFilter,
        sort: sortBy,
        direction: sortDirection,
      },
      () => {
        dispatch(setPage({ page }));
        setScrollPosition(0);
      }
    );
  };

  const handlePageSizeChange = (size) => {
    const { currentPage: page } = initialState;

    updateOpsTransfers(
      {
        page,
        size,
        status: statusFilter,
        currency: currencyFilter,
        sort: sortBy,
        direction: sortDirection,
      },
      () => {
        dispatch(setPageSize({ size }));
        setScrollPosition(0);
      }
    );
  };

  const handleSort = ({ name, order }) => {
    updateOpsTransfers(
      {
        page: currentPage,
        size: pageSize,
        status: statusFilter,
        currency: currencyFilter,
        sort: name,
        direction: order,
      },
      () => dispatch(sortOpsTransfers({ name, order }))
    );
  };

  const updateOpsTransfersWrapped = () => {
    if (!statusFilter || statusFilter === ALL_FILTERS_VALUE) {
      setScrollPosition(window.pageYOffset);
    }

    return updateOpsTransfers({
      page: currentPage,
      size: pageSize,
      status: statusFilter,
      currency: currencyFilter,
      sort: sortBy,
      direction: sortDirection,
    });
  };

  const handleStatusFilterChange = (status) => {
    const { currentPage: page } = initialState;

    updateOpsTransfers(
      {
        page,
        size: pageSize,
        status,
        currency: currencyFilter,
        sort: sortBy,
        direction: sortDirection,
      },
      () => {
        setScrollPosition(0);
        dispatch(setStatusFilter({ status }));
      }
    );
  };

  const handleCurrencyFilterChange = (currency) => {
    const { currentPage: page } = initialState;

    updateOpsTransfers(
      {
        page,
        size: pageSize,
        status: statusFilter,
        currency,
        sort: sortBy,
        direction: sortDirection,
      },
      () => {
        setScrollPosition(0);
        dispatch(setCurrencyFilter({ currency }));
      }
    );
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      <OpsTransfers
        loading={loading}
        opsTransfers={opsTransfers}
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        totalCount={totalCount}
        sort={sort}
        user={user}
        statusFilter={statusFilter}
        currencyFilter={currencyFilter}
        filtersApplied={filtersApplied}
        dispatch={dispatch}
        hasActionAccess={hasActionAccessWrapped}
        hasSectionAccess={hasSectionAccessWrapped}
        updateOpsTransfers={updateOpsTransfersWrapped}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSort={handleSort}
        onStatusFilterChange={handleStatusFilterChange}
        onCurrencyFilterChange={handleCurrencyFilterChange}
      />
    </>
  );
};

OpsTransfersData.propTypes = {
  user: PropTypes.string.isRequired,
};

export default OpsTransfersData;
