import {
  filter,
  isArray,
  join,
  map,
  size,
  split,
  toUpper,
  upperFirst,
} from 'lodash';

export const isEmpty = (value) =>
  value == null || (typeof value === 'string' && !value.length);

export const isNumeric = (value) =>
  !isEmpty(value) && typeof value !== 'boolean' && !Number.isNaN(+value);

export const encodeToBase64 = (string) =>
  Buffer.from(string).toString('base64');

export const isBrowserIE = () => window.document.documentMode;

export const copyToClipboard = (text) => {
  if (!text) {
    return;
  }

  if (isBrowserIE()) {
    window.clipboardData.setData('Text', text);
  } else {
    navigator.clipboard.writeText(text);
  }
};

export const getClipboardText = (event, format) =>
  event.clipboardData
    ? (event.originalEvent || event).clipboardData.getData(format)
    : window.clipboardData && window.clipboardData.getData('Text');

export const buildQueryParams = (params) => {
  const filteredParams = filter(
    params,
    ({ param, value }) => size(param) && !isEmpty(value)
  );

  return size(filteredParams)
    ? `?${join(
        map(filteredParams, ({ param, value }, index) => {
          let formattedValue = value;

          if (isArray(value)) {
            formattedValue = join(value);
          }

          return `${index === 0 ? '' : '&'}${param}=${formattedValue}`;
        }),
        ''
      )}`
    : '';
};

export const getNameFromEmail = (email = '') => {
  const names = split(email, '@');
  if (!names[0]) {
    return '';
  }

  const splitNames = split(names[0], '.') || [];
  const firstName = splitNames[0];
  const lastName =
    splitNames.length > 1 ? splitNames[splitNames.length - 1] : null;

  if (firstName && lastName) {
    return `${toUpper(firstName[0])}. ${upperFirst(lastName)}`;
  }

  return upperFirst(firstName || lastName);
};
