import { filter, isEmpty, values } from 'lodash';
import { ERROR_MESSAGE, actionTypes } from 'variables';
import {
  CLEARINGS_GRID_FIRST_PAGE,
  TRANSACTION_FILTERS_ANY_OPTION,
} from './constants';
import { initialState } from './state';

export const matchClearingsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: {
            total_pages: totalPages,
            total_count: totalCount,
            content = [],
          },
        },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        unmatchedClearings: content,
        totalPages,
        totalCount,
        cardAuthorizations: [],
      };
    }
    case actionTypes.LOAD_CARD_AUTHORIZATIONS: {
      const {
        payload: {
          data: {
            content = [],
            page_number: pageNumber,
            total_pages: totalPages,
          },
          cardAuthorizations,
        },
      } = action;

      return {
        ...state,
        loading: false,
        error: null,
        cardAuthorizations: [...cardAuthorizations, ...content],
        authorizationsTotalPages: totalPages,
        authorizationsPageNumber: pageNumber,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_CLEARINGS_DATE_RANGE_FILTER: {
      const {
        payload: { datePeriod },
      } = action;

      return {
        ...state,
        cardAuthorizations: [],
        datePeriod: {
          ...state.datePeriod,
          ...datePeriod,
        },
      };
    }
    case actionTypes.SET_CLEARINGS_PAGE: {
      const {
        payload: { page },
      } = action;

      const { totalPages } = state;

      return {
        ...state,
        cardAuthorizations: [],
        currentPage: page > totalPages ? totalPages : page,
      };
    }
    case actionTypes.SET_CLEARINGS_FILTER: {
      const {
        payload: { field, value },
      } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          [field]: value,
        },
        cardAuthorizations: [],
        applyFiltersEnabled: true,
      };
    }
    case actionTypes.APPLY_CLEARINGS_FILTERS: {
      const { filters } = state;
      const selectedFilters = filter(
        values(filters),
        (selectedFilterValue) =>
          !isEmpty(selectedFilterValue) &&
          selectedFilterValue.name !== TRANSACTION_FILTERS_ANY_OPTION.name
      );

      return {
        ...state,
        currentPage: CLEARINGS_GRID_FIRST_PAGE,
        filtersApplied: !!selectedFilters.length,
        applyFiltersEnabled: false,
        cardAuthorizations: [],
      };
    }
    case actionTypes.SET_CLEARINGS_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        currentPage: CLEARINGS_GRID_FIRST_PAGE,
        size,
        cardAuthorizations: [],
      };
    }
    case actionTypes.CLEAR_CLEARINGS_FILTERS: {
      const { filters } = initialState;

      return {
        ...state,
        currentPage: CLEARINGS_GRID_FIRST_PAGE,
        filters,
        filtersApplied: false,
        applyFiltersEnabled: false,
        cardAuthorizations: [],
      };
    }
    case actionTypes.SORT_CLEARINGS: {
      const {
        payload: { name: newSortFieldName, order },
      } = action;

      return {
        ...state,
        sort: {
          [newSortFieldName]: order,
          fieldName: newSortFieldName,
        },
        cardAuthorizations: [],
      };
    }
    default:
      return state;
  }
};
