import { useContext } from 'react';
import axios from 'axios';
import AuthContext from 'context/auth';
import Cookies from 'js-cookie';
import { LOGGED_USER_COOKIE, STATUS_CODES, URLS } from 'variables';
import { sessionExpired } from '../authActions';

const httpClient = axios.create();

export const useHttpClient = () => {
  const context = useContext(AuthContext);

  if (context) {
    httpClient.interceptors.response.use(
      (res) => {
        const isLogout = res.config && res.config.url === URLS.logout;
        if (res.status === STATUS_CODES.OK && !isLogout) {
          const {
            state: { user },
          } = context;

          Cookies.set(LOGGED_USER_COOKIE, user, {
            expires: 1 / 24,
          });
        }

        return res;
      },
      (err) => {
        if (err.response.status === STATUS_CODES.UNAUTHORIZED) {
          context.dispatch(sessionExpired());
        }

        return Promise.reject(err);
      }
    );
  }

  return httpClient;
};
