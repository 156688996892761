import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHttpClient } from 'hooks';
import { buildDownloadAccountStatementUrl, downloadAsBlob } from 'api';
import { EN_GB_LOCALE, LOCALES_MAP } from 'variables';
import DownloadStatementModal from './DownloadStatementModal';
import { STATEMENT_DATE_FORMAT, STATEMENT_FILE_TYPE } from './constants';

const getStatementFileName = () =>
  `${moment().format(
    STATEMENT_DATE_FORMAT
  )}_Account_details_statement.${STATEMENT_FILE_TYPE}`;

const DownloadAccountStatementModal = ({ accountId, ...props }) => {
  const httpClient = useHttpClient();

  const handleConfirm = (language) =>
    downloadAsBlob(httpClient, {
      url: buildDownloadAccountStatementUrl({
        accountId,
        locale: LOCALES_MAP[language] || EN_GB_LOCALE,
      }),
      filename: getStatementFileName(),
    });

  return <DownloadStatementModal {...props} onConfirm={handleConfirm} />;
};

DownloadAccountStatementModal.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default DownloadAccountStatementModal;
