import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown } from '@sumup/icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useHttpClient } from 'hooks';
import { getPrimaryBankAccount } from 'api';
import Dropdown from 'components/Dropdown';
import ClientDataContext from 'context/clientData';
import AccountCard from './components/AccountCard';
import PersonalCard from './components/PersonalCard';
import BusinessCard from './components/BusinessCard';
import BankCard from './components/BankCard';
import LinkButton from '../LinkButton';
import Popover from './components/Popover';

const InnerCardsWrapper = styled('div')`
  display: flex;
  button:not(:last-of-type) {
    margin-left: auto;
  }
`;

const ButtonsWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-left: ${theme.spacings.mega};
  `
);

const ClientCards = ({ inner, soapUrl, ebcUrl }) => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();
  const [accountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const [personalPopoverOpen, setPersonalPopoverOpen] = useState(false);
  const [businessPopoverOpen, setBusinessPopoverOpen] = useState(false);
  const [bankPopoverOpen, setBankPopoverOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState({});

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  useEffect(() => {
    getPrimaryBankAccount({
      httpClient,
      merchantCode: clientId,
    }).then(({ data }) => setBankDetails(data));
  }, [clientId, httpClient]);

  const accountPopoverComponent = (
    <Dropdown onClick={() => setAccountPopoverOpen(true)}>
      Account {accountPopoverOpen ? <ChevronUp /> : <ChevronDown />}
    </Dropdown>
  );
  const personalPopoverComponent = (
    <Dropdown onClick={() => setPersonalPopoverOpen(true)}>
      Personal {personalPopoverOpen ? <ChevronUp /> : <ChevronDown />}
    </Dropdown>
  );
  const businessPopoverComponent = (
    <Dropdown onClick={() => setBusinessPopoverOpen(true)}>
      Business {businessPopoverOpen ? <ChevronUp /> : <ChevronDown />}
    </Dropdown>
  );
  const bankPopoverComponent = (
    <Dropdown onClick={() => setBankPopoverOpen(true)}>
      Bank {bankPopoverOpen ? <ChevronUp /> : <ChevronDown />}
    </Dropdown>
  );

  return (
    <>
      {inner && (
        <InnerCardsWrapper>
          <Popover
            isOpen={accountPopoverOpen}
            content={
              <AccountCard popover clientData={clientData} soapUrl={soapUrl} />
            }
            pivot={accountPopoverComponent}
            onOutsideClick={() => setAccountPopoverOpen(!accountPopoverOpen)}
          />

          <Popover
            isOpen={personalPopoverOpen}
            content={
              <PersonalCard popover clientData={clientData} soapUrl={soapUrl} />
            }
            pivot={personalPopoverComponent}
            onOutsideClick={() => setPersonalPopoverOpen(!personalPopoverOpen)}
          />
          <Popover
            isOpen={businessPopoverOpen}
            content={
              <BusinessCard popover clientData={clientData} soapUrl={soapUrl} />
            }
            pivot={businessPopoverComponent}
            onOutsideClick={() => setBusinessPopoverOpen(!businessPopoverOpen)}
          />
          <Popover
            isOpen={bankPopoverOpen}
            content={
              <BankCard popover bankDetails={bankDetails} soapUrl={soapUrl} />
            }
            pivot={bankPopoverComponent}
            style={{ right: 0 }}
            onOutsideClick={() => setBankPopoverOpen(!bankPopoverOpen)}
          />
          <ButtonsWrapper>
            {clientData?.iban && <LinkButton url={ebcUrl} text="EBC" />}
            <LinkButton url={soapUrl} text="SOAP" />
          </ButtonsWrapper>
        </InnerCardsWrapper>
      )}
      <AccountCard inner={inner} clientData={clientData} soapUrl={soapUrl} />
      <PersonalCard inner={inner} clientData={clientData} soapUrl={soapUrl} />
      <BusinessCard inner={inner} clientData={clientData} soapUrl={soapUrl} />
      <BankCard inner={inner} bankDetails={bankDetails} soapUrl={soapUrl} />
    </>
  );
};

ClientCards.defaultProps = {
  inner: false,
  soapUrl: '',
  ebcUrl: '',
};

ClientCards.propTypes = {
  inner: PropTypes.bool,
  soapUrl: PropTypes.string,
  ebcUrl: PropTypes.string,
};

export default ClientCards;
