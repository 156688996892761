import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Popover, useModal } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import Status from 'components/Status';
import Modal from 'components/Modal';
import { useHttpClient } from 'hooks';
import { StyledDropdown, DropdownOption } from './PopoverCellStyled';

const PopoverCell = ({
  value,
  clientId,
  activeCard,
  rowData,
  getPopoverOptions,
  modalConfirmSuccess,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const httpClient = useHttpClient();

  const { setModal, removeModal } = useModal();

  const component = () => (
    <StyledDropdown
      onClick={(e) => {
        e.stopPropagation();
        setPopoverOpen(true);
      }}
    >
      <Status value={value} />
      {popoverOpen ? <ChevronUp /> : <ChevronDown />}
    </StyledDropdown>
  );

  const actions = map(
    getPopoverOptions({
      clientId,
      ...activeCard,
      ...rowData,
      value,
      httpClient,
    }),
    ({ label, disabled, modalInfo = {} }) => ({
      children: (
        <DropdownOption key={label} disabled={disabled}>
          <Status value={label} />
        </DropdownOption>
      ),
      onClick: () => {
        setModal({
          closeButtonLabel: 'Close',
          onClick: () => removeModal,
          // eslint-disable-next-line react/prop-types
          children: ({ onClose }) => (
            <Modal
              modalInfo={{
                ...modalInfo,
                centered: true,
                cancelText: 'Cancel',
                onConfirm: () => modalInfo.onConfirm(modalConfirmSuccess),
              }}
              onClose={onClose}
            />
          ),
        });
      },
    })
  );

  return (
    <div>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        actions={actions}
        component={component}
        onToggle={() => setPopoverOpen(!popoverOpen)}
      />
    </div>
  );
};

PopoverCell.defaultProps = {
  value: '',
  clientId: '',
  activeCard: {},
  rowData: {},
  modalConfirmSuccess: () => {},
};

PopoverCell.propTypes = {
  value: PropTypes.string,
  getPopoverOptions: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  activeCard: PropTypes.object,
  rowData: PropTypes.object,
  modalConfirmSuccess: PropTypes.func,
};

export default PopoverCell;
