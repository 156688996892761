import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { useModal } from '@sumup/circuit-ui';
import {
  TRANSACTION_IDENTIFIER,
  CREATED_AT,
  TRANSACTION_ORIGIN,
  TRANSACTION_FRAUD_REPORT,
} from 'components/Transaction/fieldsConfig';
import { SORT_ORDER_DESCENDING } from 'variables';
import EmptyState from 'components/EmptyState';
import {
  TRANSACTIONS_CLEARING_SECTION_KEY,
  TRANSACTION_ORIGIN_FILTER_OPTIONS,
} from 'components/Transactions/constants';
import ClientDataContext from 'context/clientData';
import { accountReducer } from 'components/Account/accountReducer';
import {
  Grid,
  HeaderWrapper,
  HeaderRow,
  BodyRowWrapper,
  BodyRowSection,
  BodyCell,
  ColumnTitle,
  SubColumnTitle,
  SubColumnTitleContent,
  TransactionOriginFlag,
} from './TransactionsGridStyled';
import { CellFactory } from './factories/cell';
import TransactionDetailsModal from './components/TransactionDetailsModal';
import SortableHeaderWrapper from '../../../SortableHeaderWrapper';

const TransactionsGrid = ({
  columns,
  transactions,
  sort,
  filtersApplied,
  clientId,
  onSort,
  dispatch,
  hasActionAccess,
  hasSectionAccess,
  onMarkAsPotentialFraud,
  onCancelTransaction,
  onChargebackTransaction,
  onMarkBankTransactionFraud,
  onEditBankTransactionFraud,
  onUnmarkBankTransactionFraud,
}) => {
  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  const [state = {}] = useReducer(accountReducer, {
    accountData: clientData,
  });

  const { accountData = {} } = state;

  const {
    country,
    balance,
    bank_account_identifier: accountId,
    currency,
  } = accountData;

  const { balance: nonNegativeBalance } = balance || {};
  const { exponent } = currency || {};
  const ukTransfer = country === 'GB';

  const formattedNonNegativeBalance = nonNegativeBalance / 10 ** exponent;

  const { setModal } = useModal();

  const handleBodyRowSectionClick = ({ id }) => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: () => (
        <TransactionDetailsModal
          txId={id}
          onCancelTransaction={onCancelTransaction}
          onChargebackTransaction={onChargebackTransaction}
          onMarkAsPotentialFraud={onMarkAsPotentialFraud}
          hasActionAccess={hasActionAccess}
        />
      ),
    });
  };

  return (
    <Grid>
      <SortableHeaderWrapper
        columns={columns}
        sort={sort}
        onSort={onSort}
        HeaderWrapper={HeaderWrapper}
        HeaderRow={HeaderRow}
        ColumnTitle={ColumnTitle}
        SubColumnTitle={SubColumnTitle}
        SubColumnTitleContent={SubColumnTitleContent}
      />
      <div>
        {transactions.length ? (
          map(transactions, (transaction, index) => {
            const origin = transaction[TRANSACTION_ORIGIN.name];
            return (
              <BodyRowWrapper
                fraud={!!transaction[TRANSACTION_FRAUD_REPORT.name]}
                key={`${transaction[TRANSACTION_IDENTIFIER.name]}-${index}`}
              >
                {map(
                  columns,
                  ({ sectionKey, label, subColumns, style }, colIndex) => (
                    <BodyRowSection
                      key={`body-row-section-${colIndex}-${label}`}
                      style={style}
                      onClick={() =>
                        sectionKey === TRANSACTIONS_CLEARING_SECTION_KEY &&
                        handleBodyRowSectionClick({
                          id: transaction[TRANSACTION_IDENTIFIER.name],
                        })
                      }
                    >
                      {colIndex === 0 && (
                        <TransactionOriginFlag
                          background={
                            TRANSACTION_ORIGIN_FILTER_OPTIONS[origin].color
                          }
                        />
                      )}
                      {map(
                        subColumns,
                        ({ key, style: colStyle, fields = [] }) => (
                          <BodyCell key={key} style={colStyle}>
                            {map(fields, ({ name, getValue, ...props }) => (
                              <CellFactory
                                {...props}
                                key={`${key}-${name}`}
                                value={
                                  getValue
                                    ? getValue(transaction, name)
                                    : transaction[name]
                                }
                                rowData={transaction}
                                fieldName={name}
                                clientId={clientId}
                                dispatch={dispatch}
                                clickable={
                                  sectionKey ===
                                  TRANSACTIONS_CLEARING_SECTION_KEY
                                }
                                ukTransfer={ukTransfer}
                                balance={formattedNonNegativeBalance}
                                minorUnitsBalance={nonNegativeBalance}
                                accountId={accountId}
                                exponent={exponent}
                                hasActionAccess={hasActionAccess}
                                hasSectionAccess={hasSectionAccess}
                                onMarkAsPotentialFraud={onMarkAsPotentialFraud}
                                onCancelTransaction={onCancelTransaction}
                                onChargebackTransaction={
                                  onChargebackTransaction
                                }
                                onMarkBankTransactionFraud={
                                  onMarkBankTransactionFraud
                                }
                                onEditBankTransactionFraud={
                                  onEditBankTransactionFraud
                                }
                                onUnmarkBankTransactionFraud={
                                  onUnmarkBankTransactionFraud
                                }
                              />
                            ))}
                          </BodyCell>
                        )
                      )}
                    </BodyRowSection>
                  )
                )}
              </BodyRowWrapper>
            );
          })
        ) : (
          <EmptyState
            text={
              filtersApplied
                ? 'No matching transactions'
                : 'No transactions yet'
            }
          />
        )}
      </div>
    </Grid>
  );
};

TransactionsGrid.defaultProps = {
  transactions: [],
  sort: {
    [CREATED_AT.name]: SORT_ORDER_DESCENDING,
    fieldName: CREATED_AT.name,
  },
};

TransactionsGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  transactions: PropTypes.array,
  sort: PropTypes.object,
  filtersApplied: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
  hasSectionAccess: PropTypes.func.isRequired,
  onMarkAsPotentialFraud: PropTypes.func.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  onChargebackTransaction: PropTypes.func.isRequired,
  onMarkBankTransactionFraud: PropTypes.func.isRequired,
  onEditBankTransactionFraud: PropTypes.func.isRequired,
  onUnmarkBankTransactionFraud: PropTypes.func.isRequired,
};

export default TransactionsGrid;
