import { FILE_EXTENSIONS } from 'variables';

export const createObjectUrlCsv = (content) =>
  URL.createObjectURL(
    new Blob(['\ufeff', content], { downloadType: FILE_EXTENSIONS.CSV })
  );

export const downloadObjectUrl = (objectURL, filename) => {
  // Create a new anchor element
  const a = document.createElement('a');
  a.href = objectURL;
  a.download = filename;

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(objectURL);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  a.addEventListener('click', clickHandler, false);
  a.click();
};
