import React from 'react';
import { useParams } from 'react-router-dom';
import { getUserChallenges } from 'api';
import Loading from 'components/Loading';
import Error from 'components/Error';
import { useApiCallOnLoad } from 'hooks';
import { Challenges } from './components/Challenges';
import { challengesReducer } from './challengesReducer';
import { setActiveChallenges } from './challengesActions';
import {
  CHALLENGES_NON_NUMERIC_FIELDS_MAP,
  CHALLENGES_TYPES_BANK,
} from './constants';

const initialState = {
  loading: true,
  activeColumns: [],
  challenges: [],
};

const getUserChallengesWrapped = (clientId, httpClient) =>
  getUserChallenges({ clientId, httpClient });

const ChallengesData = () => {
  const { clientId } = useParams();

  const [state, dispatch] = useApiCallOnLoad(
    getUserChallengesWrapped,
    clientId,
    challengesReducer,
    initialState
  );

  const { loading, error, challenges, activeChallengeType, activeColumns } =
    state;

  const handleTypeChange = (challengeType) => {
    dispatch(setActiveChallenges(challengeType));
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Challenges
            columns={activeColumns}
            challenges={challenges}
            activeChallengeType={activeChallengeType}
            nonNumericFields={CHALLENGES_NON_NUMERIC_FIELDS_MAP}
            noResultsText={
              activeChallengeType === CHALLENGES_TYPES_BANK.value
                ? 'No bank challenges yet'
                : 'No online challenges yet'
            }
            onChallengeTypeChange={handleTypeChange}
          />
        </>
      )}
    </>
  );
};

export default ChallengesData;
