import {
  createRecipient,
  getAccountDetails,
  getPrimaryBankAccount,
  publishPaymentEvent,
  toggleDirectDebit,
} from 'api';
import { IBAN_PREFIXES } from 'variables';

export const enableDirectDebits = (httpClient, id) =>
  toggleDirectDebit({ httpClient, accountId: id, enableDD: true });

export const createRecipientFromMerchantCode = async (httpClient, id) => {
  let result;

  try {
    const { data: primaryAccountData } = await getPrimaryBankAccount({
      httpClient,
      merchantCode: id,
    });

    const {
      bank_code: sortCode,
      account_number: accountNumber,
      swift: bic,
      iban,
      account_holder_name: fullName,
    } = primaryAccountData;

    const isGBP = iban
      ? iban.slice(0, 2) === IBAN_PREFIXES.GB
      : !!(accountNumber && sortCode);

    const {
      data: { content },
    } = await getAccountDetails(httpClient, {
      merchantCode: id,
    });

    const [businessAccountData] = content;
    if (!businessAccountData) {
      return Promise.reject({});
    }

    const primaryAccountIsSameAsBusinessAccountEur =
      iban === businessAccountData.iban;
    const primaryAccountIsSameAsBusinessAccountGbp =
      accountNumber === businessAccountData.account_number &&
      sortCode === businessAccountData.sort_code;

    if (
      (isGBP && primaryAccountIsSameAsBusinessAccountGbp) ||
      (!isGBP && primaryAccountIsSameAsBusinessAccountEur)
    ) {
      return Promise.reject({
        response: {
          data: {
            message:
              'Primary 3rd-party bank account is the same as SumUp business account.',
          },
        },
      });
    }

    const { data: recipientData, ...response } = await createRecipient(
      httpClient,
      {
        ...(isGBP
          ? {
              sortCode,
              accountNumber,
            }
          : {
              bic,
              iban,
            }),
        fullName,
        userId: businessAccountData.user_id,
      }
    );

    result = recipientData
      ? Promise.resolve(recipientData)
      : Promise.reject(response);
  } catch (error) {
    result = Promise.reject(error);
  }

  return result;
};

export const publishPaymentEvents = (httpClient, paymentId) =>
  publishPaymentEvent(httpClient, { paymentId });
