import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../../../DataGrid';
import { COLUMNS } from '../../columns';
import { StyledHeading, Row } from '../../AccountStyled';

const InactiveCards = ({ inactiveCards, onWalletTokensModalOpen }) => {
  const handleManageTokensClick = ({ id, token }) =>
    onWalletTokensModalOpen({ cardId: id, cardToken: token });

  return (
    <Row>
      <StyledHeading as="h3" size="one" noMargin>
        Inactive cards
      </StyledHeading>
      <DataGrid
        columns={COLUMNS}
        dataSource={inactiveCards}
        showModified
        errorsVisible
        onLinkClick={handleManageTokensClick}
      />
    </Row>
  );
};

InactiveCards.propTypes = {
  inactiveCards: PropTypes.array.isRequired,
  onWalletTokensModalOpen: PropTypes.func.isRequired,
};

export default InactiveCards;
