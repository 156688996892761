export const EMPTY_FIELD = 'Please fill this in';
export const LETTERS_ONLY_FIELD =
  'Please use letters only (no special characters)';
export const RECIPIENT_NAME_MIN_LENGTH = 'Please provide at least 3 characters';
export const RECIPIENT_NAME_MAX_LENGTH_UK =
  'Please use no more than 40 characters';
export const RECIPIENT_NAME_MAX_LENGTH_EU =
  'Please use no more than 70 characters';
export const INCORRECT_SORT_CODE = 'Please correct the sort code';
export const INCORRECT_ACCOUNT_NUMBER = 'Please correct the account number';
export const INCORRECT_IBAN = 'Please correct the IBAN';
export const NON_EEA_COUNTRY_IBAN =
  'Only transfers to EEA countries are allowed';
export const NOT_ENOUGH_BALANCE_FOR_TRANSFER =
  'Insufficient funds, please adjust the amount you wish to transfer.';
export const TRANSFER_AMOUNT_GREATER_THAN_ZERO =
  'Please enter an amount greater than zero.';
export const PAYMENT_REFERENCE_MAX_LENGTH_UK =
  'Please use no more than 18 characters';
export const PAYMENT_REFERENCE_MAX_LENGTH_EU =
  'Please use no more than 140 characters';
export const OPS_TRANSFER_ADDITIONAL_INFO_MAX_CHARACTERS =
  'Please use no more than 140 characters';
export const OPS_TRANSFER_COMMENT_MAX_CHARACTERS =
  'Please use no more than 255 characters';
export const FRAUD_REPORT_REFERENCE_MAX_CHARACTERS =
  'Please use no more than 40 characters';
export const FRAUD_REPORT_DETAILS_MAX_CHARACTERS =
  'Please use no more than 700 characters';
