import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Card, Body } from '@sumup/circuit-ui';

export const StyledLink = styled(Link)(
  ({ theme, active, inner, disabled }) => css`
    ${disabled && `pointer-events: none; opacity: 0.3; cursor: default;`}
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacings.byte};
    flex-shrink: 0;
    color: ${theme.colors.n700};
    border-bottom: 2px solid transparent;
    svg {
      color: ${theme.colors.n500};
    }
    &:hover {
      color: ${theme.colors.black};
    }
    ${!inner &&
    `
      font-weight: bold;
      border-radius: ${theme.borderRadius.bit};
      background: ${theme.colors.n100};
      width: 50%;
      color: black;
      margin: calc(${theme.spacings.bit} / 2);
      &:hover {
        background: ${theme.colors.p500};
        color: ${theme.colors.white};
      }
    `};
    ${active &&
    `
      border-bottom-color: ${theme.colors.p500};
      color: black;
    `}
  `
);

export const SectionLink = styled(StyledLink)(
  ({ theme, disabled }) => css`
    width: 100%;
    max-height: ${theme.spacings.tera};
    ${disabled && `pointer-events: none; opacity: 0.3; cursor: default;`}

    :hover {
      svg {
        color: ${theme.colors.bodyBg};
      }
    }
  `
);

export const StyledCardIcon = styled('div')(
  ({ theme, inner }) => css`
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 ${theme.spacings.byte};
    border: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    border-radius: 50%;

    ${!inner &&
    `
      height: 80px;
      width: 80px;
      margin-bottom: ${theme.spacings.kilo};
    `}
  `
);

export const StyledCard = styled(Card)(
  ({ theme }) => css`
    display: flex;
    flex-basis: calc(50% - 10px);
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    ${theme.mq.giga} {
      flex-direction: row;
      max-height: 216px;
    }
  `
);

export const StyledCardHeading = styled(Body)(
  ({ theme, inner }) => css`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    border-right: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    ${!inner &&
    `
      flex-direction: column;
      padding: ${theme.spacings.mega} ${theme.spacings.giga};
      width: 100%;
      ${theme.mq.giga} {
        width: 200px;
      }
    `}
  `
);

export const StyledCardContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: ${theme.spacings.mega} ${theme.spacings.giga};
    flex-wrap: wrap;
    background: ${theme.colors.white};
  `
);

export const ClientPageNavigationContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ClientCardsWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin: 0 ${theme.spacings.tera} ${theme.spacings.mega}
      ${theme.spacings.kilo};
  `
);
