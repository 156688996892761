import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Grid = styled('div')`
  ${({ theme: { colors, spacings, borderRadius } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 20px 0 ${spacings.byte};
    background: ${colors.white};
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    border-radius: ${borderRadius.bit};
  `}
`;

const Row = styled('div')`
  ${({ theme: { spacings, colors, borderWidth } }) => css`
    display: flex;
    flex-direction: row;
    color: ${colors.n900};

    > div {
      display: flex;
      padding: ${spacings.byte};
      border-bottom: ${borderWidth.kilo} solid ${colors.n200};
      min-width: 0px;
      word-break: break-word;
      align-items: center;

      div {
        justify-content: center;
      }
    }

    div {
      box-sizing: border-box;
    }
  `}
`;

export const HeaderRow = styled(Row)`
  ${({ theme: { spacings, colors } }) => css`
    div {
      display: flex;
      flex-flow: row;
      flex-grow: 1;
      flex-basis: 0;
      height: 28px;
      min-width: 0px;
      padding: ${spacings.bit} ${spacings.byte};
      position: relative;
      align-items: center;
      color: ${colors.n700};
    }
  `}
`;

export const ColumnTitle = styled('div')`
  ${({ theme: { spacings, borderWidth, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${spacings.tera};
    border-bottom: ${borderWidth.kilo} solid ${colors.n200};

    > p {
      margin: auto;
    }
  `}
`;

export const SubColumnTitle = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;

    :not(:last-of-type) {
      border-right: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    }
  `
);

export const SubColumnTitleContent = styled('span')`
  ${({ theme: { colors }, sorted, sortable }) => css`
    span {
      margin-right: 2px;

      ${sorted &&
      `
        cursor: pointer;
        color: ${colors.p500};
      `}

      ${sortable &&
      `
        &:hover {
          cursor: pointer;
          color: ${colors.p500};
        }
      `}
    }
  `}
`;

export const HeaderWrapper = styled('div')`
  ${({ theme: { borderWidth, colors } }) => css`
    display: flex;
    flex-direction: row;

    & > div:not(:first-of-type) {
      border-left: ${borderWidth.kilo} solid ${colors.n200};
    }
  `}
`;

export const BodyRowWrapper = styled('div')`
  ${({ theme: { colors }, disabled, fraud }) => css`
    display: flex;
    flex-direction: row;

    ${disabled &&
    `
      color: ${colors.n500};
    `}

    ${fraud &&
    `
      background: #FFF1F2;
    `}

    &:hover {
      background: ${colors.b100};
      color: ${colors.bodyColor};

      * {
        opacity: 1;
      }
    }
  `}
`;

export const BodyRowSection = styled('div')`
  ${({ theme: { borderWidth, colors } }) => css`
    display: flex;
    align-items: center;
    border-bottom: ${borderWidth.kilo} solid ${colors.n200};

    :not(:first-of-type) {
      border-left: ${borderWidth.kilo} solid ${colors.n200};
    }
  `}
`;

export const BodyCell = styled('div')`
  ${({ theme, centered = true }) => css`
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin: ${theme.spacings.byte} 0;
    ${centered && 'align-items: center;'}
    word-break: break-word;
    padding: 0 ${theme.spacings.byte};
  `}
`;

export const TransactionOriginFlag = styled('div')`
  ${({ background }) => css`
    display: flex;
    flex: 1 0;
    max-width: 6px;
    height: 96%;
    padding: 0;
    margin: 2px;
    background: ${background};
    border-radius: 1px;
  `}
`;
