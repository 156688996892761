import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_FIELD_TYPES } from 'variables';
import Button from 'components/Transaction/components/Button';
import Modal from 'components/Modal';
import { useHttpClient } from 'hooks';
import { useModal } from '@sumup/circuit-ui';

const ButtonCell = ({
  rowData,
  disabled,
  value,
  type,
  fieldName,
  fields,
  clientId,
  activeCard,
  modalInfo,
  dispatch,
  modalConfirmSuccess,
}) => {
  const httpClient = useHttpClient();
  const { setModal } = useModal();

  const handleConfirm = () =>
    modalInfo.onConfirm({
      ...rowData,
      ...activeCard,
      clientId,
      dispatch,
      httpClient,
      successHandler: modalConfirmSuccess,
    });

  const openModal = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            ...modalInfo,
            centered: true,
            cancelText: 'Cancel',
            onConfirm: handleConfirm,
          }}
          onClose={onClose}
        />
      ),
    });
  };

  return (
    <div>
      <Button
        disabled={disabled}
        value={value}
        type={type}
        fieldName={fieldName}
        fields={fields}
        onClick={openModal}
      />
    </div>
  );
};

ButtonCell.defaultProps = {
  type: CUSTOM_FIELD_TYPES.BUTTON_DEFAULT,
  rowData: {},
  value: '',
  fields: {},
  disabled: false,
  clientId: '',
  activeCard: {},
  modalInfo: {},
  dispatch: () => {},
  modalConfirmSuccess: () => {},
};

ButtonCell.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  fields: PropTypes.object,
  disabled: PropTypes.bool,
  rowData: PropTypes.object,
  clientId: PropTypes.string,
  activeCard: PropTypes.object,
  modalInfo: PropTypes.object,
  dispatch: PropTypes.func,
  modalConfirmSuccess: PropTypes.func,
};

export default ButtonCell;
