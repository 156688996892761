import { actionTypes } from 'variables';

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: { loading },
});

export const auth = (user) => ({
  type: actionTypes.AUTH,
  payload: {
    user,
  },
});

export const setPermissions = (data) => ({
  type: actionTypes.SET_PERMISSIONS,
  payload: {
    data,
  },
});

export const sessionExpired = () => ({
  type: actionTypes.SESSION_EXPIRED,
});
