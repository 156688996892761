import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@sumup/circuit-ui';
import { DELETE_USER_MODAL_MAX_WIDTH } from 'components/UserAdministration/constants';
import Modal from 'components/Modal';

const DeleteUserConfirmationModal = ({ onClose, onConfirm }) => (
  <Modal
    modalInfo={{
      width: DELETE_USER_MODAL_MAX_WIDTH,
      title: 'Delete this user?',
      confirmText: 'Delete user',
      cancelText: 'Cancel',
      confirmButtonProps: {
        destructive: true,
      },
      centered: true,
      content: (
        <Body noMargin size="one">
          The user will no longer be able to access the Bank Backoffice.
        </Body>
      ),
      onConfirm,
    }}
    onClose={onClose}
  />
);

DeleteUserConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteUserConfirmationModal;
