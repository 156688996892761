import { ERROR_MESSAGE, actionTypes } from 'variables';

export const merchantPageDataReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        clientDataLoading: loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: { data, externalLinks },
      } = action;

      return {
        ...state,
        clientDataLoading: false,
        clientData: data,
        externalLinks,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        clientDataLoading: false,
        error: ERROR_MESSAGE,
      };
    }
    default:
      return state;
  }
};
