import React from 'react';
import PropTypes from 'prop-types';
import { Button, useModal } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TRANSFER_ID } from 'components/OpsTransfers/fieldsConfig';
import { TransferInfo } from './TransferInfo';
import { OpsTransferModal } from './OpsTransferModal';

const SuccessButton = styled(Button)(
  ({ theme }) => css`
    background: ${theme.colors.confirm};
    border-color: ${theme.colors.confirm};

    :hover {
      background: #016c26;
      border-color: #016c26;
    }
  `
);

const confirmButtonStyles = css`
  button:not(:first-of-type) {
    background: #138849;
    border-color: #138849;

    :hover {
      background: #016c26;
    }
  }
`;

export const PreApproveAndAuthorizeButton = ({
  label,
  modalProps,
  transfer,
  exponent,
  updateOpsTransfers,
  ...props
}) => {
  const { setModal } = useModal();

  const handleClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <OpsTransferModal
          {...props}
          {...modalProps}
          id={transfer[TRANSFER_ID.name]}
          content={<TransferInfo transfer={transfer} exponent={exponent} />}
          cancelButtonLabel="Cancel"
          confirmButtonStyles={confirmButtonStyles}
          successHandler={updateOpsTransfers}
          onClose={onClose}
        />
      ),
    });
  };

  return (
    <SuccessButton noMargin variant="primary" size="kilo" onClick={handleClick}>
      {label}
    </SuccessButton>
  );
};

PreApproveAndAuthorizeButton.propTypes = {
  label: PropTypes.string.isRequired,
  transfer: PropTypes.object.isRequired,
  exponent: PropTypes.number.isRequired,
  modalProps: PropTypes.object.isRequired,
  updateOpsTransfers: PropTypes.func.isRequired,
};
