import { find } from 'lodash';
import { ERROR_MESSAGE, actionTypes } from 'variables';
import { SCHEDULED_PAYMENTS_FIRST_PAGE } from './constants';
import { initialState } from './state';

export const scheduledPaymentsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const {
        payload: { loading },
      } = action;

      return {
        ...state,
        loading,
      };
    }
    case actionTypes.LOAD_SUCCESS: {
      const {
        payload: {
          data: {
            total_pages: totalPages,
            total_elements: totalCount,
            content = [],
          },
        },
      } = action;

      const { selectedScheduledPayment } = state;

      return {
        ...state,
        loading: false,
        error: null,
        scheduledPayments: content,
        totalPages,
        totalCount,
        selectedScheduledPayment: selectedScheduledPayment
          ? find(
              content,
              (scheduledPayment) =>
                scheduledPayment.id === selectedScheduledPayment.id
            )
          : null,
      };
    }
    case actionTypes.LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: ERROR_MESSAGE,
      };
    }
    case actionTypes.SET_SCHEDULED_PAYMENTS_PAGE: {
      const {
        payload: { page },
      } = action;

      const { totalPages } = state;

      return {
        ...state,
        currentPage: page > totalPages ? totalPages : page,
      };
    }
    case actionTypes.SET_SCHEDULED_PAYMENTS_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        currentPage: SCHEDULED_PAYMENTS_FIRST_PAGE,
        pageSize: size,
      };
    }
    case actionTypes.SET_SCHEDULED_PAYMENTS_STATUS_FILTER: {
      const {
        payload: { status },
      } = action;

      return {
        ...state,
        currentPage: SCHEDULED_PAYMENTS_FIRST_PAGE,
        statusFilter: status,
      };
    }
    case actionTypes.SET_SCHEDULED_PAYMENTS_TYPE_FILTER: {
      const {
        payload: { type },
      } = action;

      return {
        ...state,
        currentPage: SCHEDULED_PAYMENTS_FIRST_PAGE,
        typeFilter: type,
      };
    }
    case actionTypes.SET_SELECTED_SCHEDULED_PAYMENT: {
      const {
        payload: { selectedScheduledPayment },
      } = action;

      return {
        ...state,
        selectedScheduledPayment,
      };
    }
    case actionTypes.SET_SCHEDULE_TRANSACTION_HISTORY_LOADING: {
      const {
        payload: { scheduledPaymentId },
      } = action;

      return {
        ...state,
        transactionHistoryLoadingForId: scheduledPaymentId,
      };
    }
    case actionTypes.LOAD_SCHEDULE_TRANSACTION_HISTORY_SUCCESS: {
      const {
        payload: {
          data: {
            total_pages: totalPages,
            total_elements: totalCount,
            content = [],
          },
        },
      } = action;

      return {
        ...state,
        transactionHistoryLoadingForId: null,
        transactionHistory: {
          ...state.transactionHistory,
          transactions: content,
          totalPages,
          totalCount,
        },
      };
    }
    case actionTypes.CLEAR_SCHEDULE_TRANSACTION_HISTORY: {
      return {
        ...state,
        transactionHistory: initialState.transactionHistory,
      };
    }
    case actionTypes.SORT_SCHEDULE_HISTORY: {
      const {
        payload: { name, order },
      } = action;

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          sort: {
            [name]: order,
            fieldName: name,
          },
        },
      };
    }
    case actionTypes.SET_SCHEDULE_HISTORY_PAGE: {
      const {
        payload: { page },
      } = action;

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          currentPage: page,
        },
      };
    }
    case actionTypes.SET_SCHEDULE_HISTORY_PAGE_SIZE: {
      const {
        payload: { size },
      } = action;

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          currentPage: SCHEDULED_PAYMENTS_FIRST_PAGE,
          pageSize: size,
        },
      };
    }
    default:
      return state;
  }
};
