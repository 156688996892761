import moment from 'moment';
import {
  DATE_PERIOD_YESTERDAY,
  DATE_PERIOD_TWO_DAYS,
  DATE_PERIOD_FIVE_DAYS,
  DATE_PERIOD_CUSTOM,
} from 'variables';

export const CLEARINGS_GRID_FIRST_PAGE = 1;

export const CARD_TOKEN_FILTER = 'token';
export const TRANSACTION_RRN_FILTER = 'rrn';
export const TRANSACTION_AUTH_CODE_FILTER = 'authCode';

export const TRANSACTIONS_CLEARING_SECTION_KEY = 'TransactionClearingSection';

export const DATE_PERIOD_OPTIONS = {
  [DATE_PERIOD_YESTERDAY]: {
    name: DATE_PERIOD_YESTERDAY,
    label: 'Yesterday',
    days: null,
    startDate: moment().subtract(1, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_TWO_DAYS]: {
    name: DATE_PERIOD_TWO_DAYS,
    label: 'Last 2 days',
    days: 2,
    startDate: moment().subtract(2, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_FIVE_DAYS]: {
    name: DATE_PERIOD_FIVE_DAYS,
    label: 'Last 5 days',
    days: 5,
    startDate: moment().subtract(5, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_CUSTOM]: {
    name: DATE_PERIOD_CUSTOM,
    label: 'Custom period',
    days: null,
  },
};

export const TRANSACTION_FILTERS_ANY_OPTION = { name: 'any', label: 'Any' };

export const UNMATCHED_CLEARINGS = 'Unmatched clearings';

export const AUTHORIZATIONS_COLUMN_WIDTH = '320px';

export const UNMATCHED_ALREADY_ADJUSTED_STATUS = 'UNMATCHED_ALREADY_ADJUSTED';
