export const POST_CODES = {
  AR: /[A-Z]{1}[0-9]{4}[A-Z]{3}/g,
  AT: /[0-9]{4}/g,
  AU: /[0-9]{4}/g,
  BE: /[0-9]{4}/g,
  BG: /[0-9]{4}/g,
  BR: /[0-9]{5}-[0-9]{3}/g,
  CA: /[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]/g,
  CH: /[0-9]{4}/g,
  CL: /[0-9]{7}/g,
  CO: /[0-9]{6}/g,
  CY: /[0-9]{4}/g,
  CZ: /[0-9]{3} [0-9]{2}/g,
  DE: /[0-9]{5}/g,
  DK: /[0-9]{5}/g,
  EE: /[0-9]{5}/g,
  ES: /[0-9]{5}/g,
  FI: /[0-9]{5}/g,
  FR: /[0-9]{5}/g,
  GB: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gim,
  GR: /[0-9]{3} [0-9]{2}/g,
  HR: /[0-9]{5}/g,
  HU: /[0-9]{4}/g,
  IE: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/g,
  IT: /[0-9]{5}/g,
  LT: /LT-[0-9]{5}/g,
  LU: /[0-9]{4}/g,
  LV: /LV-[0-9]{4}/g,
  MT: /[A-Z]{3} [0-9]{4}/g,
  NL: /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i,
  NO: /[0-9]{4}/g,
  PE: /[0-9]{5}/g,
  PL: /[0-9]{2}-[0-9]{3}/g,
  PT: /[0-9]{4}-[0-9]{3}/g,
  RO: /[0-9]{6}/g,
  SE: /[0-9]{3} [0-9]{2}/g,
  SI: /[0-9]{4}/g,
  SK: /[0-9]{3} [0-9]{2}/g,
  US: /^d{5}(?:[-s]d{4})?$/g,
};
