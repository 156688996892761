import { Input } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

const StyledInputCell = styled(Input)(
  ({ theme }) => `
    max-height: 32px;
    font-size: ${theme.typography.body.two.fontSize};
    line-height: ${theme.typography.body.two.lineHeight};
    padding: ${theme.spacings.byte};
  `
);

export default StyledInputCell;
