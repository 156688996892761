import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ChevronRight } from '@sumup/icons';
import { MODULE_PICKER_WIDTH } from 'variables';

export const ModulePickerWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    border-top: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    width: 100%;
    min-height: 48px;
    background: ${theme.colors.white};
    & > p {
      cursor: pointer;
      font-size: 0;
      ${theme.mq.giga} {
        font-size: initial;
      }
    }

    svg {
      color: ${theme.colors.n500};
    }
  `
);

export const ModulePickerModalWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: ${MODULE_PICKER_WIDTH}px;
  padding: 0;
`;

export const Modules = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    border-radius: 3px 0px 0px 3px;
    background: ${theme.colors.n100};
  `
);

export const Module = styled('div')(
  ({ theme, hovered }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;
    padding: 0px ${theme.spacings.kilo} 0px 20px;
    background: ${hovered ? theme.colors.white : theme.colors.n100};
    cursor: pointer;

    :first-of-type {
      border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    }
  `
);

export const Sections = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: ${theme.spacings.kilo};
  `
);

export const ArrowRight = styled(ChevronRight)(
  ({ theme }) => css`
    ${theme.mq.giga} {
      margin-left: ${theme.spacings.byte};
    }
  `
);
