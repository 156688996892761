import React from 'react';
import { Headline, Pagination } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Loading from 'components/Loading';
import FiltersPicker from '../AdjustAuthorizations/components/FiltersPicker';
import PageSizeDropdown from '../Account/components/PageSizeDropdown';
import { AUTHORIZATIONS_FOR_CANCELLATION } from './constants';
import AuthorizationsGrid from './components/CancelAuthorizationsGrid';
import SingleDayFilter from './components/SingleDayFilter';
import { DATE } from './fieldsConfig';
import { SORT_ORDER_DESCENDING } from '../../variables';

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Filters = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

export const AuthorizationsCount = styled('span')(
  ({ theme: { spacings, colors } }) => css`
    margin: 0 ${spacings.byte};
    color: ${colors.n500};
  `
);

export const StyledPagination = styled(Pagination)`
  padding-bottom: 0;
`;

export const CancelAuthorizations = ({
  loading,
  authorizationsForCancellation,
  columns,
  onPageSizeChange,
  size,
  totalPages,
  currentPage,
  onPageChange,
  filters,
  filtersApplied,
  applyFiltersEnabled,
  onFilterChange,
  onFiltersApply,
  onFiltersClear,
  date,
  onDateChange,
  sort,
  onSort,
}) => (
  <>
    <Header>
      <Headline noMargin as="h2" size="two">
        {AUTHORIZATIONS_FOR_CANCELLATION}
        {!isEmpty(authorizationsForCancellation) && (
          <AuthorizationsCount>
            ({authorizationsForCancellation.length})
          </AuthorizationsCount>
        )}
      </Headline>
      {loading && <Loading />}
      <Filters>
        <SingleDayFilter
          id="cancel-authorizations-day-filter"
          date={date}
          onDateChange={onDateChange}
        />
        <PageSizeDropdown size={size} onSizeChange={onPageSizeChange} />
        <FiltersPicker
          filters={filters}
          filtersApplied={filtersApplied}
          applyFiltersEnabled={applyFiltersEnabled}
          onFilterChange={onFilterChange}
          onFiltersApply={onFiltersApply}
          onFiltersClear={onFiltersClear}
          showCurrencyFilter
        />
      </Filters>
    </Header>
    <AuthorizationsGrid
      columns={columns}
      authorizationsForCancellation={authorizationsForCancellation}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
    <StyledPagination
      label="Pagination"
      previousLabel="Previous page"
      nextLabel="Next page"
      totalPages={totalPages}
      totalLabel={(totalPagesCount) => `of ${totalPagesCount}`}
      currentPage={currentPage}
      onChange={onPageChange}
    />
  </>
);

CancelAuthorizations.defaultProps = {
  sort: {
    [DATE.name]: SORT_ORDER_DESCENDING,
    fieldName: DATE.name,
  },
};

CancelAuthorizations.propTypes = {
  loading: PropTypes.bool.isRequired,
  authorizationsForCancellation: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
  applyFiltersEnabled: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  sort: PropTypes.object,
  onSort: PropTypes.func.isRequired,
};
