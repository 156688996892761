import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Popover, Spinner, useModal } from '@sumup/circuit-ui';
import { ChevronDown, ChevronUp } from '@sumup/icons';
import Status from 'components/Status';
import { ACTIONS, APPS, ACCOUNT_STATUS_MAP, POPOVER_DIVIDER } from 'variables';
import { CardStatusPopoverComponent } from 'components/Account/AccountStyled';
import { getStatusPopoverOptions } from 'components/Account/statusPopoverOptions';
import { AccountStatusModal } from './AccountStatusModal';

export const DATA_SELECTORS = {
  POPOVER_ARROW: 'POPOVER_ARROW',
  POPOVER_COMPONENT: 'POPOVER_COMPONENT',
};

const POPOVER_OPTIONS = [
  ACCOUNT_STATUS_MAP.ACTIVE,
  ACCOUNT_STATUS_MAP.BLOCKED,
  POPOVER_DIVIDER,
  ACCOUNT_STATUS_MAP.CLOSED,
];

const PopoverWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: auto;

    > div {
      min-width: 120px;
    }

    > span {
      margin-right: ${theme.spacings.mega};
    }
  `
);

const { accountcardOverviewBlockAccount, accountcardOverviewCloseAccount } =
  ACTIONS[APPS.merchant];

const AccountStatus = ({
  accountStatus,
  loading,
  hasActionAccess,
  onStatusChange,
}) => {
  const [accountStatusPopoverOpen, setAccountStatusPopoverOpen] =
    useState(false);

  const { setModal } = useModal();

  const handleAccountStatusChange = ({ status }) => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <AccountStatusModal
          status={status}
          onClose={onClose}
          onConfirm={onStatusChange}
        />
      ),
      closeButtonLabel: 'Close',
    });
  };

  const hasBlockAccountAccess = hasActionAccess({
    action: accountcardOverviewBlockAccount,
  });

  const hasCloseAccountAccess = hasActionAccess({
    action: accountcardOverviewCloseAccount,
  });

  const blockAccountPopoverComponent = () => {
    const handleClick = (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }

      setAccountStatusPopoverOpen(true);
    };

    const disabled =
      accountStatus === ACCOUNT_STATUS_MAP.CLOSED ||
      (!hasBlockAccountAccess && !hasCloseAccountAccess);

    return (
      <CardStatusPopoverComponent disabled={disabled} onClick={handleClick}>
        <Status value={accountStatus} />
        {!disabled &&
          (accountStatusPopoverOpen ? (
            <ChevronUp data-testid={DATA_SELECTORS.POPOVER_ARROW} />
          ) : (
            <ChevronDown data-testid={DATA_SELECTORS.POPOVER_ARROW} />
          ))}
      </CardStatusPopoverComponent>
    );
  };

  const ACCOUNT_STATUS_ACCESS = {
    [ACCOUNT_STATUS_MAP.ACTIVE]: hasBlockAccountAccess,
    [ACCOUNT_STATUS_MAP.BLOCKED]: hasBlockAccountAccess,
    [ACCOUNT_STATUS_MAP.CLOSED]: hasCloseAccountAccess,
  };

  const statusPopover = accountStatus ? (
    <Popover
      data-testid={DATA_SELECTORS.POPOVER_COMPONENT}
      isOpen={accountStatusPopoverOpen}
      placement="bottom"
      actions={getStatusPopoverOptions({
        statuses: POPOVER_OPTIONS,
        statusesMap: ACCOUNT_STATUS_MAP,
        onClick: handleAccountStatusChange,
        isOptionDisabled: (status) =>
          status === accountStatus || !ACCOUNT_STATUS_ACCESS[status],
      })}
      component={blockAccountPopoverComponent}
      onToggle={() => setAccountStatusPopoverOpen(!accountStatusPopoverOpen)}
    />
  ) : null;

  return (
    <PopoverWrapper>{loading ? <Spinner /> : statusPopover}</PopoverWrapper>
  );
};

AccountStatus.defaultProps = {
  accountStatus: null,
  loading: true,
};

AccountStatus.propTypes = {
  accountStatus: PropTypes.string,
  loading: PropTypes.bool,
  hasActionAccess: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default AccountStatus;
