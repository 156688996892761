import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Headline } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const ModalWrapper = styled('div')(
  ({ centered, width }) => css`
    display: flex;
    flex-direction: column;
    border-radius: 16px;

    ${width && `width: ${width}px;`}

    & > div {
      display: flex;
      align-items: center;
    }
    ${centered &&
    `
      align-items: center;
      text-align: center;
    `}
  `
);

const Header = styled('div')`
  max-width: 90%;
`;

const Body = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.spacings.mega} 0 ${theme.spacings.giga};

    > label {
      width: 100%;

      span {
        float: left;
      }
    }
  `
);

const Footer = styled('div')(
  ({ centered }) => css`
    ${centered &&
    `
      display: flex;
      justify-content: center;
    `}
  `
);

const Modal = ({
  modalInfo: {
    width,
    title,
    cancelText,
    confirmText,
    confirmButtonProps,
    confirmButtonStyles,
    content,
    centered,
    isLoading,
    disabled,
    closeOnConfirm = true,
    onConfirm,
  },
  onClose,
}) => (
  <ModalWrapper centered={centered} width={width}>
    <Header>
      <Headline as="h1" size="three" noMargin>
        {title}
      </Headline>
    </Header>
    <Body>{content}</Body>
    <Footer>
      <ButtonGroup
        css={confirmButtonStyles}
        actions={{
          primary: {
            children: confirmText,
            onClick: (e) => {
              if (onConfirm) {
                onConfirm(e);
              }
              if (closeOnConfirm) {
                onClose(e);
              }
            },
            variant: 'primary',
            isLoading,
            loadingLabel: 'Loading',
            disabled,
            ...confirmButtonProps,
          },
          secondary: {
            children: cancelText,
            onClick: onClose,
          },
        }}
      />
    </Footer>
  </ModalWrapper>
);

Modal.defaultProps = {
  onClose: () => {},
};

Modal.propTypes = {
  modalInfo: PropTypes.shape({
    width: PropTypes.number,
    title: PropTypes.string,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    confirmButtonProps: PropTypes.shape({
      destructive: PropTypes.bool,
    }),
    confirmButtonStyles: PropTypes.object,
    content: PropTypes.any,
    centered: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    closeOnConfirm: PropTypes.bool,
    onConfirm: PropTypes.func,
  }).isRequired,
  onClose: PropTypes.func,
};

export default Modal;
